import styled from 'styled-components';

import { ReactComponent as LocationIcon } from '../../assets/images/location.svg';

export const MapContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  a[href^="http://maps.google.com/maps"],
  a[href^="https://maps.google.com/maps"],
  a[href^="https://www.google.com/maps"]
  {
    display: none !important;
  }

  .gm-bundled-control .gmnoprint {
    display: block;
  }

  .gm-style-cc {
    display: none;
  }

  .poi-business {
    visibility: hidden;
  }
`;

export const StyledLocationIcon = styled(LocationIcon)`
  height: 36px;
  width: auto;

  transform: translate(-50%, -50%);
`;
