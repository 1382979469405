import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  /* Reset */
  html,body,div,span,applet,object,iframe,h1,h2,h3,h4,h5,h6,p,blockquote,pre,a,abbr,acronym,address,big,cite,code,del,dfn,em,img,ins,kbd,q,s,samp,small,strike,strong,sub,sup,tt,var,b,u,i,center,dl,dt,dd,ol,ul,li,fieldset,form,label,legend,table,caption,tbody,tfoot,thead,tr,th,td,article,aside,canvas,details,embed,figure,figcaption,footer,header,hgroup,menu,nav,output,ruby,section,summary,time,mark,audio,video{border:0;font-size:100%;font:inherit;vertical-align:baseline;margin:0;padding:0}article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,section{display:block}body{line-height:1}ol,ul{list-style:none}blockquote,q{quotes:none}blockquote:before,blockquote:after,q:before,q:after{content:none}table{border-collapse:collapse;border-spacing:0}

  :root {
    --white: #fff;
    --blue-50: #e8f8fc;
    --blue-300: #1ebde3;
    --blue-400: #0094ca;
    --blue-500: #005ead;
    --gray-500: #6d6d6f;
    --purple-50: #EFEFFF;
    --purple-500: #aea4d0;
    --purple-700: #8E4AFF;
    --yellow-50: #FFFAEC;
    --yellow-500: #FFCC43;
    --red-50: #FFF2EE;
    --red-300: #FF8157;
    --green-50: #EAFAED;
    --green-300: #31CC50;
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  .Toastify {
    position: relative;
    z-index: 99999;
  }

  body, input, button, textarea {
    font-family: 'SF Pro Text', 'Segoe UI', sans-serif;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    color: var(--gray-500);
  }

  button {
    cursor: pointer;
  }

  html {
    @media (max-width: 1200px) {
      font-size: 93.75%;
    }

    @media (max-width: 1000px) {
      font-size: 87.5%;
    }

    @media (max-width: 800px) {
      font-size: 81.25%;
    }

    @media (max-width: 600px) {
      font-size: 75%;
    }
  }
`;
