import { useEffect, useState } from 'react';
import styled from 'styled-components';

import { toast } from 'react-toastify';
import { ReactComponent as DeleteIcon } from '../../assets/images/delete.svg';

import { api } from '../../services/api';
import { ITherapistPoint } from '../../types';

import { Dropdown } from '../Dropdown';
import { IAddressFormData } from '../User/AddressForm';

import { AgendaTimesForm, IAgendaTimesFormProps } from './AgendaTimesForm';

interface IPoolAgendaFormProps extends IAgendaTimesFormProps {
  onAddAddress: () => void;
  onChangeAddress: (index: number, addressId: string) => void;
  onRemoveAddress: (index: number) => void;
}

function formatAddress(address: IAddressFormData, radius?: string | number) {
  const {
    street, number, district, city, uf, zip,
  } = address;
  if (!street) {
    return '';
  }

  return `${
    radius ? `Raio de ${radius}km - ` : ''
  } ${street}, ${number} - ${district} - ${city} - ${uf} - ${zip}`;
}

export function PoolAgendaForm({
  scheduleFormData,
  onValueChange,
  onAddAddress,
  onChangeAddress,
  onRemoveAddress,
  ...rest
}: IPoolAgendaFormProps) {
  const [therapistPoints, setTherapistPoints] = useState<ITherapistPoint[]>([]);
  const [shouldConsiderRadius, setShouldConsiderRadius] = useState(
    true,
  );

  /* const fetchTherapistPoints = async () => {
    try {
      const response = await api.get<ITherapistPoint[]>('/therapist/point');

      setTherapistPoints(response.data);
    } catch (error: any) {
      toast.error(
        error?.response?.data?.message
          || 'Error ao buscar os pontos de atendimento do terapeuta, tente novamente mais tarde',
      );
    }
  };

  useEffect(() => {
    fetchTherapistPoints();
  }, []); */

  const frequencyOptions = [
    {
      label: 'Recorrente',
      value: 'recurrent',
    },
    {
      label: 'Especifico',
      value: 'specific',
    },
  ];

  const handleToggleConsiderRadius = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const { checked } = event.target;
    setShouldConsiderRadius(checked);
  };

  const therapistPointOptions = therapistPoints.map((point) => ({
    label: formatAddress(point, point.radius),
    value: point.id_address,
  }));

  return (
    <Container>
      {/* <CheckboxLabel>
        <input
          type="checkbox"
          checked={shouldConsiderRadius}
          onChange={handleToggleConsiderRadius}
        />
        <span>Adicionar raio de atendimento</span>
      </CheckboxLabel>
       scheduleFormData.addresses.map((addressId, index) => (
        <IconInputLabel key={`${addressId}${index.toString()}`}>
          <span>{`Ponto de atendimento ${index + 1}`}</span>
          <Dropdown
            onChange={(value) => onChangeAddress(index, value)}
            value={addressId}
            options={therapistPointOptions}
            placeholder="Selecione um ponto de atendimento"
            canAddNewValue={false}
          />
          {index > 0 && (
            <DeleteAddressButton
              type="button"
              onClick={() => onRemoveAddress(index)}
            >
              <DeleteIcon />
            </DeleteAddressButton>
          )}
        </IconInputLabel>
      ))
      <div>
        <TextButton type="button" onClick={onAddAddress}>
          Adicionar novo ponto de atendimento
        </TextButton>
      </div> */}
      <Dropdown
        onChange={(value) => onValueChange(value, 'frequency')}
        value={scheduleFormData.frequency}
        options={frequencyOptions}
        placeholder="Selecione a frequência"
        label="Frequência"
        customClassName="agenda-form__dropdown"
      />
      <AgendaTimesForm
        scheduleFormData={scheduleFormData}
        onValueChange={onValueChange}
        {...rest}
      />
    </Container>
  );
}

const Container = styled.div`
  input {
    font-size: 1rem;
  }

  > * {
    max-width: 30rem;
    margin: 0 auto;
    margin-top: 2rem;
  }
`;

const CheckboxLabel = styled.label`
  width: fit-content;

  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;

  margin: 0 auto;

  color: var(--blue-400);

  > input[type="checkbox"] {
    color: var(--blue-400);
  }
`;

const TextButton = styled.button`
  background: transparent;
  border: none;

  display: block;

  color: var(--blue-400);

  margin-left: auto;

  transition: filter 0.2s;

  &:hover {
    filter: brightness(0.8);
  }
`;

const IconInputLabel = styled.label`
  display: flex;

  position: relative;

  flex-direction: column;

  span {
    padding-left: 0.75rem;
    margin-bottom: 0.375rem;
  }
`;

const DeleteAddressButton = styled.button`
  position: absolute;
  bottom: 0;
  left: -3rem;

  height: 3rem;
  width: 3rem;

  background: transparent;
  border: none;

  svg {
    width: 1rem;
    height: 1rem;

    path {
      fill: var(--gray-500);
    }
  }
`;
