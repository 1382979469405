import { useState } from 'react';

import { MdSpaceDashboard } from 'react-icons/md';
import { ReactComponent as HamburgerIcon } from '../../assets/images/hamburger.svg';
import { ReactComponent as AddUserIcon } from '../../assets/images/navbar-icons/add-user.svg';
import { ReactComponent as CalendarIcon } from '../../assets/images/calendar.svg';
import { ReactComponent as DoarcareIcon } from '../../assets/images/navbar-icons/doarcare.svg';
import { ReactComponent as GroupIcon } from '../../assets/images/navbar-icons/group.svg';
import { ReactComponent as InfoCircleIcon } from '../../assets/images/navbar-icons/info-circle.svg';
import { ReactComponent as PaperIcon } from '../../assets/images/navbar-icons/paper.svg';
import { ReactComponent as PatientsIcon } from '../../assets/images/navbar-icons/patients.svg';
import { ReactComponent as PlusIcon } from '../../assets/images/navbar-icons/plus.svg';
import { ReactComponent as ProfileIcon } from '../../assets/images/navbar-icons/profile.svg';
import { ReactComponent as TicketStarIcon } from '../../assets/images/navbar-icons/ticket-star.svg';
import { ReactComponent as UnlockIcon } from '../../assets/images/navbar-icons/unlock.svg';
import { useAuth } from '../../hooks/useAuth';

import {
  Container,
  HamburgerButton,
  NavBarLink,
  NavBarTitle,
  NavList,
} from './styles';

export default function NavBar() {
  const { data } = useAuth();
  const [isExpanded, setIsExpanded] = useState(false);

  const handleToggle = () => {
    setIsExpanded((prevState) => !prevState);
  };

  if (data && data?.user?.role === 'therapist') {
    return (
      <Container isExpanded={isExpanded}>
        <NavList isExpanded={isExpanded} aria-hidden={!isExpanded}>
          <NavBarLink to="/dashboard">
            <DoarcareIcon />
            <NavBarTitle>Doar Care</NavBarTitle>
          </NavBarLink>
          <NavBarLink to="/agenda">
            <CalendarIcon />
            <NavBarTitle>Agenda</NavBarTitle>
          </NavBarLink>
          <NavBarLink to="/perfil" className="navbar__profile-link">
            <ProfileIcon />
            <NavBarTitle>Meu perfil</NavBarTitle>
          </NavBarLink>
        </NavList>
        <HamburgerButton type="button" onClick={handleToggle}>
          <HamburgerIcon />
          <NavBarTitle>Fechar</NavBarTitle>
        </HamburgerButton>
      </Container>
    );
  }

  return (
    <Container isExpanded={isExpanded}>
      <NavList isExpanded={isExpanded} aria-hidden={!isExpanded}>
        <NavBarLink to="/usuarios">
          <DoarcareIcon />
          <NavBarTitle>Doar Care</NavBarTitle>
        </NavBarLink>
        <NavBarLink to="/usuarios">
          <AddUserIcon />
          <NavBarTitle>Usuários</NavBarTitle>
        </NavBarLink>
        <NavBarLink to="/servicos">
          <PlusIcon />
          <NavBarTitle>Serviços</NavBarTitle>
        </NavBarLink>
        <NavBarLink to="/parceiros">
          <GroupIcon />
          <NavBarTitle>Parceiros</NavBarTitle>
        </NavBarLink>
        <NavBarLink to="/vouchers">
          <TicketStarIcon />
          <NavBarTitle>Vouchers</NavBarTitle>
        </NavBarLink>
        <NavBarLink to="/anamneses">
          <InfoCircleIcon />
          <NavBarTitle>Anamneses</NavBarTitle>
        </NavBarLink>
        <NavBarLink to="/acesso">
          <UnlockIcon />
          <NavBarTitle>Perfil de acesso</NavBarTitle>
        </NavBarLink>
        <NavBarLink to="/clientes">
          <PatientsIcon />
          <NavBarTitle>Clientes</NavBarTitle>
        </NavBarLink>
        <NavBarLink to="/relatorios">
          <PaperIcon />
          <NavBarTitle>Relatórios</NavBarTitle>
        </NavBarLink>
      </NavList>
      <HamburgerButton type="button" onClick={handleToggle}>
        <HamburgerIcon />
        <NavBarTitle>Fechar</NavBarTitle>
      </HamburgerButton>
    </Container>
  );
}
