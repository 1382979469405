import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import endOfDay from 'date-fns/endOfDay';
import parseISO from 'date-fns/parseISO';
import format from 'date-fns/format';
import parse from 'date-fns/parse';

import { useNotificationModal } from '../../../hooks/useNotificationModal';

import { api } from '../../../services/api';
import { IVoucher } from '../../../types';
import { EditForm } from '../../EditForm';

import { VoucherFormFields, initialVoucherData } from '../VoucherFormFields';
import { FieldsContainer } from './styles';

export default function EditVoucherForm() {
  const [editVoucherFormData, setEditVoucherFormData] = useState(initialVoucherData);
  const { notificate } = useNotificationModal();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const { voucherId } = useParams<{
    voucherId: string;
  }>();

  useEffect(() => {
    fetchVoucherInfo();
  }, [voucherId]);

  const fetchVoucherInfo = async () => {
    try {
      const response = await api.get<IVoucher>(`/voucher/${voucherId}`);

      const foundVoucher = response.data;

      setEditVoucherFormData((prevState) => ({
        ...prevState,
        name: foundVoucher.name,
        created_at: format(parseISO(foundVoucher.created_at), 'yyyy-MM-dd'),
        expiration: format(parseISO(foundVoucher.expiration), 'yyyy-MM-dd'),
        quantity: foundVoucher.quantity,
        discount: foundVoucher.discount,
        cod: foundVoucher.cod,
      }));
      setIsLoading(false);
    } catch (error: any) {
      toast.error(error?.response?.data?.message || error?.message || 'Algo deu errado');
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    setEditVoucherFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleGoBack = () => {
    history.goBack();
  };

  const handleSuccess = () => {
    setEditVoucherFormData(initialVoucherData);
    history.push('/vouchers');
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    try {
      const dataToSubmit = {
        voucherId,
        ...editVoucherFormData,
        discount: Number(editVoucherFormData.discount),
        quantity: Number(editVoucherFormData.quantity),
        expiration: endOfDay(parse(editVoucherFormData.expiration, 'yyyy-MM-dd', new Date())).toISOString(),
        created_at: undefined,
      };

      await api.put('/voucher', dataToSubmit);

      notificate('Salvo com sucesso!', handleSuccess);
    } catch (error: any) {
      toast.error(error?.response?.data?.message || error?.message || 'Algo deu errado');
    }
  };

  const handleDeleteVoucher = async () => {
    try {
      await api.delete(`/voucher/${voucherId}`);

      history.push('/vouchers');
    } catch (error: any) {
      toast.error(error?.response?.data?.message || error?.message || 'Algo deu errado');
    }
  };

  return (
    <EditForm
      title="Editar voucher"
      onArrowClick={handleGoBack}
      onSubmit={handleSubmit}
      onDeleteClick={handleDeleteVoucher}
    >
      <FieldsContainer>
        <h1>Dados do serviço</h1>
        <VoucherFormFields
          data={editVoucherFormData}
          onInputChange={handleInputChange}
        />
      </FieldsContainer>
    </EditForm>
  );
}
