import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { api } from '../../services/api';
import { ITotalDashboard } from '../../types/Dashboard';
import { formatCurrencyInCents } from '../../utils/formatCurrencyInCents';
import { Table, TableWrapper } from './styles';

interface TotalDashboardProps {
  data?: ITotalDashboard
}

const TotalDashboard = ({ data }: TotalDashboardProps) => (
  <TableWrapper>
    <Table>
      <thead>
        <tr>
          <th>Agendados</th>
          <th>Pendentes</th>
          <th>Executados</th>
          <th>Cancelados</th>
          <th>Voucher</th>
          <th>Faturamento</th>
          <th>Comissão</th>
          <th>Saldo</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{data?.accepteds}</td>
          <td>{data?.pending}</td>
          <td>{data?.dones}</td>
          <td>{data?.canceleds}</td>
          <td>{formatCurrencyInCents(data?.total_vouchers || 0)}</td>
          <td>{formatCurrencyInCents(data?.total_billing || 0)}</td>
          <td>{formatCurrencyInCents(data?.total_comission || 0)}</td>
          <td>{formatCurrencyInCents(data?.balance || 0)}</td>
        </tr>
      </tbody>
    </Table>
  </TableWrapper>
);

export default TotalDashboard;
