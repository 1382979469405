import styled from 'styled-components';

import { ReactComponent as ArrowBackIcon } from '../../assets/images/arrow-back.svg';

interface IGoBackLocationProps {
  title: string;
  onArrowClick: () => void;
  onTitleClick?: () => void;
  className?: string;
  arrowColor?: string;
  titleColor?: string;
}

export default function GoBackLocation({
  title, onArrowClick, onTitleClick, className = '', arrowColor = 'var(--blue-400)', titleColor = 'var(--gray-500)',
}: IGoBackLocationProps) {
  return (
    <Container
      className={className}
      arrowColor={arrowColor}
      titleColor={titleColor}
    >
      <button type="button" className="go-back__arrow-button" onClick={onArrowClick}>
        <ArrowBackIcon />
      </button>
      {
        onTitleClick ? (
          <button type="button" className="go-back__title" onClick={onTitleClick}>{title}</button>
        ) : (
          <span className="go-back__title">{title}</span>
        )
      }
    </Container>
  );
}

const Container = styled.div<{
  arrowColor: string;
  titleColor: string;
}>`
  display: flex;
  align-items: center;

  button {
    background-color: transparent;
    border: none;

    transition: filter 0.2s;

    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      filter: brightness(0.9);
    }
  }

  .go-back__arrow-button {
    svg {
      width: 1rem;
      height: 1rem;

      margin-right: 0.75rem;
      vertical-align: middle;

      path {
        fill: ${({ arrowColor }) => arrowColor};
      }
    }
  }

  .go-back__title {
    color: ${({ titleColor }) => titleColor};
    font-size: 1.25rem;
    font-weight: 500;
  }

`;
