import { PrivateRoute } from './PrivateRoute';

export function AdminRoute({ component: Component, ...rest }: any) {
  return (
    <PrivateRoute
      component={Component}
      allowedRoles={['admnistrator', 'master']}
      {...rest}
    />
  );
}
