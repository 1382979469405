import styled, { css } from 'styled-components';

interface IButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode;
  variant?: 'contained' | 'outlined'
}

export default function Button({ variant = 'contained', children, ...rest }: IButtonProps) {
  return (
    <StyledButton {...rest} variant={variant}>
      {children}
    </StyledButton>
  );
}

const StyledButton = styled.button<{
  variant: 'contained' | 'outlined';
}>`
  border: none;

  background-color: var(--blue-400);
  border: 1px solid var(--blue-400);
  color: var(--white);

  width: 100%;
  border-radius: 0.5em;
  padding: 0.875em;

  display: flex;
  align-items: center;
  justify-content: center;

  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);

  transition: filter 0.2s;

  &:hover {
    filter: brightness(0.9);
  }

  ${({ variant }) => variant === 'outlined' && css`
    background-color: transparent;
    color: var(--blue-400);
    box-shadow: unset;
  `}

  &:disabled {
    filter: brightness(0.6);

    cursor: default;

    &:hover {
      filter: brightness(0.6);
    }
  }

`;
