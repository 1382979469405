import styled, { css } from 'styled-components';

import SelectArrow from '../../assets/images/select-arrow.svg';

export const Container = styled.div`
  .date-period-dropdown__datepicker {
    svg {
      path {
        fill: var(--gray-500);
      }
    }

    input {
      width: 100%;
    }
  }
`;

export const DropdownContainer = styled.div<{
  isExpanded: boolean;
}>`
  background: #fff;
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  min-height: 3rem;
  padding-left: 1rem;
  font-size: 1rem;
  border-radius: ${({ isExpanded }) => (isExpanded ? '0.5rem 0.5rem 0 0' : '0.5rem')};

  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);

  &::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 1em;
    width: 1em;
    height: 0.75em;

    overflow: visible;

    background-repeat: no-repeat;
    background-size: contain;

    pointer-events: none;

    transition: transform 0.3s ease 0s;

    transform: ${({ isExpanded }) => (isExpanded
    ? 'translateY(-50%) rotate(-180deg)'
    : 'translateY(-50%) rotate(0deg)')};

    background-image: url(${SelectArrow});
  }
`;

export const OptionsWrapper = styled.div<{
  isExpanded: boolean;
}>`
  position: absolute;
  top: 50%;
  left: 0px;
  right: 0px;
  z-index: 1;
  height: 0px;
  opacity: 0;
  visibility: hidden;

  background: #fff;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16), 0px 3px 6px rgba(0, 0, 0, 0.16);
  border-radius: 0 0 0.5rem 0.5rem;

  display: flex;
  flex-direction: column;
  padding-top: 1rem;

  transition: all 0.3s ease 0s;

  ${({ isExpanded }) => isExpanded
    && css`
      height: unset;
      opacity: 1;
      top: 100%;
      visibility: visible;
    `}
`;

export const OptionsContainer = styled.div`
  max-height: 300px;
  overflow-y: auto;

  margin: 0 1rem 1rem;

  scrollbar-width: thin;
  scrollbar-color: var(--blue-400) var(--blue-50);

  &::-webkit-scrollbar {
    width: 0.4rem;
    height: 0.4rem;
  }

  &::-webkit-scrollbar-track {
    border-radius: 1.25rem;
    background-color: var(--blue-50);
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 1.25rem;
    background-color: var(--blue-400);
  }
`;

export const Option = styled.button`
  display: block;
  background: transparent;
  border: none;

  padding: 0.375em 0.5em;
  width: 100%;

  font-size: inherit;

  text-align: left;
`;

export const IntervalsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 0.75em 0.5em;
  margin-top: 0.25rem;

  border-top: 1px solid var(--gray-500);
`;

export const StyledLabel = styled.label`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;

  width: 100%;

  font-size: inherit;

  text-align: left;
`;
