import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { toast } from 'react-toastify';
import { useNotificationModal } from '../../../hooks/useNotificationModal';

import { IServiceFormData, ServiceFormFields } from '../ServiceFormFields';
import { EditForm } from '../../EditForm';

import { Container, FieldsContainer } from './styles';
import { api } from '../../../services/api';

const initialFormData = {
  id: '',
  name: '',
  description: '',
  price: '',
  logo: null,
  logoUrl: '',
  img_url: '',
};

export default function EditServiceForm() {
  const history = useHistory();
  const [editServiceFormData, setEditServiceFormData] = useState<IServiceFormData>(initialFormData);
  const { notificate } = useNotificationModal();
  const [isLoading, setIsLoading] = useState(true);
  const { serviceId } = useParams<{
    serviceId: string;
  }>();

  useEffect(() => {
    fetchServiceInfo();
  }, [serviceId]);

  const fetchServiceInfo = async () => {
    try {
      const response = await api.get(`/service/${serviceId}`);
      setEditServiceFormData({
        ...response.data,
        price: response.data.price / 100,
      });
      setIsLoading(false);
    } catch (error: any) {
      toast.error(error?.response?.data?.message || error?.message || 'Algo deu errado');
    }
  };

  const handleDelete = async () => {
    try {
      await api.delete(`/service/${serviceId}`);
      toast.success('Serviço excluído com sucesso!');
      history.push('/servicos');
    } catch (error: any) {
      toast.error(error?.response?.data?.message || error?.message || 'Algo deu errado');
    }
  };

  const handleGoBack = () => {
    history.goBack();
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setEditServiceFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleLogoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const logo = event.target.files?.[0];

    if (!logo) return;

    setEditServiceFormData((prevState) => ({
      ...prevState,
      logo,
      img_url: URL.createObjectURL(logo),
    }));
  };

  const handleSuccess = () => {
    setEditServiceFormData(initialFormData);
    history.goBack();
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    const { name, price, description } = editServiceFormData;

    const dataToSubmit = {
      serviceId,
      name,
      description,
      price: Number(price) * 100,
    };

    const formDataToSubmit = new FormData();

    Object.entries(dataToSubmit).forEach(([key, value]) => {
      if (value) {
        formDataToSubmit.append(key, value.toString());
      }
    });

    if (editServiceFormData.logo) {
      formDataToSubmit.append('img', editServiceFormData.logo);
    }

    try {
      await api.put('/service', formDataToSubmit, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      notificate('Salvo com sucesso!', handleSuccess);
    } catch (error: any) {
      toast.error(error?.response?.data?.message || error?.message || 'Algo deu errado');
    }
  };

  if (isLoading) {
    return null;
  }

  return (
    <Container>
      <EditForm
        title="Editar serviço"
        onArrowClick={handleGoBack}
        onSubmit={handleSubmit}
        onDeleteClick={handleDelete}
      >
        <FieldsContainer>
          <h1>Dados do serviço</h1>
          <ServiceFormFields
            data={editServiceFormData}
            onInputChange={handleInputChange}
            onLogoChange={handleLogoChange}
          />
        </FieldsContainer>
      </EditForm>
    </Container>
  );
}
