import styled from 'styled-components';

import { ReactComponent as LoadingIcon } from '../../assets/images/loading.svg';

export default function LoadingSpinner({ sizeInRem = 2 }: { sizeInRem?: number }) {
  return (
    <StyledLoadingIcon
      sizeInRem={sizeInRem}
    />
  );
}

const StyledLoadingIcon = styled(LoadingIcon)<{
  sizeInRem: number;
}>`
  width: ${({ sizeInRem }) => sizeInRem}rem;
  height: ${({ sizeInRem }) => sizeInRem}rem;

  vertical-align: middle;
  align-self: center;

  animation: loading 1s linear infinite;

  @keyframes loading {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
`;
