import styled, { css } from 'styled-components';

export const ReportsListWrapper = styled.div`
  max-height: calc(100vh - 7rem);

  width: calc(95% + 2rem);
  max-width: 97vw;

  padding-right: 2rem;

  overflow-y: auto;

  scrollbar-width: thin;
  scrollbar-color: var(--blue-400) #e5e5ea;

  &::-webkit-scrollbar {
    width: 0.5rem;
    height: 0.5rem;
  }

  &::-webkit-scrollbar-track {
    background-color: #e5e5ea;
    border-radius: 1rem;
    width: 1rem;
    height: 100%;
  }

  &::-webkit-scrollbar-thumb {
    width: 0.25rem;
    border-radius: 1rem;
    background-color: var(--blue-400);
  }
`;

export const ReportsList = styled.ul`
  list-style: none;
  padding: 0;

  > * {
    padding: 1rem 2rem;
    color: var(--gray-500);

    &:nth-child(2n + 1) {
      background-color: var(--purple-50);
    }

    &:first-child {
      border-top-left-radius: 0.5rem;
      border-top-right-radius: 0.5rem;
    }

    &:last-child {
      border-bottom-left-radius: 0.5rem;
      border-bottom-right-radius: 0.5rem;
    }
  }

  .bg-transparent {
    background-color: transparent;
  }
`;

export const ReportItem = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;

  line-height: 1.5;
`;

export const ReportItemSection = styled.div<{
  hasBorderTop?: boolean;
}>`
  display: flex;

  justify-content: space-between;

  width: 100%;

  ${({ hasBorderTop }) => hasBorderTop && css`
    padding-top: 1rem;
    margin-top: 1rem;
    border-top: 1px solid #70707050;
  `}


`;

export const ReportItemSubsection = styled.div<{
  flexAlignItems?: string;
}>`
  display: flex;
  flex-direction: column;
  ${({ flexAlignItems }) => flexAlignItems && css`
    align-items: ${flexAlignItems};
  `}

`;

export const ReportItemButton = styled.button`
  background: transparent;
  outline: none;
  border: none;

  color: inherit;
  font-size: inherit;
`;
