import format from 'date-fns/format';
import { Input, SuffixInput } from '../../Input';

export const initialVoucherData:IVoucherFormData = {
  name: '',
  created_at: '',
  expiration: new Date().toISOString().split('T')[0],
  quantity: '',
  discount: '',
  cod: '',
};

export interface IVoucherFormData {
  id?: string;
  name: string;
  created_at: string;
  expiration: string;
  quantity: number | string;
  discount: number | string;
  cod: string;
}

interface IVoucherFormFieldsProps {
  data: IVoucherFormData;
  onInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export default function VoucherFormFields({ data, onInputChange }: IVoucherFormFieldsProps) {
  return (
    <>
      <Input
        name="name"
        type="text"
        label="Nome do voucher:"
        placeholder="Insira o nome do voucher"
        onChange={onInputChange}
        value={data.name}
        required
      />
      <Input
        name="cod"
        type="text"
        label="Código do voucher:"
        placeholder="Insira o código do voucher"
        onChange={onInputChange}
        value={data.cod}
        required
      />
      <Input
        label="Data de criação:"
        name="created_at"
        type="date"
        onChange={onInputChange}
        value={data.created_at || format(new Date(), 'yyyy-MM-dd')}
        readOnly
      />
      <Input
        label="Data de vencimento:"
        type="date"
        name="expiration"
        onChange={onInputChange}
        value={data.expiration}
        min={new Date().toISOString().split('T')[0]}
        required
      />
      <Input
        type="number"
        label="Quantidade de vouchers:"
        name="quantity"
        placeholder="Insira a quantidade de vouchers"
        onChange={onInputChange}
        value={data.quantity}
        min="0"
        required
      />
      <SuffixInput
        id="discount"
        label="Desconto:"
        suffix="%"
        type="number"
        name="discount"
        placeholder="Insira a % de desconto"
        onChange={onInputChange}
        value={data.discount}
        min="0"
        max="100"
        required
      />
    </>
  );
}
