import {
  ForwardedRef,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';

import { cpf } from 'cpf-cnpj-validator';

import { toast } from 'react-toastify';
import { Input } from '../Input';

import { ReactComponent as PartnersIcon } from '../../assets/images/navbar-icons/group.svg';
import ProfilePlaceholder from '../../assets/images/profile-placeholder.png';
import { ReactComponent as WalletIcon } from '../../assets/images/wallet.svg';

import { IAppFormElement, UserRoles } from '../../types';
import { maskCpfOrCnpj } from '../../utils/maskCpfOrCnpj';
import { maskPhone } from '../../utils/maskPhone';
import { ImageUploadBox } from '../ImageUploadBox';
import {
  FormContainer,
  IconButtonContent,
  IconLinkButton,
  LinkButtonsContainer,
  TextAreaContainer,
  TherapistInformationContainer,
} from './styles';
import { LinkPartnersModal, IConfirmData as IConfirmPartnersData } from '../LinkPartnersModal';
import { LinkPatientsModal, IConfirmData as IConfirmPatientsData } from '../LinkPatientsModal';
import { useAuth } from '../../hooks/useAuth';

export interface IPersonalDetailsFormData {
  name: string;
  email: string;
  phone: string;
  born: string;
  languages: string;
  password?: string;
  confirm_password?: string;
  avatar_url?: string;
  cpf?: string;
  img_url?: string;
  formation?: string;
  aditionalCertificates?: string;
  created_at?: string;
  video?: string;
}

export const initialPersonalDetails: IPersonalDetailsFormData = {
  name: '',
  email: '',
  phone: '',
  born: '',
  languages: '',
  password: '',
  confirm_password: '',
  video: '',
  img_url: '',
};

interface IPersonalDetailsFormProps {
  initialData: IPersonalDetailsFormData;
  onSubmit: (data: IPersonalDetailsFormData) => void;
  title?: string;
  userType?: UserRoles;
  onAvatarUpload?: (file: File) => void;
  isEditing?: boolean;
  isEditingSelf?: boolean;
  shouldHidePasswordFields?: boolean;
  partnerIds?: string[];
  onConfirmLinkPartners?: (confirmData: IConfirmPartnersData) => void;
  patientIds?: string[];
  onConfirmLinkPatients?: (confirmData: IConfirmPatientsData) => void;
}

export const PersonalDetailsForm = forwardRef(
  (
    {
      initialData,
      onSubmit,
      title,
      userType = 'therapist',
      onAvatarUpload = () => {},
      isEditing = false,
      isEditingSelf = false,
      shouldHidePasswordFields = false,
      partnerIds = [],
      patientIds = [],
      onConfirmLinkPartners = () => {},
      onConfirmLinkPatients = () => {},
    }: IPersonalDetailsFormProps,
    ref: ForwardedRef<IAppFormElement>,
  ) => {
    const { data } = useAuth();
    const [formData, setFormData] = useState(initialData);
    const [avatar, setAvatar] = useState<File | null>(null);
    const [isPartnersModalOpen, setIsPartnersModalOpen] = useState(false);
    const [isPatientsModalOpen, setIsPatientsModalOpen] = useState(false);

    const handleInputChange = (
      event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
      const { name, value } = event.target;

      let newValue = value;

      if (name === 'phone') {
        newValue = maskPhone(value || '');
      } else if (name === 'cpf') {
        newValue = maskCpfOrCnpj(value || '');
      }

      setFormData((prevState) => ({
        ...prevState,
        [name]: newValue,
      }));
    };

    useImperativeHandle(ref, () => ({
      requestSubmit: () => {
        if (formData.password !== formData.confirm_password) {
          toast.error('As senhas não conferem');
          return;
        }

        if (formData.cpf && !cpf.isValid(formData.cpf)) {
          toast.error('CPF inválido');
          return;
        }

        onSubmit({
          ...formData,
          phone: formData.phone ? formData.phone.replace(/\D/g, '') : '',
        });
      },
      getFormData: () => ({
        ...formData,
        phone: formData.phone ? formData.phone.replace(/\D/g, '') : '',
      }),
    }));

    useEffect(() => {
      setFormData(initialData);
    }, [initialData]);

    const handleAvatarChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const { files } = event.target;
      if (files && files.length > 0) {
        setAvatar(files[0]);
        onAvatarUpload(files[0]);
        setFormData((prevState) => ({
          ...prevState,
          img_url: URL.createObjectURL(files[0]),
        }));
      }
    };

    return (
      <FormContainer>
        {title && <h1>{title}</h1>}
        <Input
          type="email"
          name="email"
          label="E-mail:"
          placeholder="Insira o e-mail"
          value={formData?.email || ''}
          onChange={handleInputChange}
          required
        />
        {(userType === 'therapist') && (
          <TherapistInformationContainer>
            {isEditing && (
            <LinkButtonsContainer>
              <IconLinkButton
                type="button"
                variant="outlined"
                onClick={() => setIsPatientsModalOpen(true)}
              >
                <IconButtonContent>
                  <WalletIcon />
                  <span>Carteira de clientes</span>
                </IconButtonContent>
              </IconLinkButton>
              <IconLinkButton
                type="button"
                variant="outlined"
                onClick={() => setIsPartnersModalOpen(true)}
              >
                <IconButtonContent>
                  <PartnersIcon />
                  <span>Parceiros ativos</span>
                </IconButtonContent>
              </IconLinkButton>
            </LinkButtonsContainer>
            )}
            <ImageUploadBox
              id="img_url"
              label={
                formData.img_url
                  ? 'Altere a foto de perfil'
                  : 'Insira a foto de perfil'
              }
              imgUrl={formData?.img_url || ''}
              placeholderUrl={ProfilePlaceholder}
              onChange={handleAvatarChange}
              customClassName="grid-row-span-2 avatar-upload-box"
            />
          </TherapistInformationContainer>
        )}
        <Input
          type="text"
          name="name"
          label="Nome completo:"
          placeholder="Insira o nome completo"
          value={formData?.name || ''}
          onChange={handleInputChange}
          required
        />
        {userType !== 'admnistrator' && (
          <Input
            type="text"
            name="phone"
            label="Telefone:"
            placeholder="Insira o telefone"
            value={formData?.phone || ''}
            onChange={handleInputChange}
            maxLength={14}
            required={!isEditing}
          />
        )}
        {userType !== 'therapist' && (
          <Input
            type="text"
            name="cpf"
            label="CPF:"
            placeholder="Insira o CPF"
            value={formData?.cpf || ''}
            onChange={handleInputChange}
            maxLength={14}
            required
          />
        )}
        <Input
          type="text"
          label="Data de cadastro:"
          value={
            formData?.created_at
              ? new Date(formData?.created_at).toLocaleDateString('pt-BR', {
                timeZone: 'UTC',
              })
              : new Date().toLocaleDateString()
          }
          required
          readOnly
        />
        {userType !== 'admnistrator' && (
          <Input
            type="date"
            name="born"
            label="Data de nascimento:"
            placeholder="Insira a data de nascimento"
            value={formData?.born || ''}
            onChange={handleInputChange}
            required
          />
        )}
        {userType === 'therapist' && (
          <Input
            type="text"
            name="languages"
            label="Idiomas:"
            placeholder="Insira os idiomas aceitos"
            value={formData?.languages || ''}
            onChange={handleInputChange}
            required={!isEditing}
          />
        )}
        {shouldHidePasswordFields ? null : (
          <>
            <Input
              type="password"
              name="password"
              label="Senha:"
              placeholder="Insira a senha"
              minLength={8}
              value={formData?.password || ''}
              onChange={handleInputChange}
              required={!isEditing}
              pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$"
              title="A senha deve conter pelo menos 8 caracteres, sendo pelo menos uma letra maiúscula, uma minúscula, um número e um caractere especial"
            />
            <Input
              type="password"
              name="confirm_password"
              label="Confirmar senha:"
              placeholder="Confirme a senha"
              minLength={8}
              value={formData?.confirm_password || ''}
              onChange={handleInputChange}
              required={!isEditing}
              pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$"
              title="A senha deve conter pelo menos 8 caracteres, sendo pelo menos uma letra maiúscula, uma minúscula, um número e um caractere especial"
            />
          </>
        )}
        {userType === 'therapist' && (
          <>
            <Input
              type="text"
              name="video"
              label="Vídeo:"
              placeholder="Insira o link do vídeo"
              value={formData?.video || ''}
              onChange={handleInputChange}
            />
            <TextAreaContainer>
              <label htmlFor="formation">Formação acadêmica</label>
              <textarea
                name="formation"
                id="formation"
                value={formData?.formation || ''}
                onChange={handleInputChange}
                placeholder="Insira a formação acadêmica"
                required
              />
            </TextAreaContainer>
            <TextAreaContainer>
              <label htmlFor="aditionalCertificates">
                Certificados adicionais
              </label>
              <textarea
                name="aditionalCertificates"
                id="aditionalCertificates"
                value={formData?.aditionalCertificates || ''}
                onChange={handleInputChange}
                placeholder="Insira os certificados adicionais"
              />
            </TextAreaContainer>
          </>
        )}
        {!isEditingSelf && (
        <>
          <LinkPartnersModal
            isOpen={isPartnersModalOpen}
            onClose={() => setIsPartnersModalOpen(false)}
            partnersIds={partnerIds}
            onConfirm={onConfirmLinkPartners}
            shouldUseIcons
          />

          <LinkPatientsModal
            isOpen={isPatientsModalOpen}
            onClose={() => setIsPatientsModalOpen(false)}
            patientIds={patientIds}
            onConfirm={onConfirmLinkPatients}
          />
        </>
        )}
      </FormContainer>
    );
  },
);
