import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  height: calc(100vh - 7rem);

  > * {
    margin-top: 2rem;
  }

  .vouchers__filters-container {
    display: flex;
    width: 100%;
    align-items: center;
    gap: 1.25rem;
    flex-wrap: wrap;

    position: relative;

    > form:first-child {
      width: unset;
      flex: 1;
    }
  }

  .vouchers__date-picker {
    z-index: 5;

    .react-datepicker__input-container {
      > input {
        min-width: 15rem;
      }
    }
  }
`;

export const LinkServiceButton = styled.button`
  background-color: transparent;
  border: none;

  width: 100%;

  color: var(--blue-400);

  font-size: inherit;

  transition: filter 0.2s;

  &:hover {
    filter: brightness(0.8);
  }
`;

export const ClearFilterButton = styled.button`
  background: transparent;
  border: none;

  color: var(--blue-400);

  position: absolute;

  right: 0;
  bottom: -1.5rem;

  transition: filter 0.2s;

  &:hover {
    filter: brightness(0.8);
  }
`;
