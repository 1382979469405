import { useEffect, useState } from 'react';

import { toast } from 'react-toastify';
import { Button } from '../../components/Button';
import { EditTherapistProfileForm } from '../../components/EditTherapistProfileForm';

import { GoBackLocation } from '../../components/GoBackLocation';

import { Input } from '../../components/Input';
import { useAuth } from '../../hooks/useAuth';
import { useNotificationModal } from '../../hooks/useNotificationModal';
import { api } from '../../services/api';
import { IUser } from '../../types';
import { maskCpfOrCnpj } from '../../utils/maskCpfOrCnpj';

import { EditPasswordContainer, InformationContainer } from './styles';

interface IPasswordFormData {
  old_password: string;
  password: string;
  password_confirmation: string;
}

const portugueseRoles = {
  admnistrator: 'Administrador',
  therapist: 'Terapeuta',
  master: 'Master',
  patient: 'Paciente',
  internal: 'Interno',
};

const initialPasswordFormData = {
  old_password: '',
  password: '',
  password_confirmation: '',
};

export default function MyProfile() {
  const { data } = useAuth();
  const [userData, setUserData] = useState<IUser>();
  const [isEditingPassword, setIsEditingPassword] = useState(false);
  const [passwordFormData, setPasswordFormData] = useState<IPasswordFormData>(
    initialPasswordFormData,
  );
  const [isLoading, setIsLoading] = useState(true);

  const { notificate } = useNotificationModal();

  useEffect(() => {
    fetchUserData();
  }, []);

  const fetchUserData = async () => {
    try {
      const response = await api.get<IUser>('/me');
      setUserData(response.data);
      setIsLoading(false);
    } catch (error: any) {
      toast.error(error?.response?.data?.message || 'Não foi possivel obter os dados do usuário');
    }
  };

  const handleDisableEditingPassword = () => {
    setIsEditingPassword(false);
  };

  const handlePasswordFormInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setPasswordFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSuccess = () => {
    setIsEditingPassword(false);
    setPasswordFormData(initialPasswordFormData);
  };

  const handlePasswordSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    if (passwordFormData.password !== passwordFormData.password_confirmation) {
      toast.error('As senhas não conferem');
      return;
    }
    try {
      await api.put('/user/password', {
        oldPassword: passwordFormData.old_password,
        newPassword: passwordFormData.password,
      });
      notificate('Salvo com sucesso', handleSuccess);
    } catch (error: any) {
      toast.error(error?.response?.data?.message || error?.message || 'Algo deu errado ao redefinir a senha');
    }
  };

  if (isLoading) {
    return null;
  }

  if (isEditingPassword) {
    return (
      <EditPasswordContainer as="form" onSubmit={handlePasswordSubmit}>
        <GoBackLocation title="Redefinição de senha" onArrowClick={handleDisableEditingPassword} />
        <div className="edit-password__fields-container">
          <Input
            label="Senha atual:"
            type="password"
            name="old_password"
            placeholder="Insira a senha atual"
            value={passwordFormData.old_password}
            onChange={handlePasswordFormInputChange}
            required
          />
          <Input
            label="Nova senha:"
            type="password"
            name="password"
            placeholder="Insira a nova senha"
            value={passwordFormData.password}
            onChange={handlePasswordFormInputChange}
            required
            pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$"
            title="A senha deve conter pelo menos 8 caracteres, sendo pelo menos uma letra maiúscula, uma minúscula, um número e um caractere especial"
          />
          <Input
            label="Confirmar senha:"
            type="password"
            name="password_confirmation"
            placeholder="Confirme a senha"
            value={passwordFormData.password_confirmation}
            onChange={handlePasswordFormInputChange}
            required
            pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$"
            title="A senha deve conter pelo menos 8 caracteres, sendo pelo menos uma letra maiúscula, uma minúscula, um número e um caractere especial"
          />
        </div>
        <Button type="submit">Salvar alterações</Button>
      </EditPasswordContainer>
    );
  }

  if (userData?.role === 'therapist') {
    return (
      <EditTherapistProfileForm
        userData={userData}
        onClickEditPassword={() => setIsEditingPassword(true)}
      />
    );
  }

  return (
    <InformationContainer>
      <Input
        label="Nome completo:"
        defaultValue={userData?.name}
        readOnly
      />
      <Input
        label="Tipo:"
        defaultValue={portugueseRoles[data.user?.role]}
        readOnly
      />
      <Input
        label="CPF:"
        defaultValue={userData?.cpf ? maskCpfOrCnpj(userData?.cpf) : ''}
        readOnly
      />
      <Input
        label="E-mail:"
        defaultValue={data.user.email}
        readOnly
      />
      <button type="button" onClick={() => setIsEditingPassword(true)}>
        Redefinir senha
      </button>
    </InformationContainer>
  );
}
