import axios, { AxiosError } from 'axios';

export const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

function removeStorageItems() {
  window.localStorage.removeItem('@doarcareWebAdmin-accessToken');
  window.localStorage.removeItem('@doarcareWebAdmin-refreshToken');
  window.localStorage.removeItem('@doarcareWebAdmin-user');

  window.sessionStorage.removeItem('@doarcareWebAdmin-accessToken');
  window.sessionStorage.removeItem('@doarcareWebAdmin-refreshToken');
}

api.interceptors.request.use((config) => {
  const storageToken = window.localStorage.getItem('@doarcareWebAdmin-accessToken') || window.sessionStorage.getItem('@doarcareWebAdmin-accessToken');
  if (!config.headers || !storageToken) return config;
  return {
    ...config,
    headers: {
      ...config.headers,
      Authorization: `Bearer ${storageToken}`,
    },
  };
});

let failedRequests = [] as {
  onSuccess: (token: string) => void;
  onError: (error: AxiosError) => void;
}[];
let isRefreshing = false;

api.interceptors.response.use((response) => response, (error: AxiosError) => {
  if (error.response?.status === 401) {
    if (error.response?.data?.message.toLowerCase().includes('token jwt expirado')) {
      const requestConfig = error.config;

      if (!isRefreshing) {
        isRefreshing = true;

        api.put('/auth', {
          refreshToken: window.localStorage.getItem('@doarcareWebAdmin-refreshToken') || ''
          ,
        }).then((response) => {
          const { accessToken } = response.data;

          const localStorageAccessToken = window.localStorage.getItem('@doarcareWebAdmin-accessToken');

          if (localStorageAccessToken) {
            window.localStorage.setItem('@doarcareWebAdmin-accessToken', accessToken);
          } else {
            window.sessionStorage.setItem('@doarcareWebAdmin-accessToken', accessToken);
          }

          failedRequests.forEach((request) => request.onSuccess(accessToken));
          failedRequests = [];
        }).catch((err: AxiosError) => {
          failedRequests.forEach((request) => request.onError(err));
          failedRequests = [];

          removeStorageItems();

          window.location.href = '/entrar';
        })
          .finally(() => {
            isRefreshing = false;
          });
      }

      if (error?.config?.url?.includes('/auth')) {
        failedRequests.forEach((request) => request.onError(error));
        failedRequests = [];

        removeStorageItems();

        window.location.href = '/entrar';

        isRefreshing = false;

        return Promise.reject(error);
      }

      return new Promise((resolve, reject) => {
        failedRequests.push({
          onSuccess: (token: string) => {
            if (requestConfig.headers) {
              requestConfig.headers.Authorization = `Bearer ${token}`;
              resolve(api(requestConfig));
            }
          },
          onError: (err: AxiosError) => {
            reject(err);
          },
        });
      });
    }

    return Promise.reject(error);
  }
  return Promise.reject(error);
});
