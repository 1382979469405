import { useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { api } from '../../services/api';
import { IDailyDashboard } from '../../types/Dashboard';
import { formatCurrencyInCents } from '../../utils/formatCurrencyInCents';
import {
  DailyDashboardVariant, VariantRow, DailyVariantCard, DailyCardTitle, DailyCardValue,
} from './styles';

const DailyDashboard = () => {
  const [dailyDashboard, setDailyDashboard] = useState<IDailyDashboard>();

  const getDailyDashboard = async () => {
    try {
      const { data } = await api.get<IDailyDashboard>('/dashboard/daily');

      setDailyDashboard(data);
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    getDailyDashboard();
  }, []);

  const totalDailyDashboard = useMemo(() => {
    if (!dailyDashboard) return 0;

    return (dailyDashboard.accepteds + dailyDashboard.canceleds + dailyDashboard.dones);
  }, [dailyDashboard]);

  return (
    <DailyDashboardVariant>
      <VariantRow>
        <DailyVariantCard>
          <DailyCardTitle>Agendados</DailyCardTitle>
          <DailyCardValue>{dailyDashboard?.accepteds}</DailyCardValue>
        </DailyVariantCard>
        <DailyVariantCard>
          <DailyCardTitle>Pendentes</DailyCardTitle>
          <DailyCardValue>
            {dailyDashboard?.pending}
          </DailyCardValue>
        </DailyVariantCard>
        <DailyVariantCard>
          <DailyCardTitle>Executados</DailyCardTitle>
          <DailyCardValue>{dailyDashboard?.dones}</DailyCardValue>
        </DailyVariantCard>
        <DailyVariantCard>
          <DailyCardTitle>Cancelados</DailyCardTitle>
          <DailyCardValue>{dailyDashboard?.canceleds}</DailyCardValue>
        </DailyVariantCard>
        <DailyVariantCard>
          <DailyCardTitle>Faturamento</DailyCardTitle>
          <DailyCardValue>{formatCurrencyInCents(dailyDashboard?.total || 0)}</DailyCardValue>
        </DailyVariantCard>
      </VariantRow>
    </DailyDashboardVariant>
  );
};

export default DailyDashboard;
