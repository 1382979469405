import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { api } from '../../services/api';
import { IPermission } from '../../types';

import { IModalProps, Modal } from '../Modal';
import { Toggle } from '../Toggle';

import {
  Container, PermissionItem, PermissionsList, StyledButton, StyledHeader,
} from './styles';

export interface IConfirmData {
  addPermissionsIds: string[];
  removePermissionsIds: string[];
}

interface IEnablingPermission extends IPermission {
  isActive: boolean;
}

interface IPermissionsModalProps extends IModalProps {
  userId: string;
  onConfirm?: (confirmData: IConfirmData) => void;
  name: string;
}

export default function PermissionsModal({
  userId,
  name,
  isOpen,
  onClose,
  onConfirm = () => {},
}: IPermissionsModalProps) {
  const [permissions, setPermissions] = useState<IEnablingPermission[]>([]);

  const [addPermissionsIds, setAddPermissionsIds] = useState<string[]>([]);
  const [removePermissionsIds, setRemovePermissionsIds] = useState<string[]>([]);

  useEffect(() => {
    fetchPermissions();
  }, []);

  useEffect(() => {
    if (userId) {
      checkUserPermissions();
    }
  }, [userId, isOpen]);

  const fetchPermissions = async () => {
    try {
      const response = await api.get<IPermission[]>('/permission');
      setPermissions(response.data.map((permission) => ({
        ...permission,
        isActive: false,
      })));
    } catch (error: any) {
      toast.error(error?.response?.data?.message || error?.message || 'Algo deu errado');
    }
  };

  const checkUserPermissions = async () => {
    try {
      const response = await api.get<IPermission[]>(`/permission/${userId}`);
      setPermissions((prevState) => prevState.map((permission) => ({
        ...permission,
        isActive: (response.data.some(
          (apiPermission) => apiPermission.id_permission === permission.id_permission,
        )),
      })));
    } catch (error: any) {
      toast.error(error?.response?.data?.message || error?.message || 'Algo deu errado');
    }
  };

  const handleToggle = (id: string, current: boolean) => {
    setPermissions((prevState) => prevState.map((permission) => {
      if (permission.id_permission === id) {
        return {
          ...permission,
          isActive: !current,
        };
      }
      return permission;
    }));

    if (current) {
      setAddPermissionsIds((prevState) => prevState.filter((p) => p !== id));
      setRemovePermissionsIds((prevState) => [...prevState, id]);
    } else {
      setAddPermissionsIds((prevState) => [...prevState, id]);
      setRemovePermissionsIds((prevState) => prevState.filter((p) => p !== id));
    }
  };

  const handleClose = () => {
    setAddPermissionsIds([]);
    setRemovePermissionsIds([]);
    onClose();
  };

  const handleConfirm = () => {
    onConfirm({
      addPermissionsIds,
      removePermissionsIds,
    });
    handleClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
    >
      <Container>
        <StyledHeader>
          {name}
        </StyledHeader>
        <PermissionsList>
          {permissions.map((permission) => (
            <PermissionItem key={permission.id_permission}>
              <span>{permission.name}</span>
              <Toggle
                value={permission.isActive}
                onToggle={() => handleToggle(permission.id_permission, permission.isActive)}
              />
            </PermissionItem>
          ))}
        </PermissionsList>
        <StyledButton type="button" onClick={handleConfirm}>
          Confirmar
        </StyledButton>
      </Container>
    </Modal>
  );
}
