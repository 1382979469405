import styled from 'styled-components';
import { Button } from '../Button';

export const Container = styled.div`
  margin: 2rem auto;
`;

export const Content = styled.div`
  border-radius: 2rem;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  margin: 2rem 0;
`;

export const EditFormHeader = styled.header`
  background-color: var(--blue-400);
  min-height: 4rem;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 0.75rem;
  border-radius: 2rem 2rem 0 0;
  padding: 1rem 2rem;
`;

export const EditFormDeleteButton = styled.button`
  background-color: transparent;
  border: none;

  margin-left: auto;

  svg {
    width: 1rem;
    height: 1rem;
  }

  transition: filter 0.2s;

  &:hover {
    filter: brightness(0.9);
  }
`;

export const EditFormSubmitButton = styled(Button)`
  margin-left: auto;
  width: 100%;
  max-width: 16.25rem;
  font-size: 1.25rem;
`;
