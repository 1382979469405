import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { ReactComponent as HomeIcon } from '../../assets/images/home.svg';

import {
  IConfirmData,
  LinkPartnersModal,
} from '../../components/LinkPartnersModal';
import { PatientDetails } from '../../components/Patients/PatientDetails';
import { SearchBox } from '../../components/SearchBox';
import { Table } from '../../components/Table';
import { useInfiniteScroll } from '../../hooks/useInfiniteScroll';
import { api } from '../../services/api';
import { IPatient, IUser } from '../../types';
import { applySort } from '../../utils/applySort';
import { maskCpfOrCnpj } from '../../utils/maskCpfOrCnpj';
import { Container, TextButton } from './styles';

const tableHeaders = {
  name: {
    label: 'Nome',
    sortable: true,
  },
  city: {
    label: 'Cidade',
    sortable: true,
  },
  cpf_cnpj: {
    label: 'CPF',
    sortable: true,
  },
  partner: {
    label: 'Parceiro',
  },
};

const limitPerPage = 15;

export default function Patients() {
  const [patients, setPatients] = useState<IUser[]>([]);

  const [selectedUser, setSelectedUser] = useState<IUser | undefined>();

  const [search, setSearch] = useState('');
  const [cityFilter, setCityFilter] = useState('');
  const [sortBy, setSortBy] = useState('name');
  const [sortDir, setSortDir] = useState('');

  const [handleLinkPartnersModal, setHandleLinkPartnersModal] = useState({
    isOpen: false,
    partnerIds: [] as string[],
    selectedUserId: '',
  });

  const [shouldFetch, setShouldFetch] = useState(true);

  const {
    loadMoreRef,
    page,
    setPage,
    maximumPage,
    setMaximumPage,
  } = useInfiniteScroll();

  useEffect(() => {
    // Debounce for searches
    const timer = setTimeout(() => {
      setPage(1);
      setMaximumPage(1);
      setShouldFetch(true);
    }, 250);

    return () => clearTimeout(timer);
  }, [search, cityFilter]);

  useEffect(() => {
    if (page > 1 && page <= maximumPage) {
      setShouldFetch(true);
    }
  }, [page]);

  useEffect(() => {
    if (shouldFetch) {
      fetchPatients();
    }
  }, [shouldFetch]);

  const fetchPatients = async () => {
    const params = {
      role: 'patient',
      query: search || undefined,
      city: cityFilter || undefined,
      limit: limitPerPage,
      page,
    };
    try {
      const response = await api.get<{
        users: IUser[];
        totalUsers: number;
      }>('/user', {
        params,
      });

      if (page > 1) {
        setPatients((prevState) => [...prevState, ...response.data.users]);
      } else {
        setPatients(response.data.users);
      }

      setMaximumPage(Math.ceil(response.data.totalUsers / limitPerPage));
    } catch (error: any) {
      toast.error(
        error?.response?.data?.message
          || 'Erro ao buscar os clientes, tente novamente mais tarde',
      );
    } finally {
      setShouldFetch(false);
    }
  };

  const handleCloseLinkPartnersModal = () => {
    setHandleLinkPartnersModal({
      isOpen: false,
      partnerIds: [],
      selectedUserId: '',
    });
  };

  const removePartnerFromPatient = async (cod: string) => {
    const response = await api.delete<IPatient>(`/patient/${handleLinkPartnersModal.selectedUserId}/partner`, {
      data: {
        cod,
      },
    });

    setPatients((prevState) => prevState.map((patient) => {
      if (patient.id_user === response.data.user_id) {
        return {
          ...patient,
          patient: response.data,
        };
      }
      return patient;
    }));
  };

  const addPartnerToPatient = async (cod: string) => {
    const response = await api.patch<IPatient>('/patient/partner', {
      cod,
      userId: handleLinkPartnersModal.selectedUserId,
    });

    setPatients((prevState) => prevState.map((patient) => {
      if (patient.id_user === response.data.user_id) {
        return {
          ...patient,
          patient: response.data,
        };
      }
      return patient;
    }));
  };

  const handleConfirmLinkPartners = async (confirmData: IConfirmData) => {
    const { addPartnerIds: addPartnerCodes, removePartnerIds: removePartnerCodes } = confirmData;

    try {
      if (removePartnerCodes.length > 0 && addPartnerCodes.length <= 0) {
        await removePartnerFromPatient(removePartnerCodes[0]);
      } else {
        await addPartnerToPatient(addPartnerCodes[0] || '');
      }

      setHandleLinkPartnersModal({
        isOpen: false,
        partnerIds: [],
        selectedUserId: '',
      });
    } catch (error: any) {
      toast.error(error?.response?.data?.message || 'Algo deu errado, tente novamente mais tarde');
    }
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  const handleSelectUser = (userId: string) => {
    setSelectedUser(patients.find((user) => user.id_user === userId));
  };

  const handleSortChange = (key: string) => {
    if (sortBy === key) {
      if (sortDir === 'asc') {
        setSortDir('desc');
      } else if (sortDir === 'desc') {
        setSortDir('');
      } else {
        setSortDir('asc');
      }
    } else {
      setSortBy(key as keyof IPatient);
      setSortDir('asc');
    }
  };

  const handleOpenLinkPartnersModal = (
    patientId: string,
    partnerId?: null | string,
  ) => {
    setHandleLinkPartnersModal({
      isOpen: true,
      partnerIds: [partnerId ?? ''],
      selectedUserId: patientId,
    });
  };

  const formattedPatients = patients.map((p) => ({
    id_user: p.id_user,
    name: p.name,
    cpf_cnpj: maskCpfOrCnpj(p?.cpf) || '-',
    city: p?.address?.city || '-',
    partner_name: p?.patient?.partner?.name,
    partner_id: p?.patient?.partner_id,
  }));

  const sortedPatients = applySort(formattedPatients, sortBy, sortDir);

  const tableData = sortedPatients.map((patient) => ({
    id: patient.id_user,
    name: (
      <TextButton type="button" onClick={() => handleSelectUser(patient.id_user)}>
        {patient.name}
      </TextButton>
    ),
    city: patient.city,
    cpf_cnpj: patient.cpf_cnpj,
    partner: (
      <TextButton
        onClick={() => handleOpenLinkPartnersModal(patient.id_user, patient?.partner_id)}
      >
        {patient?.partner_id ? patient?.partner_name : 'Vincular parceiro'}
      </TextButton>
    ),
  }));

  if (selectedUser) {
    return (
      <PatientDetails
        user={selectedUser}
        onRemoveSelectedUser={() => setSelectedUser(undefined)}
      />
    );
  }

  const searchBoxExtraFilters = [
    {
      icon: <HomeIcon />,
      name: 'Cidade',
      onChange: (value: string) => {
        setCityFilter(value);
      },
      value: cityFilter || '',
      isDropdown: false,
    },
  ];

  return (
    <Container>
      <SearchBox
        onSubmit={() => {}}
        placeholder="Pesquisar usuários ou CPF"
        value={search}
        onChange={handleSearchChange}
        extraFilters={searchBoxExtraFilters}
      />
      <Table
        headers={tableHeaders}
        data={tableData}
        onSort={handleSortChange}
        sortDir={sortDir}
        sortBy={sortBy}
        hasMore={page <= maximumPage}
        loadMoreRef={loadMoreRef}
      />
      <LinkPartnersModal
        isOpen={handleLinkPartnersModal.isOpen}
        onClose={handleCloseLinkPartnersModal}
        partnersIds={handleLinkPartnersModal.partnerIds}
        canSelectMultiple={false}
        onConfirm={handleConfirmLinkPartners}
        shouldUseCode
      />
    </Container>
  );
}
