import {
  useCallback, useEffect, useRef, useState,
} from 'react';

type UseInfiniteScrollReturn = {
  loadMoreRef: React.RefObject<HTMLDivElement>;
  page: number;
  maximumPage: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  setMaximumPage: React.Dispatch<React.SetStateAction<number>>;
}

export function useInfiniteScroll(): UseInfiniteScrollReturn {
  const [page, setPage] = useState(1);
  const [maximumPage, setMaximumPage] = useState(1);
  const ref = useRef<HTMLDivElement>(null);

  const handleIncreasePage = useCallback(() => {
    setPage((prevState) => {
      if (prevState < maximumPage) {
        return prevState + 1;
      }
      return prevState;
    });
  }, [maximumPage]);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '10px',
      threshold: 0.25,
    };

    const observer = new IntersectionObserver((entities) => {
      const target = entities[0];

      if (target.isIntersecting) {
        handleIncreasePage();
      }
    }, options);

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [ref.current, handleIncreasePage]);

  return {
    loadMoreRef: ref,
    maximumPage,
    page,
    setPage,
    setMaximumPage,
  };
}
