import startOfMonth from 'date-fns/startOfMonth';
import subMonths from 'date-fns/subMonths';
import { DatePeriodDropdownOption } from '../components/DatePeriodDropdown';

const now = new Date();

export const datePeriodOptions: DatePeriodDropdownOption[] = [
  {
    key: '1mo',
    value: {
      startDate: startOfMonth(now),
      endDate: now,
    },
    label: 'Último mês',
  },
  {
    key: '3mo',
    value: {
      startDate: startOfMonth(subMonths(now, 2)),
      endDate: now,
    },
    label: 'Últimos 3 meses',
  },
  {
    key: '6mo',
    value: {
      startDate: startOfMonth(subMonths(now, 5)),
      endDate: now,
    },
    label: 'Últimos 6 meses',
  },
];
