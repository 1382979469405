import { useRouteMatch } from 'react-router-dom';

import { AddAnamnesisForm } from '../components/Anamnesis/AddAnamnesisForm';
import { EditAnamnesisForm } from '../components/Anamnesis/EditAnamnesisForm';

import { Anamnesis } from '../pages/Anamnesis';
import { AdminRoute } from './AdminRoute';

export function AnamnesisRouter() {
  const { path } = useRouteMatch();

  return (
    <>
      <AdminRoute path={path} exact component={Anamnesis} />
      <AdminRoute path={`${path}/adicionar`} exact component={AddAnamnesisForm} />
      <AdminRoute path={`${path}/editar/:anamnesisId`} component={EditAnamnesisForm} />
    </>
  );
}
