import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { ReactComponent as ContainedPlusIcon } from '../../assets/images/contained-plus.svg';
import { useInfiniteScroll } from '../../hooks/useInfiniteScroll';

import { api } from '../../services/api';

import { IPartner } from '../../types';

import { IModalProps, Modal } from '../Modal';
import { SearchBox } from '../SearchBox';
import { Toggle } from '../Toggle';

import {
  Container,
  IconButton,
  PartnerItem,
  PartnerList,
  SearchBoxWrapper,
  StyledButton,
  StyledDeleteIcon,
  StyledHeader,
} from './styles';

interface ILinkServicesModalProps extends IModalProps {
  partnersIds: string[];
  onConfirm?: (confirmData: IConfirmData) => void;
  canSelectMultiple?: boolean;
  shouldUseIcons?: boolean;
  shouldUseCode?: boolean;
}

export interface IConfirmData {
  addPartnerIds: string[];
  removePartnerIds: string[];
}

interface ILinkingPartners extends IPartner {
  isActive: boolean;
}

const limitPerPage = 15;

export default function LinkPartnersModal({
  isOpen,
  onClose,
  partnersIds = [],
  onConfirm = () => {},
  canSelectMultiple = true,
  shouldUseIcons = false,
  shouldUseCode = false,
}: ILinkServicesModalProps) {
  const [partners, setPartners] = useState<ILinkingPartners[]>([]);

  const [addPartnerIds, setAddPartnerIds] = useState<string[]>([]);
  const [removePartnerIds, setRemovePartnerIds] = useState<string[]>([]);

  const [nameFilter, setNameFilter] = useState('');

  const [shouldFetch, setShouldFetch] = useState(true);

  const {
    loadMoreRef, page, setPage, maximumPage, setMaximumPage,
  } = useInfiniteScroll();

  useEffect(() => {
    // Debounce for searches
    const timer = setTimeout(() => {
      setPage(1);
      setMaximumPage(1);
      setShouldFetch(true);
    }, 250);

    return () => clearTimeout(timer);
  }, [nameFilter]);

  useEffect(() => {
    if (page > 1 && page <= maximumPage) {
      setShouldFetch(true);
    }
  }, [page]);

  useEffect(() => {
    if (shouldFetch) {
      fetchPartners();
    }
  }, [shouldFetch]);

  useEffect(() => {
    checkActivePartners();
  }, [partnersIds, isOpen]);

  const fetchPartners = async () => {
    try {
      const response = await api.get<{
        partners: IPartner[];
        totalPartners: number;
      }>('/partner', {
        params: {
          page,
          limit: limitPerPage,
          query: nameFilter || undefined,
        },
      });

      if (page > 1) {
        const newPartners = response.data.partners.map((partner) => ({
          ...partner,
          isActive: partnersIds.some(
            (partnerId) => partnerId === partner.id_partner,
          ),
        }));

        setPartners([...partners, ...newPartners]);
      } else {
        setPartners(
          response.data.partners.map((partner) => ({
            ...partner,
            isActive: partnersIds.some(
              (partnerId) => partnerId === partner.id_partner,
            ),
          })),
        );
      }

      setMaximumPage(Math.ceil(response.data.totalPartners / limitPerPage));
    } catch (error: any) {
      toast.error(
        error?.response?.data?.message || error?.message || 'Algo deu errado',
      );
    } finally {
      setShouldFetch(false);
    }
  };

  const checkActivePartners = () => {
    setPartners((prevState) => prevState.map((partner) => ({
      ...partner,
      isActive: partnersIds.some(
        (partnerId) => partnerId === partner.id_partner,
      ),
    })));
  };

  const handleToggle = (id: string, current: boolean) => {
    if (canSelectMultiple) {
      setPartners((prevState) => prevState.map((partner) => {
        if (partner.id_partner === id) {
          return {
            ...partner,
            isActive: !partner.isActive,
          };
        }
        return partner;
      }));
      if (current) {
        setAddPartnerIds((prevState) => prevState.filter((s) => s !== id));
        setRemovePartnerIds((prevState) => [...prevState, id]);
      } else {
        setAddPartnerIds((prevState) => [...prevState, id]);
        setRemovePartnerIds((prevState) => prevState.filter((s) => s !== id));
      }
    } else {
      setPartners((prevState) => prevState.map((partner) => {
        if (partner.id_partner === id) {
          return {
            ...partner,
            isActive: !current,
          };
        }
        return {
          ...partner,
          isActive: false,
        };
      }));
      if (current) {
        setAddPartnerIds([]);
        setRemovePartnerIds([id]);
      } else {
        setAddPartnerIds([id]);
        setRemovePartnerIds([]);
      }
    }
  };

  const handleConfirm = () => {
    if (shouldUseCode) {
      onConfirm({
        addPartnerIds: addPartnerIds.map((id) => partners.find((p) => p.id_partner === id)?.cod || ''),
        removePartnerIds: removePartnerIds.map((id) => partners.find((p) => p.id_partner === id)?.cod || ''),
      });
    } else {
      onConfirm({
        addPartnerIds,
        removePartnerIds,
      });
    }

    handleClose();
  };

  const handleClose = () => {
    setAddPartnerIds([]);
    setRemovePartnerIds([]);
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose}>
      <Container>
        <StyledHeader>
          <h1>Selecione o parceiro</h1>
        </StyledHeader>
        <SearchBoxWrapper>
          <SearchBox
            onSubmit={() => {}}
            shouldShowExtraFilters={false}
            placeholder="Pesquisar por nome"
            value={nameFilter}
            onChange={(e) => setNameFilter(e.target.value)}
          />
        </SearchBoxWrapper>
        <PartnerList>
          {partners.map((partner) => (
            <PartnerItem key={partner.id_partner}>
              <span>{partner.name}</span>
              {shouldUseIcons ? (
                <IconButton
                  onClick={() => handleToggle(partner.id_partner, partner.isActive)}
                >
                  {partner.isActive ? (
                    <StyledDeleteIcon />
                  ) : (
                    <ContainedPlusIcon />
                  )}
                </IconButton>
              ) : (
                <Toggle
                  onToggle={() => handleToggle(partner.id_partner, partner.isActive)}
                  value={partner.isActive}
                />
              )}
            </PartnerItem>
          ))}
          {page <= maximumPage && <div className="partner-list__load-more" ref={loadMoreRef} />}
        </PartnerList>
        <StyledButton type="button" onClick={handleConfirm}>
          Confirmar
        </StyledButton>
      </Container>
    </Modal>
  );
}
