import styled, { css } from 'styled-components';
import SelectArrow from '../../assets/images/select-arrow.svg';

export const Container = styled.div``;

export const StyledLabel = styled.label`
  display: flex;
  flex-direction: column;

  > span {
    padding-left: 0.75em;
    margin-bottom: 0.375rem;
  }
`;

export const NewValueContainer = styled.div`
  display: flex;

  max-width: max(20rem, 30%);

  margin-top: 0.5rem;
`;

export const NewValueInput = styled.input`
  width: 100%;
  border: none;
  outline: none;
  font-size: 1rem;
  padding: 0.25em 0.5em;

  &::placeholder {
    filter: opacity(0.5);
  }
`;

export const IconButton = styled.button`
  border: none;
  background: var(--white);

  font-size: 1rem;

  border-radius: 50%;

  width: 2rem;
  height: 2rem;

  padding: 0.5rem;

  svg {
    width: 1rem;
    height: 1rem;
    path {
      stroke: var(--gray-500);
    }
  }

  transition: filter 0.2s;

  &:hover {
    filter: brightness(0.9);
  }
`;

export const DropdownContainer = styled.div<{
  isExpanded?: boolean;
}>`
  background: #fff;
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  min-height: 3rem;
  padding: 1em;
  font-size: 1rem;
  border-radius: ${({ isExpanded }) => (isExpanded ? '0.5rem 0.5rem 0 0' : '0.5rem')};

  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);

  > button {
    background: transparent;
    border: none;
    width: 100%;
    text-align: left;
    font-size: 1rem;
    padding-right: 1.25rem;

    overflow: hidden;
  }

  &::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 1em;
    width: 1em;
    height: 0.75em;

    overflow: visible;

    background-repeat: no-repeat;
    background-size: contain;

    pointer-events: none;

    transition: transform 0.3s ease 0s;

    transform: ${({ isExpanded }) => (isExpanded
    ? 'translateY(-40%) rotate(-180deg)'
    : 'translateY(-40%) rotate(0deg)')};

    background-image: url(${SelectArrow});
  }
`;

export const OptionsWrapper = styled.div<{
  isExpanded?: boolean;
}>`
  position: absolute;
  top: 50%;
  left: 0px;
  right: 0px;
  z-index: 1;
  height: 0px;
  opacity: 0;
  visibility: hidden;

  background: #fff;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16), 0px 3px 6px rgba(0, 0, 0, 0.16);
  border-radius: 0 0 0.5rem 0.5rem;

  display: flex;
  flex-direction: column;
  padding-top: 1rem;

  transition: all 0.3s ease 0s;

  ${({ isExpanded }) => isExpanded
    && css`
      height: unset;
      opacity: 1;
      top: 100%;
      visibility: visible;
    `}
`;

export const OptionsContainer = styled.div`
  max-height: 300px;
  overflow-y: auto;

  margin: 0 1rem 1rem;

  scrollbar-width: thin;
  scrollbar-color: var(--blue-400) var(--blue-50);

  &::-webkit-scrollbar {
    width: 0.4rem;
    height: 0.4rem;
  }

  &::-webkit-scrollbar-track {
    border-radius: 1.25rem;
    background-color: var(--blue-50);
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 1.25rem;
    background-color: var(--blue-400);
  }
`;

export const OptionWrapper = styled.div`
  display: flex;
  align-items: center;

  width: 100%;
  max-width: max(20rem, 30%);
`;

export const Option = styled.button`
  display: block;
  background: transparent;
  border: none;
  width: 100%;

  padding: 0.375em 0.125em;

  font-size: inherit;

  text-align: left;
`;

export const RemoveButton = styled.button`
  background: transparent;
  border: none;

  padding: 0.5rem;

  background: var(--white);

  border-radius: 50%;

  line-height: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  > svg {
    width: 1rem;
    height: 1rem;
    path {
      fill: var(--gray-500);
    }
  }

  transition: filter 0.2s;

  &:hover {
    filter: brightness(0.9);
  }
`;
