import {
  startOfDay, endOfDay, format, parseISO,
} from 'date-fns';
import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useInfiniteScroll } from '../../hooks/useInfiniteScroll';
import { api } from '../../services/api';
import { AppointmentStatus, IAppointment, IAvaliation } from '../../types';
import {
  ReportItem, ReportItemSection, ReportItemSubsection, ReportsList, ReportsListWrapper,
} from './styles';

interface IAppointmentsListProps {
  userId?: string;
  appointmentStatus: AppointmentStatus;
  initialDate?: Date;
  finalDate?: Date;
  isPatient?: boolean;
}

const limitPerPage = 8;

export function AppointmentsList(
  {
    userId,
    appointmentStatus,
    initialDate,
    finalDate,
    isPatient = false,
  }: IAppointmentsListProps,
) {
  const [appointments, setAppointments] = useState<IAppointment[]>([]);
  const [shouldFetch, setShouldFetch] = useState(true);

  const {
    page, loadMoreRef, setPage, maximumPage, setMaximumPage,
  } = useInfiniteScroll();

  const fetchAppointments = async () => {
    if (page > maximumPage) {
      return;
    }

    const params = {
      userId: userId || undefined,
      status: appointmentStatus,
      initialDate: initialDate
        ? startOfDay(initialDate).toISOString() : new Date().toISOString(),
      finalDate: finalDate
        ? endOfDay(finalDate).toISOString() : new Date('2070-12-31').toISOString(),
      page,
      limit: limitPerPage,
    };
    try {
      const response = await api.get<{
        appointments: IAppointment[];
        totalAppointments: number;
      }>('/appointment/status', {
        params,
      });

      if (page === 1) {
        setAppointments((prevState) => response.data.appointments);
      } else {
        setAppointments((prevState) => [
          ...prevState,
          ...response.data.appointments,
        ]);
      }
      setMaximumPage(Math.ceil(response.data.totalAppointments / limitPerPage));
    } catch (error: any) {
      toast.error(
        error?.response?.data?.message
          || 'Algo deu errado, tente novamente mais tarde',
      );
    } finally {
      setShouldFetch(false);
    }
  };

  useEffect(() => {
    if (!initialDate) {
      return;
    }

    setPage((_prevState) => 1);
    setMaximumPage((_prevState) => 1);
    setShouldFetch(true);
  }, [initialDate, finalDate]);

  useEffect(() => {
    if (page > 1) {
      setShouldFetch(true);
    }
  }, [page]);

  useEffect(() => {
    if (!appointmentStatus || !shouldFetch) {
      return;
    }
    fetchAppointments();
  }, [userId, appointmentStatus, shouldFetch]);

  const renderAvaliations = (avaliations: IAvaliation[] | undefined) => {
    if (!avaliations || avaliations.length === 0) {
      return null;
    }

    const avaliationByTherapist = avaliations.find((avaliation) => avaliation.therapist_id == null);
    const avaliationByUser = avaliations.find((avaliation) => avaliation.patient_id == null);

    return (
      <ReportItemSubsection flexAlignItems="flex-end">
        {avaliationByUser && (
        <p>
          Avaliado:
          {' '}
          {avaliationByUser?.rating?.toLocaleString('pt-BR', {
            maximumFractionDigits: 1,
          })}
        </p>
        )}
        {avaliationByTherapist && (
        <p>
          Avaliação:
          {' '}
          {avaliationByTherapist?.rating?.toLocaleString('pt-BR', {
            maximumFractionDigits: 1,
          })}
        </p>
        )}
      </ReportItemSubsection>
    );
  };

  return appointments?.length > 0 ? (
    <ReportsListWrapper>
      <ReportsList>
        {appointments.map((appointment) => (
          <ReportItem key={appointment.id_appointment}>
            <ReportItemSection>
              <ReportItemSubsection>
                <p>{appointment.service.name || ''}</p>
                <p>
                  {isPatient
                    ? appointment?.therapist?.user?.name
                    : appointment?.patient?.user?.name}
                </p>
                <p>
                  {format(parseISO(appointment?.datetime), "dd/MM/yyyy '-' HH:mm")}
                </p>
              </ReportItemSubsection>
              {appointmentStatus === 'done' ? renderAvaliations(appointment.avaliations) : null}
            </ReportItemSection>
          </ReportItem>
        ))}
        <div ref={loadMoreRef} className="bg-transparent" />
      </ReportsList>
    </ReportsListWrapper>
  ) : null;
}
