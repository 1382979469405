import styled from 'styled-components';
import { Button } from '../Button';

export const FormContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 2rem;
  grid-row-gap: 2rem;
  grid-column-gap: 4rem;

  h1 {
    grid-column: span 2;
    font-size: 1.5rem;
    font-weight: 500;
  }

  label,
  input {
    font-size: 1rem;
  }

  .grid-column-1 {
    grid-column: 1;
  }

  .grid-row-span-2 {
    grid-row: span 2;
  }

  .avatar-upload-box {
    margin-left: auto;
    margin-right: 0;

    @media (max-width: 600px) {
      grid-row: 1;
      margin: 0 auto;
    }
  }

  .link-buttons-container {
    justify-self: center;
    display: flex;
    gap: 2rem;

    grid-column: span 2;
  }

  @media (max-width: 600px) {
    grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));

    h1 {
      grid-column: span 1;
    }

    .link-buttons-container {
      grid-column: span 1;
    }
  }
`;

/* export const IconLinkButton = styled(Button)`
  font-size: 1.25rem;
  min-height: 3rem;
  width: fit-content;

  padding: 1rem;

  margin-left: auto;
  margin-top: auto;
  border-radius: 1rem;

  svg {
    width: 1.25rem;
    height: 1.25rem;
    path {
      fill: var(--blue-400);
    }
  }
`; */

export const TextAreaContainer = styled.div`
  grid-column: 1 / span 2;

  label {
    display: block;
    margin-bottom: 0.375rem;
    padding-left: 0.75em;
  }

  textarea {
    font-family: "SF Pro Text", "Segoe UI", sans-serif;

    background: transparent;
    border: none;
    resize: none;

    font-size: 1rem;

    width: 100%;
    border-radius: 0.5em;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    height: 8rem;

    padding: 1em;

    &::placeholder {
      filter: opacity(0.75);
    }

    &:focus {
      outline: 2px solid var(--gray-500);
    }

    scrollbar-width: thin;
    scrollbar-color: var(--blue-400) #e5e5ea;

    &::-webkit-scrollbar {
      width: 0.5rem;
      height: 0.5rem;
    }

    &::-webkit-scrollbar-track {
      background-color: #e5e5ea;
      border-radius: 1rem;
      width: 1rem;
      height: 100%;
      margin: 1rem;
    }

    &::-webkit-scrollbar-thumb {
      width: 0.25rem;
      border-radius: 1rem;
      background-color: var(--blue-400);
    }
  }

  @media (max-width: 600px) {
    grid-column: unset;
    grid-row: unset;
  }
`;

/* export const IconButtonContent = styled.div`
  display: flex;
  align-items: center;
  gap: 1.625rem;
`; */

export const LinkServicesButton = styled(Button)`
  font-size: 1.25rem;
  padding: 0.5rem 2.5rem;

  margin: auto;

  height: fit-content;
`;

export const IconLinkButton = styled(Button)`
  font-size: 1rem;
  min-height: 3rem;
  width: 100%;
  max-width: 15rem;

  padding: 0 1rem;

  border-radius: 1rem;

  svg {
    width: 1.25rem;
    height: 1.25rem;
    path {
      fill: var(--blue-400);
    }
  }
`;

export const IconButtonContent = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 1.625rem;
`;

export const TherapistInformationContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 1rem;

  grid-row: span 2;

  flex-wrap: wrap-reverse;
`;

export const LinkButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  @media (max-width: 1300px) {
    flex-direction: row;
  }
`;
