import styled from 'styled-components';
import { ReactComponent as CheckSquareIcon } from '../../assets/images/check-square.svg';

import { Modal } from '../Modal';

interface INotificationModalProps {
  isOpen: boolean;
  onClose: () => void;
  message: string;
  type?: 'success' | 'error';
}

export interface IHandleNotificationModal {
  isOpen: boolean;
  onClose: () => void;
  message: string;
  type?: 'success' | 'error';
}

export default function NotificationModal({
  isOpen, onClose, message, type = 'success',
}: INotificationModalProps) {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
    >
      <Container>
        <CheckSquareIcon />
        <Message>
          {message}
        </Message>
      </Container>
    </Modal>
  );
}

const Container = styled.div`
  width: 100%;
  height: 100%;

  max-width: 400px;
  max-height: 300px;

  padding: 4rem;
  border-radius: 2rem;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;

  background-color: var(--white);

  box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.16);

  svg {
    width: 6.5rem;
    height: 6.5rem;
  }
`;

const Message = styled.h1`
  font-size: 1.625rem;
  font-weight: 700;
  color: var(--blue-400);
`;
