import { useRef, useState } from 'react';

import { ReactComponent as FilterIcon } from '../../assets/images/filter.svg';
import { ReactComponent as SearchIcon } from '../../assets/images/search.svg';

import {
  Container,
  FiltersContainer,
  Option,
  OptionInput,
  OptionsContainer,
  SearchBarButton,
  SearchBarInput,
} from './styles';
import { DropdownCheck } from './DropdownCheck';
import { useOutside } from '../../hooks/useOutside';

interface ISearchBoxProps extends React.InputHTMLAttributes<HTMLInputElement> {
  onSubmit: () => void;
  extraFilters?: {
    icon: JSX.Element;
    name: string;
    onChange: (value: string) => void;
    value: string;
    isDropdown?: boolean;
    dropdownOptions?: {
      label: string;
      value: string;
    }[];
  }[];
  shouldShowExtraFilters?: boolean;
  customClassName?: string;
}

export default function SearchBox({
  onSubmit,
  extraFilters,
  value,
  onChange,
  shouldShowExtraFilters = true,
  customClassName = '',
  ...rest
}: ISearchBoxProps) {
  const [areFiltersExpanded, setAreFiltersExpanded] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  const handleCloseFilters = () => {
    setAreFiltersExpanded(false);
  };

  const handleToggleFilters = () => {
    if (areFiltersExpanded) {
      handleCloseFilters();
    } else {
      setAreFiltersExpanded(true);
    }
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    onSubmit();
  };

  useOutside(containerRef, handleCloseFilters);

  return (
    <Container as="form" onSubmit={handleSubmit} className={customClassName}>
      <SearchBarButton type="submit">
        <SearchIcon />
      </SearchBarButton>
      <SearchBarInput type="text" value={value} onChange={onChange} {...rest} />
      <FiltersContainer isExpanded={areFiltersExpanded} ref={containerRef}>
        {shouldShowExtraFilters ? (
          <>
            <SearchBarButton type="button" onClick={handleToggleFilters}>
              <FilterIcon />
            </SearchBarButton>
            <OptionsContainer isExpanded={areFiltersExpanded}>
              {extraFilters?.map((filter) => (filter.isDropdown ? (
                <DropdownCheck
                  key={filter.name}
                  options={filter.dropdownOptions || []}
                  title={filter.name}
                  icon={filter.icon}
                  value={filter.value || ''}
                  onChange={filter.onChange}
                />
              ) : (
                <Option key={filter.name}>
                  {filter.icon}
                  <OptionInput
                    type="text"
                    placeholder={filter.name}
                    value={filter.value}
                    onChange={(e) => filter.onChange(e.target.value)}
                  />
                </Option>
              )))}
            </OptionsContainer>
          </>
        ) : null}
      </FiltersContainer>
    </Container>
  );
}
