import { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { toast } from 'react-toastify';
import { useNotificationModal } from '../../../hooks/useNotificationModal';

import {
  AdditionalInformationalForm,
  IAdditionalInformationFormData,
  initialAdditionalInformation,
} from '../AdditionalInformationForm';
import { AddressForm, IAddressFormData, initialAddress } from '../AddressForm';
import {
  IPersonalDetailsFormData,
  PersonalDetailsForm,
  initialPersonalDetails,
} from '../PersonalDetailsForm';

import { EditForm } from '../../EditForm';
import { api } from '../../../services/api';
import {
  IAppFormElement,
  IPartner,
  IPatient,
  ITherapistService,
  IUser,
  UserRoles,
} from '../../../types';
import { IConfirmData as IConfirmServicesData } from '../../LinkServicesModal';
import { IConfirmData as IConfirmPartnersData } from '../../LinkPartnersModal';
import { IConfirmData as IConfirmPatientsData } from '../../LinkPatientsModal';
import { maskPhone } from '../../../utils/maskPhone';
import { maskCpfOrCnpj } from '../../../utils/maskCpfOrCnpj';

interface IEditUserFormData {
  personalDetails: IPersonalDetailsFormData;
  address: IAddressFormData;
  additionalInfo: IAdditionalInformationFormData;
}

type FormDataTypes =
  | IPersonalDetailsFormData
  | IAddressFormData
  | IAdditionalInformationFormData;

export default function EditUserForm() {
  const [currentStep, setCurrentStep] = useState(0);
  const history = useHistory();
  const [editUserFormData, setEditUserFormData] = useState<IEditUserFormData>({
    personalDetails: initialPersonalDetails,
    address: initialAddress,
    additionalInfo: initialAdditionalInformation,
  });
  const [editUserFormServices, setEditUserFormServices] = useState<{
    therapistId: string;
    originalServices: ITherapistService[];
    addServiceIds: string[];
    removeServiceIds: string[];
  }>({
    therapistId: '',
    originalServices: [],
    addServiceIds: [],
    removeServiceIds: [],
  });
  const [editUserFormPartners, setEditUserFormPartners] = useState<{
    therapistId: string;
    originalPartners: IPartner[];
    addPartnerIds: string[];
    removePartnerIds: string[];
  }>({
    therapistId: '',
    originalPartners: [],
    addPartnerIds: [],
    removePartnerIds: [],
  });
  const [editUserFormPatients, setEditUserFormPatients] = useState<{
    userId: string;
    originalPatients: IPatient[];
    addPatientIds: string[];
    removePatientIds: string[];
  }>({
    userId: '',
    originalPatients: [],
    addPatientIds: [],
    removePatientIds: [],
  });
  const [avatar, setAvatar] = useState<File | null>(null);
  const currentFormRef = useRef<IAppFormElement>(null);
  const { notificate } = useNotificationModal();
  const [isLoading, setIsLoading] = useState(true);
  const { userId, userRole = 'therapist' } = useParams<{
    userId: string;
    userRole: UserRoles;
  }>();

  const editUserFormSteps = [
    {
      key: 'personalDetails',
      label: 'Dados pessoais',
    },
    {
      key: 'address',
      label: 'Endereço',
    },
    {
      key: 'additionalInfo',
      label: 'Dados adicionais',
    },
  ].slice(
    0,
    userRole === 'therapist'
      ? 3
      : userRole === 'patient'
        ? 2
        : editUserFormData.address.street
          ? 2
          : 1,
  );

  useEffect(() => {
    fetchUserInfo();
  }, [userId]);

  const fetchUserInfo = async () => {
    try {
      const response = await api.get<IUser>(`/user/${userId}`);

      const {
        name,
        email,
        phone,
        cpf,
        born,
        active,
        address,
        therapist,
        id_user,
        img_url,
      } = response.data;

      setEditUserFormServices((prevState) => ({
        ...prevState,
        originalServices: therapist?.therapistServices || [],
        therapistId: therapist?.id_therapist || '',
      }));

      setEditUserFormPartners((prevState) => ({
        ...prevState,
        originalPartners: therapist?.partners || [],
        therapistId: therapist?.id_therapist || '',
      }));

      setEditUserFormPatients((prevState) => ({
        ...prevState,
        originalPatients: therapist?.patients || [],
        userId: id_user || '',
      }));

      setEditUserFormData((prevState) => ({
        personalDetails: {
          ...prevState.personalDetails,
          name,
          email,
          phone: maskPhone(phone || ''),
          cpf: cpf ? maskCpfOrCnpj(cpf) : undefined,
          avatar_url: undefined,
          born: new Date(born).toISOString().split('T')[0].replace('+', ''),
          languages: therapist?.languages || '',
          img_url: img_url || '',
          formation: therapist?.formation || '',
          video: therapist?.video || '',
          aditionalCertificates: therapist?.aditional_certificates || '',
          created_at: new Date(response.data.created_at)
            .toISOString()
            .split('T')[0],
        },
        address: {
          ...prevState.address,
          ...address,
        },
        additionalInfo: {
          ...prevState.additionalInfo,
          ...therapist,
          therapistServices: undefined,
          cnpj: maskCpfOrCnpj(therapist?.cnpj || cpf || ''),
        },
      }));
      setIsLoading(false);
    } catch (error: any) {
      toast.error(
        error?.response?.data?.message || error.message || 'Algo deu errado',
      );
    }
  };

  const onStepChange = (step: number) => {
    setCurrentStep(step);
  };

  const handleGoBack = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    } else {
      history.goBack();
    }
  };

  const handleSuccess = () => {
    setEditUserFormData({
      personalDetails: initialPersonalDetails,
      address: initialAddress,
      additionalInfo: initialAdditionalInformation,
    });
    history.push('/usuarios');
  };

  const handleSubmit = async (data?: FormDataTypes) => {
    const newEditUserFormData = {
      ...editUserFormData,
      [editUserFormSteps[currentStep].key]: data,
    };

    setEditUserFormData(newEditUserFormData);
    if (currentStep !== editUserFormSteps.length - 1) {
      onStepChange(currentStep + 1);
    } else {
      if (userRole !== 'therapist') {
        handleAdmSubmit(newEditUserFormData);
        return;
      }
      const {
        name,
        email,
        password,
        phone,
        languages,
        born,
        formation,
        aditionalCertificates,
        video,
      } = newEditUserFormData.personalDetails;
      const {
        tip, presentation, cnpj, facebook, instagram, tiktok,
      } = newEditUserFormData.additionalInfo;
      const {
        zip, street, number, complement, district, city, uf,
      } = newEditUserFormData.address;

      const documentDigits = cnpj.replace(/\D/g, '');

      const dataToSubmit = {
        ...(documentDigits.length === 11 && { cpf: documentDigits, cnpj: undefined }),
        ...(documentDigits.length === 14 && { cnpj: documentDigits, cpf: undefined }),
        userId,
        name,
        email,
        phone,
        languages,
        born,
        formation,
        aditionalCertificates,
        tip,
        presentation,
        facebook,
        instagram,
        tiktok,
        video,
        password: password || undefined,
        img_url: undefined,
        address: {
          zip,
          street,
          number,
          complement,
          district,
          city,
          uf,
        },
      };

      const formDataToSubmit = new FormData();

      Object.entries(dataToSubmit).forEach(([key, value]) => {
        if (value != null) {
          formDataToSubmit.append(key, value.toString());
        }
      });

      formDataToSubmit.set('address', JSON.stringify(dataToSubmit.address));

      if (avatar) {
        formDataToSubmit.append('img', avatar);
      }

      try {
        await api.put('/therapist', formDataToSubmit, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        const { addServiceIds, removeServiceIds } = editUserFormServices;
        const { addPartnerIds, removePartnerIds } = editUserFormPartners;
        const { addPatientIds, removePatientIds } = editUserFormPatients;

        if (addServiceIds.length || removeServiceIds.length) {
          const servicesToSubmit = {
            id_therapist: editUserFormServices.therapistId,
            addServiceIds,
            removeServiceIds,
          };

          await api.patch('/therapist/service', servicesToSubmit);
        }

        if (addPatientIds.length || removePatientIds.length) {
          const patientsToSubmit = {
            userId: editUserFormPatients.userId,
            addPatientIds,
            removePatientIds,
          };

          await api.patch('/patient/therapist', patientsToSubmit);
        }

        if (addPartnerIds.length || removePartnerIds.length) {
          const partnersToSubmit = {
            id_therapist: editUserFormPartners.therapistId,
            addPartnerIds,
            removePartnerIds,
          };

          await api.patch('/therapist/partner', partnersToSubmit);
        }

        notificate('Salvo com sucesso!', handleSuccess);
      } catch (error: any) {
        toast.error(
          error?.response?.data?.message || error.message || 'Algo deu errado',
        );
      }
    }
  };

  const handleAdmSubmit = async (data: IEditUserFormData) => {
    const {
      name, email, password, phone, born, img_url, cpf,
    } = data.personalDetails;
    const {
      street, number, complement, district, city, uf, zip,
    } = data.address;

    const dataToSubmit = {
      userId,
      name,
      email,
      languages: undefined,
      born: new Date(born).toISOString().split('T')[0],
      active: true,
      phone: phone || undefined,
      cpf: cpf || undefined,
      img_url: undefined,
      password: password || undefined,
      address: street
        ? {
          street,
          number,
          complement,
          district,
          city,
          uf,
          zip,
        }
        : undefined,
    };

    try {
      await api.put('/user', dataToSubmit);

      notificate('Salvo com sucesso!', handleSuccess);
    } catch (error: any) {
      toast.error(
        error?.response?.data?.message || error.message || 'Algo deu errado',
      );
    }
  };

  const handleDeleteUser = async () => {
    try {
      await api.delete(`/user/${userId}`);

      history.push('/usuarios');
    } catch (error: any) {
      toast.error(
        error?.response?.data?.message || error.message || 'Algo deu errado',
      );
    }
  };

  const handleAvatarUpload = (file: File) => {
    setAvatar(file);
  };

  const handleClickNext = (event: React.FormEvent) => {
    event.preventDefault();
    if (!currentFormRef.current) return;
    currentFormRef.current.requestSubmit();
  };

  const handleLinkServicesConfirm = (confirmData: IConfirmServicesData) => {
    setEditUserFormServices((prevState) => ({
      ...prevState,
      addServiceIds: confirmData.addServiceIds,
      removeServiceIds: confirmData.removeServiceIds,
    }));
  };

  const handleLinkPartnersConfirm = (confirmData: IConfirmPartnersData) => {
    const { addPartnerIds, removePartnerIds } = confirmData;

    const filteredPartnersToAdd = editUserFormPartners.addPartnerIds.filter(
      (id) => !removePartnerIds.includes(id),
    );

    const filteredPartnersToRemove = editUserFormPartners.removePartnerIds.filter(
      (id) => !addPartnerIds.includes(id)
          && editUserFormPartners.originalPartners.some(
            (partner) => partner.id_partner === id,
          ),
    );

    const partnersToAdd = Array.from(
      new Set([...filteredPartnersToAdd, ...addPartnerIds]),
    );

    const partnersToRemove = Array.from(
      new Set([...filteredPartnersToRemove, ...removePartnerIds]),
    );

    setEditUserFormPartners((prevState) => ({
      ...prevState,
      addPartnerIds: partnersToAdd,
      removePartnerIds: partnersToRemove,
    }));
  };

  const handleLinkPatientsConfirm = (confirmData: IConfirmPatientsData) => {
    const { addPatientIds, removePatientIds } = confirmData;

    const filteredPatientsToAdd = editUserFormPatients.addPatientIds.filter(
      (id) => !removePatientIds.includes(id),
    );

    const filteredPatientsToRemove = editUserFormPatients.removePatientIds.filter(
      (id) => !addPatientIds.includes(id)
          && editUserFormPatients.originalPatients.some(
            (patient) => patient.id_patient === id,
          ),
    );

    const patientsToAdd = Array.from(
      new Set([...filteredPatientsToAdd, ...addPatientIds]),
    );

    const patientsToRemove = Array.from(
      new Set([...filteredPatientsToRemove, ...removePatientIds]),
    );

    setEditUserFormPatients((prevState) => ({
      ...prevState,
      addPatientIds: patientsToAdd,
      removePatientIds: patientsToRemove,
    }));
  };

  const getCurrentPartnersIds = () => {
    const { originalPartners, addPartnerIds, removePartnerIds } = editUserFormPartners;

    const initialPartnerIds = originalPartners.map(
      (partner) => partner.id_partner,
    );

    const filteredPartnerIds = initialPartnerIds.filter(
      (id) => !removePartnerIds.includes(id),
    );

    const newPartnerIds = Array.from(
      new Set([...addPartnerIds, ...filteredPartnerIds]),
    );

    return newPartnerIds;
  };

  const getCurrentPatientsIds = () => {
    const { originalPatients, addPatientIds, removePatientIds } = editUserFormPatients;

    const initialPatientIds = originalPatients.map(
      (patient) => patient.id_patient,
    );

    const filteredPatientIds = initialPatientIds.filter(
      (id) => !removePatientIds.includes(id),
    );

    const newPatientIds = Array.from(
      new Set([...addPatientIds, ...filteredPatientIds]),
    );

    return newPatientIds;
  };

  const formProps = {
    onSubmit: handleSubmit,
    title: editUserFormSteps[currentStep]?.label || '',
    ref: currentFormRef,
  };

  const editUserFormComponents = {
    personalDetails: (
      <PersonalDetailsForm
        {...formProps}
        initialData={editUserFormData.personalDetails}
        onAvatarUpload={handleAvatarUpload}
        userType={userRole}
        isEditing
        partnerIds={getCurrentPartnersIds()}
        onConfirmLinkPartners={handleLinkPartnersConfirm}
        patientIds={getCurrentPatientsIds()}
        onConfirmLinkPatients={handleLinkPatientsConfirm}
      />
    ),
    address: (
      <AddressForm {...formProps} initialData={editUserFormData.address} />
    ),
    additionalInfo: (
      <AdditionalInformationalForm
        {...formProps}
        initialData={editUserFormData.additionalInfo}
        serviceIds={editUserFormServices.originalServices.map(
          (service) => service.service_id,
        )}
        onConfirmLinkServices={handleLinkServicesConfirm}
        partnerIds={getCurrentPartnersIds()}
        onConfirmLinkPartners={handleLinkPartnersConfirm}
        patientIds={getCurrentPatientsIds()}
        onConfirmLinkPatients={handleLinkPatientsConfirm}
      />
    ),
  } as {
    [key: string]: JSX.Element;
  };

  if (isLoading) {
    return null;
  }

  return (
    <EditForm
      title="Editar usuário"
      onArrowClick={handleGoBack}
      onSubmit={handleClickNext}
      buttonText={
        currentStep === editUserFormSteps.length - 1
          ? 'Salvar alterações'
          : 'Próximo'
      }
      formSteps={editUserFormSteps.slice(0, currentStep)}
      onStepChange={onStepChange}
      onDeleteClick={handleDeleteUser}
    >
      {editUserFormComponents[editUserFormSteps[currentStep]?.key]}
    </EditForm>
  );
}
