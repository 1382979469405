import {
  ForwardedRef,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { IAppFormElement, IGeolocation } from '../../types';
import { AddressModal } from '../AddressModal';
import { IAddressFormData } from '../User/AddressForm';
import { FormContainer } from '../User/styles';
import {
  RightArrow,
  TextButton,
  TherapistPointButton,
  TherapistPointButtonText,
  TherapistPointLabel,
} from './styles';

export interface IPointFormData {
  id_address?: string;
  zip: string;
  street: string;
  number: string;
  district: string;
  city: string;
  uf: string;
  radius: number;
  complement?: string;
  lat?: number | null;
  lng?: number | null;

  wasChanged?: boolean;
}

interface ITherapistPointsFormProps {
  initialData: IPointFormData[];
  onSubmit: (data: IPointFormData[]) => void;
  title?: string;
}

export const initialPoint: IPointFormData[] = [
  {
    zip: '',
    street: '',
    number: '',
    district: '',
    city: '',
    uf: '',
    radius: 0,
    wasChanged: false,
  },
];

function formatAddress(address: IPointFormData, radius?: string | number) {
  const {
    street, number, district, city, uf, zip,
  } = address;

  if (!street) {
    return '';
  }

  return `${
    radius ? `Raio de ${radius}km - ` : ''
  } ${street}, ${number} - ${district} - ${city} - ${uf} - ${zip}`;
}

export const TherapistPointsForm = forwardRef(
  (
    { initialData, onSubmit, title }: ITherapistPointsFormProps,
    ref: ForwardedRef<IAppFormElement>,
  ) => {
    const [formData, setFormData] = useState(initialData);
    const [selectedPointIndex, setSelectedPointIndex] = useState<
      undefined | number
    >();

    useImperativeHandle(ref, () => ({
      requestSubmit: () => {
        onSubmit(
          formData.map((point) => ({
            ...point,
            complement: point.complement || undefined,
            zip: point.zip.replace(/\D/g, ''),
          })),
        );
      },
      getFormData: () => formData,
    }));

    useEffect(() => {
      setFormData(initialData);
    }, [initialData]);

    const handleAddressModalConfirmation = (
      address: IAddressFormData,
      geolocation: IGeolocation,
      radius?: string,
    ) => {
      setFormData((prevState) => prevState.map((point, index) => {
        if (index === selectedPointIndex) {
          return {
            ...point,
            ...address,
            lat: geolocation.lat,
            lng: geolocation.lng,
            radius: radius ? parseInt(radius, 10) : 0,
            wasChanged: true,
          };
        }
        return point;
      }));
    };

    const handleOpenAddressModal = (index: number) => {
      setSelectedPointIndex(index);
    };

    const handleAddNewPoint = () => {
      setFormData([...formData, ...initialPoint]);
    };

    return (
      <>
        <FormContainer>
          {title && <h1>{title}</h1>}
          {formData.map((point, index) => (
            <TherapistPointLabel
              key={point.id_address || `therapist-point-${index}`}
            >
              <span>
                Ponto de atendimento
                {' '}
                {index + 1}
              </span>
              <TherapistPointButton
                type="button"
                onClick={() => handleOpenAddressModal(index)}
              >
                <TherapistPointButtonText isEmpty={!point?.street}>
                  {formatAddress(point, point?.radius)
                    || 'Selecione o endereço'}
                </TherapistPointButtonText>
                <RightArrow />
              </TherapistPointButton>
            </TherapistPointLabel>
          ))}
        </FormContainer>
        <TextButton type="button" center onClick={handleAddNewPoint}>
          Adicionar novo ponto de atendimento
        </TextButton>
        <AddressModal
          isOpen={!(selectedPointIndex == null)}
          onClose={() => setSelectedPointIndex(undefined)}
          onConfirm={handleAddressModalConfirmation}
          initialAddress={selectedPointIndex != null ? formData[selectedPointIndex] : undefined}
          initialRadius={
            formData[selectedPointIndex ?? 0]?.radius
              ? formData[selectedPointIndex ?? 0]?.radius.toString()
              : undefined
          }
          shouldNotConsiderRadius={false}
        />
      </>
    );
  },
);
