import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';

export const Container = styled.div<{
  isExpanded?: boolean;
}>`
  position: absolute;
  top: 0;
  left: 0;

  z-index: 10;

  width: 7.5%;
  min-width: fit-content;

  min-height: ${({ isExpanded }) => (isExpanded ? '100vh' : '100%')};
  max-height: ${({ isExpanded }) => (isExpanded ? '1080px' : '100%')};

  background-color: var(--blue-400);
  border-bottom-right-radius: 2rem;

  display: flex;
  flex-direction: column;
  // justify-content: ${({ isExpanded }) => (isExpanded ? 'space-between' : 'center')};
  // align-items: center;

  transition: all 0.5s;

  padding: 1.75rem max(1.125rem, 2.0625vw) 1.75rem max(1.75rem, 2.5vw);

  ${({ isExpanded }) => isExpanded && css`
    &:hover {
      ${NavBarTitle}{
        transition: max-width 0.6s, overflow 0.3s;
        max-width: 200px;
      }
    }
  `}
`;

export const NavBarTitle = styled.span`
  transition: max-width 0.6s, overflow 0.6s;
  color: #fff;
  max-width: 0px;
  overflow: hidden;
  white-space: nowrap;

  font-size: 1rem;
`;

export const HamburgerButton = styled.button`
  background-color: transparent;
  border: none;

  padding-left: 0.25rem;

  margin-right: auto;

  display: flex;
  align-items: center;
  gap: 1rem;

  transition: filter 0.2s;

  &:hover {
    filter: brightness(0.9);
  }

  svg {
    width: 2rem;
    height: 1.375rem;
  }
`;

export const NavList = styled.nav<{
  isExpanded?: boolean;
}>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  gap: 1rem;

  visibility: ${({ isExpanded }) => (isExpanded ? 'visible' : 'hidden')};
  max-height: ${({ isExpanded }) => (isExpanded ? '100%' : '0px')};

  padding-bottom: ${({ isExpanded }) => (isExpanded ? '2rem' : '0')};

  transition: max-height 0.2s, visibility 0.1s;
`;

export const NavBarLink = styled(NavLink)`
  position: relative;

  display: flex;
  align-items: center;
  gap: 0.75rem;

  text-decoration: none;

  &.navbar__profile-link {
    margin-top: auto;
  }

  transition: filter 0.2s;

  &:hover {
    filter: brightness(0.9);
  }

  svg {
    width: 2.5rem;
    height: 2.5rem;

    path {
      fill: var(--white);
    }
  }
`;
