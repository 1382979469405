import styled, { css } from 'styled-components';

export const Container = styled.div`
  position: relative;

  z-index: 5;

  width: 100%;
  height: 3em;

  padding: 0 1.5em;

  display: flex;
  justify-content: space-between;
  align-items: center;

  border-radius: 1em;

  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
`;

export const SearchBarButton = styled.button`
  background-color: transparent;
  border: none;
  display: flex;
  align-items: center;

  transition: filter 0.2s;

  &:hover {
    filter: brightness(0.9);
  }

  svg {
    height: 1rem;
    width: 1rem;
  }
`;

export const SearchBarInput = styled.input`
  border: none;
  outline: none;

  font-size: 1em;

  flex: 1;

  margin-left: 1rem;

  &::placeholder {
    filter: opacity(0.5);
  }
`;

export const FiltersContainer = styled.div<{
  isExpanded?: boolean;
}>`
  position: absolute;
  top: 0;
  right: 0;

  border-radius: 1em;

  padding: 2em;
  padding-right: 4em;

  transition: all 0.5s;

  background-color: transparent;
  min-height: 100%;
  max-height: 100%;

  ${({ isExpanded }) => isExpanded
    && css`
      background-color: var(--blue-400);
      min-height: fit-content;
      max-height: max-content;

      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    `}

  > button {
    position: absolute;
    top: 1rem;
    right: 1rem;

    > svg {
      path {
        fill: ${({ isExpanded }) => (isExpanded ? 'var(--white)' : 'var(--blue-400)')};
      }

    }
  }

  @media (max-width: 800px) {
    > button {
      top: 0.875rem;
    }
  }
  `;

export const OptionsContainer = styled.div<{
  isExpanded?: boolean;
}>`
  display: flex;
  flex-direction: column;

  max-height: 0px;
  min-height: 0px;

  overflow: hidden;

  transition: max-height 0.5s;

  ${({ isExpanded }) => isExpanded
    && css`
      max-height: max-content;
    `}

  > * + * {
    margin-top: 1rem;
  }
`;

export const Option = styled.div`
  display: flex;
  align-items: center;
  background-color: var(--white);

  min-height: 3em;
  padding: 0 1em;

  min-width: 14rem;

  border-radius: 0.5em;

  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);

  svg {
    height: 1.25rem;
    width: 1.25rem;
    margin-right: 1em;
  }
`;

export const OptionInput = styled.input`
  border: none;
  outline: none;

  font-size: 1em;

  flex: 1;

  margin-right: auto;
  margin-left: 0;

  &::placeholder {
    color: var(--gray-500);
    filter: opacity(1);
  }
`;

export const DropdownContainer = styled.div<{
  isExpanded?: boolean;
}>`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  background-color: var(--white);

  min-height: 3em;
  padding: 1em;

  min-width: 14rem;

  border-radius: 0.5em;

  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
`;

export const IconTextButton = styled.button`
  background: transparent;
  border: none;

  display: flex;
  align-items: center;
  margin-right: auto;

  font-size: 1rem;

  svg {
    height: 1.25rem;
    width: 1.25rem;
    margin-right: 1rem;
  }
`;

export const RadioLabel = styled.label`
  display: flex;
  align-items: center;
  margin-right: auto;

  cursor: pointer;

  &:first-of-type {
    margin-top: 1rem;
  }
`;

export const StyledRadioInput = styled.input`
  width: 1rem;
  height: 1rem;
  margin-right: 0.5rem;
  margin-left: 0.125rem;
`;
