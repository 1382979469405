import styled from 'styled-components';
import DatePicker, { ReactDatePickerCustomHeaderProps, ReactDatePickerProps, registerLocale } from 'react-datepicker';

import format from 'date-fns/format';

import ptBR from 'date-fns/locale/pt-BR';

import 'react-datepicker/dist/react-datepicker.css';

import { ReactComponent as ArrowBackIcon } from '../../assets/images/arrow-back.svg';

interface IAppDatePickerProps extends ReactDatePickerProps<
  '', true | undefined
> {
  customClassName?: string;
  icon?: JSX.Element;
  leftIcon?: boolean;
}

registerLocale('pt-BR', ptBR);

function renderCustomHeader({
  date, decreaseMonth, increaseMonth, ...rest
}: ReactDatePickerCustomHeaderProps) {
  const formattedDate = format(date, 'MMMM, yyyy', { locale: ptBR });

  function handleDecreaseMonth() {
    decreaseMonth();
  }

  function handleIncreaseMonth() {
    increaseMonth();
  }

  return (
    <CustomHeaderContainer>
      <button type="button" onClick={handleDecreaseMonth}>
        <ArrowBackIcon />
      </button>
      <span>
        {formattedDate}
      </span>
      <button className="app-datepicker__header-button--rotate" type="button" onClick={handleIncreaseMonth}>
        <ArrowBackIcon />
      </button>
    </CustomHeaderContainer>
  );
}

function formatWeekDay(day: string): React.ReactNode {
  return (
    <span className="app-datepicker__weekday">
      {day.substring(0, 3)}
    </span>
  );
}

export default function AppDatePicker({
  customClassName, icon, leftIcon = true, ...rest
}: IAppDatePickerProps) {
  return (
    <Container className={customClassName} leftIcon={leftIcon} hasIcon={!!icon}>
      {leftIcon ? icon && icon : null}
      <DatePicker
        locale="pt-BR"
        renderCustomHeader={renderCustomHeader}
        formatWeekDay={formatWeekDay}
        showPopperArrow={false}
        {...rest}
      />
      {leftIcon ? null : icon && icon}
    </Container>
  );
}

const Container = styled.div<{
  leftIcon?: boolean;
  hasIcon?: boolean;
}>`
  display: flex;
  align-items: center;
  width: fit-content;

  height: 3em;
  border-radius: 0.5em;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);

  padding: 1em;

  transition: all 0.3s;

  .react-datepicker {
    font-family: 'SF Pro Text', sans-serif;

    font-size: inherit;

    padding: 1.5em 2em;

    border: none;

    border-radius: 2rem;

    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  }

  .react-datepicker__header {
    background: #fff;
    border: none;
  }

  .react-datepicker__day-names {
    display: flex;
    justify-content: space-between;

    background-color: var(--purple-50);
    color: var(--blue-400);

    border-radius: 0.5rem;

    margin-top: 1.5rem;

    padding: 0.5em 0 ;

  }

  .react-datepicker__day-name {
    color: inherit;
    font-size: inherit;

    font-size: 0.875em;

    margin: auto 0.5em;

    width: 2.25em;
    max-width: 2.25em;
  }

  .app-datepicker__weekday {
    text-transform: capitalize;
    color: inherit;

    font-size: 0.875em;
    font-weight: bold;
  }

  .react-datepicker__month {
    margin: 0;
  }

  .react-datepicker__week {
    display: flex;
    justify-content: space-between;
  }

  .react-datepicker__day {
    font-size: 0.875em;

    width: 2.25em;
    height: 2.25em;

    max-width: 2.25em;

    border-radius: 0.5rem;

    margin: 0.5em;

    line-height: 2.25em;

    text-align: center;
    vertical-align: center;

    color: var(--gray-500);
  }

  .react-datepicker__input-container {
    > input {
      font-size: 1em;
      border: none;
      outline: none;
      padding-left: ${({ leftIcon, hasIcon }) => (leftIcon && hasIcon ? '1em' : '0')};
    }
  }


  // hide calendar days that arent the current month
  .react-datepicker__day--outside-month {
    visibility: hidden;
  }


  // all classes for selected days
  .react-datepicker__day--selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--selecting-range-start,
  .react-datepicker__day--in-range,
  .react-datepicker__month-text--selected,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--selected,
  .react-datepicker__quarter-text--in-selecting-range,
  .react-datepicker__quarter-text--in-range,
  .react-datepicker__year-text--selected,
  .react-datepicker__year-text--in-selecting-range,
  .react-datepicker__year-text--in-range,
  .react-datepicker__day--keyboard-selected,
  .react-datepicker__day--keyboard-selected:hover {
    background-color: var(--blue-400);

    color: var(--white);
  }

  .react-datepicker__day--disabled {
    opacity: 0.2;
  }

  @media (max-width: 350px) {
    .react-datepicker {
      padding: 0.75em 0.5em;
    }
  }

`;

const CustomHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  > * + * {
    margin-left: 1em;
  }

  span {
    font-size: 1rem;
    font-weight: 700;
    color: var(--gray-500);

    text-transform: capitalize;
  }

  button {
    background: transparent;
    border: none;

    display: flex;
    align-items: center;
    justify-content: center;

    height: 0.5rem;
    width: 0.5rem;
  }

  path {
    fill: var(--blue-400);
  }

  .app-datepicker__header-button--rotate {
    transform: rotate(180deg);
  }
`;
