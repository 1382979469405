import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 4.75rem;
  row-gap: 2rem;

  padding: 2rem;

  max-width: 100vw;

  input, label {
    font-size: 1rem;
  }

  @media (max-width: 600px) {
    grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
  }
`;
