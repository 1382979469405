import { useRef, useState } from 'react';

import { ReactComponent as DeleteIcon } from '../../assets/images/delete.svg';
import { ReactComponent as PlusIcon } from '../../assets/images/plus.svg';

import { useOutside } from '../../hooks/useOutside';
import { IPartnerType } from '../../types';
import {
  Container,
  DropdownContainer,
  IconButton,
  NewValueContainer,
  NewValueInput,
  Option,
  OptionsContainer,
  OptionsWrapper,
  OptionWrapper,
  RemoveButton,
  StyledLabel,
} from './styles';

interface IPartnerTypeDropdownProps {
  partnerTypes: IPartnerType[];
  value: string;
  label: string;
  onChange: (text: string) => void;
  onAddNewValue?: (text: string) => void;
  onRemoveType: (type: IPartnerType) => void;
  customClassName?: string;
  placeholder?:string;
}

export default function PartnerTypeDropdown({
  customClassName,
  partnerTypes,
  onChange,
  value,
  label,
  placeholder,
  onRemoveType,
  onAddNewValue = () => {},
}: IPartnerTypeDropdownProps) {
  const [isExpanded, setIsExpanded] = useState(false);
  const [newValue, setNewValue] = useState('');
  const containerRef = useRef<HTMLDivElement>(null);

  const toggle = () => {
    setIsExpanded((prevState) => !prevState);
  };

  const handleClose = () => {
    setIsExpanded(false);
  };

  const handleAddNewValue = () => {
    if (newValue) {
      onAddNewValue(newValue);
      handleChangeValue(newValue);
      setNewValue('');
    }
  };

  const handleChangeValue = (text: string) => {
    onChange(text);
    handleClose();
  };

  if (!containerRef) {
    return null;
  }

  useOutside(containerRef, handleClose);

  const selectedType = partnerTypes.find((partnerType) => partnerType.type === value)?.type;

  return (
    <Container ref={containerRef} className={customClassName}>
      <StyledLabel htmlFor="dropdown-partner-types">
        {label ? <span>{label}</span> : null}
      </StyledLabel>
      <DropdownContainer isExpanded={isExpanded}>
        <button id="dropdown-partner-types" type="button" onClick={toggle}>
          {selectedType || value || placeholder || 'Selecione...'}
        </button>
        <OptionsWrapper isExpanded={isExpanded}>
          <OptionsContainer>
            {partnerTypes.map((partnerType) => (
              <OptionWrapper key={partnerType.id_type}>
                <Option
                  type="button"
                  onClick={() => handleChangeValue(partnerType.type)}
                >
                  {partnerType.type}
                </Option>
                <RemoveButton type="button" onClick={() => onRemoveType(partnerType)}>
                  <DeleteIcon />
                </RemoveButton>
              </OptionWrapper>
            ))}
            <NewValueContainer>
              <NewValueInput
                type="text"
                placeholder="Adicionar novo..."
                value={newValue}
                onChange={(e) => setNewValue(e.target.value)}
              />
              <IconButton type="button" onClick={handleAddNewValue}>
                <PlusIcon />
              </IconButton>
            </NewValueContainer>
          </OptionsContainer>
        </OptionsWrapper>
      </DropdownContainer>
    </Container>
  );
}
