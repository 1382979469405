import PlaceholderImage from '../../../assets/images/placeholder-image.png';

import { ImageUploadBox } from '../../ImageUploadBox';
import { Input } from '../../Input';

export interface IServiceFormData {
  id?: string;
  name: string;
  description: string;
  price: string;
  logo: File | null;
  logoUrl?: string;
  img_url?: string;
}

interface IServiceFormFieldsProps {
  data: IServiceFormData;
  onInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onLogoChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export default function ServiceFormFields({
  data, onInputChange, onLogoChange,
}: IServiceFormFieldsProps) {
  return (
    <>
      <Input
        name="name"
        label="Nome"
        type="text"
        placeholder="Insira o nome do serviço"
        onChange={onInputChange}
        value={data.name}
        required
      />
      <ImageUploadBox
        id="logo"
        label={
          data.img_url
            ? 'Altere a logo'
            : 'Insira a logo'
        }
        imgUrl={data.img_url}
        placeholderUrl={PlaceholderImage}
        onChange={onLogoChange}
        customClassName="grid-row-span-2"
      />
      <Input
        name="description"
        label="Descrição"
        type="text"
        placeholder="Insira a descrição do serviço"
        onChange={onInputChange}
        value={data.description}
        required
      />
      <Input
        name="price"
        label="Valor"
        type="number"
        placeholder="Insira o valor do serviço"
        onChange={onInputChange}
        value={data.price}
        min="0"
        required
      />
    </>
  );
}
