export const applySort = <T>(data: T[], sortBy: string, sortDir: string): T[] => {
  if (!sortBy || !sortDir) {
    return data;
  }
  const castedSortBy = sortBy as keyof T;
  const sortedData = data.sort((a, b) => {
    if (a[castedSortBy] < b[castedSortBy]) {
      return sortDir === 'asc' ? -1 : 1;
    }
    if (a[castedSortBy] > b[castedSortBy]) {
      return sortDir === 'asc' ? 1 : -1;
    }
    return 0;
  });

  return sortedData;
};
