import { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { ReactComponent as HeartIcon } from '../../../assets/images/heart.svg';
import { ReactComponent as ArrowDownFilledIcon } from '../../../assets/images/arrow-down-filled.svg';

import { Button } from '../../Button';
import { Card, CardsContainer, Container } from './styles';

export default function AddUser() {
  const [selectedType, setSelectedType] = useState<'terapeuta' | 'interno' | ''>('');
  const history = useHistory();

  const handleChangeSelectedType = (type: 'terapeuta' | 'interno') => {
    if (type === selectedType) {
      setSelectedType('');
    } else {
      setSelectedType(type);
    }
  };

  const handleButtonClick = () => {
    history.push(`/usuarios/adicionar/prestador/${selectedType}`);
  };

  return (
    <Container>
      <h1>Selecione o perfil do usuário</h1>
      <CardsContainer>
        <Card as="button" onClick={() => handleChangeSelectedType('terapeuta')} isActive={selectedType === 'terapeuta'}>
          <HeartIcon />
          <h2>Terapeuta</h2>
        </Card>
        <Card as="button" onClick={() => handleChangeSelectedType('interno')} isActive={selectedType === 'interno'}>
          <ArrowDownFilledIcon />
          <h2>Interno</h2>
        </Card>
      </CardsContainer>
      <Button type="button" disabled={selectedType === ''} onClick={handleButtonClick}>
        Próximo
      </Button>
    </Container>
  );
}
