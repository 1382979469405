import { useEffect, useReducer, useState } from 'react';

import addDays from 'date-fns/addDays';
import addMonths from 'date-fns/addMonths';
import format from 'date-fns/format';
import ptBR from 'date-fns/locale/pt-BR';
import isSameDay from 'date-fns/isSameDay';
import isWithinInterval from 'date-fns/isWithinInterval';
import parseISO from 'date-fns/parseISO';

import { toast } from 'react-toastify';

import startOfDay from 'date-fns/startOfDay';
import { ReactComponent as ArrowBackIcon } from '../../assets/images/arrow-back.svg';

import { AppDatePicker } from '../../components/AppDatePicker';
import { Scheduler } from '../../components/Scheduler';

import { api } from '../../services/api';

import { IAppointment, IDay, ISchedule } from '../../types';

import { ManageAgenda } from '../../components/Agenda/ManageAgenda';
import { schedulesReducer } from './schedulesReducer';

import {
  CalendarContainer,
  CalendarHeader,
  CalendarIntervalOptions,
  Container,
  DayButton,
  DaysContainer,
  DaySelector,
  HeaderButton,
  HeaderButtonsContainer,
  IntervalButton,
  IntervalSelector,
  MonthSelector,
} from './styles';
import { AgendaType, AgendaTypesIndicators } from '../../components/Agenda/AgendaTypesIndicators';

function areSpecificDatesCompatible(initialDay: Date, finalDay: Date, date: Date): boolean {
  try {
    return isWithinInterval(date, {
      start: initialDay,
      end: finalDay,
    });
  } catch (error: any) {
    return false;
  }
}

const daysOfTheWeek = [
  'dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sab',
];

function areWeekdaysCompatible(days: IDay[], currentDay: Date) {
  const day = currentDay.getDay();
  const daysIndexes = days.reduce((acc, el) => {
    const index = daysOfTheWeek.findIndex((dayOfTheWeek) => dayOfTheWeek === el.name);

    if (index > -1) {
      return [...acc, index];
    }
    return acc;
  }, [] as number[]);

  return daysIndexes.includes(day);
}

function getAgendaTypesOnDay(schedules: ISchedule[], day: Date): AgendaType[] {
  return schedules.reduce((acc, schedule) => {
    if (schedule.frequency === 'recurrent') {
      const weekDayCompatible = areWeekdaysCompatible(schedule.days, day);

      if (weekDayCompatible) {
        if (acc.includes(schedule.type_name)) {
          return acc;
        }
        return [...acc, schedule.type_name];
      }
      return acc;
    }
    const specificCompatible = areSpecificDatesCompatible(parseISO(schedule?.initial_date || ''), parseISO(schedule?.final_date || ''), day);

    if (specificCompatible) {
      if (acc.includes(schedule.type_name)) {
        return acc;
      }
      return [...acc, schedule.type_name];
    }
    return acc;
  }, [] as AgendaType[]);
}

export default function Agenda() {
  const [selectedOption, setSelectedOption] = useState<'calendar' | 'config'>('calendar');
  const [selectedInterval, setSelectedInterval] = useState<'week' | 'month'>('week');
  const [selectedDay, setSelectedDay] = useState<Date>(startOfDay(new Date()));
  const [schedules, dispatch] = useReducer(schedulesReducer, []);
  const [appointments, setAppointments] = useState<IAppointment[]>([]);

  useEffect(() => {
    fetchSchedules();
    fetchAppointments();
  }, []);

  const weekDays = Array.from({ length: 7 }, (_, index) => {
    const selectedDayOfWeek = selectedDay.getDay();
    const date = addDays(selectedDay, index - selectedDayOfWeek);

    const types = getAgendaTypesOnDay(schedules, date);

    return {
      date,
      types,
    };
  });

  const fetchSchedules = async () => {
    try {
      const response = await api.get<ISchedule[]>('/schedule');

      dispatch({ type: 'SET_SCHEDULES', payload: { schedules: response.data } });
    } catch (error: any) {
      toast.error(error?.response?.data?.message || error?.message);
    }
  };

  const fetchAppointments = async () => {
    try {
      const response = await api.get<IAppointment[]>('/appointment');

      setAppointments(response.data);
    } catch (error: any) {
      toast.error(
        error?.response?.data?.message
          || 'Erro ao obter os agendamentos, tente novamente mais tarde',
      );
    }
  };

  const handleIncreaseWeek = () => {
    setSelectedDay(addDays(selectedDay, 7));
  };

  const handleDecreaseWeek = () => {
    setSelectedDay(addDays(selectedDay, -7));
  };

  const handleIncreaseMonth = () => {
    setSelectedDay(addMonths(selectedDay, 1));
  };

  const handleDecreaseMonth = () => {
    setSelectedDay(addMonths(selectedDay, -1));
  };

  const activeSchedules = schedules.filter((schedule) => schedule.active);

  const selectedDaySchedules = activeSchedules.filter((schedule) => {
    const { frequency } = schedule;
    const { initialDate, finalDate } = schedule;

    if (frequency === 'recurrent') {
      const selectedDayWeekDay = selectedDay.toLocaleDateString('pt-BR', { weekday: 'long' }).split('-')[0].substring(0, 3).toLowerCase().replace('á', 'a');
      const isSelectedDayRecurrentWeekday = schedule.days.some(
        (day) => day.name === selectedDayWeekDay,
      );

      if (isSelectedDayRecurrentWeekday) {
        return true;
      }
      return false;
    }

    if (frequency === 'specific') {
      if (initialDate && finalDate) {
        const isSelectedDayBetweenDates = selectedDay >= initialDate && selectedDay <= finalDate;

        if (isSelectedDayBetweenDates) {
          return true;
        }
        return false;
      }
    }
    return false;
  });

  const acceptedDaysAppointments = appointments.filter((appointment) => {
    const {
      datetime, frequency, week_day_index, status,
    } = appointment;

    if (status !== 'accepted') {
      return false;
    }

    if (frequency === 'specific') {
      const date = parseISO(datetime);
      return isSameDay(date, selectedDay);
    }

    return selectedDay.getDay() === week_day_index;
  });

  const renderMonthlyCalendarDay = (dayOfMonth: number, date: Date) => {
    const agendaTypes = getAgendaTypesOnDay(activeSchedules, date);

    return (
      <div style={{ position: 'relative' }}>
        {dayOfMonth}
        {/* <AgendaTypesIndicators types={agendaTypes} /> */}
      </div>
    );
  };

  return (
    <Container>
      <HeaderButtonsContainer>
        <HeaderButton type="button" isActive={selectedOption === 'calendar'} onClick={() => { setSelectedOption('calendar'); }}>
          Calendário
        </HeaderButton>
        <HeaderButton type="button" isActive={selectedOption === 'config'} onClick={() => { setSelectedOption('config'); }}>
          Configurar
        </HeaderButton>
      </HeaderButtonsContainer>
      {
        selectedOption === 'calendar' ? (
          <CalendarContainer>
            <CalendarHeader>
              <CalendarIntervalOptions>
                <MonthSelector>
                  <button type="button" onClick={handleDecreaseMonth}>
                    <ArrowBackIcon />
                  </button>
                  <span>
                    {format(selectedDay, 'MMMM, yyyy', { locale: ptBR })}
                  </span>
                  <button type="button" className="agenda__button--rotate" onClick={handleIncreaseMonth}>
                    <ArrowBackIcon />
                  </button>
                </MonthSelector>
                <IntervalSelector>
                  <IntervalButton type="button" isActive={selectedInterval === 'month'} onClick={() => setSelectedInterval('month')}>
                    Mensal
                  </IntervalButton>
                  <IntervalButton type="button" isActive={selectedInterval === 'week'} onClick={() => setSelectedInterval('week')}>
                    Semanal
                  </IntervalButton>
                </IntervalSelector>
              </CalendarIntervalOptions>
              {
            selectedInterval === 'week' && (
              <>
                <DaySelector>
                  <button
                    type="button"
                    className="agenda__day-selector__icon-button"
                    onClick={handleDecreaseWeek}
                  >
                    <ArrowBackIcon />
                  </button>
                  <DaysContainer>
                    {
                      weekDays.map((day, index) => (
                        <DayButton
                          isActive={selectedDay.getDay() === index}
                          type="button"
                          key={day.date.toString()}
                          onClick={() => setSelectedDay(day.date)}
                        >
                          <p>
                            {day.date.toLocaleDateString('pt-BR', { weekday: 'long' }).split('-')[0]}
                          </p>
                          <h3>
                            {day.date.getDate()}
                          </h3>
                          {/* <AgendaTypesIndicators types={day.types} /> */}
                        </DayButton>
                      ))
                    }
                  </DaysContainer>
                  <button
                    type="button"
                    className="agenda__day-selector__icon-button agenda__button--rotate"
                    onClick={handleIncreaseWeek}
                  >
                    <ArrowBackIcon />
                  </button>
                </DaySelector>
                <Scheduler
                  daySchedules={selectedDaySchedules}
                  dayAppointments={acceptedDaysAppointments}
                />
              </>
            )
          }
            </CalendarHeader>
            {
          selectedInterval === 'month'
             && (
             <AppDatePicker
               customClassName="agenda__month-datepicker"
               selected={selectedDay}
               onChange={([date, dateTwo]: [Date, Date]) => {}}
               renderCustomHeader={() => null}
               renderDayContents={renderMonthlyCalendarDay}
               inline
             />
             )
        }
          </CalendarContainer>
        ) : (
          <ManageAgenda
            schedules={schedules}
            scheduleDispatch={dispatch}
          />
        )
      }
    </Container>
  );
}
