import { ReactComponent as DeleteIcon } from '../../assets/images/delete.svg';

import { GoBackLocation } from '../GoBackLocation';

import {
  Container,
  Content,
  EditFormDeleteButton,
  EditFormHeader,
  EditFormSubmitButton,
} from './styles';

interface IEditFormProps {
  children: React.ReactNode;
  title: string;
  onArrowClick: () => void;
  onSubmit: (event: React.FormEvent) => void;
  onDeleteClick?: () => void;
  customClassName?: string;
  buttonText?: string;
  formSteps?: {
    key: string;
    label: string;
  }[];
  onStepChange?: (step: number) => void;
  showDeleteButton?: boolean;
  hideSubmitButton?: boolean;
}

export default function EditForm({
  children,
  title,
  onArrowClick,
  onSubmit,
  buttonText = 'Salvar alterações',
  customClassName = '',
  onStepChange,
  onDeleteClick = () => {},
  formSteps = [],
  showDeleteButton = true,
  hideSubmitButton = false,
}: IEditFormProps) {
  return (
    <Container as="form" onSubmit={onSubmit} className={customClassName}>
      <Content>
        <EditFormHeader>
          <GoBackLocation
            title={title}
            onArrowClick={onArrowClick}
            arrowColor="var(--white)"
            titleColor="var(--white)"
          />
          {formSteps.map((step) => (
            <GoBackLocation
              key={step.key}
              title={step.label}
              onArrowClick={onArrowClick}
              onTitleClick={
                onStepChange
                  ? () => onStepChange(formSteps.indexOf(step))
                  : undefined
              }
              arrowColor="var(--white)"
              titleColor="var(--white)"
            />
          ))}
          {showDeleteButton && (
          <EditFormDeleteButton type="button" onClick={onDeleteClick}>
            <DeleteIcon />
          </EditFormDeleteButton>
          )}
        </EditFormHeader>
        {children}
      </Content>
      {!hideSubmitButton && <EditFormSubmitButton type="submit">{buttonText}</EditFormSubmitButton>}
    </Container>
  );
}
