import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { IConfirmData, PermissionsModal } from '../../components/PermissionsModal';
import { SearchBox } from '../../components/SearchBox';
import { Table } from '../../components/Table';
import { useInfiniteScroll } from '../../hooks/useInfiniteScroll';
import { api } from '../../services/api';
import { IUser } from '../../types';
import { Container, PermissionsButton } from './styles';

const tableHeaders = {
  name: {
    label: 'Usuário',
  },
  permissions: {
    label: 'Permissões',
  },
};

const limitPerPage = 25;

export default function AccessControl() {
  const [users, setUsers] = useState<IUser[]>([]);
  const [selectedUser, setSelectedUser] = useState<IUser | undefined>();
  const [nameFilter, setNameFilter] = useState('');

  const [shouldFetch, setShouldFetch] = useState(true);

  const {
    loadMoreRef,
    page,
    setPage,
    maximumPage,
    setMaximumPage,
  } = useInfiniteScroll();

  useEffect(() => {
    // Debounce for searches
    const timer = setTimeout(() => {
      setPage(1);
      setMaximumPage(1);
      setShouldFetch(true);
    }, 250);

    return () => clearTimeout(timer);
  }, [nameFilter]);

  useEffect(() => {
    setShouldFetch(true);
  }, [page]);

  useEffect(() => {
    if (shouldFetch) {
      fetchUsers();
    }
  }, [shouldFetch]);

  const fetchUsers = async () => {
    const params = {
      role: 'admnistrator',
      query: nameFilter || undefined,
      limit: limitPerPage,
      page,
    };

    try {
      const response = await api.get<{
        users: IUser[];
        totalUsers: number;
      }>('/user', {
        params,
      });

      if (page > 1) {
        setUsers((prevState) => [...prevState, ...response.data.users]);
      } else {
        setUsers(response.data.users);
      }

      setMaximumPage(Math.ceil(response.data.totalUsers / limitPerPage));
    } catch (error: any) {
      toast.error(error?.response?.data?.message || error.message || 'Algo deu errado');
    } finally {
      setShouldFetch(false);
    }
  };

  const handleOpenPermissions = (user: IUser) => {
    setSelectedUser(user);
  };

  const handleNameFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNameFilter(event.target.value);
  };

  const handlePermissionsConfirm = async (confirmData: IConfirmData) => {
    try {
      await api.patch('/adm', {
        ...confirmData,
        userId: selectedUser?.id_user,
      });
    } catch (error: any) {
      toast.error(error?.response?.data?.message || error?.message || 'Algo deu errado');
    }
  };

  const tableData = users.map((user) => ({
    name: user.name,
    id: user.id_user,
    permissions:
  <PermissionsButton type="button" onClick={() => handleOpenPermissions(user)}>
    Vincular
  </PermissionsButton>,
  }));

  return (
    <Container>
      <SearchBox
        value={nameFilter}
        onChange={handleNameFilterChange}
        onSubmit={() => {}}
        placeholder="Pesquisar usuários"
        shouldShowExtraFilters={false}
      />
      <Table
        headers={tableHeaders}
        data={tableData}
        onSort={() => {}}
        loadMoreRef={loadMoreRef}
        hasMore={page <= maximumPage}
      />
      <PermissionsModal
        isOpen={!!selectedUser}
        onClose={() => setSelectedUser(undefined)}
        onConfirm={handlePermissionsConfirm}
        userId={selectedUser?.id_user || ''}
        name={selectedUser?.name || ''}
      />
    </Container>
  );
}
