import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { api } from '../../services/api';

import { IService } from '../../types';
import { Modal, IModalProps } from '../Modal';
import { Toggle } from '../Toggle';

import {
  Container, ServiceItem, ServiceList, StyledButton, StyledHeader,
} from './styles';

export interface IConfirmData {
  addServiceIds: string[];
  removeServiceIds: string[];
}

interface ILinkServicesModalProps extends IModalProps {
  servicesIds: string[];
  onConfirm?: (confirmData: IConfirmData) => void;
}

interface ILinkingServices extends IService {
  isActive: boolean;
}

export default function LinkServicesModal({
  isOpen, onConfirm = () => {}, onClose, servicesIds,
}: ILinkServicesModalProps) {
  const [services, setServices] = useState<ILinkingServices[]>([]);

  const [addServiceIds, setAddServiceIds] = useState<string[]>([]);
  const [removeServiceIds, setRemoveServiceIds] = useState<string[]>([]);

  useEffect(() => {
    fetchServices();
  }, []);

  useEffect(() => {
    checkActiveServices();
  }, [servicesIds, isOpen]);

  const fetchServices = async () => {
    try {
      const response = await api.get<IService[]>('/service');

      setServices(response.data.map((service) => ({
        ...service,
        isActive: false,
      })));
    } catch (error: any) {
      toast.error(error?.response?.data?.message || error?.message || 'Algo deu errado');
    }
  };

  const checkActiveServices = () => {
    setServices((prevState) => prevState.map((service) => ({
      ...service,
      isActive: (servicesIds.some((serviceId) => serviceId === service.id_service)),
    })));
  };

  const handleToggle = (id: string, current: boolean) => {
    setServices((prevState) => (prevState.map((service) => {
      if (service.id_service === id) {
        return {
          ...service,
          isActive: !service.isActive,
        };
      }
      return service;
    })));
    if (current) {
      setAddServiceIds((prevState) => prevState.filter((s) => s !== id));
      setRemoveServiceIds((prevState) => [...prevState, id]);
    } else {
      setAddServiceIds((prevState) => [...prevState, id]);
      setRemoveServiceIds((prevState) => prevState.filter((s) => s !== id));
    }
  };

  const handleConfirm = () => {
    onConfirm({
      addServiceIds,
      removeServiceIds,
    });
    handleClose();
  };

  const handleClose = () => {
    setAddServiceIds([]);
    setRemoveServiceIds([]);
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
    >
      <Container>
        <StyledHeader>
          <h1>
            Selecione os serviços
          </h1>
        </StyledHeader>
        <ServiceList>
          {services.map((service) => (
            <ServiceItem key={service.id_service}>
              <span>{service.name}</span>
              <Toggle
                onToggle={() => handleToggle(service.id_service, service.isActive)}
                value={service.isActive}
              />
            </ServiceItem>
          ))}
        </ServiceList>
        <StyledButton type="button" onClick={handleConfirm}>
          Confirmar
        </StyledButton>
      </Container>
    </Modal>
  );
}
