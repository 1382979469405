import styled from 'styled-components';

export const Container = styled.div<{
  isActive?:boolean;
}>`
  display: flex;
  align-items: center;

  color: ${({ isActive }) => (isActive ? 'var(--blue-400)' : 'var(--gray-500)')};
  font-size: 1.25rem;

  cursor: pointer;

  width: fit-content;

  margin-left: auto;

  input {
    appearance: none;
    width: 2.5rem;
    height: 1.5rem;
    background-color: var(--gray-500);
    border-radius: 2rem;
    margin-left: 1rem;

    cursor: pointer;

    &:after {
      content: '';
      width: 1rem;
      height: 1rem;
      margin: 0.25rem;

      display: inline-block;

      border-radius: 50%;
      background-color: var(--white);
      transition: transform 0.2s ease;
    }

    &:checked {
      background-color: var(--blue-400);

      &:after {
        transform: translateX(100%);
      }
    }
  }

  @media(max-width: 300px) {
    label {
      display: none;
    }
  }
`;
