import { useEffect, useRef, useState } from 'react';
import { NavLink, useHistory } from 'react-router-dom';

import UserPlaceholder from '../../assets/images/user-placeholder.png';
import { ReactComponent as SelectArrowIcon } from '../../assets/images/select-arrow.svg';

import { useAuth } from '../../hooks/useAuth';
import { useOutside } from '../../hooks/useOutside';

import {
  Container, Content, DropdownOptions, UserAvatar,
} from './styles';

export default function HeaderButton() {
  const { data, signOut } = useAuth();
  const history = useHistory();
  const [isExpanded, setIsExpanded] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    handleClose();
  }, [history.location]);

  const handleClose = () => {
    setIsExpanded(false);
  };

  const handleSignOut = () => {
    signOut();
    history.push('/');
  };

  const toggle = () => {
    setIsExpanded((prevState) => !prevState);
  };

  if (!containerRef) {
    return null;
  }

  useOutside(containerRef, handleClose);

  return (
    <Container isExpanded={isExpanded} ref={containerRef}>
      <Content onClick={toggle} isExpanded={isExpanded}>
        <UserAvatar
          src={data?.user?.img_url || UserPlaceholder}
          onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => {
            (e.target as HTMLImageElement).src = UserPlaceholder;
          }}
          alt=""
        />

        <h2>{data.user.name}</h2>

        <SelectArrowIcon />
      </Content>
      <DropdownOptions isExpanded={isExpanded} aria-hidden={!isExpanded}>
        <NavLink to="/perfil">Meu Perfil</NavLink>
        <button type="button" onClick={handleSignOut}>
          Sair
        </button>
      </DropdownOptions>
    </Container>
  );
}
