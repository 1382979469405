import { useRef, useState } from 'react';
import styled, { css } from 'styled-components';

import { ReactComponent as PlusIcon } from '../../assets/images/plus.svg';
import SelectArrow from '../../assets/images/select-arrow.svg';
import { useOutside } from '../../hooks/useOutside';

interface IDropdownProps {
  options: {
    label: string;
    value: string;
  }[];
  placeholder: string;
  value: string;
  onChange: (text: string) => void;
  label?: string;
  customClassName?: string;
  canAddNewValue?: boolean;
  onAddNewValue?: (text: string) => void;
  disabled?: boolean;
}

export default function Dropdown({
  onChange,
  options,
  placeholder,
  value,
  customClassName = '',
  label = '',
  canAddNewValue = false,
  disabled = false,
  onAddNewValue = () => {},
}: IDropdownProps) {
  const [isExpanded, setIsExpanded] = useState(false);
  const [newValue, setNewValue] = useState('');
  const containerRef = useRef<HTMLDivElement>(null);

  const toggle = () => {
    if (disabled && !isExpanded) {
      return;
    }

    setIsExpanded((prevState) => !prevState);
  };

  const handleClose = () => {
    setIsExpanded(false);
  };

  const handleAddNewValue = () => {
    if (newValue) {
      onAddNewValue(newValue);
      handleChangeValue(newValue);
      setNewValue('');
    }
  };

  const handleChangeValue = (text: string) => {
    onChange(text);
    handleClose();
  };

  if (!containerRef) {
    return null;
  }

  useOutside(containerRef, handleClose);

  const selectedLabel = options.find((option) => option.value === value)?.label || '';

  return (
    <Container ref={containerRef} className={customClassName}>
      <StyledLabel htmlFor={`dropdown-${placeholder}`}>
        {label ? <span>{label}</span> : null}
      </StyledLabel>
      <DropdownContainer id={`dropdown-${placeholder}`} isExpanded={isExpanded}>
        <button type="button" onClick={toggle}>
          {selectedLabel || value || placeholder || 'Selecione...'}
        </button>
        <OptionsWrapper isExpanded={isExpanded}>
          <OptionsContainer>
            {options.map(({ label: optionLabel, value: optionValue }) => (
              <Option
                type="button"
                key={optionValue}
                onClick={() => handleChangeValue(optionValue)}
              >
                {optionLabel}
              </Option>
            ))}
            {canAddNewValue && (
              <NewValueContainer>
                <NewValueInput
                  type="text"
                  placeholder="Adicionar novo..."
                  value={newValue}
                  onChange={(e) => setNewValue(e.target.value)}
                />
                <IconButton type="button" onClick={handleAddNewValue}>
                  <PlusIcon />
                </IconButton>
              </NewValueContainer>
            )}
          </OptionsContainer>
        </OptionsWrapper>
      </DropdownContainer>
    </Container>
  );
}

const Container = styled.div``;

const StyledLabel = styled.label`
  display: flex;
  flex-direction: column;

  > span {
    padding-left: 0.75em;
    margin-bottom: 0.375rem;
  }
`;

const NewValueContainer = styled.div`
  display: flex;

  max-width: max(20rem, 30%);

  margin-top: 0.5rem;
`;

const NewValueInput = styled.input`
  width: 100%;
  border: none;
  outline: none;
  font-size: 1rem;
  padding: 0.25em 0.5em;

  &::placeholder {
    filter: opacity(0.5);
  }
`;

const IconButton = styled.button`
  border: none;
  background: var(--white);

  font-size: 1rem;

  border-radius: 50%;

  width: 2rem;
  height: 2rem;

  padding: 0.5rem;

  svg {
    width: 1rem;
    height: 1rem;
    path {
      stroke: var(--gray-500);
    }
  }

  transition: filter 0.2s;

  &:hover {
    filter: brightness(0.9);
  }
`;

const DropdownContainer = styled.div<{
  isExpanded?: boolean;
}>`
  background: #fff;
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  min-height: 3rem;
  padding: 1em;
  font-size: 1rem;
  border-radius: ${({ isExpanded }) => (isExpanded ? '0.5rem 0.5rem 0 0' : '0.5rem')};

  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);

  > button {
    background: transparent;
    border: none;
    width: 100%;
    text-align: left;
    font-size: 1rem;
    padding-right: 1.25rem;

    overflow: hidden;
  }

  &::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 1em;
    width: 1em;
    height: 0.75em;

    overflow: visible;

    background-repeat: no-repeat;
    background-size: contain;

    pointer-events: none;

    transition: transform 0.3s ease 0s;

    transform: ${({ isExpanded }) => (isExpanded
    ? 'translateY(-50%) rotate(-180deg)'
    : 'translateY(-50%) rotate(0deg)')};

    background-image: url(${SelectArrow});
  }
`;

const OptionsWrapper = styled.div<{
  isExpanded?: boolean;
}>`
  position: absolute;
  top: 50%;
  left: 0px;
  right: 0px;
  z-index: 1;
  height: 0px;
  opacity: 0;
  visibility: hidden;

  background: #fff;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16), 0px 3px 6px rgba(0, 0, 0, 0.16);
  border-radius: 0 0 0.5rem 0.5rem;

  display: flex;
  flex-direction: column;
  padding-top: 1rem;

  transition: all 0.3s ease 0s;

  ${({ isExpanded }) => isExpanded
    && css`
      height: unset;
      opacity: 1;
      top: 100%;
      visibility: visible;
    `}
`;

const OptionsContainer = styled.div`
  max-height: 300px;
  overflow-y: auto;

  margin: 0 1rem 1rem;

  scrollbar-width: thin;
  scrollbar-color: var(--blue-400) var(--blue-50);

  &::-webkit-scrollbar {
    width: 0.4rem;
    height: 0.4rem;
  }

  &::-webkit-scrollbar-track {
    border-radius: 1.25rem;
    background-color: var(--blue-50);
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 1.25rem;
    background-color: var(--blue-400);
  }
`;

const Option = styled.button`
  display: block;
  background: transparent;
  border: none;

  padding: 0.375em 0.5em;
  width: 100%;

  font-size: inherit;

  text-align: left;
`;
