import styled from 'styled-components';

export const Container = styled.div`
  min-height: 100vh;
  width: 100%;

  display: flex;
  flex-direction: column;
`;

export const Content = styled.div`
  width: 80%;
  margin: 0 auto;
  flex: 1;

  @media (max-width: 601px) {
    width: 95%;
  }
`;
