import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  height: calc(100vh - 7rem);

  > * {
    margin-top: 2rem;
  }
`;

export const FloatingButtonsContainer = styled.div`
  position: absolute;
  bottom: 4rem;
  right: 3%;

  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const ContainedIconButton = styled.button`
  background-color: var(--blue-400);
  border: none;
  border-radius: 50%;

  padding: 1rem;

  width: fit-content;
  height: fit-content;

  svg {
    width: 1.75rem;
    height: 1.75rem;
    vertical-align: middle;
    path {
      fill: #fff;
    }
  }

  transition: filter 0.2s;

  &:hover {
    filter: brightness(0.9);
  }
`;

export const AssociatedPatientsButton = styled.button`
  background: transparent;
  border: none;

  padding: 0.25rem;
`;
