import {
  startOfDay, endOfDay, format, parseISO,
} from 'date-fns';
import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useInfiniteScroll } from '../../hooks/useInfiniteScroll';
import { api } from '../../services/api';
import { AppointmentStatus, IAppointment, IAvaliation } from '../../types';
import {
  ReportItem,
  ReportItemSection,
  ReportItemSubsection,
  ReportsList,
  ReportsListWrapper,
} from './styles';

interface IAvaliationsListProps {
  userId?: string;
  initialDate?: Date;
  finalDate?: Date;
  isPatient?: boolean;
  isMaster?: boolean;
}

const filterAppointments = (
  appointments: IAppointment[],
  userId?: string,
): IAppointment[] => appointments.filter(
  (appointment) => {
    if (appointment.avaliations && appointment.avaliations.length > 0) {
      if (!userId) return true;

      const avaliationByOtherUser = appointment.avaliations.find(
        (avaliation) => avaliation.owner_id !== userId,
      );

      if (avaliationByOtherUser) {
        return true;
      }
    }
    return false;
  },
);

const limitPerPage = 12;

export function AvaliationsList({
  userId,
  initialDate,
  finalDate,
  isPatient = false,
  isMaster = false,
}: IAvaliationsListProps) {
  const [appointments, setAppointments] = useState<IAppointment[]>([]);
  const [shouldFetch, setShouldFetch] = useState(true);

  const {
    page, loadMoreRef, setPage, maximumPage, setMaximumPage,
  } = useInfiniteScroll();

  const fetchAppointments = async () => {
    if (page > maximumPage) {
      return;
    }

    const params = {
      userId: userId || undefined,
      initial_date: initialDate
        ? startOfDay(initialDate).toISOString()
        : new Date().toISOString(),
      final_date: finalDate
        ? endOfDay(finalDate).toISOString()
        : new Date('2070-12-31').toISOString(),
      page,
      limit: limitPerPage,
      status: isMaster ? 'done' : undefined,
    };

    const requestUrl = isMaster ? '/appointment/status' : '/appointment';

    const requestParams = isMaster ? {
      ...params,
      initialDate: params.initial_date,
      finalDate: params.final_date,
      initial_date: undefined,
      final_date: undefined,
    } : params;

    try {
      const response = await api.get<{
        appointments: IAppointment[];
        totalAppointments: number;
      }>(requestUrl, {
        params: requestParams,
      });

      if (page === 1) {
        setAppointments(
          filterAppointments(response.data.appointments, userId),
        );
      } else {
        setAppointments((prevState) => [
          ...prevState,
          ...filterAppointments(response.data.appointments, userId),
        ]);
      }
      setMaximumPage(Math.ceil(response.data.totalAppointments / limitPerPage));
    } catch (error: any) {
      console.log(error);
      toast.error(
        error?.response?.data?.message
          || 'Algo deu errado, tente novamente mais tarde',
      );
    } finally {
      setShouldFetch(false);
    }
  };

  useEffect(() => {
    if (!initialDate) {
      return;
    }

    setPage((_prevState) => 1);
    setMaximumPage((_prevState) => 1);
    setShouldFetch(true);
  }, [initialDate, finalDate]);

  useEffect(() => {
    if (page > 1) {
      setShouldFetch(true);
    }
  }, [page]);

  useEffect(() => {
    if (!shouldFetch) {
      return;
    }
    fetchAppointments();
  }, [userId, shouldFetch]);

  const renderAvaliations = (avaliations: IAvaliation[] | undefined) => {
    if (!avaliations || avaliations.length === 0) {
      return null;
    }

    const avaliationByTherapist = avaliations.find(
      (avaliation) => avaliation.therapist_id == null,
    );
    const avaliationByUser = avaliations.find(
      (avaliation) => avaliation.patient_id == null,
    );

    return (
      <ReportItemSubsection flexAlignItems="flex-end">
        {avaliationByUser && (
          <p>
            {isPatient ? 'Avaliação' : 'Avaliado'}
            :
            {' '}
            {avaliationByUser?.rating?.toLocaleString('pt-BR', {
              maximumFractionDigits: 1,
            })}
          </p>
        )}
        {avaliationByTherapist && (
          <p>
            {isPatient ? 'Avaliado' : 'Avaliação'}
            :
            {' '}
            {avaliationByTherapist?.rating?.toLocaleString('pt-BR', {
              maximumFractionDigits: 1,
            })}
          </p>
        )}
      </ReportItemSubsection>
    );
  };

  const renderAvaliationComment = (avaliations: IAvaliation[] | undefined) => {
    const avaliationByUser = avaliations?.find(
      (avaliation) => avaliation.owner_id !== userId,
    );

    return <p>{avaliationByUser?.comment}</p>;
  };

  return appointments?.length > 0 ? (
    <ReportsListWrapper>
      <ReportsList>
        {appointments.map((appointment) => (
          <ReportItem key={appointment.id_appointment}>
            <ReportItemSection>
              <ReportItemSubsection>
                <p>{appointment.service.name || ''}</p>
                <p>
                  {isPatient
                    ? appointment?.therapist?.user?.name
                    : appointment?.patient?.user?.name}
                </p>
                <p>
                  {format(
                    parseISO(appointment?.datetime),
                    "dd/MM/yyyy '-' HH:mm",
                  )}
                </p>
              </ReportItemSubsection>
              {renderAvaliations(appointment.avaliations)}
            </ReportItemSection>
            <ReportItemSection hasBorderTop>
              <ReportItemSubsection flexAlignItems="flex-end">
                {renderAvaliationComment(appointment.avaliations)}
              </ReportItemSubsection>
            </ReportItemSection>
          </ReportItem>
        ))}
        <div ref={loadMoreRef} className="bg-transparent" />
      </ReportsList>
    </ReportsListWrapper>
  ) : null;
}
