import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import { EditCell } from '../../components/EditCell';

import { FloatingAddButton } from '../../components/FloatingAddButton';
import { Table } from '../../components/Table';
import { Toggle } from '../../components/Toggle';
import { api } from '../../services/api';
import { IAnamnesis } from '../../types';

import { Container } from './styles';

const tableHeaders = {
  name: {
    label: 'Perguntas',
    sortable: false,
  },
  status: {
    label: 'Status',
  },
};

export default function Anamnesis() {
  const [anamnesis, setAnamnesis] = useState<IAnamnesis[]>([]);
  const [sortBy, setSortBy] = useState('');
  const [sortDir, setSortDir] = useState('');
  const history = useHistory();

  useEffect(() => {
    fetchAnamnesis();
  }, []);

  const fetchAnamnesis = async () => {
    try {
      const response = await api.get('/anamnese');
      setAnamnesis(response.data);
    } catch (error: any) {
      toast.error(error?.response?.data?.message || error?.message || 'Algo deu errado');
    }
  };

  const handleAddAnamnesis = () => {
    history.push('/anamneses/adicionar');
  };

  const handleEditAnamnesis = (id: string) => {
    history.push(`/anamneses/editar/${id}`);
  };

  const handleSortChange = (key: string) => {
    if (sortBy === key) {
      if (sortDir === 'asc') {
        setSortDir('desc');
      } else {
        setSortBy('');
        setSortDir('');
      }
    } else {
      setSortBy(key);
      setSortDir('asc');
    }
  };

  const handleToggleAnamnesisActive = async (id: string, current: boolean) => {
    try {
      const response = await api.put('/anamnese', {
        anamneseId: id,
        active: !current,
      });
      setAnamnesis((prevState) => (prevState.map(
        (a) => (a.id_anamnese === id ? response.data : a),
      )));
    } catch (error: any) {
      toast.error(error?.response?.data?.message || error?.message || 'Algo deu errado');
    }
  };

  const tableData = anamnesis.map((a) => ({
    ...a,
    id: a.id_anamnese,
    name:
  <EditCell
    content={a.name}
    onEditClick={() => handleEditAnamnesis(a.id_anamnese)}
  />,
    status:
  <Toggle
    value={a.active}
    onToggle={() => handleToggleAnamnesisActive(a.id_anamnese, a.active)}
  />,
  }));

  return (
    <Container>
      <Table
        headers={tableHeaders}
        data={tableData}
        onSort={handleSortChange}
        sortDir={sortDir}
        sortBy={sortBy}
      />
      <FloatingAddButton type="button" onClick={handleAddAnamnesis} />

    </Container>
  );
}
