import { createContext, useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import { api } from '../services/api';
import { IUser } from '../types';

interface ICredentials {
  email: string;
  password: string;
}

interface IData {
  user: IUser;
  token: string;
  refreshToken: string;
}

interface IAuthContextReturn {
  data: IData;
  signIn: (credentials: ICredentials, shouldSave?: boolean) => Promise<void>;
  signOut: () => Promise<void>;
  updateUserInfo: () => Promise<void>;
  setUserInfo: (user: Partial<IUser>) => void;
}

export const AuthContext = createContext({} as IAuthContextReturn);

interface IAuthProviderProps {
  children: React.ReactNode;
}

export function AuthProvider({ children }: IAuthProviderProps) {
  const token = window.localStorage.getItem('@doarcareWebAdmin-accessToken') || window.sessionStorage.getItem('@doarcareWebAdmin-accessToken') || '';
  const refreshToken = window.localStorage.getItem('@doarcareWebAdmin-refreshToken') || '';
  const user = JSON.parse(window.localStorage.getItem('@doarcareWebAdmin-user') || window.sessionStorage.getItem('@doarcareWebAdmin-user') || '{}') as IUser;

  const [data, setData] = useState({
    token,
    refreshToken,
    user,
  });

  const signIn = useCallback(async (credentials: ICredentials, shouldSave = true) => {
    try {
      const response = await api.post<{
        user: IUser;
        refreshToken: string;
        accessToken: string;
      }>('/auth', credentials);
      const { accessToken, refreshToken: apiRefreshToken, user: apiUser } = response.data;

      if (apiUser.role !== 'admnistrator' && apiUser.role !== 'master' && apiUser.role !== 'therapist') {
        toast.error('Você não tem permissão para acessar essa página');
        return;
      }

      setData({
        token: accessToken,
        refreshToken: apiRefreshToken,
        user: apiUser,
      });

      if (shouldSave) {
        window.localStorage.setItem('@doarcareWebAdmin-accessToken', accessToken);
        window.localStorage.setItem('@doarcareWebAdmin-user', JSON.stringify(apiUser));
      } else {
        window.sessionStorage.setItem('@doarcareWebAdmin-accessToken', accessToken);
        window.sessionStorage.setItem('@doarcareWebAdmin-user', JSON.stringify(apiUser));
      }
      window.localStorage.setItem('@doarcareWebAdmin-refreshToken', apiRefreshToken);
    } catch (error: any) {
      toast.error(error?.response?.data?.message || 'Não foi possivel realizar o login');
    }
  }, []);

  const updateUserInfo = useCallback(async () => {
    try {
      const response = await api.get<IUser>('/me');
      setData((prevState) => ({
        ...prevState,
        user: response.data,
      }));

      const isSavingToLocalStorage = window.localStorage.getItem('@doarcareWebAdmin-user') !== null;
      if (isSavingToLocalStorage) {
        window.localStorage.setItem('@doarcareWebAdmin-user', JSON.stringify(response.data));
      } else {
        window.sessionStorage.setItem('@doarcareWebAdmin-user', JSON.stringify(response.data));
      }
    } catch (error: any) {
      toast.error(error?.response?.data?.message || 'Não foi possivel obter os dados do usuário');
    }
  }, []);

  const setUserInfo = useCallback((userData: Partial<IUser>) => {
    setData((prevState) => {
      const newState = {
        ...prevState,
        user: {
          ...prevState.user,
          ...userData,
        },
      };

      const isSavingToLocalStorage = window.localStorage.getItem('@doarcareWebAdmin-user') !== null;

      if (isSavingToLocalStorage) {
        window.localStorage.setItem('@doarcareWebAdmin-user', JSON.stringify(newState.user));
      } else {
        window.sessionStorage.setItem('@doarcareWebAdmin-user', JSON.stringify(newState.user));
      }

      return newState;
    });
  }, []);

  const signOut = useCallback(async () => {
    try {
      await api.put('/auth/logout', {
        refreshToken,
      });
    } catch (error: any) {
      console.log(error?.response?.data?.message || error?.message);
    } finally {
      setData({
        token: '',
        refreshToken: '',
        user: {} as IUser,
      });

      window.localStorage.removeItem('@doarcareWebAdmin-accessToken');
      window.localStorage.removeItem('@doarcareWebAdmin-refreshToken');
      window.localStorage.removeItem('@doarcareWebAdmin-user');

      window.sessionStorage.removeItem('@doarcareWebAdmin-accessToken');
      window.sessionStorage.removeItem('@doarcareWebAdmin-refreshToken');
    }
  }, [refreshToken]);

  return (
    <AuthContext.Provider value={{
      data, signIn, signOut, updateUserInfo, setUserInfo,
    }}
    >
      {children}
    </AuthContext.Provider>
  );
}
