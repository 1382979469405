import { useRouteMatch } from 'react-router-dom';
import { PatientDetails } from '../components/Patients/PatientDetails';
import { Patients } from '../pages/Patients';
import { AdminRoute } from './AdminRoute';

export function PatientsRouter() {
  const { path } = useRouteMatch();
  return (
    <>
      <AdminRoute path={`${path}`} exact component={Patients} />
      <AdminRoute path={`${path}/:patientId`} component={PatientDetails} />
    </>
  );
}
