import { IPatient } from '../../types';
import { Modal } from '../Modal';
import {
  Container, PatientItem, PatientName, PatientsList, StyledHeader, Subtitle,
} from './styles';

interface IPartnerPatientsModalProps {
  patients: IPatient[];
  partnerName: string;
  isOpen: boolean;
  onClose: () => void;
}

export default function PartnerPatientsModal({
  isOpen, partnerName, onClose, patients,
}: IPartnerPatientsModalProps) {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Container>
        <StyledHeader>
          {partnerName}
        </StyledHeader>
        <Subtitle>{patients.length > 0 ? 'Clientes associados:' : ' O parceiro não possui pacientes associados'}</Subtitle>
        <PatientsList>
          {patients.map((patient) => (
            <PatientItem key={patient.user.id_user}>
              <PatientName>{patient.user.name}</PatientName>
            </PatientItem>
          ))}
        </PatientsList>
      </Container>
    </Modal>
  );
}
