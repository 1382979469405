import styled from 'styled-components';

export const InformationContainer = styled.div`
  margin: 4rem 0;

  display: grid;
  grid-template-columns: 1fr 1fr;

  column-gap: 4.75rem;
  row-gap: 2rem;

  input {
    font-size: 1rem;
  }

  button {
    background-color: transparent;
    border: none;

    grid-column: 2;

    margin-left: auto;

    color: var(--blue-400);

    font-size: 1.25rem;

    transition: filter 0.2s;

    &:hover {
      filter: brightness(0.8);
    }
  }
`;

export const EditPasswordContainer = styled.div`
  margin: 2rem 0;

  .edit-password__fields-container {
    display: flex;
    flex-direction: column;

    gap: 2rem;

    max-width: 31.25rem;
    margin: 4rem auto;

    input {
      font-size: 1rem;
    }
  }

  button[type="submit"] {
    font-size: 1.25rem;
    margin-left: auto;

    max-width: 16.25rem;
  }
`;
