export const UTCToTime = (time:string): string => {
  const [hour, minute, seconds] = time.split(':');
  const numberHour = Number(hour);

  const newHour = numberHour > 3 ? numberHour - 3 : 24 - 3;

  if (!seconds) {
    return `${newHour.toString().padStart(2, '0')}:${minute}`;
  }

  return `${newHour.toString().padStart(2, '0')}:${minute}:${seconds}`;
};
