import { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { useNotificationModal } from '../../../hooks/useNotificationModal';

import { api } from '../../../services/api';
import { IAppFormElement, IPartnerService } from '../../../types';
import { EditForm } from '../../EditForm';
import { IConfirmData } from '../../LinkServicesModal';

import {
  AddressForm,
  IAddressFormData,
  initialAddress,
} from '../../User/AddressForm';
import {
  initialPersonalDetails,
  PersonalDetailsForm,
  IPartnerPersonalDetailsFormData,
} from '../PersonalDetailsForm';

const editPartnerFormSteps = [
  {
    key: 'personalDetails',
    label: 'Dados pessoais',
  },
  {
    key: 'address',
    label: 'Endereço',
  },
];

interface IEditPartnerFormData {
  address: IAddressFormData;
  personalDetails: IPartnerPersonalDetailsFormData;
}

export default function EditPartnerForm() {
  const [userData, setUserData] = useState<IEditPartnerFormData>({
    address: initialAddress,
    personalDetails: initialPersonalDetails,
  });
  const [editPartnerFormServices, setEditPartnerFormServices] = useState<{
    partnerId: string;
    originalServices: IPartnerService[];
    addServiceIds: string[];
    removeServiceIds: string[];
  }>({
    partnerId: '',
    originalServices: [],
    addServiceIds: [],
    removeServiceIds: [],
  });
  const [currentStep, setCurrentStep] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();
  const [avatar, setAvatar] = useState<File | null>(null);
  const currentFormRef = useRef<IAppFormElement>(null);
  const { notificate } = useNotificationModal();
  const { partnerId } = useParams<{
    partnerId: string;
  }>();

  useEffect(() => {
    fetchPartnerInfo();
  }, [partnerId]);

  const fetchPartnerInfo = async () => {
    try {
      const response = await api.get(`/partner/${partnerId}`);

      const {
        type_name,
        updated_at,
        id_partner,
        address,
        services,
        ...personalDetails
      } = response.data;

      setEditPartnerFormServices((prevState) => ({
        ...prevState,
        originalServices: services || [],
        partnerId: id_partner || '',
      }));

      if (!address) {
        setUserData({
          address: initialAddress,
          personalDetails: {
            ...personalDetails,
            type: type_name,
          },
        });

        setIsLoading(false);
        return;
      }

      const {
        zip, street, number, district, city, uf, complement,
      } = address;

      setUserData({
        address: {
          zip,
          street,
          number,
          district,
          city,
          uf,
          complement,
        },
        personalDetails: {
          ...personalDetails,
          type: type_name,
        },
      });

      setIsLoading(false);
    } catch (error: any) {
      toast.error(
        error?.response?.data?.message || error?.message || 'Algo deu errado',
      );
    }
  };

  const onStepChange = (step: number) => {
    setCurrentStep(step);
  };

  const handleAvatarUpload = (file: File) => {
    setAvatar(file);
  };

  const handleGoBack = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    } else {
      history.goBack();
    }
  };

  const handleSuccess = () => {
    history.goBack();
  };

  const handleSubmit = async (
    data?: IPartnerPersonalDetailsFormData | IAddressFormData,
  ) => {
    const newEditPartnerFormData = {
      ...userData,
      [editPartnerFormSteps[currentStep].key]: data,
    };
    setUserData(newEditPartnerFormData);
    if (currentStep !== editPartnerFormSteps.length - 1) {
      onStepChange(currentStep + 1);
    } else {
      try {
        const { addServiceIds, removeServiceIds } = editPartnerFormServices;

        if (addServiceIds.length || removeServiceIds.length) {
          const servicesToSubmit = {
            id_partner: editPartnerFormServices.partnerId,
            addServiceIds,
            removeServiceIds,
          };

          await api.patch('/partner/service', servicesToSubmit);
        }

        const dataToSubmit = {
          partnerId,
          ...newEditPartnerFormData.personalDetails,
          address: newEditPartnerFormData.address,
          img_url: undefined,
          avatar_url: undefined,
          created_at: undefined,
          active: undefined,
          cod: undefined,
          schedules: undefined,
        };

        const formDataToSubmit = new FormData();

        Object.entries(dataToSubmit).forEach(([key, value]) => {
          if (value) {
            formDataToSubmit.append(key, value.toString());
          }
        });

        formDataToSubmit.set('address', JSON.stringify(dataToSubmit.address));

        if (avatar) {
          formDataToSubmit.append('img', avatar);
        }

        await api.put('/partner', formDataToSubmit, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        notificate('Salvo com sucesso!', handleSuccess);
      } catch (error: any) {
        toast.error(
          error?.response?.data?.message || error.message || 'Algo deu errado',
        );
      }
    }
  };

  const handleClickNext = (event: React.FormEvent) => {
    event.preventDefault();
    if (!currentFormRef.current) return;
    currentFormRef.current.requestSubmit();
  };

  const handleDeletePartner = async () => {
    try {
      await api.delete(`/partner/${partnerId}`);

      history.push('/parceiros');
    } catch (error: any) {
      toast.error(
        error?.response?.data?.message || error.message || 'Algo deu errado',
      );
    }
  };

  const handleLinkServicesConfirm = (confirmData: IConfirmData) => {
    const { addServiceIds, removeServiceIds } = confirmData;

    const filteredServicesToAdd = editPartnerFormServices.addServiceIds.filter(
      (serviceId) => !removeServiceIds.includes(serviceId),
    );

    const filteredServicesToRemove = editPartnerFormServices.removeServiceIds.filter(
      (serviceId) => !addServiceIds.includes(serviceId)
          && editPartnerFormServices.originalServices.some(
            (service) => service.service_id === serviceId,
          ),
    );

    const servicesToAdd = Array.from(
      new Set([...filteredServicesToAdd, ...addServiceIds]),
    );

    const servicesToRemove = Array.from(
      new Set([...filteredServicesToRemove, ...removeServiceIds]),
    );

    setEditPartnerFormServices((prevState) => ({
      ...prevState,
      addServiceIds: servicesToAdd,
      removeServiceIds: servicesToRemove,
    }));
  };

  const getCurrentServiceIds = () => {
    const { addServiceIds, originalServices, removeServiceIds } = editPartnerFormServices;

    const initialServiceIds = originalServices.map(
      (service) => service.service_id,
    );

    const filteredServiceIds = initialServiceIds.filter(
      (serviceId) => !removeServiceIds.includes(serviceId),
    );

    const newServiceIds = Array.from(
      new Set([...addServiceIds, ...filteredServiceIds]),
    );

    return newServiceIds;
  };

  const formProps = {
    onSubmit: handleSubmit,
    title: editPartnerFormSteps[currentStep].label,
    ref: currentFormRef,
  };

  const editUserFormComponents = {
    personalDetails: (
      <PersonalDetailsForm
        {...formProps}
        initialData={userData.personalDetails}
        onAvatarUpload={handleAvatarUpload}
        showLinkServices
        onConfirmLinkServices={handleLinkServicesConfirm}
        serviceIds={getCurrentServiceIds()}
      />
    ),
    address: <AddressForm {...formProps} initialData={userData.address} />,
  } as {
    [key: string]: JSX.Element;
  };

  if (isLoading) {
    return null;
  }

  return (
    <EditForm
      title="Editar parceiro"
      onArrowClick={handleGoBack}
      formSteps={editPartnerFormSteps.slice(0, currentStep)}
      onStepChange={onStepChange}
      onSubmit={handleClickNext}
      buttonText={
        currentStep === editPartnerFormSteps.length - 1
          ? 'Salvar alterações'
          : 'Próximo'
      }
      onDeleteClick={handleDeletePartner}
    >
      {editUserFormComponents[editPartnerFormSteps[currentStep].key]}
    </EditForm>
  );
}
