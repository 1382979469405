import { useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import { useNotificationModal } from '../../../hooks/useNotificationModal';
import { api } from '../../../services/api';
import { IAppFormElement } from '../../../types';

import { CreationForm } from '../../CreationForm';
import { AddressForm, IAddressFormData, initialAddress } from '../AddressForm';
import { IPersonalDetailsFormData, PersonalDetailsForm, initialPersonalDetails } from '../PersonalDetailsForm';

const addInternalUserFormSteps = [
  {
    key: 'personalDetails',
    label: 'Dados pessoais',
  },
  {
    key: 'address',
    label: 'Endereço',
  },
];

interface IAddInternalUserFormData {
  personalDetails: IPersonalDetailsFormData;
  address: IAddressFormData;
}

type FormDataTypes = IPersonalDetailsFormData | IAddressFormData;

export default function AddInternalUserForm() {
  const [addInternalUserFormData, setAddInternalUserFormData] = useState<IAddInternalUserFormData>({
    personalDetails: initialPersonalDetails,
    address: initialAddress,
  });
  const [currentStep, setCurrentStep] = useState(0);
  const { notificate } = useNotificationModal();
  const currentFormRef = useRef<IAppFormElement>(null);
  const history = useHistory();

  const onStepChange = (step: number) => {
    if (step < 0) {
      history.goBack();
    } else {
      setCurrentStep(step);
    }
  };

  const handleSuccess = () => {
    history.goBack();
  };

  const handleSubmit = async (data?: FormDataTypes) => {
    const newFormData = {
      ...addInternalUserFormData,
      [addInternalUserFormSteps[currentStep].key]: data,
    };
    setAddInternalUserFormData(newFormData);
    if (currentStep !== addInternalUserFormSteps.length - 1) {
      onStepChange(currentStep + 1);
    } else {
      try {
        const dataToSubmit = {
          ...newFormData.personalDetails,
          avatar_url: undefined,
          img_url: undefined,
          video: undefined,
          confirm_password: undefined,
          address: newFormData.address,
          cpf: newFormData.personalDetails.cpf?.replace(/\D/g, '') || undefined,
          born: new Date(newFormData.personalDetails.born).toISOString(),
        };

        await api.post('/adm', dataToSubmit);

        notificate('Criado com sucesso!', handleSuccess);
      } catch (error: any) {
        toast.error(error?.response?.data?.message || error.message);
      }
    }
  };

  const handleClickNext = (event: React.FormEvent) => {
    event.preventDefault();
    if (!currentFormRef.current) return;
    currentFormRef.current.requestSubmit();
  };

  const formProps = {
    onSubmit: handleSubmit,
    ref: currentFormRef,
  };

  const addInternalUserFormComponents = {
    personalDetails: <PersonalDetailsForm {...formProps} userType="internal" initialData={addInternalUserFormData.personalDetails} />,
    address: <AddressForm {...formProps} initialData={addInternalUserFormData.address} />,
  } as {[key: string]: JSX.Element};

  return (
    <CreationForm
      title={addInternalUserFormSteps[currentStep].label}
      onArrowClick={() => onStepChange(currentStep - 1)}
      buttonText={currentStep === addInternalUserFormSteps.length - 1 ? 'Criar' : 'Próximo'}
      onSubmit={handleClickNext}
    >
      {addInternalUserFormComponents[addInternalUserFormSteps[currentStep].key]}
    </CreationForm>
  );
}
