import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { useAuth } from '../../hooks/useAuth';
import { api } from '../../services/api';
import { IPartner } from '../../types';

import { Dropdown } from '../Dropdown';

import { AgendaTimesForm, IAgendaTimesFormProps } from './AgendaTimesForm';

interface IPartnerAgendaFormProps extends IAgendaTimesFormProps {
  disablePartnerSelect?: boolean;
}

const frequencyOptions = [
  {
    label: 'Recorrente',
    value: 'recurrent',
  },
  {
    label: 'Especifico',
    value: 'specific',
  },
];

export function PartnerAgendaForm({
  scheduleFormData,
  onValueChange,
  disablePartnerSelect = false,
  ...rest
}: IPartnerAgendaFormProps) {
  const { data } = useAuth();
  const [partners, setPartners] = useState<IPartner[]>([]);

  useEffect(() => {
    fetchPartners();
  }, []);

  const fetchPartners = async () => {
    try {
      if (data?.user.role === 'therapist') {
        const response = await api.get(`/therapist/partner/${data?.user.therapist?.id_therapist}`);

        setPartners(response.data);
      } else {
        const response = await api.get<{
          partners: IPartner[];
          totalPartners: number;
        }>('/partner', {
          params: {
            page: 1,
            limit: 99,
          },
        });

        setPartners(response.data.partners);
      }
    } catch (error: any) {
      toast.error(error?.response?.data?.message || error?.message);
    }
  };

  const partnerOptions = partners.map((partner) => ({
    value: partner.id_partner,
    label: partner.name,
  }));

  return (
    <>
      <Dropdown
        onChange={(value) => onValueChange(value, 'partnerId')}
        value={scheduleFormData.partnerId || ''}
        options={partnerOptions}
        placeholder="Selecione o parceiro"
        label="Parceiro"
        customClassName="agenda-form__dropdown"
        disabled={disablePartnerSelect}
      />
      {
        scheduleFormData.partnerId && (
          <Dropdown
            onChange={(value) => onValueChange(value, 'frequency')}
            value={scheduleFormData.frequency}
            options={frequencyOptions}
            placeholder="Selecione a frequência"
            label="Frequência"
            customClassName="agenda-form__dropdown"
          />
        )
      }
      {
        scheduleFormData.frequency && (
        <AgendaTimesForm
          scheduleFormData={scheduleFormData}
          onValueChange={onValueChange}
          {...rest}
        />
        )
      }
    </>
  );
}
