/* export const maskCpfOrCnpj = (value: string) => {
  const onlyDigits = value.replace(/\D/g, '');

  if (onlyDigits.length > 11) {
    return onlyDigits.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, '$1.$2.$3/$4-$5');
  }
  return onlyDigits.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '$1.$2.$3-$4');
};
 */

export const maskCpfOrCnpj = (value: string) => {
  if (!value) {
    return '';
  }

  const digits = value.replace(/\D/g, '');

  if (digits.length <= 11) {
    return digits.replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d{1,2})$/, '$1-$2');
  }
  return digits.replace(/^(\d{2})(\d)/, '$1.$2')
    .replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3')
    .replace(/\.(\d{3})(\d)/, '.$1/$2')
    .replace(/(\d{4})(\d)/, '$1-$2');
};
