import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import addYears from 'date-fns/addYears';
import isWithinInterval from 'date-fns/isWithinInterval';
import startOfDay from 'date-fns/startOfDay';
import endOfDay from 'date-fns/endOfDay';

import { toast } from 'react-toastify';
import parseISO from 'date-fns/parseISO';
import { ReactComponent as CalendarIcon } from '../../assets/images/calendar.svg';
import { AppDatePicker } from '../../components/AppDatePicker';

import { FloatingAddButton } from '../../components/FloatingAddButton';
import { IConfirmData, LinkServicesModal } from '../../components/LinkServicesModal';
import { SearchBox } from '../../components/SearchBox';
import { Table } from '../../components/Table';

import { applySort } from '../../utils/applySort';

import { ClearFilterButton, Container, LinkServiceButton } from './styles';
import { EditCell } from '../../components/EditCell';
import { IVoucher } from '../../types';
import { api } from '../../services/api';

const vouchersTableHeaders = {
  name: {
    label: 'Voucher',
    sortable: true,
  },
  created_at: {
    label: 'Criação',
  },
  expiration: {
    label: 'Vencimento',
  },
  quantity: {
    label: 'Quantidade',
    sortable: true,
  },
  discount: {
    label: 'Desconto',
    sortable: true,
  },
  services: {
    label: 'Serviços',
  },
};

export default function Vouchers() {
  const [vouchers, setVouchers] = useState<IVoucher[]>([]);
  const [sortBy, setSortBy] = useState<keyof IVoucher>('name');
  const [sortDir, setSortDir] = useState('');
  const [search, setSearch] = useState('');
  const [initialDate, setInitialDate] = useState<Date | undefined>();
  const [finalDate, setFinalDate] = useState<Date | undefined>();
  const [selectedVoucher, setSelectedVoucher] = useState<IVoucher | undefined>();
  const history = useHistory();

  useEffect(() => {
    fetchVouchers();
  }, []);

  const fetchVouchers = async () => {
    try {
      const response = await api.get<IVoucher[]>('/voucher');
      setVouchers(response.data.map((voucher) => ({
        ...voucher,
        created_at: new Date(voucher.created_at).toLocaleDateString(),
        discount: `${voucher.discount}%`,
      })));
    } catch (error: any) {
      toast.error(error?.response?.data?.message || error?.message || 'Algo deu errado');
    }
  };

  const handleOpenLinkServicesModal = (voucher: IVoucher) => {
    setSelectedVoucher(voucher);
  };

  const handleSortChange = (key: string) => {
    if (sortBy === key) {
      if (sortDir === 'asc') {
        setSortDir('desc');
      } else if (sortDir === 'desc') {
        setSortDir('');
      } else {
        setSortDir('asc');
      }
    } else {
      setSortBy(key as keyof IVoucher);
      setSortDir('asc');
    }
  };

  const handleAddVoucher = () => {
    history.push('/vouchers/adicionar');
  };

  const handleEditVoucher = (id: string) => {
    history.push(`/vouchers/editar/${id}`);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  const vouchersByDate = initialDate ? vouchers.filter((voucher) => isWithinInterval(
    parseISO(voucher.expiration),
    {
      start: initialDate,
      end: finalDate || endOfDay(addYears(endOfDay(new Date()), 25)),
    },
  )) : vouchers;

  const filteredVouchers = vouchersByDate.filter(
    (voucher) => voucher.name.toLowerCase().includes(search.toLowerCase()),
  );

  const tableData = applySort(filteredVouchers, sortBy, sortDir).map((voucher) => ({
    ...voucher,
    expiration: new Date(voucher.expiration).toLocaleDateString(),
    id: voucher.id_voucher,
    name:
  <EditCell content={voucher.name} onEditClick={() => handleEditVoucher(voucher.id_voucher)} />,
    services: <LinkServiceButton type="button" onClick={() => handleOpenLinkServicesModal(voucher)}>Vincular</LinkServiceButton>,
  }));

  const handleDatesChange = ([first, last]: [Date, Date]) => {
    setInitialDate(first ? startOfDay(first) : undefined);
    setFinalDate(last ? endOfDay(last) : undefined);
  };

  const handleLinkServicesConfirm = async (confirmData: IConfirmData) => {
    if (!selectedVoucher) {
      return;
    }

    if (confirmData.addServiceIds.length === 0 && confirmData.removeServiceIds.length === 0) {
      return;
    }

    try {
      const response = await api.patch<IVoucher>('/voucher', {
        ...confirmData,
        id_voucher: selectedVoucher.id_voucher,
      });

      const newVouchers = vouchers.map(
        (voucher) => (voucher.id_voucher === selectedVoucher.id_voucher ? ({
          ...response.data,
          created_at: new Date(response.data.created_at).toLocaleDateString(),
          discount: `${response.data.discount}%`,
        }) : voucher),
      );

      setVouchers(newVouchers);
    } catch (error: any) {
      toast.error(error?.response?.data?.message || error.message || 'Algo deu errado');
    }
  };

  const handleClearFilters = () => {
    setInitialDate(undefined);
    setFinalDate(undefined);
    setSearch('');
  };

  return (
    <Container>
      <FloatingAddButton onClick={handleAddVoucher} styledZIndex={1} />
      <div className="vouchers__filters-container">
        <SearchBox
          placeholder="Buscar vouchers"
          onSubmit={() => {}}
          value={search}
          onChange={handleSearchChange}
          shouldShowExtraFilters={false}
        />
        <AppDatePicker
          customClassName="vouchers__date-picker"
          icon={<CalendarIcon />}
          value={
            initialDate ? `De ${initialDate?.toLocaleDateString('pt-BR') || ''} à ${finalDate?.toLocaleDateString('pt-BR') || ''}` : '-'
          }
          startDate={initialDate}
          endDate={finalDate}
          onChange={handleDatesChange}
          selectsRange
        />
        {/* <ClearFilterButton type="button" onClick={handleClearFilters}>
          Limpar filtros
        </ClearFilterButton> */}
      </div>
      <Table
        headers={vouchersTableHeaders}
        data={tableData}
        onSort={handleSortChange}
        sortDir={sortDir}
        sortBy={sortBy}
        shouldStickLastColumn={false}
      />
      <LinkServicesModal
        isOpen={!!selectedVoucher}
        servicesIds={selectedVoucher
          ? selectedVoucher.services.map((service) => service.id_service)
          : []}
        onClose={() => setSelectedVoucher(undefined)}
        onConfirm={handleLinkServicesConfirm}
      />
    </Container>
  );
}
