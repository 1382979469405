import { Dispatch, useEffect, useState } from 'react';
import styled from 'styled-components';

import addDays from 'date-fns/addDays';

import { toast } from 'react-toastify';

import { isBefore } from 'date-fns';
import { ReactComponent as DeleteIcon } from '../../assets/images/delete.svg';

import {
  IAddress, IDay, IInterval, ISchedule, ITherapistPoint,
} from '../../types';
import { api } from '../../services/api';
import { useNotificationModal } from '../../hooks/useNotificationModal';
import { SchedulesReducerActions } from '../../pages/Agenda/schedulesReducer';

import { Button } from '../Button';
import { Dropdown } from '../Dropdown';

import { AgendaTimesForm } from './AgendaTimesForm';
import { PartnerAgendaForm } from './PartnerAgendaForm';
import { PoolAgendaForm } from './PoolAgendaForm';
import { useAuth } from '../../hooks/useAuth';
import { convertUTCToLocal } from '../../utils/convertUTCToLocal';
import { IAddressFormData } from '../User/AddressForm';
import { UTCToTime } from '../../utils/UTCToTime';
import { timeToUTC } from '../../utils/timeToUTC';

interface IAgendaFormProps {
  editingScheduleId: string;
  onSuccessfulEdit: () => void;
  scheduleDispatch: Dispatch<SchedulesReducerActions>;
}

const agendaTypeOptions = [
  {
    label: 'Carteira de clientes',
    value: 'portfolio',
  },
  {
    label: 'Parceiro',
    value: 'partner',
  },
  {
    label: 'Pool',
    value: 'pool',
  },
];

export interface IScheduleFormData {
  type: string;
  frequency: string;
  partnerId?: string;
  scheduleId?: string;
  initial_time: string; // "HH:mm"
  final_time: string; // "HH:mm"
  intervals: IInterval[];
  days: IDay[];
  initial_date?: string; // "yyyy-MM-dd"
  final_date?: string; // "yyyy-MM-dd"
  addresses: string[];
  radius?: number | string;
  partnerName?: string;

  initialDate: Date;
  finalDate: Date;
}

export const initialScheduleFormData: IScheduleFormData = {
  type: '',
  frequency: 'specific',
  initial_time: '',
  final_time: '',
  intervals: [],
  days: [],
  partnerId: '',
  initial_date: '',
  final_date: '',
  addresses: [''],
  radius: '1',
  partnerName: '',
  initialDate: new Date(),
  finalDate: addDays(new Date(), 7),
};

const initialInterval: IInterval = {
  id_interval: `${Math.random()}`,
  initial_time: '',
  final_time: '',
};

function formatAddress(address: IAddressFormData, radius?: string | number) {
  const {
    street, number, district, city, uf, zip,
  } = address;
  if (!street) {
    return '';
  }

  return `${
    radius ? `Raio de ${radius}km - ` : ''
  } ${street}, ${number} - ${district} - ${city} - ${uf} - ${zip}`;
}

export function AgendaForm({
  editingScheduleId,
  onSuccessfulEdit,
  scheduleDispatch,
}: IAgendaFormProps) {
  const [scheduleFormData, setScheduleFormData] = useState(
    initialScheduleFormData,
  );
  const [therapistPoints, setTherapistPoints] = useState<ITherapistPoint[]>([]);
  const { notificate } = useNotificationModal();
  const { data } = useAuth();

  useEffect(() => {
    if (editingScheduleId) {
      fetchScheduleInfo();
    }
  }, [editingScheduleId]);

  const fetchScheduleInfo = async () => {
    try {
      const response = await api.get(`/schedule/${editingScheduleId}`);

      const {
        type_name,
        initial_time,
        final_time,
        partner_id,
        initial_date,
        final_date,
        addresses,
        partner,
        intervals,
        ...rest
      } = response.data;

      setScheduleFormData((prevState) => ({
        ...prevState,
        ...rest,
        intervals: intervals?.map((interval: IInterval) => ({
          ...interval,
          initial_time: UTCToTime(interval.initial_time),
          final_time: UTCToTime(interval.final_time),
        })) || [],
        initialDate: initial_date ? convertUTCToLocal(new Date(initial_date)) : undefined,
        finalDate: final_date ? convertUTCToLocal(new Date(final_date)) : undefined,
        partnerId: partner_id,
        partnerName: partner?.name || '',
        type: type_name,
        initial_time: UTCToTime(initial_time).substring(0, 5),
        final_time: UTCToTime(final_time).substring(0, 5),
        addresses: addresses.map((address: IAddress) => address.id_address),
      }));
    } catch (error: any) {
      toast.error(
        error?.response?.data?.message || error?.message || 'Algo deu errado',
      );
    }
  };

  const fetchTherapistPoints = async () => {
    try {
      const response = await api.get<ITherapistPoint[]>('/therapist/point');

      setTherapistPoints(response.data);
    } catch (error: any) {
      toast.error(
        error?.response?.data?.message
          || 'Error ao buscar os pontos de atendimento do terapeuta, tente novamente mais tarde',
      );
    }
  };

  useEffect(() => {
    fetchTherapistPoints();
  }, []);

  const handleSelectChange = (value: string) => {
    setScheduleFormData({
      ...initialScheduleFormData,
      type: value,
    });
  };

  const handleValueChange = (value: string | Date, name: string) => {
    setScheduleFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleAddInterval = () => {
    setScheduleFormData((prevState) => ({
      ...prevState,
      intervals: [
        ...prevState.intervals,
        { ...initialInterval, id_interval: `${Math.random()}` },
      ],
    }));
  };

  const handleIntervalChange = (id: string, name: string, value: string) => {
    setScheduleFormData((prevState) => ({
      ...prevState,
      intervals: prevState.intervals.map(
        (interval) => (interval.id_interval === id ? { ...interval, [name]: value } : interval),
      ),
    }));
  };

  const handleRemoveInterval = (id: string) => {
    setScheduleFormData((prevState) => ({
      ...prevState,
      intervals: prevState.intervals.filter(
        (interval) => interval.id_interval !== id,
      ),
    }));
  };

  const handleAddAddress = () => {
    setScheduleFormData((prevState) => ({
      ...prevState,
      addresses: [...prevState.addresses, ''],
    }));
  };

  const handleChangeAddress = (
    index: number,
    addressId: string,
  ) => {
    setScheduleFormData((prevState) => ({
      ...prevState,
      addresses: prevState.addresses.map(
        (prevAddress, prevIndex) => (
          prevIndex === index ? addressId : prevAddress
        ),
      ),
    }));
  };

  const handleRemoveAddress = (index: number) => {
    setScheduleFormData((prevState) => ({
      ...prevState,
      addresses: prevState.addresses.filter((_, i) => i !== index),
    }));
  };

  const handleDayChange = (value: IDay) => {
    setScheduleFormData((prevState) => {
      const isDaySelected = prevState.days.some(
        (day) => day.id_week_day === value.id_week_day,
      );
      if (isDaySelected) {
        return {
          ...prevState,
          days: prevState.days.filter(
            (day) => day.id_week_day !== value.id_week_day,
          ),
        };
      }
      return {
        ...prevState,
        days: [...prevState.days, value],
      };
    });
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = event.target;

    handleValueChange(value, name);
  };

  const isIntervalValid = (interval: IInterval) => {
    if (!interval.initial_time || !interval.final_time) return false;

    const [initialTimeHour, initialTimeMinutes] = interval.initial_time.split(':');
    const [finalTimeHour, finalTimeMinutes] = interval.final_time.split(':');

    const initialTimeInMinutes = Number(initialTimeHour) * 60 + Number(initialTimeMinutes);
    const finalTimeInMinutes = Number(finalTimeHour) * 60 + Number(finalTimeMinutes);

    return initialTimeInMinutes <= finalTimeInMinutes;
  };

  const validateForm = () => {
    const { type, frequency } = scheduleFormData;

    if (!frequency) {
      toast.error('Por favor, selecione uma frequência');
      return false;
    }

    if (!type) {
      toast.error('Por favor, selecione um tipo de agenda');
      return false;
    }

    if (scheduleFormData.addresses.length === 1 && !scheduleFormData.addresses[0]) {
      toast.error('Por favor, selecione pelo menos um endereço');
      return false;
    }

    if (frequency === 'specific') {
      if (!scheduleFormData.initialDate || !scheduleFormData.finalDate) {
        toast.error('Por favor, preencha os campos de data inicial e final');
        return false;
      }

      if (isBefore(scheduleFormData.initialDate, scheduleFormData.finalDate)) {
        toast.error('A data final não pode ser anterior à data inicial');
        return false;
      }

      if (type === 'partner') {
        if (!scheduleFormData.partnerId) {
          toast.error('Por favor, selecione um parceiro');
          return false;
        }
      }
    } else if (frequency === 'recurrent' && !scheduleFormData.days.length) {
      toast.error('Por favor, selecione pelo menos um dia da semana');
      return false;
    }

    if (scheduleFormData.intervals.length > 0) {
      const areIntervalsValid = scheduleFormData.intervals.every(
        (interval) => isIntervalValid(interval),
      );

      if (!areIntervalsValid) {
        toast.error('O horário final do intervalo deve ser maior que o inicial');
        return false;
      }
    }

    const halfHourIntervalRegex = /^([0-1][0-9]|2[0-3]):([03][0])$/;

    const regexp = new RegExp(halfHourIntervalRegex);

    if (!regexp.test(scheduleFormData.initial_time)) {
      toast.error('O horário inicial deve estar em intervalos de 30 minutos');
      return false;
    }

    if (!regexp.test(scheduleFormData.final_time)) {
      toast.error('O horário final deve estar em intervalos de 30 minutos');
      return false;
    }

    return true;
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    let dataToSubmit;

    if (!validateForm()) {
      return;
    }

    const {
      initialDate,
      finalDate,
      initial_time,
      final_time,
      intervals,
      days,
      partnerId,
      type,
      frequency,
    } = scheduleFormData;

    const { addresses } = scheduleFormData;

    dataToSubmit = {
      scheduleId: editingScheduleId || undefined,
      type,
      frequency,
      initial_time: timeToUTC(initial_time),
      final_time: timeToUTC(final_time),
      intervals: intervals.map((interval) => ({
        initial_time: timeToUTC(interval.initial_time),
        final_time: timeToUTC(interval.final_time),
      })),
      addresses,
    };

    if (frequency === 'recurrent') {
      dataToSubmit = {
        ...dataToSubmit,
        days: days.map((day) => ({
          id_week_day: day.id_week_day,
          name: day.name,
        })),
      };
    } else if (frequency === 'specific') {
      dataToSubmit = {
        ...dataToSubmit,
        initial_date: initialDate.toISOString().split('T')[0],
        final_date: finalDate.toISOString().split('T')[0],
      };
    }

    if (type === 'partner') {
      dataToSubmit = {
        ...dataToSubmit,
        partnerId,
      };
    } else if (type === 'pool') {
      dataToSubmit = {
        ...dataToSubmit,
        addresses,
      };
    }

    try {
      if (editingScheduleId) {
        await api.put('/schedule', dataToSubmit);
        const response = await api.get<ISchedule[]>('/schedule');

        scheduleDispatch({ type: 'SET_SCHEDULES', payload: { schedules: response.data } });
        notificate('Salvo com sucesso!', onSuccessfulEdit);
      } else {
        const response = await api.post('/schedule', {
          ...dataToSubmit, ownerType: 'therapist', ownerId: data?.user.id_user,
        });

        scheduleDispatch({
          type: 'SET_SCHEDULES',
          payload: { schedules: response.data },
        });

        notificate('Criado com sucesso!');
      }

      setScheduleFormData(initialScheduleFormData);
    } catch (error: any) {
      toast.error(
        error?.response?.data?.message || error?.message || 'Algo deu errado',
      );
    }
  };

  const commonFormsProps = {
    onValueChange: handleValueChange,
    onInputChange: handleInputChange,
    onDayChange: handleDayChange,
    onAddInterval: handleAddInterval,
    onIntervalChange: handleIntervalChange,
    onRemoveInterval: handleRemoveInterval,
    scheduleFormData,
  };

  const therapistPointOptions = therapistPoints.map((point) => ({
    label: formatAddress(point, point.radius),
    value: point.id_address,
  }));

  return (
    <Container as="form" onSubmit={handleSubmit}>
      <Dropdown
        onChange={handleSelectChange}
        value={scheduleFormData.type}
        options={agendaTypeOptions}
        placeholder="Selecione o tipo de agenda"
        label="Tipo de agenda"
        customClassName="agenda-form__dropdown"
      />
      {!!scheduleFormData.type && (
        <>
          {scheduleFormData.addresses.map((addressId, index) => (
            <IconInputLabel key={`${addressId}${index.toString()}`}>
              <span>{`Ponto de atendimento ${index + 1}`}</span>
              <Dropdown
                onChange={(value) => handleChangeAddress(index, value)}
                value={addressId}
                options={therapistPointOptions}
                placeholder="Selecione um ponto de atendimento"
                canAddNewValue={false}
              />
              {index > 0 && (
                <DeleteAddressButton
                  type="button"
                  onClick={() => handleRemoveAddress(index)}
                >
                  <DeleteIcon />
                </DeleteAddressButton>
              )}
            </IconInputLabel>
          ))}
          <div>
            <TextButton type="button" onClick={handleAddAddress}>
              Adicionar novo ponto de atendimento
            </TextButton>
          </div>
        </>
      )}
      {scheduleFormData.type === 'portfolio' && (
        <AgendaTimesForm {...commonFormsProps} />
      )}
      {scheduleFormData.type === 'partner' && (
        <PartnerAgendaForm {...commonFormsProps} />
      )}
      {scheduleFormData.type === 'pool' && (
        <PoolAgendaForm
          {...commonFormsProps}
          onAddAddress={handleAddAddress}
          onChangeAddress={handleChangeAddress}
          onRemoveAddress={handleRemoveAddress}
        />
      )}
      <SubmitButton type="submit">
        {editingScheduleId ? 'Salvar alterações' : 'Criar'}
      </SubmitButton>
    </Container>
  );
}

export const Container = styled.div`
  /* display: flex;
  flex-direction: column;
  padding-bottom: 10rem; */

  > * {
    max-width: 30rem;
    margin: 0 auto;
    margin-top: 2rem;
  }

  .agenda-form__dropdown {
    max-width: 30rem;
    width: 100%;
    margin: 2rem auto;
    margin-bottom: 0;
  }

  .agenda-form__custom-datepicker {
    position: relative;

    svg {
      path {
        fill: var(--gray-500);
      }
    }

    input {
      width: 100%;
    }
  }

  > button {
    margin-left: auto;
    width: 100%;
    max-width: 16.25rem;
    font-size: 1.25rem;
  }
`;

const TextButton = styled.button`
  background: transparent;
  border: none;

  display: block;

  color: var(--blue-400);

  margin-left: auto;

  transition: filter 0.2s;

  &:hover {
    filter: brightness(0.8);
  }
`;

const IconInputLabel = styled.label`
  display: flex;

  position: relative;

  flex-direction: column;

  span {
    padding-left: 0.75rem;
    margin-bottom: 0.375rem;
  }
`;

const DeleteAddressButton = styled.button`
  position: absolute;
  bottom: 0;
  left: -3rem;

  height: 3rem;
  width: 3rem;

  background: transparent;
  border: none;

  svg {
    width: 1rem;
    height: 1rem;

    path {
      fill: var(--gray-500);
    }
  }
`;

const SubmitButton = styled(Button)`
  margin: 2rem 0 0 auto;
  width: 100%;
  max-width: 16.25rem;
  font-size: 1.25rem;
`;
