import { useEffect, useState } from 'react';

import { toast } from 'react-toastify';
import { maskCep } from '../../utils/maskCep';
import {
  IAddressFormData,
  initialAddress as initialAddressFormData,
} from '../User/AddressForm';

import { Button } from '../Button';
import { Input } from '../Input';
import { IModalProps, Modal } from '../Modal';

import {
  AddressInput,
  AddressInputLabel,
  AddressInputWrapper,
  Container, MapWrapper, StyledForm, StyledHeader,
} from './styles';
import { Map } from '../Map';
import { IGeolocation } from '../../types';
import { LoadingSpinner } from '../LoadingSpinner';

interface IAddressModalProps extends IModalProps {
  onConfirm: (
    address: IAddressFormData,
    geolocation: IGeolocation,
    radius?: string
  ) => void;
  initialAddress?: IAddressFormData;
  initialRadius?: string;
  shouldNotConsiderRadius?: boolean;
}

function formatAddress(address: IAddressFormData) {
  const {
    street, number, district, city, uf, zip,
  } = address;

  if (!street?.trim()) {
    return '';
  }

  return `${street}, ${number} - ${district} - ${city} - ${uf} - ${zip}`;
}

export default function AddressModal({
  isOpen,
  onClose,
  onConfirm,
  initialAddress = initialAddressFormData,
  initialRadius = '1',
  shouldNotConsiderRadius = true,
}: IAddressModalProps) {
  const [addressFormData, setAddressFormData] = useState<IAddressFormData>(initialAddress);
  const [radius, setRadius] = useState<string | undefined>(initialRadius);
  const [address, setAddress] = useState(formatAddress(addressFormData));
  const [shouldSearchAddress, setShouldSearchAddress] = useState(false);
  const [geolocation, setGeolocation] = useState({
    lat: -23.5489,
    lng: -46.6388,
  });
  const [isSearchingAddress, setIsSearchingAddress] = useState(false);

  useEffect(() => {
    setAddressFormData(initialAddress);
    setAddress(formatAddress(initialAddress));
    setRadius(initialRadius);
  }, [initialAddress, initialRadius]);

  useEffect(() => {
    if (!address) {
      setIsSearchingAddress(false);
      return () => {};
    }

    setIsSearchingAddress(true);

    const timeout = setTimeout(() => {
      setShouldSearchAddress(true);
    }, 2000);

    return () => clearTimeout(timeout);
  }, [address]);

  useEffect(() => {
    let timeout: NodeJS.Timeout;

    if (shouldSearchAddress) {
      timeout = setTimeout(() => {
        setShouldSearchAddress(false);
      }, 1500);
    }
  }, [shouldSearchAddress]);

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    event.stopPropagation();

    if (!addressFormData.number) {
      toast.error('Por favor, digite um número para o endereço');
      return;
    }

    if (!addressFormData.zip) {
      toast.error('Por favor, digite um CEP');
      return;
    }

    onConfirm(addressFormData, geolocation, radius);
    setAddressFormData(initialAddressFormData);
    setAddress('');
    onClose();
  };

  const handleChangeAddress = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setAddress(value);
  };

  const handleRadiusChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setRadius(value);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Container>
        <StyledHeader>
          <h1>Selecione o endereço</h1>
        </StyledHeader>

        <MapWrapper>
          <Map
            address={address}
            shouldSearchAddress={shouldSearchAddress}
            radiusInKm={shouldNotConsiderRadius ? undefined : Number(radius)}
            onAddressChange={setAddress}
            onAddressFormDataChange={setAddressFormData}
            onGeoChange={setGeolocation}
            geolocation={geolocation}
            onFinishSearchingAddress={() => setIsSearchingAddress(false)}
          />
        </MapWrapper>

        <StyledForm onSubmit={handleSubmit}>
          <AddressInputLabel>
            <span>Endereço</span>
            <AddressInputWrapper>
              <AddressInput
                name="address"
                type="text"
                placeholder="Insira o endereço de atendimento"
                onChange={handleChangeAddress}
                value={address}
                className="address-modal__input"
              />
              {isSearchingAddress && (
                <LoadingSpinner sizeInRem={1.25} />
              )}
            </AddressInputWrapper>
          </AddressInputLabel>
          {!shouldNotConsiderRadius && (
            <Input
              name="radius"
              type="number"
              label="Raio de atendimento (em km)"
              placeholder="Insira o raio de atendimento"
              value={radius}
              onChange={handleRadiusChange}
              step="1"
              min="0"
            />
          )}
          <Button type="submit">Selecionar</Button>
        </StyledForm>
      </Container>
    </Modal>
  );
}
