import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import { EditCell } from '../../components/EditCell';
import { FloatingAddButton } from '../../components/FloatingAddButton';

import { Table } from '../../components/Table';
import { api } from '../../services/api';
import { IService } from '../../types';
import { applySort } from '../../utils/applySort';
import { formatCurrencyInCents } from '../../utils/formatCurrencyInCents';

import { Container } from './styles';

const tableHeaders = {
  name: {
    label: 'Serviços',
    sortable: true,
  },
  price: {
    label: 'Valor',
    sortable: true,
  },
};

export default function Services() {
  const [services, setServices] = useState<IService[]>([]);
  const [sortBy, setSortBy] = useState<keyof IService>('name');
  const [sortDir, setSortDir] = useState('');
  const history = useHistory();

  useEffect(() => {
    fetchServices();
  }, []);

  const fetchServices = async () => {
    try {
      const response = await api.get('/service');

      setServices(response.data);
    } catch (error: any) {
      toast.error(error?.response?.data?.message || error?.message || 'Algo deu errado');
    }
  };

  const handleSortChange = (key: string) => {
    if (sortBy === key) {
      if (sortDir === 'asc') {
        setSortDir('desc');
      } else if (sortDir === 'desc') {
        setSortDir('');
      } else {
        setSortDir('asc');
      }
    } else {
      setSortBy(key as keyof IService);
      setSortDir('asc');
    }
  };

  const handleEditService = (serviceId: string) => {
    history.push(`/servicos/editar/${serviceId}`);
  };

  const handleAddService = () => {
    history.push('/servicos/adicionar');
  };

  const tableData = applySort(services, sortBy, sortDir).map((service) => ({
    ...service,
    id: service.id_service,
    price: formatCurrencyInCents(service.price),
    name:
  <EditCell
    content={service.name}
    onEditClick={() => handleEditService(service.id_service)}
  />,

  }));

  return (
    <Container>
      <Table
        headers={tableHeaders}
        data={tableData}
        onSort={handleSortChange}
        sortDir={sortDir}
        sortBy={sortBy}
      />
      <FloatingAddButton onClick={handleAddService} />
    </Container>
  );
}
