import styled from 'styled-components';

export const Container = styled.div`
  position: relative;

  width: 100%;

  display: flex;
  align-items: center;
  flex-wrap: wrap-reverse;
  justify-content: center;

  background-color: var(--purple-50);

  min-height: 5.25rem;

  > *:last-child {
    margin-left: auto;
    margin-right: calc(10%);
  }

`;

export const Content = styled.div`
  margin-left: max(calc(10%), 7rem);
`;

export const PageTitle = styled.h1`
  color: var(--blue-400);
  font-size: 2.25rem;
  font-weight: 700;
`;
