import { useLocation, matchPath } from 'react-router-dom';

import { NavigationTitles } from '../../resources/NavigationTitles';

import { HeaderButton } from '../HeaderButton';
import { NavBar } from '../NavBar';

import { Container, Content, PageTitle } from './styles';

export default function Header() {
  const { pathname } = useLocation();

  // const pageTitle = NavigationTitles[pathname.replace('/', '')] || 'DoarCare';

  const foundPath = Object.keys(NavigationTitles).find(
    (path) => !!matchPath(pathname, { path, exact: true, strict: false }),
  );

  const pageTitle = foundPath ? NavigationTitles[foundPath] : 'DoarCare';

  return (
    <Container>
      <NavBar />
      <Content>
        <PageTitle>
          {pageTitle}
        </PageTitle>
      </Content>
      <HeaderButton />
    </Container>
  );
}
