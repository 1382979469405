import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

import { AuthProvider } from './contexts/AuthContext';
import { Layout } from './components/Layout';
import { NotificationModalProvider } from './contexts/NotificationModalContext';

function App() {
  return (
    <BrowserRouter>
      <>
        <ToastContainer
          autoClose={3000}
        />
        <NotificationModalProvider>
          <AuthProvider>
            <Layout />
          </AuthProvider>
        </NotificationModalProvider>
      </>
    </BrowserRouter>
  );
}

export default App;
