import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import endOfDay from 'date-fns/endOfDay';
import parse from 'date-fns/parse';

import { useNotificationModal } from '../../../hooks/useNotificationModal';
import { api } from '../../../services/api';

import { CreationForm } from '../../CreationForm';
import { VoucherFormFields, initialVoucherData } from '../VoucherFormFields';

import { FieldsContainer } from './styles';

export default function AddVoucherForm() {
  const history = useHistory();
  const [addVoucherFormData, setAddVoucherFormData] = useState(initialVoucherData);
  const { notificate } = useNotificationModal();

  const handleGoBack = () => {
    history.goBack();
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    setAddVoucherFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSuccess = () => {
    setAddVoucherFormData(initialVoucherData);
    history.push('/vouchers');
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    const parsedExpiration = parse(addVoucherFormData.expiration, 'yyyy-MM-dd', new Date());

    const dataToSubmit = {
      ...addVoucherFormData,
      expiration: endOfDay(parsedExpiration).toISOString(),
      created_at: undefined,
      quantity: Number(addVoucherFormData.quantity),
      discount: Number(addVoucherFormData.discount),
    };

    try {
      await api.post('/voucher', dataToSubmit);
      notificate('Criado com sucesso!', handleSuccess);
    } catch (error: any) {
      toast.error(error?.response?.data?.message || error?.message || 'Algo deu errado');
    }
  };

  return (
    <CreationForm
      title="Dados do voucher"
      onArrowClick={handleGoBack}
      buttonText="Criar"
      onSubmit={handleSubmit}
    >
      <FieldsContainer>
        <VoucherFormFields
          data={addVoucherFormData}
          onInputChange={handleInputChange}
        />
      </FieldsContainer>
    </CreationForm>
  );
}
