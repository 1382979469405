import styled from 'styled-components';

export const Container = styled.div`
  .agenda-form__dropdown {
    max-width: 30rem;
    width: 100%;
    margin: 2rem auto;
    margin-bottom: 0;
  }

  .agenda-form__custom-datepicker {
    position: relative;

    svg {
      path {
        fill: var(--gray-500);
      }
    }

    input {
      width: 100%;
    }
  }

  .manage-partner-agenda__edit-form {
    > div {
      padding-bottom: 5rem;
    }
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;

  max-width: 30rem;
  margin: 0 auto;
  margin-top: 2rem;

  width: 100%;

  padding-bottom: 2rem;

  border-bottom: 1px solid #70707010;

  > button {
    width: 100%;
    font-size: 1.25rem;
    padding: 0.5rem;
  }
`;
