import styled from 'styled-components';

import { ReactComponent as ArrowBackIcon } from '../../assets/images/arrow-back.svg';

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;

  height: calc(100vh - 7rem);

  padding-top: 2rem;

  gap: 4rem;
`;

export const GraphsContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;

  > * + * {
    margin-top: 3rem;
  }
`;

export const SchedulerContainer = styled.div`
  flex: 1;
`;

export const SelectedDateContainer = styled.div`
  display: flex;
  align-items: center;

  gap: 1rem;

  width: fit-content;

  margin: auto;
`;

export const IconButton = styled.button`
  background-color: transparent;
  border: none;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const RightArrow = styled(ArrowBackIcon)`
  transform: rotate(180deg);

  path {
    fill: var(--gray-500);
  }
`;

export const LeftArrow = styled(ArrowBackIcon)`
  path {
    fill: var(--gray-500);
  }
`;

export const SelectedDate = styled.p`
  font-size: 1rem;
  min-width: 24ch;
  text-align: center;

  strong {
    font-weight: 700;
    text-transform: capitalize;
  }
`;
