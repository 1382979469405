import { Dispatch, useState } from 'react';
import styled from 'styled-components';
import { SchedulesReducerActions } from '../../pages/Agenda/schedulesReducer';
import { ISchedule } from '../../types';
import { Button } from '../Button';
import { AgendaForm } from './AgendaForm';
import { AgendaTable } from './AgendaTable';

interface IManageAgendaProps {
  schedules: ISchedule[];
  scheduleDispatch: Dispatch<SchedulesReducerActions>;
}

export function ManageAgenda({ schedules, scheduleDispatch }: IManageAgendaProps) {
  const [editingScheduleId, setEditingScheduleId] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const handleEditSchedule = (id: string) => {
    setEditingScheduleId(id);
    setIsEditing(true);
  };

  const handleSuccessfulEdit = () => {
    setEditingScheduleId('');
    setIsEditing(true);
  };

  return (
    <Container>
      <ButtonsContainer>
        <Button
          variant={
              !isEditing ? 'contained' : 'outlined'
            }
          type="button"
          onClick={() => {
            setIsEditing(false);
            setEditingScheduleId('');
          }}
        >
          Nova agenda
        </Button>
        <Button
          variant={
              isEditing ? 'contained' : 'outlined'
            }
          type="button"
          onClick={() => setIsEditing(true)}
        >
          Editar agenda
        </Button>
      </ButtonsContainer>
      {
        (isEditing && !!editingScheduleId) && (
          <AgendaForm
            editingScheduleId={editingScheduleId}
            onSuccessfulEdit={handleSuccessfulEdit}
            scheduleDispatch={scheduleDispatch}
          />
        )
      }
      {
        (isEditing && !editingScheduleId) && (
          <AgendaTable
            schedules={schedules}
            onClickEdit={handleEditSchedule}
            scheduleDispatch={scheduleDispatch}
          />
        )
      }

      {
        !isEditing && (
          <AgendaForm editingScheduleId="" onSuccessfulEdit={() => {}} scheduleDispatch={scheduleDispatch} />
        )
      }

    </Container>
  );
}

const Container = styled.div``;

const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;

  max-width: 30rem;
  margin: 0 auto;
  margin-top: 2rem;

  width: 100%;

  padding-bottom: 2rem;

  border-bottom: 1px solid #70707010;

  > button {
    width: 100%;
    font-size: 1.25rem;
    padding: 0.5rem;
  }
`;
