import styled from 'styled-components';

interface ISuffixInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  suffix: string;
  label: string;
  id: string;
}

export default function SuffixInput({
  value, id, label, suffix, ...rest
}: ISuffixInputProps) {
  return (
    <Container textLength={value ? value.toString().length : 0}>
      <label htmlFor={id}>{label}</label>
      <div className="suffix-input__wrapper">
        <input
          id={id}
          value={value || ''}
          {...rest}
        />
        {(value && value !== '') && <label className="suffix-input__suffix" htmlFor={id}>{suffix}</label>}
      </div>
    </Container>
  );
}

const Container = styled.div<{
  textLength: number;
}>`
  display: flex;
  flex-direction: column;



  > label {
    padding-left: 0.75em;
    margin-bottom: 0.375rem;
  }

  .suffix-input__wrapper {
    position: relative;

    display: flex;
    width: 100%;
    height: 3em;
    border-radius: 0.5em;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);

    padding: 1em;

    &:focus-within {
      outline: 2px solid var(--gray-500);
    }
  }

  input {
    background: transparent;
    border: none;
    outline: none;
    width: 100%;
    font-size: 1rem;

    &::placeholder {
      filter: opacity(0.75);
    }
  }

  .suffix-input__suffix {
    position: absolute;
    top: 1rem;
    vertical-align: middle;
    font-size: 1rem;
    line-height: 1.125rem;
    left: ${({ textLength }) => (textLength > 0 ? `calc(${textLength}ch + 1rem)` : 0)};
  }

`;
