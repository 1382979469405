import { useCallback, useEffect, useState } from 'react';
import {
  Circle,
  GoogleMap,
  Marker,
  OverlayView,
  useJsApiLoader,
} from '@react-google-maps/api';
import { toast } from 'react-toastify';

import LocationIcon from '../../assets/images/location.svg';

import { IAddressFormData } from '../User/AddressForm';

import { mapAddressComponentsToFormData } from './mapAddressComponentsToFormData';
import { MapContainer, StyledLocationIcon } from './styles';

interface IMapProps {
  radiusInKm?: number;
  address?: string;
  geolocation?: {
    lat: number;
    lng: number;
  }
  shouldSearchAddress?: boolean;
  onAddressChange: (address: string) => void;
  onAddressFormDataChange: (addressFormData: IAddressFormData) => void;
  onGeoChange: (geolocation: { lat: number; lng: number }) => void;
  onFinishSearchingAddress: () => void;
}

const containerStyle = {
  width: '100%',
  height: '100%',
};

interface IGeolocation {
  lat: number;
  lng: number;
}

const initialGeo:IGeolocation = {
  lat: -23.5489,
  lng: -46.6388,
};

export default function Map({
  address = '',
  radiusInKm,
  geolocation = initialGeo,
  shouldSearchAddress = false,
  onAddressChange,
  onAddressFormDataChange,
  onGeoChange,
  onFinishSearchingAddress,
}: IMapProps) {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || '',
  });
  const [map, setMap] = useState<null | google.maps.Map>(null);

  const onLoad = useCallback((onLoadMap: google.maps.Map) => {
    onLoadMap.fitBounds(new google.maps.LatLngBounds(
      new google.maps.LatLng(geolocation.lat - 0.01, geolocation.lng - 0.01),
      new google.maps.LatLng(geolocation.lat + 0.01, geolocation.lng + 0.01),
    ));
    onLoadMap.setZoom(12);
    onLoadMap.setOptions({
      streetViewControl: false,
      fullscreenControl: false,
      mapTypeControl: false,
    });

    setMap(onLoadMap);
  }, []);

  const onUnmount = useCallback((onUnmountMap: google.maps.Map) => {
    setMap(null);
  }, []);

  useEffect(() => {
    if (address && shouldSearchAddress) {
      const geocoder = new window.google.maps.Geocoder();
      geocoder.geocode({ address }, (results, status) => {
        if (status === 'OK') {
          const geoLocation = results ? results[0].geometry.location : undefined;
          if (results && results.length > 0) {
            onAddressFormDataChange(
              mapAddressComponentsToFormData(results[0].address_components),
            );
            onAddressChange(results[0].formatted_address);
            onGeoChange(geoLocation?.toJSON() ?? initialGeo);
            onFinishSearchingAddress();
          } else {
            toast.error('Não foi possível encontrar um endereço.');
          }
        } else {
          toast.error('Ocorreu um erro ao buscar o endereço.');
        }
      });
    }
  }, [shouldSearchAddress]);

  return isLoaded ? (
    <MapContainer>
      <GoogleMap
        onLoad={onLoad}
        onUnmount={onUnmount}
        mapContainerStyle={containerStyle}
        zoom={10}
        center={geolocation}
      >
        <Marker
          icon={{
            url: LocationIcon,
            scaledSize: new google.maps.Size(30, 30),
          }}
          position={geolocation}
        />
        {radiusInKm !== undefined ? (
          <Circle
            radius={radiusInKm * 1000}
            center={geolocation}
            options={{
              fillColor: '#0094CA',
              fillOpacity: 0.25,
              strokeColor: '#0094CA',
              strokeWeight: 1,
            }}
          />
        ) : null}
      </GoogleMap>
    </MapContainer>
  ) : null;
}
