import { Container } from './styles';

interface IToggleProps {
  onToggle: (value: boolean) => void;
  value: boolean;
}

export default function Toggle({ value, onToggle }: IToggleProps) {
  return (
    <Container isActive={value}>
      <label>
        {value ? 'ativo' : 'inativo'}
      </label>
      <input type="checkbox" onChange={(e) => onToggle(e.target.checked)} checked={value} />
    </Container>
  );
}
