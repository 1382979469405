import { GoBackLocation } from '../GoBackLocation';
import { Container, StyledButton } from './styles';

interface ICreationFormProps {
  children: React.ReactNode;
  onSubmit: (event: React.FormEvent) => void;
  title: string;
  onArrowClick: () => void;
  buttonText?: string;
}

export default function CreationForm({
  onSubmit,
  children,
  title,
  onArrowClick,
  buttonText = 'Criar',
}: ICreationFormProps) {
  return (
    <Container as="form" onSubmit={onSubmit}>
      <header>
        <GoBackLocation
          title={title}
          onArrowClick={onArrowClick}
        />
      </header>
      {children}
      <StyledButton type="submit">{buttonText}</StyledButton>
    </Container>
  );
}
