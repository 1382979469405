import styled from 'styled-components';
import { Button } from '../Button';

export const Container = styled.div`
  margin: 2rem 0;
`;

export const StyledButton = styled(Button)`
  margin-left: auto;
  width: 100%;
  max-width: 16.25rem;
  font-size: 1.25rem;
`;
