import styled from 'styled-components';

import { ReactComponent as EditPencilIcon } from '../../assets/images/edit-pencil.svg';

interface IEditCellProps {
  content: string;
  onEditClick: () => void;
  customClassName?: string;
  canEdit?: boolean;
}

export default function EditCell({
  content,
  onEditClick,
  customClassName,
  canEdit = true,
}: IEditCellProps) {
  return (
    <Container className={customClassName}>
      <button type="button" onClick={canEdit ? onEditClick : () => {}}>
        {canEdit && <EditPencilIcon />}
      </button>

      {content}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  align-items: center;

  button {
    background: transparent;
    border: none;
    width: 1rem;
    height: 1rem;
    margin-right: 1rem;

    svg {
      width: 1rem;
      height: 1rem;
    }

    transition: filter 0.2s;

    &:hover {
      filter: brightness(0.8);
    }
  }
`;
