import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  // gap: clamp(1rem, 7vh, 4rem);

  width: 100%;
  height: 80vh;
  min-height: 28.75rem;
  max-height: 45rem;

  max-width: 70rem;

  justify-content: space-evenly;
  align-items: center;

  margin: 0 auto;

  h1 {
    // margin-top: clamp(1rem, 5vh, 2rem);
    font-size: 2.25rem;
    font-weight: 500;
    text-align: center;
  }

  > button {
    margin-top: clamp(0px, 5vh, 2rem);
    margin-left: auto;
    max-width: 16.25rem;
    font-size: 1.25rem;
  }

  @media (max-width: 600px) {
    height: unset;
    min-height: 25rem;
    max-height: 60rem;
  }
`;

export const CardsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 4rem;

  align-items: center;
  justify-content: center;
`;

export const Card = styled.div<{
  isActive?: boolean;
}>`
  background-color: transparent;
  border: none;
  height: 16.25rem;
  width: 16.25rem;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;

  border-radius: 2rem;
  box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.16);

  svg {
    width: 6.25rem;
    height: 6.25rem;

    path {
      fill: ${({ isActive }) => (isActive ? 'var(--blue-400)' : 'var(--gray-500)')};
    }

  }

  h2 {
    font-size: 1.875rem;
    color: ${({ isActive }) => (isActive ? 'var(--blue-400)' : 'var(--gray-500)')};
  }

  transition: all 0.3s ease;

  &:hover {
    h2 {
      color: var(--blue-400);
    }

    svg {
      path {
        fill: var(--blue-400);
      }
    }
  }
`;
