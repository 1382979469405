import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import { useNotificationModal } from '../../../hooks/useNotificationModal';
import { api } from '../../../services/api';

import { CreationForm } from '../../CreationForm';
import { ServiceFormFields, IServiceFormData } from '../ServiceFormFields';

import { FieldsContainer } from './styles';

const initialFormData = {
  name: '',
  description: '',
  price: '',
  logo: null,
  logoUrl: '',
  img_url: '',
};

export default function AddServiceForm() {
  const history = useHistory();
  const [addServiceFormData, setAddServiceFormData] = useState<IServiceFormData>(initialFormData);
  const { notificate } = useNotificationModal();

  const handleGoBack = () => {
    history.goBack();
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setAddServiceFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleLogoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const logo = event.target.files?.[0];

    if (!logo) return;

    setAddServiceFormData((prevState) => ({
      ...prevState,
      logo,
      img_url: URL.createObjectURL(logo),
    }));
  };

  const handleSuccess = () => {
    setAddServiceFormData(initialFormData);
    history.goBack();
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if (!addServiceFormData.logo) {
      toast.error('Por favor, adicione uma imagem para o serviço');
      return;
    }

    try {
      const { name, description, price } = addServiceFormData;
      const dataToSubmit = {
        name,
        description,
        price: Number(price) * 100,
      };

      const formDataToSubmit = new FormData();

      Object.entries(dataToSubmit).forEach(([key, value]) => {
        if (value) {
          formDataToSubmit.append(key, value.toString());
        }
      });

      if (addServiceFormData.logo) {
        formDataToSubmit.append('img', addServiceFormData.logo);
      }

      await api.post('/service', formDataToSubmit, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      notificate('Criado com sucesso!', handleSuccess);
    } catch (error: any) {
      toast.error(error?.response?.data?.message || error?.message || 'Algo deu errado');
    }
  };

  return (
    <CreationForm
      title="Dados do serviço"
      onArrowClick={handleGoBack}
      buttonText="Criar"
      onSubmit={handleSubmit}
    >
      <FieldsContainer>
        <ServiceFormFields
          data={addServiceFormData}
          onInputChange={handleInputChange}
          onLogoChange={handleLogoChange}
        />
      </FieldsContainer>
    </CreationForm>
  );
}
