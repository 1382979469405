import { ReactComponent as SortArrowIcon } from '../../assets/images/sort-arrow.svg';

import { Container } from './styles';

interface ITableProps {
  headers: {
    [key: string]: {
      label: string;
      sortable?: boolean;
    };
  };
  sortDir?:string;
  sortBy?: string;
  data: {
    id: string;
    [key: string]: React.ReactNode;
  }[];
  onSort: (key: string) => void;
  shouldStickFirstColumn?: boolean;
  shouldStickLastColumn?: boolean;
  loadMoreRef?: React.MutableRefObject<HTMLDivElement | null>;
  hasMore?: boolean;
}

export default function Table({
  headers,
  data,
  onSort,
  sortBy = '',
  sortDir = '',
  shouldStickFirstColumn = true,
  shouldStickLastColumn = true,
  loadMoreRef,
  hasMore = false,
}: ITableProps) {
  return (
    <Container
      shouldStickFirstColumn={shouldStickFirstColumn}
      shouldStickLastColumn={shouldStickLastColumn}
    >
      <table>
        <thead>
          <tr>
            {Object.keys(headers).map((key) => (
              <th
                key={key}
                className={
                  `${headers[key]?.sortable && `sortable${sortDir ? `-${sortDir}` : ''}`}`
                }
                onClick={headers[key]?.sortable ? () => onSort(key) : () => {}}
              >
                {headers[key].label}
                {
                  headers[key].sortable && (
                    (key === sortBy) && (!!sortDir) && (
                      <SortArrowIcon />
                    )
                  )
                }
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((row) => (
            <tr key={row.id}>
              {Object.keys(headers).map((key) => (
                <td key={`${row.id}-${key}`}>
                  {row[key]}
                </td>
              ))}
            </tr>
          ))}
          <tr>
            <td colSpan={Object.keys(headers).length}>
              {
              hasMore && (
                <div ref={loadMoreRef} className="table__load-more-container" />
              )
            }
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr />
        </tfoot>
      </table>
    </Container>
  );
}
