import styled from 'styled-components';

import BackgroundImg from '../../assets/images/background.jpg';

export const Container = styled.div`
  position: absolute;
  inset: 0;

  width: 100%;
  min-height: 100vh;
  height: fit-content;

  background-image: url(${BackgroundImg});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  z-index: -1;
`;

export const Content = styled.div`
  width: 100%;
  max-width: max(575px, 35vw);
  min-height: 100vh;
  margin-right: auto;

  background-color: var(--white);

  border-radius: 0px 4rem 4rem 0px;

  box-shadow: 5px 0px 6px rgba(0, 0, 0, 0.16);

  display: flex;
  flex-direction: column;
  align-items: center;

  padding: min(6rem, 10vw);

  > img {
    width: 85%;
    margin: 0 auto;
  }

  h1 {
    font-size: 1.5rem;
    font-weight: 700;
  }

  h2 {
    font-size: 1.25rem;
    font-weight: 300;

    margin-bottom: 1rem;
  }

  > form {
    margin-top: 5rem;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;

    input {
      font-size: 1rem;
    }

    button {
      font-size: 1.25rem;
    }

    > * + * {
      margin-top: 1rem;
    }

    .sign-in__remember-container {
      display: flex;
      justify-content: space-between;
      width: 100%;

      margin: 2rem 0;

      a {
        color: var(--gray-500);

        transition: filter 0.2s;

        &:hover {
          filter: brightness(0.8);
        }
      }
    }

    .sign-in__remember-checkbox-label {
      display: flex;
      align-items: center;

      font-weight: 300;

      cursor: pointer;

      input {
        margin-right: 0.5rem;
        border-radius: 0.25rem;

        cursor: pointer;
        &:checked {
          background-color: var(--blue-500);
        }
      }
    }
  }

`;
