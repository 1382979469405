import styled from 'styled-components';

export const Container = styled.div<{
  shouldStickFirstColumn?: boolean;
  shouldStickLastColumn?: boolean;
}>`
  position: relative;
  overflow: overlay;

  flex: 1;

  border-radius: 2rem;

  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);

  table {
    width: 100%;
    background: var(--white);
    z-index: 1;
  }

  thead {
    color: var(--white);
    tr th {
      position: sticky;
      top: 0;
      background-color: var(--blue-400);
      vertical-align: middle;
      padding: 1.25rem;
      z-index: 1;
      font-weight: 500;


      &.sortable,
      &.sortable-asc,
      &.sortable-desc {
        cursor: pointer;

        svg {
          margin-left: 0.5rem;
          width: 0.75rem;
          height: 0.75rem;
          vertical-align: middle;
        }
      }

      &.sortable-desc {
        svg {
          transform: rotate(180deg);
        }
      }
    }

    tr th:first-child {
      left: ${({ shouldStickFirstColumn }) => (shouldStickFirstColumn ? '0' : 'unset')};
      z-index: 2;
    }

    tr th:last-child {
      right: ${({ shouldStickLastColumn }) => (shouldStickLastColumn ? '0' : 'unset')};
      z-index: 2;
    }

    tr {
      margin-bottom: 1rem;
    }
  }

  tbody {
    tr td {
      padding: 0.875rem;
      border-bottom: 1px solid #70707010;
      text-align: center;
    }

    tr td:first-child {
      position: ${({ shouldStickFirstColumn }) => (shouldStickFirstColumn ? 'sticky' : 'unset')};
      left: 0;
      background-color: var(--white);
      padding-left: 2rem;
      text-align:left;
    }

    tr td:last-child {
      position: ${({ shouldStickLastColumn }) => (shouldStickLastColumn ? 'sticky' : 'unset')};
      right: 0;
      background-color: var(--white);
      padding-right: 2rem;
    }

    tr:first-child td {
      padding-top: 1.125rem;
    }

    tr:last-child td {
      border-bottom: unset;
    }
  }

  tfoot tr td {
    position: sticky;
    bottom: 0;
    height: 1.5rem;
    background-color: var(--white);
  }

  scrollbar-width: thin;
  scrollbar-color: var(--blue-400) #E5E5EA;

  &::-webkit-scrollbar {
    width: 0.5rem;
    height: 0.5rem;
  }

  &::-webkit-scrollbar-track {
    background-color: #E5E5EA;
    border-radius: 1rem;
    width: 1rem;
    height: 100%;
    margin: 4.5rem 2rem 2rem;
  }

  &::-webkit-scrollbar-thumb {
    width: 0.25rem;
    border-radius: 1rem;
    background-color: var(--blue-400);
  }

  @supports (-moz-appearance:none) {
    overflow: auto;
  }

  .table__load-more-container {
    width: 100%;
    height: 30px;
  }
`;
