import styled, { css } from 'styled-components';

export const Container = styled.div<{
  isExpanded: boolean;
}>`
  position: relative;
  min-width: 13rem;
  min-height: 3rem;

  z-index: ${({ isExpanded }) => (isExpanded ? '999' : '0')};

  @media (max-width: 400px) {
    min-width: fit-content;
  }
`;

export const Content = styled.div<{
  isExpanded: boolean;
}>`
  cursor: pointer;

  display: flex;
  align-items: center;
  gap: 0.75rem;

  background-color: rgba(255, 255, 255, 0.5);

  padding: 0.5rem;
  border-radius: 1rem;

  border: 1px solid #6d6d6f50;

  svg {
    margin-left: auto;
    margin-right: 0.5rem;

    overflow: visible;

    path {
      fill: var(--gray-500);
    }
  }

  transition: border-radius 0.75s;

  ${({ isExpanded }) => isExpanded
    && css`
      border-radius: 1rem 1rem 0 0;
    `}

  @media (max-width: 400px) {
    h2 {
      display: none;
    }

    svg {
      margin-right: 0;
    }
  }
`;

export const UserAvatar = styled.img`
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  object-fit: cover;
`;

export const DropdownOptions = styled.div<{
  isExpanded: boolean;
}>`
  position: absolute;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;

  font-size: 1rem;
  color: var(--gray-500);

  max-height: 0px;
  overflow: hidden;
  width: 100%;

  border: 1px solid #6d6d6f50;

  background-color: var(--white);

  border-radius: 0 0 1rem 1rem;

  transition: all 0.5s;

  a {
    text-decoration: none;
    color: var(--gray-500);
  }

  button {
    background-color: transparent;
    border: none;
    font-size: inherit;
  }

  ${({ isExpanded }) => (isExpanded
    ? css`
          max-height: 100px;
          padding: 1rem;
        `
    : css`
          border: unset;
          padding: 0;
          visibility: hidden;
        `)}
`;
