import { useRouteMatch } from 'react-router-dom';
import { AddVoucherForm } from '../components/Vouchers/AddVoucherForm';
import { EditVoucherForm } from '../components/Vouchers/EditVoucherForm';
import { Vouchers } from '../pages/Vouchers';
import { AdminRoute } from './AdminRoute';

export function VouchersRouter() {
  const { path } = useRouteMatch();

  return (
    <>
      <AdminRoute path={`${path}`} exact component={Vouchers} />
      <AdminRoute path={`${path}/adicionar`} exact component={AddVoucherForm} />
      <AdminRoute path={`${path}/editar/:voucherId`} exact component={EditVoucherForm} />
    </>
  );
}
