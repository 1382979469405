import endOfDay from 'date-fns/endOfDay';
import startOfDay from 'date-fns/startOfDay';
import parse from 'date-fns/parse';
import { ISchedule } from '../../types';
import { UTCToTime } from '../../utils/UTCToTime';

export const formatScheduleFromApi = (schedule: ISchedule): ISchedule => ({
  ...schedule,
  initialDate: schedule.initial_date
    ? startOfDay((parse(schedule.initial_date, 'yyyy-MM-dd', new Date())))
    : undefined,
  finalDate: schedule.final_date
    ? endOfDay((parse(schedule.final_date, 'yyyy-MM-dd', new Date())))
    : undefined,
  initial_time: UTCToTime(schedule.initial_time),
  final_time: UTCToTime(schedule.final_time),
  intervals: schedule.intervals.map((interval) => ({
    ...interval,
    initial_time: UTCToTime(interval.initial_time),
    final_time: UTCToTime(interval.final_time),
  })),
});

export const schedulesReducer: SchedulesReducer = (state, action) => {
  switch (action.type) {
    case 'SET_SCHEDULES':
      return action.payload.schedules.map(formatScheduleFromApi);
    case 'ADD_SCHEDULE':
      return [...state, formatScheduleFromApi(action.payload.schedule)];
    case 'UPDATE_SCHEDULE':
      return state.map((schedule) => (schedule.id_schedule === action.payload.schedule.id_schedule
        ? formatScheduleFromApi(action.payload.schedule)
        : schedule));
    case 'DELETE_SCHEDULE':
      return state.filter(
        (schedule) => schedule.id_schedule !== action.payload.scheduleId,
      );
    case 'SET_SCHEDULES_BY_PARTNER':
      return [
        ...state.filter(
          (schedule) => schedule.partner_id !== action.payload.partnerId,
        ),
        ...action.payload.schedules.map((schedule) => formatScheduleFromApi(schedule)),
      ];
    default:
      return state;
  }
};

type SetSchedulesAction = {
  type: 'SET_SCHEDULES';
  payload: {
    schedules: ISchedule[];
  };
};

type AddScheduleAction = {
  type: 'ADD_SCHEDULE';
  payload: {
    schedule: ISchedule;
  };
};

type UpdateScheduleAction = {
  type: 'UPDATE_SCHEDULE';
  payload: {
    schedule: ISchedule;
  };
};

type DeleteScheduleAction = {
  type: 'DELETE_SCHEDULE';
  payload: {
    scheduleId: string;
  };
};

type SetSchedulesByUser = {
  type: 'SET_SCHEDULES_BY_PARTNER';
  payload: {
    schedules: ISchedule[];
    partnerId: string;
  };
};

export type SchedulesReducerActions =
  | SetSchedulesAction
  | AddScheduleAction
  | UpdateScheduleAction
  | DeleteScheduleAction
  | SetSchedulesByUser;

export type SchedulesReducer = (
  state: ISchedule[],
  action: SchedulesReducerActions
) => ISchedule[];
