import { ReportsObject, ReportsSubtypes } from '../../pages/Reports';
import {
  ReportItemButton,
  ReportItemSection,
  ReportItemSubsection,
  ReportsList,
  ReportsListWrapper,
} from './styles';

interface IReportsItems {
  reportsObject: Partial<ReportsObject>;
  onSelectSubtype: (subtype: ReportsSubtypes) => void;
}

export function ReportsItems({
  onSelectSubtype,
  reportsObject,
}: IReportsItems) {
  return (
    <ReportsListWrapper>
      <ReportsList>
        {Object.entries(reportsObject).map(
          ([key, value]) => value != null && (
          <ReportItemSection key={key}>
            <ReportItemSubsection>
              <ReportItemButton
                onClick={() => onSelectSubtype(key as ReportsSubtypes)}
                disabled={!!value.disableButton}
              >
                {value.label}
              </ReportItemButton>
            </ReportItemSubsection>
            <p>{value.formattedValue}</p>
          </ReportItemSection>
          ),
        )}
      </ReportsList>
    </ReportsListWrapper>
  );
}
