import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import format from 'date-fns/format';
import subDays from 'date-fns/subDays';
import addDays from 'date-fns/addDays';
import isSameDay from 'date-fns/isSameDay';
import ptBR from 'date-fns/locale/pt-BR';
import parseISO from 'date-fns/parseISO';
import startOfDay from 'date-fns/startOfDay';
import endOfDay from 'date-fns/endOfDay';

import { DashboardChart } from '../../components/Dashboard/DashboardChart';
import { DatePeriodDropdownOption } from '../../components/DatePeriodDropdown';
import { Scheduler } from '../../components/Scheduler';
import { api } from '../../services/api';
import { useAuth } from '../../hooks/useAuth';
import { datePeriodOptions } from '../../resources/DatePeriodOptions';

import {
  IAppointment,
  IAppointmentsGraphResponse,
  ISchedule,
  ITherapistHomeResponse,
  ITipGraphResponse,
} from '../../types';

import {
  Container,
  GraphsContainer,
  IconButton,
  LeftArrow,
  RightArrow,
  SchedulerContainer,
  SelectedDate,
  SelectedDateContainer,
} from './styles';
import { formatScheduleFromApi } from '../Agenda/schedulesReducer';

export default function Dashboard() {
  const { data } = useAuth();
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [schedules, setSchedules] = useState<ISchedule[]>([]);
  const [homeData, setHomeData] = useState<ITherapistHomeResponse>();
  const [appointmentsGraphData, setAppointmentsGraphData] = useState<IAppointmentsGraphResponse>();
  const [tipGraphData, setTipGraphData] = useState<ITipGraphResponse>();
  const [
    appointmentsSelectedTimePeriod,
    setAppointmentsSelectedTimePeriod,
  ] = useState<DatePeriodDropdownOption>(datePeriodOptions[2]);
  const [
    revenuesSelectedTimePeriod,
    setRevenuesSelectedTimePeriod,
  ] = useState<DatePeriodDropdownOption>(datePeriodOptions[2]);

  const fetchSchedules = async () => {
    try {
      const response = await api.get<ISchedule[]>('/schedule');

      const appointmentsResponse = await api.get<IAppointment[]>('/appointment');

      setSchedules(response.data.map(formatScheduleFromApi));
      setHomeData((prevState) => ({
        dashboard: [],
        total: 0,
        appointments: appointmentsResponse.data,
      }));
    } catch (error: any) {
      toast.error(
        error?.response?.data?.message
          || 'Erro ao obter as agendas, tente novamente mais tarde',
      );
    }
  };

  const fetchTipsGraph = async () => {
    const initialDate = revenuesSelectedTimePeriod?.value?.startDate;
    const finalDate = revenuesSelectedTimePeriod?.value?.endDate;

    const params = {
      initialDate: initialDate
        ? startOfDay(initialDate).toISOString() : new Date().toISOString(),
      finalDate: finalDate
        ? endOfDay(finalDate).toISOString() : new Date().toISOString(),
      userId: data?.user?.id_user,
    };

    try {
      const tipsResponse = await api.get<ITipGraphResponse>('/report/tip', {
        params,
      });

      setTipGraphData(tipsResponse.data);
    } catch (error: any) {
      toast.error(
        error?.response?.data?.message
          || 'Algo deu errado, tente novamente mais tarde',
      );
    }
  };

  const fetchAppointmentsGraph = async () => {
    const initialDate = appointmentsSelectedTimePeriod?.value.startDate;
    const finalDate = appointmentsSelectedTimePeriod?.value.endDate;

    const params = {
      initialDate: initialDate
        ? startOfDay(initialDate).toISOString() : new Date().toISOString(),
      finalDate: finalDate
        ? endOfDay(finalDate).toISOString() : new Date().toISOString(),
      userId: data?.user?.id_user,
    };
    try {
      const appointmentsResponse = await api.get<IAppointmentsGraphResponse>('/report/appointments', {
        params,
      });

      setAppointmentsGraphData(appointmentsResponse.data);
    } catch (error: any) {
      toast.error(
        error?.response?.data?.message
          || 'Algo deu errado, tente novamente mais tarde',
      );
    }
  };

  useEffect(() => {
    fetchSchedules();
  }, []);

  useEffect(() => {
    fetchAppointmentsGraph();
  }, [appointmentsSelectedTimePeriod]);

  useEffect(() => {
    fetchTipsGraph();
  }, [revenuesSelectedTimePeriod]);

  const handleDecreaseDay = () => {
    setSelectedDate(subDays(selectedDate, 1));
  };

  const handleIncreaseDay = () => {
    setSelectedDate(addDays(selectedDate, 1));
  };

  const activeSchedules = schedules.filter((schedule) => schedule.active);

  const daysSchedule = activeSchedules.filter((schedule) => {
    const { frequency } = schedule;
    const { initialDate, finalDate } = schedule;

    if (frequency === 'recurrent') {
      const selectedDayWeekDay = selectedDate.toLocaleDateString('pt-BR', { weekday: 'long' }).split('-')[0].substring(0, 3).toLowerCase().replace('á', 'a');
      const isSelectedDayRecurrentWeekday = schedule.days.some(
        (day) => day.name === selectedDayWeekDay,
      );

      if (isSelectedDayRecurrentWeekday) {
        return true;
      }
      return false;
    }

    if (frequency === 'specific') {
      if (initialDate && finalDate) {
        const isSelectedDayBetweenDates = selectedDate >= initialDate && selectedDate <= finalDate;

        if (isSelectedDayBetweenDates) {
          return true;
        }
        return false;
      }
    }
    return false;
  });

  const acceptedDaysAppointments = homeData ? homeData.appointments.filter((appointment) => {
    const {
      datetime, frequency, week_day_index, status,
    } = appointment;

    if (status !== 'accepted') {
      return false;
    }

    if (frequency === 'specific') {
      const date = parseISO(datetime);
      return isSameDay(date, selectedDate);
    }

    return selectedDate.getDay() === week_day_index;
  }) : [];

  return (
    <Container>
      <GraphsContainer>
        <DashboardChart
          title="Faturamento"
          data={tipGraphData?.graph ?? []}
          onPeriodChange={setRevenuesSelectedTimePeriod}
          selectedTimePeriod={revenuesSelectedTimePeriod}
          change={tipGraphData?.totalTip ?? 0}
        />
        <DashboardChart
          title="Atendimentos"
          data={appointmentsGraphData?.graph || []}
          onPeriodChange={setAppointmentsSelectedTimePeriod}
          selectedTimePeriod={appointmentsSelectedTimePeriod}
          yFormatFn={(value: number) => (Number.isInteger(value) ? value.toString() : '')}
          change={appointmentsGraphData?.totalAppointments || 0}
        />
      </GraphsContainer>
      <SchedulerContainer>
        <SelectedDateContainer>
          <IconButton type="button" onClick={handleDecreaseDay}>
            <LeftArrow />
          </IconButton>
          <SelectedDate>
            <strong>
              {format(selectedDate, 'EEE, ', {
                locale: ptBR,
              })}
            </strong>
            {format(selectedDate, "dd 'de' MMMM", {
              locale: ptBR,
            })}
          </SelectedDate>
          <IconButton type="button" onClick={handleIncreaseDay}>
            <RightArrow />
          </IconButton>
        </SelectedDateContainer>
        <Scheduler daySchedules={daysSchedule} dayAppointments={acceptedDaysAppointments} />
      </SchedulerContainer>
    </Container>
  );
}
