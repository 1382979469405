import styled from 'styled-components';

export const Container = styled.div`
  width: 70%;
  margin: 4rem auto;

  > button {
    margin-left: auto;
    width: 100%;
    max-width: 16.25rem;
    font-size: 1.25rem;
  }

  @media (max-width: 600px) {
    width: 100%;
  }
`;

export const FieldsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  column-gap: 6.75rem;
  row-gap: 2rem;
  padding: 2rem;
  padding-bottom: 4rem;

  h1 {
    grid-column: span 2;
    font-size: 1.5rem;
    font-weight: 500;
  }

  input {
    font-size: 1rem;
  }

  .grid-row-span-2 {
    grid-row: span 2;

    @media (max-width: 600px) {
      grid-row: 2;
    }
  }
`;
