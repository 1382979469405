import styled, { css } from 'styled-components';

export const Container = styled.div`
  margin: 2rem auto;

  .agenda__button--rotate {
    transform: rotate(180deg);
  }

  .agenda__month-datepicker {
    height: fit-content;

    box-shadow: unset;

    width: 100%;

    margin: 0 auto;

    * {
      box-shadow: unset;
      font-size: 1.25rem;
      width: 100%;
    }

    .react-datepicker {
      box-shadow: unset;
      padding: 0;
    }

    .react-datepicker__day-names {
      padding: 1rem 4rem;
      font-weight: 700;
    }

    .react-datepicker__week {
      padding-left: 4rem;
      padding-right: 4rem;
    }

    .react-datepicker__day--outside-month {
      visibility: visible;
      filter: opacity(0.4);
    }
  }

  @media (max-width: 600px) {
    .agenda__month-datepicker {
      .react-datepicker__week {
        padding-left: 0;
        padding-right: 0;
      }

      .react-datepicker__day-names {
        padding: 1rem 0;
        font-weight: 700;
      }

      .react-datepicker__day-name {
        margin: 0;
      }
    }
  }
`;

export const HeaderButtonsContainer = styled.div`
  display: flex;

  gap: 2rem;

  padding-bottom: 2rem;

  border-bottom: 1px solid var(--purple-50);
`;

export const HeaderButton = styled.button<{
  isActive: boolean;
}>`
  border: none;

  min-height: 4rem;
  width: 100%;
  max-width: 14rem;

  border-radius: 1rem;

  font-size: 1.25rem;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: var(--purple-50);

  transition: all 0.2s;

  &:hover {
    background-color: var(--blue-400);
    color: var(--white);
  }

  ${({ isActive }) => isActive
    && css`
      background-color: var(--blue-400);
      color: var(--white);
    `}
`;

export const CalendarContainer = styled.div`
  height: 100%;
`;

export const CalendarHeader = styled.div``;

export const CalendarIntervalOptions = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding-top: 2rem;
  margin-top: 2rem;

  span {
    text-transform: capitalize;
  }
`;

export const MonthSelector = styled.div`
  display: flex;
  gap: 1.25rem;

  button {
    background-color: transparent;
    border: none;

    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 1rem;
      height: 1rem;

      path {
        fill: var(--gray-500);
      }
    }
  }

  span {
    font-size: 1.25rem;
    font-weight: 700;
  }
`;

export const IntervalSelector = styled.div`
  background-color: var(--purple-50);

  border-radius: 0.5rem;

  display: flex;
  width: 100%;
  max-width: 17.5rem;
`;

export const IntervalButton = styled.button<{
  isActive: boolean;
}>`
  background-color: transparent;
  border: none;

  padding: 0.75rem;

  width: 100%;

  font-size: 1.25rem;

  border-radius: 0.5rem;

  &:hover {
    background-color: var(--blue-400);
    color: var(--white);
  }

  ${({ isActive }) => isActive
    && css`
      background-color: var(--blue-400);
      color: var(--white);
    `}
`;

export const DaySelector = styled.div`
  display: flex;
  margin-top: 2rem;

  .agenda__day-selector__icon-button {
    background-color: transparent;
    border: none;

    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 1.5rem;
      height: 1.5rem;

      path {
        fill: var(--blue-400);
      }
    }
  }
`;

export const DaysContainer = styled.div`
  display: flex;

  width: 100%;

  margin: 0 1rem;

  background-color: var(--purple-50);

  border-radius: 0.5rem;

  min-height: 6.125rem;

  @media (max-width: 750px) {
    flex-wrap: wrap;
  }
`;

export const DayButton = styled.button<{
  isActive: boolean;
}>`
  background-color: transparent;
  border: none;

  padding: 0.75rem;

  width: 100%;

  font-size: 1.25rem;
  font-weight: 400;
  text-transform: capitalize;

  border-radius: 0.5rem;

  line-height: 1.5;

  p {
    color: var(--gray-500);
  }

  h3 {
    font-size: 1.75rem;
    font-weight: 700;
    color: var(--blue-400);
  }

  &:hover {
    background-color: var(--blue-400);

    p,
    h3 {
      color: var(--white);
    }
  }

  ${({ isActive }) => isActive
    && css`
      background-color: var(--blue-400);

      p,
      h3 {
        color: var(--white);
      }
    `}
`;
