import { useState } from 'react';
import {
  DropdownContainer,
  IconTextButton,
  RadioLabel,
  StyledRadioInput,
} from './styles';

interface IDropdownCheckProps {
  options: {
    label: string;
    value: string;
  }[];
  title: string;
  icon: JSX.Element;
  value: string;
  onChange: (value: string) => void;
}

export function DropdownCheck({
  options,
  icon,
  title,
  value,
  onChange,
}: IDropdownCheckProps) {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggle = () => {
    setIsExpanded((prevState) => !prevState);
  };

  const handleChange = (selectedOption: string) => {
    if (selectedOption !== value) {
      onChange(selectedOption);
    } else {
      onChange('');
    }
  };

  return (
    <DropdownContainer isExpanded={isExpanded}>
      <IconTextButton type="button" onClick={toggle}>
        {icon}
        <span>{title}</span>
      </IconTextButton>
      {isExpanded ? (
        <>
          {options.map((option) => (
            <RadioLabel>
              <StyledRadioInput
                type="radio"
                name="filter"
                value={option.value}
                checked={option.value === value}
                onChange={() => handleChange(option.value)}
              />
              {option.label}
            </RadioLabel>
          ))}
        </>
      ) : null}
    </DropdownContainer>
  );
}
