import {
  ForwardedRef, forwardRef, useEffect, useImperativeHandle, useState,
} from 'react';

import { toast } from 'react-toastify';
import { Input, SuffixInput } from '../Input';

import PlaceholderImage from '../../assets/images/placeholder-image.png';

import {
  LinkServicesModal,
  IConfirmData as IConfirmServicesData,
} from '../LinkServicesModal';

import { FormContainer, LinkServicesButton } from './styles';
import { IAppFormElement } from '../../types';
import { Dropdown } from '../Dropdown';
import { api } from '../../services/api';
import { maskCpfOrCnpj } from '../../utils/maskCpfOrCnpj';
import { maskPhone } from '../../utils/maskPhone';
import { ImageUploadBox } from '../ImageUploadBox';
import { PartnerTypeDropdown } from '../PartnerTypeDropdown';

export interface IPartnerPersonalDetailsFormData {
  name: string;
  cpf_cnpj: string;
  email: string;
  site: string;
  type: string;
  phone: string;
  discount: string | number;
  tip: string | number;
  comments?: string;
  created_at?: string;
  img_url?: string;
  avatar_url?: string;
  cod?: string;
}

export const initialPersonalDetails: IPartnerPersonalDetailsFormData = {
  name: '',
  cpf_cnpj: '',
  email: '',
  site: '',
  type: '',
  phone: '',
  discount: '',
  tip: '',
  cod: '',
  comments: '',
};

interface IPersonalDetailsFormProps {
  initialData: IPartnerPersonalDetailsFormData,
  onSubmit: (data: IPartnerPersonalDetailsFormData) => void;
  title?: string;
  onAvatarUpload?: (file: File) => void;
  showLinkServices?: boolean;
  serviceIds?: string[];
  onConfirmLinkServices?: (confirmData: IConfirmServicesData) => void;
}

interface IPartnerType {
  id_type: string;
  type: string;
}

export const PersonalDetailsForm = forwardRef(({
  initialData,
  onSubmit,
  title,
  onAvatarUpload = () => {},
  showLinkServices = false,
  serviceIds = [],
  onConfirmLinkServices = () => {},
}: IPersonalDetailsFormProps, ref: ForwardedRef<IAppFormElement>) => {
  const [formData, setFormData] = useState(initialData);
  const [avatar, setAvatar] = useState<File | null>(null);
  const [partnerTypes, setPartnerTypes] = useState<IPartnerType[]>([]);
  const [isServicesModalOpen, setIsServicesModalOpen] = useState(false);

  useImperativeHandle(ref, () => ({
    requestSubmit: () => {
      if (!formData.type) {
        toast.error('O tipo do parceiro é obrigatório');
        return;
      }
      onSubmit({
        ...formData,
        comments: formData.comments || undefined,
        cpf_cnpj: formData.cpf_cnpj.replace(/\D/g, ''),
        phone: formData.phone.replace(/\D/g, ''),
      });
    },
    getFormData: () => formData,
  }));

  useEffect(() => {
    fetchPartnerTypes();
  }, []);

  useEffect(() => {
    formatData();
  }, [initialData]);

  const fetchPartnerTypes = async () => {
    try {
      const response = await api.get('/type', {
        params: {
          from: 'partner',
        },
      });

      setPartnerTypes(response.data);
    } catch (error: any) {
      toast.error(error?.response?.data?.message || error?.message || 'Algo deu errado');
    }
  };

  const formatData = () => {
    setFormData((prevState) => ({
      ...prevState,
      cpf_cnpj: maskCpfOrCnpj(prevState?.cpf_cnpj || ''),
      phone: maskPhone(prevState?.phone || ''),
    }));
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = event.target;

    let newValue = value;

    if (name === 'cpf_cnpj') {
      newValue = maskCpfOrCnpj(value);
    } else if (name === 'phone') {
      newValue = maskPhone(value);
    }

    setFormData((prevState) => ({
      ...prevState,
      [name]: newValue,
    }));
  };

  const handlePartnerTypeChange = (value: string) => {
    setFormData((prevState) => ({
      ...prevState,
      type: value,
    }));
  };

  const handleAddNewPartnerType = async (value: string) => {
    try {
      const response = await api.post<IPartnerType>('/type', {
        type: value,
        owner: 'partner',
      });

      setPartnerTypes((prevState) => [...prevState, response.data]);
    } catch (error: any) {
      toast.error(error?.response?.data?.message || error?.message || 'Algo deu errado');
    }
  };

  const handleRemovePartnerType = async (partnerType: IPartnerType) => {
    const { type, id_type } = partnerType;
    try {
      await api.delete(`/type/${id_type}`);

      if (formData.type === type) {
        setFormData((prevState) => ({
          ...prevState,
          type: '',
        }));
      }

      setPartnerTypes((prevState) => prevState.filter((pType) => pType.id_type !== id_type));
    } catch (error: any) {
      toast.error(error?.response?.data?.message || error?.message || 'Algo deu errado');
    }
  };

  const handleAvatarChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    if (files && files.length > 0) {
      setAvatar(files[0]);
      onAvatarUpload(files[0]);
      setFormData((prevState) => ({
        ...prevState,
        img_url: URL.createObjectURL(files[0]),
      }));
    }
  };

  const handleCloseServicesModal = () => {
    setIsServicesModalOpen(false);
  };

  const handleOpenServicesModal = () => {
    setIsServicesModalOpen(true);
  };

  const partnerTypeOptions = partnerTypes.map((partnerType) => ({
    value: partnerType.type,
    label: partnerType.type,
  }));

  return (
    <FormContainer>
      {title && <h1>{title}</h1>}
      <Input
        type="text"
        name="name"
        label="Nome completo:"
        placeholder="Insira o nome completo"
        value={formData?.name || ''}
        onChange={handleInputChange}
        required
      />
      <ImageUploadBox
        id="logo"
        label={
          formData.img_url
            ? 'Altere a logo'
            : 'Insira a logo'
        }
        imgUrl={formData.img_url}
        onChange={handleAvatarChange}
        placeholderUrl={PlaceholderImage}
        customClassName="grid-row-span-2 avatar-upload-box"
      />
      {/* <Dropdown
        options={partnerTypeOptions}
        onChange={handlePartnerTypeChange}
        placeholder="Selecione o tipo"
        value={formData.type}
        label="Tipo:"
        canAddNewValue
        onAddNewValue={handleAddNewPartnerType}
      /> */}
      <PartnerTypeDropdown
        partnerTypes={partnerTypes}
        onChange={handlePartnerTypeChange}
        placeholder="Selecione o tipo"
        value={formData.type}
        label="Tipo:"
        onAddNewValue={handleAddNewPartnerType}
        onRemoveType={handleRemovePartnerType}
      />
      <Input
        type="email"
        name="email"
        label="E-mail:"
        placeholder="Insira o e-mail"
        value={formData?.email || ''}
        onChange={handleInputChange}
        required
      />
      <Input
        type="text"
        name="cpf_cnpj"
        label="CPF ou CNPJ:"
        placeholder="Insira o CPF ou CNPJ"
        value={formData?.cpf_cnpj || ''}
        onChange={handleInputChange}
        maxLength={18}
        required
      />
      <Input
        type="text"
        name="site"
        label="Site:"
        placeholder="Insira o site"
        value={formData?.site || ''}
        onChange={handleInputChange}
      />
      <Input
        type="text"
        name="phone"
        label="Telefone:"
        placeholder="Insira o telefone"
        value={formData?.phone || ''}
        onChange={handleInputChange}
        maxLength={14}
        required
      />
      <SuffixInput
        label="Desconto:"
        suffix="%"
        id="discount"
        type="number"
        name="discount"
        placeholder="Insira a % de desconto"
        value={formData?.discount || ''}
        onChange={handleInputChange}
        min="0"
        max="100"
        required
      />
      <SuffixInput
        label="Comissão:"
        suffix="%"
        id="tip"
        type="number"
        name="tip"
        placeholder="Insira a % de comissão"
        value={formData?.tip || ''}
        onChange={handleInputChange}
        min="0"
        max="100"
        required
      />
      <Input
        type="text"
        name="comments"
        label="Observação:"
        placeholder="Insira alguma observação"
        value={formData?.comments || ''}
        onChange={handleInputChange}
      />
      <Input
        type="text"
        name="cod"
        label="Código de parceiro"
        placeholder="O código de parceiro aparecerá aqui após a criação"
        value={formData?.cod || ''}
        disabled
        onChange={handleInputChange}
      />
      {showLinkServices && (
      <>
        <div className="grid-col-span-2">
          <LinkServicesButton type="button" variant="outlined" onClick={handleOpenServicesModal}>
            Vincular serviços
          </LinkServicesButton>
        </div>
        <LinkServicesModal
          servicesIds={serviceIds}
          isOpen={isServicesModalOpen}
          onClose={handleCloseServicesModal}
          onConfirm={onConfirmLinkServices}
        />
      </>
      )}
    </FormContainer>
  );
});
