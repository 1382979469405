import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { ReactComponent as ContainedPlusIcon } from '../../assets/images/contained-plus.svg';
import { useInfiniteScroll } from '../../hooks/useInfiniteScroll';
import { api } from '../../services/api';
import { IUser } from '../../types';

import { IModalProps, Modal } from '../Modal';
import { SearchBox } from '../SearchBox';
import {
  PatientItem,
  PatientsList,
  Container,
  IconButton,
  SearchBoxWrapper,
  StyledButton,
  StyledDeleteIcon,
  StyledHeader,
} from './styles';

export interface IConfirmData {
  addPatientIds: string[];
  removePatientIds: string[];
}

interface ILinkPatientsModalProps extends IModalProps {
  patientIds: string[];
  onConfirm?: (confirmData: IConfirmData) => void;
}

interface ILinkingUsers extends IUser {
  isActive: boolean;
}

const limitPerPage = 15;

export default function LinkPatientsModal({
  patientIds: initialPatientIds,
  isOpen,
  onClose,
  onConfirm = () => {},
}: ILinkPatientsModalProps) {
  const [patients, setPatients] = useState<ILinkingUsers[]>([]);

  const [nameFilter, setNameFilter] = useState('');
  const [addPatientIds, setAddPatientIds] = useState<string[]>([]);
  const [removePatientIds, setRemovePatientIds] = useState<string[]>([]);

  const [shouldFetch, setShouldFetch] = useState(true);

  const {
    loadMoreRef, page, setPage, maximumPage, setMaximumPage,
  } = useInfiniteScroll();

  useEffect(() => {
    // Debounce for searches
    const timer = setTimeout(() => {
      setPage(1);
      setMaximumPage(1);
      setShouldFetch(true);
    }, 250);

    return () => clearTimeout(timer);
  }, [nameFilter]);

  useEffect(() => {
    if (page > 1 && page <= maximumPage) {
      setShouldFetch(true);
    }
  }, [page]);

  useEffect(() => {
    if (shouldFetch) {
      fetchPatients();
    }
  }, [shouldFetch]);

  useEffect(() => {
    checkActivePatients();
  }, [initialPatientIds, isOpen]);

  const fetchPatients = async () => {
    try {
      const response = await api.get<{
        users:IUser[];
        totalUsers: number;
      }>('/user', {
        params: {
          page,
          limit: limitPerPage,
          role: 'patient',
          query: nameFilter || undefined,
        },
      });

      if (page > 1) {
        const newPatients = response.data.users.map((user) => ({
          ...user,
          isActive: initialPatientIds.some((patientId) => patientId === user?.patient?.id_patient),
        }));

        setPatients((prevState) => [...prevState, ...newPatients]);
      } else {
        setPatients(response.data.users.map((user) => ({
          ...user,
          isActive: initialPatientIds.some((patientId) => patientId === user?.patient?.id_patient),
        })));
      }

      setMaximumPage(Math.ceil(response.data.totalUsers / limitPerPage));
    } catch (error: any) {
      toast.error(
        error?.response?.data?.message
          || 'Algo deu errado ao buscar os pacientes, tente novamente mais tarde',
      );
    } finally {
      setShouldFetch(false);
    }
  };

  const checkActivePatients = () => {
    setPatients((prevState) => prevState.map((user) => ({
      ...user,
      isActive: initialPatientIds.some((p) => user?.patient?.id_patient === p),
    })));
  };

  const handleToggle = (id: string, current: boolean) => {
    setPatients((prevState) => prevState.map((patient) => {
      if (patient?.patient?.id_patient === id) {
        return {
          ...patient,
          isActive: !current,
        };
      }
      return patient;
    }));
    if (current) {
      setAddPatientIds((prevState) => prevState.filter((patientId) => patientId !== id));
      setRemovePatientIds((prevState) => [...prevState, id]);
    } else {
      setAddPatientIds((prevState) => [...prevState, id]);
      setRemovePatientIds((prevState) => prevState.filter((patientId) => patientId !== id));
    }
  };

  const handleClose = () => {
    setAddPatientIds([]);
    setRemovePatientIds([]);
    onClose();
  };

  const handleConfirm = () => {
    onConfirm({
      addPatientIds,
      removePatientIds,
    });
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose}>
      <Container>
        <StyledHeader>Carteira de clientes</StyledHeader>
        <SearchBoxWrapper>
          <SearchBox
            onSubmit={() => {}}
            shouldShowExtraFilters={false}
            placeholder="Pesquisar por nome"
            value={nameFilter}
            onChange={(e) => setNameFilter(e.target.value)}
          />
        </SearchBoxWrapper>
        <PatientsList>
          {patients.map((patient) => (
            <PatientItem key={patient?.patient?.id_patient}>
              <span>{patient.name}</span>
              <IconButton
                onClick={() => handleToggle(patient?.patient?.id_patient || '', patient.isActive)}
              >
                {patient.isActive ? (
                  <StyledDeleteIcon />
                ) : (
                  <ContainedPlusIcon />
                )}
              </IconButton>
            </PatientItem>
          ))}
          {page <= maximumPage && <div className="patients-list__load-more" ref={loadMoreRef} />}
        </PatientsList>
        <StyledButton type="button" onClick={handleConfirm}>
          Confirmar
        </StyledButton>
      </Container>
    </Modal>
  );
}
