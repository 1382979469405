import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import { ReactComponent as HomeIcon } from '../../assets/images/home.svg';
import { ReactComponent as ArrowDownIcon } from '../../assets/images/arrow-down.svg';

import { EditCell } from '../../components/EditCell';
import { FloatingAddButton } from '../../components/FloatingAddButton';
import { IConfirmData, LinkServicesModal } from '../../components/LinkServicesModal';

import { SearchBox } from '../../components/SearchBox';
import { Table } from '../../components/Table';
import { Toggle } from '../../components/Toggle';
import { api } from '../../services/api';
import { IUser, UserRoles } from '../../types';
import { applySort } from '../../utils/applySort';

import { maskCpfOrCnpj } from '../../utils/maskCpfOrCnpj';

import { Container, LinkServiceButton } from './styles';
import { useInfiniteScroll } from '../../hooks/useInfiniteScroll';

const tableHeaders = {
  'user.name': {
    label: 'Nome',
    sortable: true,
  },
  'user.role': {
    label: 'Perfil',
    sortable: true,
  },
  cpf_cnpj: {
    label: 'CPF/CNPJ',
  },
  'address.city': {
    label: 'Cidade',
    sortable: true,
  },
  service: {
    label: 'Serviço',
  },
  active: {
    label: 'Status',
  },
};

const portugueseRoles = {
  admnistrator: 'Administrador',
  therapist: 'Terapeuta',
  master: 'Master',
  patient: 'Paciente',
  internal: 'Interno',
};

function getUserRole(user: IUser) {
  if (user.role === 'admnistrator') {
    if (user.address !== null) {
      return 'Interno';
    }
    return 'Administrador';
  }
  return portugueseRoles[user.role];
}

const limitPerPage = 15;

interface ISearchBoxOptions {
  city: string | undefined;
  role: UserRoles | undefined;
}

export default function Users() {
  const history = useHistory();
  const [users, setUsers] = useState<IUser[]>([]);
  const [sortBy, setSortBy] = useState('');
  const [sortDir, setSortDir] = useState('');
  const [search, setSearch] = useState('');
  const [servicesUser, setServicesUser] = useState<IUser | undefined>();
  const [searchBoxOptions, setSearchBoxOptions] = useState<ISearchBoxOptions>({
    city: undefined,
    role: undefined,
  });
  const [shouldFetch, setShouldFetch] = useState(true);

  const {
    loadMoreRef,
    page,
    maximumPage,
    setMaximumPage,
    setPage,
  } = useInfiniteScroll();

  useEffect(() => {
    // Debounce for searches
    const timer = setTimeout(() => {
      setPage(1);
      setMaximumPage(1);
      setShouldFetch(true);
    }, 250);

    return () => clearTimeout(timer);
  }, [search, searchBoxOptions, sortDir, sortBy]);

  useEffect(() => {
    if (page > 1) {
      setShouldFetch(true);
    }
  }, [page]);

  useEffect(() => {
    if (shouldFetch) {
      fetchUsers();
    }
  }, [shouldFetch]);

  const fetchUsers = async () => {
    const params = {
      role: searchBoxOptions.role ? searchBoxOptions.role : undefined,
      city: searchBoxOptions.city ? searchBoxOptions.city : undefined,
      query: search || undefined,
      limit: limitPerPage,
      field: sortBy || undefined,
      order: sortDir ? sortDir.toUpperCase() : undefined,
      page,
    };

    try {
      const response = await api.get<{
        users: IUser[];
        totalUsers: number;
      }>('/user', {
        params,
      });

      if (page > 1) {
        setUsers((prevState) => [...prevState, ...response.data.users]);
      } else {
        setUsers(response.data.users);
      }

      setMaximumPage(Math.ceil(response.data.totalUsers / limitPerPage));
    } catch (error: any) {
      toast.error(error?.response?.data?.message || error.message || 'Algo deu errado');
    } finally {
      setShouldFetch(false);
    }
  };

  const handleToggleUserActive = async (id: string, previous: boolean) => {
    try {
      await api.put('/user', {
        userId: id,
        active: !previous,
      });

      const newUsers = users.map((user) => {
        if (user.id_user === id) {
          return {
            ...user,
            active: !previous,
          };
        }
        return user;
      });

      setUsers(newUsers);
    } catch (error: any) {
      toast.error(error?.response?.data?.message || error.message || 'Algo deu errado');
    }
  };

  const handleSortChange = (key: string) => {
    if (sortBy === key) {
      if (sortDir === 'asc') {
        setSortDir('desc');
      } else if (sortDir === 'desc') {
        setSortDir('');
      } else {
        setSortDir('asc');
      }
    } else {
      setSortBy(key as keyof IUser);
      setSortDir('asc');
    }
  };

  const handleOpenLinkServicesModal = (userId: string) => {
    const foundUser = users.find((user) => user.id_user === userId);
    setServicesUser(foundUser);
  };

  const handleAddUser = () => {
    history.push('/usuarios/adicionar');
  };

  const handleEditUser = (userId: string, userRole: string) => {
    switch (userRole) {
      case 'Administrador':
        history.push(`/usuarios/editar/${userId}/admnistrator`);
        break;
      case 'Interno':
        history.push(`/usuarios/editar/${userId}/internal`);
        break;
      case 'Terapeuta':
        history.push(`/usuarios/editar/${userId}`);
        break;
      case 'Paciente':
        history.push(`/usuarios/editar/${userId}/patient`);
        break;
      default:
        history.push(`/usuarios/editar/${userId}`);
    }
  };

  const handleLinkServicesConfirm = async (confirmData: IConfirmData) => {
    if (!servicesUser || !servicesUser.therapist || !servicesUser.id_user) {
      return;
    }

    if (confirmData.addServiceIds.length === 0 && confirmData.removeServiceIds.length === 0) {
      return;
    }

    try {
      const response = await api.patch<IUser>('/therapist/service', {
        ...confirmData,
        id_therapist: servicesUser.therapist.id_therapist,
      });

      setUsers((prevState) => prevState.map((user) => {
        if (user.id_user === servicesUser.id_user) {
          return {
            ...user,
            therapist: {
              ...user.therapist,
              ...response.data?.therapist,
            },
          } as IUser;
        }
        return user;
      }));
    } catch (error: any) {
      toast.error(error?.response?.data?.message || error.message || 'Algo deu errado');
    }
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  const formattedUsers = users.map((user) => (
    {
      id: user.id_user,
      'user.name': user.name,
      'user.role': getUserRole(user),
      cpf_cnpj: user.cpf ? maskCpfOrCnpj(user.cpf) : ((user?.therapist && maskCpfOrCnpj(user?.therapist.cnpj || '')) || '-'),
      'address.city': user?.address ? user.address.city : '-',
      active: user.active,
    }
  ));

  /* const sortedUsers = applySort(formattedUsers, sortBy, sortDir); */

  const tableData = formattedUsers.map((user) => ({
    ...user,
    'user.name':
  <EditCell
    content={user['user.name']}
    onEditClick={() => handleEditUser(user.id, user['user.role'] as UserRoles)}
  />,
    active: <Toggle
      value={user.active}
      onToggle={() => handleToggleUserActive(user.id, user.active)}
    />,
    service: user['user.role'] === 'Terapeuta' ? <LinkServiceButton type="button" onClick={() => handleOpenLinkServicesModal(user.id)}>Vincular serviços</LinkServiceButton> : '-',
  }));

  const searchBoxExtraFilters = [
    {
      icon: <ArrowDownIcon />,
      name: 'Perfil',
      onChange: (value: string) => {
        setSearchBoxOptions({
          ...searchBoxOptions,
          role: value as UserRoles,
        });
      },
      value: searchBoxOptions.role || '',
      isDropdown: true,
      dropdownOptions: [
        {
          label: 'Administrador',
          value: 'admnistrator',
        },
        {
          label: 'Terapeuta',
          value: 'therapist',
        },
        {
          label: 'Todos',
          value: '',
        },
      ],
    },
    {
      icon: <HomeIcon />,
      name: 'Cidade',
      onChange: (value: string) => {
        setSearchBoxOptions({
          ...searchBoxOptions,
          city: value,
        });
      },
      value: searchBoxOptions.city || '',
      isDropdown: false,
    },
  ];

  return (
    <Container>
      <SearchBox onSubmit={() => {}} placeholder="Pesquisar usuários, CPF ou CNPJ" value={search} onChange={handleSearchChange} extraFilters={searchBoxExtraFilters} />
      <Table
        headers={tableHeaders}
        data={tableData}
        onSort={handleSortChange}
        sortDir={sortDir}
        sortBy={sortBy}
        loadMoreRef={loadMoreRef}
        hasMore={page <= maximumPage}
      />
      <FloatingAddButton type="button" onClick={handleAddUser} />
      <LinkServicesModal
        isOpen={!!servicesUser}
        servicesIds={servicesUser?.therapist
          ? servicesUser.therapist.therapistServices.map((service) => service.service_id)
          : []}
        onClose={() => setServicesUser(undefined)}
        onConfirm={handleLinkServicesConfirm}
      />
    </Container>
  );
}
