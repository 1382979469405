import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import { isBefore } from 'date-fns';
import { ReactComponent as CalendarIcon } from '../../assets/images/calendar.svg';
import { ReactComponent as DeleteIcon } from '../../assets/images/delete.svg';

import { api } from '../../services/api';

import { IDay } from '../../types';

import { AppDatePicker } from '../AppDatePicker';
import { Button } from '../Button';
import { Input } from '../Input';
import { IScheduleFormData } from './AgendaForm';

export interface IAgendaTimesFormProps {
  scheduleFormData: IScheduleFormData;
  onValueChange: (value: string | Date, name: string) => void;
  onInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onAddInterval: () => void;
  onIntervalChange: (id: string, name: string, value: string) => void;
  onDayChange: (value: IDay) => void;
  onRemoveInterval: (id: string) => void;
}

const formattedWeekDays = [
  'Domingo',
  'Segunda',
  'Terça',
  'Quarta',
  'Quinta',
  'Sexta',
  'Sábado',
];

export function AgendaTimesForm({
  scheduleFormData,
  onInputChange,
  onValueChange,
  onAddInterval,
  onIntervalChange,
  onDayChange,
  onRemoveInterval,
}: IAgendaTimesFormProps) {
  const [weekDays, setWeekDays] = useState<IDay[]>([]);

  useEffect(() => {
    fetchWeekDays();
  }, []);

  const fetchWeekDays = async () => {
    try {
      const response = await api.get<IDay[]>('/days');
      setWeekDays(
        response.data.map((day, index) => ({
          ...day,
          label: formattedWeekDays[index],
        })),
      );
    } catch (error: any) {
      toast.error(error?.response?.data?.message || error?.message);
    }
  };

  return (
    <>
      {scheduleFormData.frequency === 'recurrent' && (
        <RecurrentDaysContainer>
          <label className="recurrent-days__label">
            Selecione os dias da semana
          </label>
          <div className="recurrent-days__wrapper">
            {weekDays.map((day) => (
              <Button
                key={day.id_week_day}
                type="button"
                variant={
                  scheduleFormData.days.some(
                    (d) => d.id_week_day === day.id_week_day,
                  )
                    ? 'contained'
                    : 'outlined'
                }
                onClick={() => onDayChange(day)}
                className="recurrent-days__button"
              >
                {day.label}
              </Button>
            ))}
          </div>
        </RecurrentDaysContainer>
      )}
      <FieldsContainer>
        {scheduleFormData.frequency === 'specific' && (
          <>
            <StyledLabel>
              <span>Data de início</span>
              <AppDatePicker
                selected={scheduleFormData.initialDate}
                dateFormat="dd/MM/yyyy"
                onChange={(date: Date) => {
                  if (scheduleFormData.finalDate && isBefore(scheduleFormData.finalDate, date)) {
                    onValueChange(date, 'finalDate');
                  }

                  onValueChange(date, 'initialDate');
                }}
                icon={<CalendarIcon />}
                leftIcon={false}
                customClassName="agenda-form__custom-datepicker"
                popperPlacement="bottom-end"
              />
            </StyledLabel>
            <StyledLabel>
              <span>Data de término</span>
              <AppDatePicker
                selected={scheduleFormData.finalDate}
                dateFormat="dd/MM/yyyy"
                onChange={(date: Date) => onValueChange(date, 'finalDate')}
                icon={<CalendarIcon />}
                leftIcon={false}
                minDate={scheduleFormData.initialDate}
                customClassName="agenda-form__custom-datepicker"
                popperPlacement="bottom-end"
                popperModifiers={[
                  {
                    name: 'offset',
                    options: {
                      offset: [0, 0],
                    },
                  },
                  {
                    name: 'preventOverflow',
                    enabled: false,
                  },
                  {
                    name: 'hide',
                    enabled: false,
                  },
                  {
                    name: 'flip',
                    enabled: false,
                    options: {
                      allowedAutoPlacements: ['bottom'],
                    },
                  },
                ]}
              />
            </StyledLabel>
          </>
        )}

        <Input
          name="initial_time"
          onChange={onInputChange}
          label="Horário inicial"
          type="time"
          step="1800"
          value={scheduleFormData.initial_time}
          required
        />
        <Input
          name="final_time"
          onChange={onInputChange}
          label="Horário final"
          type="time"
          step="1800"
          value={scheduleFormData.final_time}
          required
        />
        {scheduleFormData.intervals.map((interval) => (
          <div
            key={interval.id_interval}
            className="agenda-form__intervals-container"
          >
            <button
              type="button"
              className="agenda-form__delete-interval-button"
              onClick={() => onRemoveInterval(interval.id_interval || '')}
            >
              <DeleteIcon />
            </button>
            <Input
              label="Intervalo inicial"
              type="time"
              name="initial_time"
              value={interval.initial_time}
              onChange={(e) => onIntervalChange(
                interval.id_interval || '',
                'initial_time',
                e.target.value,
              )}
              required
            />
            <Input
              label="Intervalo final"
              type="time"
              name="final_time"
              value={interval.final_time}
              onChange={(e) => onIntervalChange(
                interval.id_interval || '',
                'final_time',
                e.target.value,
              )}
              required
            />
          </div>
        ))}
        <button
          type="button"
          className="agenda-form__add-interval-button"
          onClick={onAddInterval}
        >
          Adicionar novo intervalo
        </button>
      </FieldsContainer>
    </>
  );
}

const RecurrentDaysContainer = styled.div`
  max-width: 54rem;
  margin: 0 auto;
  margin-top: 2rem;

  .recurrent-days__label {
    display: block;
    margin-bottom: 0.375rem;
    padding-left: 0.75em;
  }

  .recurrent-days__wrapper {
    display: flex;
    align-items: center;
    justify-content: center;

    gap: 1rem;
  }

  .recurrent-days__button {
    font-size: 1rem;
    height: 2rem;
    padding: 0.75rem;
    padding-bottom: 0.875rem;
  }

  @media (max-width: 650px) {
    .recurrent-days__wrapper {
      flex-wrap: wrap;
    }
  }
`;

const FieldsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;

  max-width: 30rem;
  width: 100%;
  margin: 2rem auto;

  input {
    font-size: 1rem;
    height: 3rem;
  }

  .agenda-form__intervals-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 2rem;

    position: relative;

    width: 100%;

    grid-column: 1 / 3;
  }

  .agenda-form__delete-interval-button {
    background-color: transparent;
    border: none;

    position: absolute;
    bottom: 0;
    left: 0;

    height: 3rem;
    width: 3rem;

    display: flex;
    align-items: center;
    justify-content: center;

    transform: translateX(-100%);

    svg {
      width: 1rem;
      height: 1rem;

      path {
        fill: var(--gray-500);
      }
    }
  }

  .agenda-form__add-interval-button {
    background-color: transparent;
    border: none;

    font-size: 0.875rem;

    color: var(--blue-400);

    margin-left: auto;
    grid-column: 1 / 3;

    transition: filter 0.2s;

    &:hover {
      filter: brightness(0.8);
    }
  }
`;

const StyledLabel = styled.label`
  display: flex;
  flex-direction: column;

  gap: 0.5rem;

  span {
    margin-left: 0.75rem;
  }
`;
