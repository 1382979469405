import {
  ForwardedRef,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { IAppFormElement, IPatient } from '../../types';
import { maskCpfOrCnpj } from '../../utils/maskCpfOrCnpj';
import { Button } from '../Button';
import { Input, SuffixInput } from '../Input';
import { ReactComponent as PartnersIcon } from '../../assets/images/navbar-icons/group.svg';
import { ReactComponent as WalletIcon } from '../../assets/images/wallet.svg';
import { LinkPatientsModal, IConfirmData as IConfirmPatientsData } from '../LinkPatientsModal';
import {
  LinkPartnersModal,
  IConfirmData as IConfirmPartnersData,
} from '../LinkPartnersModal';
import {
  LinkServicesModal,
  IConfirmData as IConfirmServicesData,
} from '../LinkServicesModal';
import {
  FormContainer, IconButtonContent, IconLinkButton, LinkServicesButton,
} from './styles';

export interface IAdditionalInformationFormData {
  cnpj: string;
  tip: string | number;
  presentation: string;
  facebook?: string;
  instagram?: string;
  tiktok?: string;
}

export const initialAdditionalInformation: IAdditionalInformationFormData = {
  cnpj: '',
  tip: '',
  presentation: '',
};

interface IAdditionalInformationFormProps {
  initialData: IAdditionalInformationFormData;
  onSubmit: (data: IAdditionalInformationFormData) => void;
  title?: string;
  serviceIds?: string[];
  onConfirmLinkServices?: (confirmData: IConfirmServicesData) => void;
  partnerIds?: string[];
  onConfirmLinkPartners?: (confirmData: IConfirmPartnersData) => void;
  patientIds?: string[];
  onConfirmLinkPatients?: (confirmData: IConfirmPatientsData) => void;
  shouldHideLinkButtons?: boolean;
  showServicesModal?: boolean;
  isEditingSelf?: boolean;
}

export const AdditionalInformationalForm = forwardRef(
  (
    {
      initialData,
      onSubmit,
      title,
      serviceIds = [],
      partnerIds = [],
      patientIds = [],
      onConfirmLinkServices = () => {},
      onConfirmLinkPartners = () => {},
      onConfirmLinkPatients = () => {},
      shouldHideLinkButtons = false,
      showServicesModal = false,
      isEditingSelf = false,
    }: IAdditionalInformationFormProps,
    ref: ForwardedRef<IAppFormElement>,
  ) => {
    const [formData, setFormData] = useState(initialData);
    const [isServicesModalOpen, setIsServicesModalOpen] = useState(false);
    const [isPartnersModalOpen, setIsPartnersModalOpen] = useState(false);
    const [isPatientsModalOpen, setIsPatientsModalOpen] = useState(false);

    const handleInputChange = (
      event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
      const { name, value } = event.target;

      let newValue = value;

      if (name === 'cnpj') {
        newValue = maskCpfOrCnpj(value);
      }

      setFormData((prevState) => ({
        ...prevState,
        [name]: newValue,
      }));
    };

    useImperativeHandle(ref, () => ({
      requestSubmit: () => onSubmit(formData),
      getFormData: () => formData,
    }));

    useEffect(() => {
      setFormData(initialData);
    }, [initialData]);

    const handleOpenServicesModal = () => {
      setIsServicesModalOpen(true);
    };

    const handleOpenPartnersModal = () => {
      setIsPartnersModalOpen(true);
    };

    const handleOpenPatientsModal = () => {
      setIsPatientsModalOpen(true);
    };

    const handleCloseServicesModal = () => {
      setIsServicesModalOpen(false);
    };

    const handleClosePartnersModal = () => {
      setIsPartnersModalOpen(false);
    };

    const handleClosePatientsModal = () => {
      setIsPatientsModalOpen(false);
    };

    return (
      <>
        <FormContainer>
          {title && <h1>{title}</h1>}
          <Input
            name="cnpj"
            label="CPF ou CNPJ:"
            placeholder="Insira o CPF ou CNPJ"
            onChange={handleInputChange}
            value={formData.cnpj || ''}
            maxLength={18}
          />
          {!isEditingSelf && (
          <SuffixInput
            label="Comissão:"
            suffix="%"
            id="tip"
            name="tip"
            type="number"
            min="0"
            max="100"
            placeholder="Insira a comissão"
            onChange={handleInputChange}
            value={formData.tip || ''}
          />
          )}
          <Input
            name="presentation"
            label="Apresentação:"
            placeholder="Insira a apresentação"
            onChange={handleInputChange}
            value={formData.presentation || ''}
          />
          <Input
            name="facebook"
            label="Facebook:"
            placeholder="Insira o Facebook"
            onChange={handleInputChange}
            value={formData.facebook || ''}
            className="grid-column-1"
          />
          <Input
            name="instagram"
            label="Instagram:"
            placeholder="Insira o Instagram"
            onChange={handleInputChange}
            value={formData.instagram || ''}
          />
          <Input
            name="tiktok"
            label="Tiktok:"
            placeholder="Insira o Tiktok"
            onChange={handleInputChange}
            value={formData.tiktok || ''}
          />
          {/* {shouldHideLinkButtons ? null : (
            <div className="link-buttons-container">
              <IconLinkButton
                type="button"
                variant="outlined"
                onClick={handleOpenPatientsModal}
              >
                <IconButtonContent>
                  <WalletIcon />
                  <span>Visualizar carteira de clientes</span>
                </IconButtonContent>
              </IconLinkButton>
              <IconLinkButton
                type="button"
                variant="outlined"
                onClick={handleOpenPartnersModal}
              >
                <IconButtonContent>
                  <PartnersIcon />
                  <span>Visualizar parceiros ativos</span>
                </IconButtonContent>
              </IconLinkButton>
            </div>
          )} */}
          {
            showServicesModal ? (
              <div className="link-buttons-container">
                <LinkServicesButton type="button" variant="outlined" onClick={handleOpenServicesModal}>
                  Vincular serviços
                </LinkServicesButton>
              </div>
            ) : null
          }
        </FormContainer>
        {shouldHideLinkButtons ? null : (
          <>
            <LinkPartnersModal
              isOpen={isPartnersModalOpen}
              onClose={handleClosePartnersModal}
              partnersIds={partnerIds}
              onConfirm={onConfirmLinkPartners}
              shouldUseIcons
            />

            <LinkPatientsModal
              isOpen={isPatientsModalOpen}
              onClose={handleClosePatientsModal}
              onConfirm={onConfirmLinkPatients}
              patientIds={patientIds}
            />
          </>
        )}
        {
          showServicesModal ? (
            <LinkServicesModal
              servicesIds={serviceIds}
              isOpen={isServicesModalOpen}
              onClose={handleCloseServicesModal}
              onConfirm={onConfirmLinkServices}
            />
          ) : null
        }

      </>
    );
  },
);
