import { useLocation } from 'react-router-dom';
import Router from '../../routes/Router';
import { Header } from '../Header';
import { Container, Content } from './styles';

export default function Layout() {
  const location = useLocation();
  const SingleElementPages = ['/entrar'];

  if (SingleElementPages.includes(location.pathname)) {
    return <Router />;
  }

  return (
    <Container>
      <Header />
      <Content>
        <Router />
      </Content>
    </Container>
  );
}
