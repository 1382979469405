import React, { useEffect, useReducer, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useNotificationModal } from '../../../hooks/useNotificationModal';
import { schedulesReducer } from '../../../pages/Agenda/schedulesReducer';
import { api } from '../../../services/api';
import {
  IDay, IInterval, IPartner, ISchedule,
} from '../../../types';
import { timeToUTC } from '../../../utils/timeToUTC';
import { UTCToTime } from '../../../utils/UTCToTime';
import { initialScheduleFormData, IScheduleFormData } from '../../Agenda/AgendaForm';
import { AgendaTable } from '../../Agenda/AgendaTable';
import { PartnerAgendaForm } from '../../Agenda/PartnerAgendaForm';
import { Button } from '../../Button';
import { EditForm } from '../../EditForm';
import { ButtonsContainer, Container } from './styles';

const initialInterval: IInterval = {
  id_interval: `${Math.random()}`,
  initial_time: '',
  final_time: '',
};

export default function ManagePartnerAgenda() {
  const history = useHistory();
  const [isEditing, setIsEditing] = useState(true);
  const [editingScheduleId, setEditingScheduleId] = useState('');
  const [scheduleFormData, setScheduleFormData] = useState<IScheduleFormData>(
    initialScheduleFormData,
  );
  const [partners, setPartners] = useState<IPartner[]>([]);
  const { notificate } = useNotificationModal();

  const [schedules, dispatch] = useReducer(schedulesReducer, []);

  useEffect(() => {
    fetchPartnerSchedules();
  }, []);

  useEffect(() => {
    if (editingScheduleId) {
      handleEditingIdChange();
    } else {
      setScheduleFormData(initialScheduleFormData);
    }
  }, [editingScheduleId]);

  useEffect(() => {
    fetchPartners();
  }, []);

  const fetchPartnerSchedules = async () => {
    try {
      const response = await api.get<ISchedule[]>('/schedule');

      const partnerSchedules = response.data.filter(
        (schedule) => schedule.owner === 'partner',
      );

      dispatch({
        type: 'SET_SCHEDULES',
        payload: { schedules: partnerSchedules },
      });
    } catch (error: any) {
      toast.error(
        error?.response?.data?.message
          || 'Algo deu errado ao carregar as agendas, tente novamente mais tarde',
      );
    }
  };

  const fetchPartners = async () => {
    try {
      const response = await api.get<{
        partners: IPartner[];
        totalPartners: number;
      }>('/partner', {
        params: {
          page: 1,
          limit: 99,
        },
      });
      setPartners(response.data.partners);
    } catch (error: any) {
      toast.error(error?.response?.data?.message || 'Algo deu errado, tente novamente mais tarde');
    }
  };

  const handleEditingIdChange = () => {
    const schedule = schedules.find(
      (s) => s.id_schedule === editingScheduleId,
    );

    if (!schedule) {
      return;
    }

    const {
      type_name,
      initial_time,
      final_time,
      partner_id,
      initial_date,
      final_date,
      addresses,
      intervals,
      ...rest
    } = schedule;

    setScheduleFormData((prevState) => ({
      ...prevState,
      ...rest,
      addresses: addresses.map((address) => address?.id_address || ''),
      partnerId: partner_id ?? '',
      initialDate: initial_date ? new Date(initial_date) : new Date(),
      finalDate: final_date ? new Date(final_date) : new Date(),
      type: 'partner',
      initial_time: initial_time.substring(0, 5),
      final_time: final_time.substring(0, 5),
      radius: undefined,
      intervals,
    }));
  };

  const handleGoBack = () => {
    history.goBack();
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    const {
      finalDate,
      initialDate,
      initial_time,
      final_time,
      days,
      frequency,
      intervals,
      partnerId,
    } = scheduleFormData;

    let dataToSubmit = {
      scheduleId: editingScheduleId || undefined,
      type: 'partner',
      partnerId,
      initial_time: timeToUTC(initial_time),
      final_time: timeToUTC(final_time),
      frequency,
      intervals: intervals.map((interval) => ({
        initial_time: timeToUTC(interval.initial_time),
        final_time: timeToUTC(interval.final_time),
      })),
    } as {
      [key: string]: any;
    };

    if (frequency === 'recurrent') {
      dataToSubmit = {
        ...dataToSubmit,
        days: days.map((day) => ({
          id_week_day: day.id_week_day,
          name: day.name,
        })),
      };
    } else {
      dataToSubmit = {
        ...dataToSubmit,
        initial_date: initialDate.toISOString().split('T')[0],
        final_date: finalDate.toISOString().split('T')[0],
      };
    }

    try {
      if (editingScheduleId) {
        const response = await api.put('/schedule', dataToSubmit);

        dispatch({
          type: 'UPDATE_SCHEDULE',
          payload: {
            schedule: response.data,
          },
        });

        notificate('Salvo com sucesso', handleSuccess);
      } else {
        const response = await api.post<ISchedule[]>('/schedule', {
          ...dataToSubmit,
          ownerId: partnerId,
          ownerType: 'partner',
        });

        dispatch({
          type: 'SET_SCHEDULES_BY_PARTNER',
          payload: {
            schedules: response.data,
            partnerId: response.data[0].partner_id || '',
          },
        });
        notificate('Criado com sucesso', handleSuccess);
      }
    } catch (error: any) {
      toast.error(error?.response?.data?.message || 'Algo deu errado, tente novamente mais tarde!');
    }
  };

  const handleSuccess = () => {
    history.push('/parceiros');
  };

  const handleEditSchedule = (id: string) => {
    setEditingScheduleId(id);
    setIsEditing(true);
  };

  const handleSuccessfulEdit = () => {
    setEditingScheduleId('');
    setIsEditing(false);
  };

  const handleValueChange = (value: string | Date, name: string) => {
    setScheduleFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleAddInterval = () => {
    setScheduleFormData((prevState) => ({
      ...prevState,
      intervals: [
        ...prevState.intervals,
        { ...initialInterval, id_interval: `${Math.random()}` },
      ],
    }));
  };

  const handleIntervalChange = (id: string, name: string, value: string) => {
    setScheduleFormData((prevState) => ({
      ...prevState,
      intervals: prevState.intervals.map(
        (interval) => (interval.id_interval === id ? { ...interval, [name]: value } : interval),
      ),
    }));
  };

  const handleRemoveInterval = (id: string) => {
    setScheduleFormData((prevState) => ({
      ...prevState,
      intervals: prevState.intervals.filter(
        (interval) => interval.id_interval !== id,
      ),
    }));
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = event.target;

    handleValueChange(value, name);
  };

  const handleDayChange = (value: IDay) => {
    setScheduleFormData((prevState) => {
      const isDaySelected = prevState.days.some(
        (day) => day.id_week_day === value.id_week_day,
      );
      if (isDaySelected) {
        return {
          ...prevState,
          days: prevState.days.filter(
            (day) => day.id_week_day !== value.id_week_day,
          ),
        };
      }
      return {
        ...prevState,
        days: [...prevState.days, value],
      };
    });
  };

  return (
    <Container>
      <EditForm
        onArrowClick={handleGoBack}
        onSubmit={handleSubmit}
        title="Editar Agenda"
        buttonText="Salvar alterações"
        showDeleteButton={false}
        customClassName="manage-partner-agenda__edit-form"
        hideSubmitButton={isEditing && !editingScheduleId}
      >
        <ButtonsContainer>
          <Button
            variant={isEditing ? 'contained' : 'outlined'}
            type="button"
            onClick={() => setIsEditing(true)}
          >
            Editar agenda
          </Button>
          <Button
            variant={!isEditing ? 'contained' : 'outlined'}
            type="button"
            onClick={() => {
              setIsEditing(false);
              setEditingScheduleId('');
            }}
          >
            Nova agenda
          </Button>
        </ButtonsContainer>

        {isEditing && !editingScheduleId && (
          <AgendaTable
            schedules={schedules}
            onClickEdit={handleEditSchedule}
            scheduleDispatch={dispatch}
          />
        )}

        {(!isEditing || (isEditing && !!editingScheduleId)) && (
        <PartnerAgendaForm
          scheduleFormData={scheduleFormData}
          onAddInterval={handleAddInterval}
          onValueChange={handleValueChange}
          onRemoveInterval={handleRemoveInterval}
          onInputChange={handleInputChange}
          onDayChange={handleDayChange}
          onIntervalChange={handleIntervalChange}
          disablePartnerSelect={isEditing}
        />
        )}
      </EditForm>
    </Container>
  );
}
