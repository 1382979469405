import { useRouteMatch } from 'react-router-dom';
import { AddPartnerForm } from '../components/Partners/AddPartnerForm';
import { EditPartnerForm } from '../components/Partners/EditPartnerForm';
import { ManagePartnerAgenda } from '../components/Partners/ManagePartnerAgenda';

import { Partners } from '../pages/Partners';
import { AdminRoute } from './AdminRoute';

export function PartnersRouter() {
  const { path } = useRouteMatch();
  return (
    <>
      <AdminRoute path={`${path}`} exact component={Partners} />
      <AdminRoute path={`${path}/agenda`} exact component={ManagePartnerAgenda} />
      <AdminRoute path={`${path}/editar/:partnerId`} exact component={EditPartnerForm} />
      <AdminRoute path={`${path}/adicionar`} exact component={AddPartnerForm} />
    </>

  );
}
