import { useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import { useNotificationModal } from '../../../hooks/useNotificationModal';
import { api } from '../../../services/api';

import { IAppFormElement } from '../../../types';

import { CreationForm } from '../../CreationForm';
import { IConfirmData as IServiceConfirmData } from '../../LinkServicesModal';
import { IConfirmData as IPartnerConfirmData } from '../../LinkPartnersModal';
import { AdditionalInformationalForm, IAdditionalInformationFormData, initialAdditionalInformation } from '../AdditionalInformationForm';
import { AddressForm, IAddressFormData, initialAddress } from '../AddressForm';
import { PersonalDetailsForm, IPersonalDetailsFormData, initialPersonalDetails } from '../PersonalDetailsForm';

const addTherapistFormSteps = [
  {
    key: 'personalDetails',
    label: 'Dados pessoais',
  },
  {
    key: 'address',
    label: 'Endereço',
  },
  {
    key: 'additionalInfo',
    label: 'Dados adicionais',
  },
] as const;

interface IAddTherapistFormData {
  personalDetails: IPersonalDetailsFormData;
  address: IAddressFormData;
  additionalInfo: IAdditionalInformationFormData;
}

type FormDataTypes = IPersonalDetailsFormData | IAddressFormData | IAdditionalInformationFormData;

export default function AddTherapistForm() {
  const [addTherapistFormData, setAddTherapistFormData] = useState<IAddTherapistFormData>({
    personalDetails: initialPersonalDetails,
    address: initialAddress,
    additionalInfo: initialAdditionalInformation,
  });
  const [currentStep, setCurrentStep] = useState(0);
  const [avatar, setAvatar] = useState<File | null>(null);
  const [confirmLinkServicesData, setConfirmLinkServicesData] = useState<IServiceConfirmData>({
    addServiceIds: [],
    removeServiceIds: [],
  });
  const [confirmLinkPartnersData, setConfirmLinkPartnersData] = useState<IPartnerConfirmData>({
    addPartnerIds: [],
    removePartnerIds: [],
  });
  const { notificate } = useNotificationModal();
  const currentFormRef = useRef<IAppFormElement>(null);
  const history = useHistory();

  const onStepChange = (step: number) => {
    if (step < 0) {
      history.goBack();
    } else {
      setCurrentStep(step);
    }
  };

  const handleSuccess = () => {
    history.goBack();
  };

  const handleSubmit = async (data?: FormDataTypes) => {
    const newAddTherapistFormData = {
      ...addTherapistFormData,
      [addTherapistFormSteps[currentStep].key]: data,
    };

    setAddTherapistFormData(newAddTherapistFormData);

    if (currentStep !== addTherapistFormSteps.length - 1) {
      onStepChange(currentStep + 1);
    } else {
      if (confirmLinkServicesData?.addServiceIds.length < 1) {
        toast.error('É necessário vincular pelo menos um serviço');
        return;
      }

      const document = newAddTherapistFormData.additionalInfo.cnpj;

      const documentDigits = document.replace(/\D/g, '');

      const dataToSubmit = {
        ...newAddTherapistFormData.personalDetails,
        ...newAddTherapistFormData.additionalInfo,
        address: newAddTherapistFormData.address,
        video: newAddTherapistFormData.personalDetails.video || undefined,
        tip: Number(newAddTherapistFormData.additionalInfo.tip),
        born: new Date(newAddTherapistFormData.personalDetails.born).toISOString(),
        services: confirmLinkServicesData.addServiceIds,
        img_url: undefined,
        confirm_password: undefined,
        avatar_url: undefined,
        ...(documentDigits.length === 11 && { cpf: documentDigits, cnpj: undefined }),
        ...(documentDigits.length === 14 && { cnpj: documentDigits, cpf: undefined }),
      };

      const formDataToSubmit = new FormData();

      Object.entries(dataToSubmit).forEach(([key, value]) => {
        if (value) {
          formDataToSubmit.append(key, value.toString());
        }
      });

      formDataToSubmit.set('services', JSON.stringify(confirmLinkServicesData.addServiceIds));

      formDataToSubmit.set('address', JSON.stringify(newAddTherapistFormData.address));

      if (avatar) {
        formDataToSubmit.append('img', avatar, avatar.name.replace(' ', '-'));
      }

      try {
        await api.post('/therapist', formDataToSubmit);

        /* if (confirmLinkPartnersData?.addPartnerIds.length > 0) {
          await api.patch('/therapist/partner', {
            addPartnerIds: confirmLinkPartnersData.addPartnerIds,
            removePartnerIds: confirmLinkPartnersData.removePartnerIds,
            id_therapist: response.data.id_therapist,
          });
        } */

        notificate('Criado com sucesso!', handleSuccess);
      } catch (error: any) {
        toast.error(error?.response?.data?.message || 'Algo deu errado');
      }
    }
  };

  const handleClickNext = (event: React.FormEvent) => {
    event.preventDefault();
    if (!currentFormRef.current) return;
    currentFormRef.current.requestSubmit();
  };

  const handleAvatarUpload = (file: File) => {
    setAvatar(file);
  };

  const handleConfirmLinkServices = (confirmData:IServiceConfirmData) => {
    const { addServiceIds, removeServiceIds } = confirmData;

    const filteredServiceIds = confirmLinkServicesData.addServiceIds.filter(
      (id) => !removeServiceIds.includes(id),
    );

    const newServiceIds = Array.from(new Set([...filteredServiceIds, ...addServiceIds]));

    setConfirmLinkServicesData({
      addServiceIds: newServiceIds,
      removeServiceIds: [],
    });
  };

  const handleConfirmLinkPartners = (confirmData:IPartnerConfirmData) => {
    setConfirmLinkPartnersData(confirmData);
  };

  const formProps = {
    onSubmit: handleSubmit,
    ref: currentFormRef,
  };

  const addTherapistFormComponents = {
    personalDetails: <PersonalDetailsForm
      {...formProps}
      initialData={addTherapistFormData.personalDetails}
      onAvatarUpload={handleAvatarUpload}
    />,
    address: <AddressForm
      {...formProps}
      initialData={addTherapistFormData.address}
    />,
    additionalInfo: <AdditionalInformationalForm
      {...formProps}
      initialData={addTherapistFormData.additionalInfo}
      serviceIds={confirmLinkServicesData.addServiceIds}
      partnerIds={[]}
      onConfirmLinkServices={handleConfirmLinkServices}
      onConfirmLinkPartners={handleConfirmLinkPartners}
      shouldHideLinkButtons
      showServicesModal
    />,
  };

  return (
    <CreationForm
      title={addTherapistFormSteps[currentStep].label}
      onArrowClick={() => onStepChange(currentStep - 1)}
      onSubmit={handleClickNext}
      buttonText={currentStep === addTherapistFormSteps.length - 1 ? 'Criar' : 'Próximo'}
    >
      {addTherapistFormComponents[addTherapistFormSteps[currentStep].key]}
    </CreationForm>
  );
}
