import styled, { css } from 'styled-components';

import { ReactComponent as SelectArrow } from '../../assets/images/select-arrow.svg';

import { Button } from '../Button';

export const TherapistProfileForm = styled.form`
  margin-bottom: 2rem;

  > * + * {
    border-top: 1px solid #70707015;
    margin-top: 4rem;
  }
`;

export const TextButton = styled.button<{
  center?: boolean;
}>`
  background: transparent;
  border: none;

  display: block;

  margin-left: auto;

  margin-top: 0;

  color: var(--blue-400);

  font-size: 1.25rem;

  ${({ center }) => center
    && css`
      margin: auto;
    `}

  transition: filter 0.2s;

  &:hover {
    filter: brightness(0.8);
  }
`;

export const StyledButton = styled(Button)`
  width: 100vw;
  max-width: 17rem;
  margin-left: auto;
  font-size: 1.25rem;
`;

export const TherapistPointLabel = styled.label`
  display: flex;
  flex-direction: column;

  gap: 0.375rem;

  overflow: hidden;
  padding: 0.375rem;

  > span {
    margin-left: 0.75rem;
  }
`;

export const TherapistPointButton = styled.button`
  background: transparent;
  border: none;

  min-height: 3rem;
  font-size: 1rem;

  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);

  padding: 1rem;
  border-radius: 0.5rem;

  display: flex;
  align-items: center;
  width: 100%;
`;

export const TherapistPointButtonText = styled.span<{
  isEmpty: boolean;
}>`
  flex: 1;
  max-width: 100%;

  text-align: left;

  text-overflow: clip;
  overflow: hidden;

  ${({ isEmpty }) => isEmpty
    && css`
      opacity: 0.5;
    `}
`;

export const RightArrow = styled(SelectArrow)`
  transform: rotate(270deg);

  overflow: visible;

  path {
    fill: var(--gray-500);
  }
`;
