import styled from 'styled-components';

export const SelectContainer = styled.div`
  position: relative;

  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  width: 100%;
`;

export const SelectLabel = styled.label`
  font-size: 1rem;
  font-weight: 400;
  color: '#1c1c1c';
  `;

export const ErrorMessage = styled.span`
  position: absolute;
  bottom: -1.25rem;
  left: 1rem;
  font-size: 0.75rem;
  color: red;
`;
