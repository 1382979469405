import styled, { css } from 'styled-components';

export const Container = styled.div<{
  isClosing: boolean;
}>`
  position: fixed;
  inset: 0;

  min-height: 100vh;
  width: 100%;

  overflow: auto;

  background-color: rgba(0, 0, 0, 0.5);

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  z-index: 99999;

  transition: all 0.3s;

  opacity: 1;
  visibility: visible;

  ${({ isClosing }) => isClosing
    && css`
      opacity: 0;
      visibility: hidden;
    `}

`;

export const Content = styled.div<{
  isClosing: boolean;
}>`
  height: fit-content;
  width: fit-content;
  max-height: 100%;
`;
