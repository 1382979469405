import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;

  padding-bottom: 4rem;
`;

export const Field = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  .admin-dashboard__date-picker {
    z-index: 5;
    height: 2.6875rem;
    font-size: 1rem;
    width: 100%;

    .react-datepicker__input-container {
      width: 100%;

      > input {
        width: 100%;
        min-width: 100%;
        font-size: 1rem;
      }
    }

    .react-datepicker__wrapper {
      width: 100%;
    }

    > * {
      width: 100%;
    }
  }
`;

export const Label = styled.label``;

export const RequiredAsterisk = styled.strong`
  color: var(--blue-400);
  font-weight: bold;
  display: inline-block;
  margin-left: 0.25rem;
`;

export const Input = styled.input`
  background: transparent;
  border: none;

  width: 100%;
  height: 2.6875rem;
  border-radius: 0.5rem;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);

  padding: 0 1rem;

  &::placeholder {
    filter: opacity(0.75);
  }

  &:focus {
    outline: 2px solid var(--gray-500);
  }
`;

export const DailyDashboardContainer = styled.div`
  margin-top: 1rem;

  width: 100%;
  max-width: 75rem;

  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
`;

export const DailyDashboardCard = styled.div`
  padding: 1.75rem 2rem;

  width: 100%;

  border-radius: 1.25rem;
  box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.16);

  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
`;

export const FilterGrid = styled.div`
  margin-top: 2rem;

  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(13rem, 1fr));
  grid-column-gap: 1.5rem;
  grid-row-gap: 1.5rem;
  place-items: start;
`;

export const TableWrapper = styled.div`
  position: relative;
  overflow: overlay;

  flex: 1;

  border-radius: 0.5rem;

  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);

  scrollbar-width: thin;
  scrollbar-color: var(--blue-400) #e5e5ea;

  &::-webkit-scrollbar {
    width: 0.5rem;
    height: 0.5rem;
  }

  &::-webkit-scrollbar-track {
    background-color: #e5e5ea;
    border-radius: 1rem;
    width: 1rem;
    height: 100%;
    margin: 4.5rem 2rem 2rem;
  }

  &::-webkit-scrollbar-thumb {
    width: 0.25rem;
    border-radius: 1rem;
    background-color: var(--blue-400);
  }

  @supports (-moz-appearance: none) {
    overflow: auto;
  }
`;

export const Table = styled.table`
  width: 100%;
  background: var(--white);
  z-index: 1;

  thead {
    color: var(--white);
    tr th {
      position: sticky;
      top: 0;
      background-color: var(--blue-400);
      vertical-align: middle;
      padding: 1.25rem;
      z-index: 1;
      font-weight: 500;

      &.sortable,
      &.sortable-asc,
      &.sortable-desc {
        cursor: pointer;

        svg {
          margin-left: 0.5rem;
          width: 0.75rem;
          height: 0.75rem;
          vertical-align: middle;
        }
      }

      &.sortable-desc {
        svg {
          transform: rotate(180deg);
        }
      }
    }

    tr th:first-child {
      z-index: 2;
    }

    tr th:last-child {
      z-index: 2;
    }

    tr {
      margin-bottom: 1rem;
    }
  }

  tbody {
    tr td {
      padding: 0.875rem;
      border-bottom: 1px solid #70707010;
      text-align: center;
      white-space: nowrap;
    }

    tr td:first-child {
      left: 0;
      background-color: var(--white);
      padding-left: 2rem;
      text-align: left;
    }

    tr td:last-child {
      right: 0;
      background-color: var(--white);
      padding-right: 2rem;
    }

    tr:first-child td {
      padding-top: 1.125rem;
    }

    tr:last-child td {
      border-bottom: unset;
    }
  }

  tfoot tr td {
    position: sticky;
    bottom: 0;
    height: 1.5rem;
    background-color: var(--white);
  }
`;

export const Title = styled.p`
  font-size: 1.5rem;
  font-weight: 500;
`;

interface DashboardTypeButtonProps {
  isActive: boolean;
}

export const DashboardTypeButton = styled.button<DashboardTypeButtonProps>`
  border: none;

background-color: var(--white);
color: var(--grey-500);

width: 100%;
min-height: 3.5rem;

padding: 1rem;

box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);

border-radius: 0.5rem;

display: flex;
align-items: center;
justify-content: center;

font-size: 1.25rem;

transition: all 0.2s;

&:hover {
  background-color: var(--blue-400);
  color: var(--white);
}

${({ isActive }) => (isActive && css`
  background-color: var(--blue-400);
  color: var(--white);
`)}
`;

export const TextButton = styled.button`
  border: none;
  background: transparent;

  margin-top: 1rem;

  font-size: 1rem;
  font-weight: 500;
  color: var(--blue-400);
  white-space: nowrap;

  transition: filter 0.2s;

  &:hover {
    filter: brightness(0.9);
  }
`;

export const PaginateWrapper = styled.div`
  ul {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    width: fit-content;
    margin-left: auto;
  }

  li {
    padding: 0.5rem;
    border: 1px solid var(--blue-400);
    border-radius: 0.25rem;
    color: var(--gray-500);
    cursor: pointer;
    background: #ffffff;
    min-width: 2rem;
    text-align: center;
  }

  .selected {
    color: white;
    background: var(--blue-400);
    font-weight: 600;
  }

  .next, .previous {
    font-size: 1.75rem;
    padding: 0;
    height: 2.125rem;

    svg {
      height: 1.25rem;
      margin-top: 0.375rem;
    }
  }
`;
