import styled from 'styled-components';
import { Button } from '../Button';

export const FormContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 2rem;
  grid-row-gap: 2rem;
  grid-column-gap: 4rem;

  h1 {
    grid-column: span 2;
    font-size: 1.5rem;
    font-weight: 500;
  }

  label, input {
    font-size: 1rem;
  }

  .grid-row-span-2 {
    grid-row: span 2;
  }

  .grid-col-span-2 {
    grid-column: span 2;
  }

  .avatar-upload-box {
    margin-left: auto;
    margin-right: 0;

    @media (max-width: 600px) {
      grid-row: 1;
      margin: 0 auto;
    }
  }

  @media (max-width: 600px) {
    grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));

    h1 {
      grid-column: span 1;
    }
  }
`;

export const LinkServicesButton = styled(Button)`
  font-size: 1.25rem;
  padding: 0.5rem 2.5rem;

  margin: auto;

  height: fit-content;

  width: 100%;
  max-width: 320px;
`;
