import { Redirect, Route } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';

export function PrivateRoute({ component: Component, allowedRoles, ...rest }: any) {
  const { data } = useAuth();

  const castedAllowedRoles = allowedRoles ? allowedRoles as string[] : [];

  if (castedAllowedRoles && castedAllowedRoles.length) {
    if (data && data?.user?.role && castedAllowedRoles.includes(data?.user?.role)) {
      return <Route {...rest} render={(props) => <Component {...props} />} />;
    }
    return <Redirect to="/entrar" />;
  }

  return (
    <Route
      {...rest}
      render={(props) => (data.token ? (
        <Component {...props} />
      ) : (
        <Redirect to="/entrar" />
      ))}
    />
  );
}
