import {
  endOfDay, format, parseISO, startOfDay,
} from 'date-fns';

import { useEffect, useState } from 'react';

import {
  MdOutlineKeyboardArrowLeft,
  MdOutlineKeyboardArrowRight,
} from 'react-icons/md';
import ReactPaginate from 'react-paginate';
import { toast } from 'react-toastify';

import { AppDatePicker } from '../../components/AppDatePicker';
import AsyncSelectPaginate from '../../components/AsyncSelectPaginate/AsyncSelectPaginate';
import DailyDashboard from '../../components/DailyDashboard/DailyDashboard';
import RatingDashboard from '../../components/RatingDashboard/RatingDashboard';
import Select from '../../components/Select/Select';
import TotalDashboard from '../../components/TotalDashboard/TotalDashboard';

import { api } from '../../services/api';

import {
  IPartner, IService, IUser, IVoucher,
} from '../../types';

import { IAppointmentDashboard, ITotalDashboard } from '../../types/Dashboard';

import { formatCurrencyInCents } from '../../utils/formatCurrencyInCents';

import {
  Container,
  Field,
  FilterGrid,
  Label,
  PaginateWrapper,
  RequiredAsterisk,
  Table,
  TableWrapper,
  TextButton,
  Title,
} from './styles';

import {
  appointmentTypeOptions,
  ratingOptions,
  scheduleTypePt,
  stateOptions,
  statusOptions,
  statusPt,
} from './utils';
import { states } from '../../resources/States';
import MultiSelect from '../../components/Select/MultiSelect';

interface Option {
  value: string | number;
  label: string;
}

const LIMIT = 10;

const AdminDashboard = () => {
  const [initialDate, setInitialDate] = useState<Date | undefined>();
  const [finalDate, setFinalDate] = useState<Date | undefined>();
  const [isLoadingService, setIsLoadingService] = useState(false);
  const [isLoadingVoucher, setIsLoadingVoucher] = useState(false);

  const [selectedTherapists, setSelectedTherapists] = useState<Option[]>([]);
  const [selectedPatients, setSelectedPatients] = useState<Option[]>([]);
  const [selectedServices, setSelectedServices] = useState<Option[]>([]);
  const [selectedPartners, setSelectedPartners] = useState<Option[]>([]);
  const [selectedVouchers, setSelectedVouchers] = useState<Option[]>([]);

  const [selectedRating, setSelectedRating] = useState<Option | null>(null);
  const [selectedStatus, setSelectedStatus] = useState<Option | null>(null);
  const [selectedAppointmentType, setSelectedAppointmentType] = useState<Option | null>(null);
  const [selectedState, setSelectedState] = useState<Option | null>(null);

  const [appointmentDashboard, setAppointmentDashboard] = useState<IAppointmentDashboard>();
  const [dashboardPage, setDashboardPage] = useState(1);
  const [maximumDashboardPage, setMaximumDashboardPage] = useState(1);

  const [serviceOptions, setServiceOptions] = useState<Option[]>([]);
  const [voucherOptions, setVoucherOptions] = useState<Option[]>([]);

  const [totalDashboard, setTotalDashboard] = useState<ITotalDashboard>();

  const handleDatesChange = ([first, last]: [Date, Date]) => {
    setInitialDate(first ? startOfDay(first) : undefined);
    setFinalDate(last ? endOfDay(last) : undefined);
  };

  const therapistIds = selectedTherapists?.map(
    (therapist) => therapist.value,
  );
  const patientIds = selectedPatients?.map((patient) => patient.value);
  const serviceIds = selectedServices?.map((service) => service.value);
  const partnerIds = selectedPartners?.map((partner) => partner.value);
  const voucherIds = selectedVouchers?.map((voucher) => voucher.value);

  const params = {
    initialDate: initialDate ? initialDate.toISOString() : undefined,
    finalDate: finalDate ? finalDate.toISOString() : undefined,
    therapistId: therapistIds?.length ? therapistIds : undefined,
    patientId: patientIds?.length ? patientIds : undefined,
    serviceId: serviceIds?.length ? serviceIds : undefined,
    partnerId: partnerIds?.length ? partnerIds : undefined,
    voucherId: voucherIds?.length ? voucherIds : undefined,
    rating: selectedRating?.value || undefined,
    status: selectedStatus?.value || undefined,
    type: selectedAppointmentType?.value || undefined,
    page: dashboardPage,
    address: selectedState?.value || undefined,
    limit: LIMIT,
  };

  const getDashboard = async () => {
    if (!initialDate) {
      setAppointmentDashboard(undefined);
      return;
    }

    try {
      const { data } = await api.get<IAppointmentDashboard>('/dashboard', {
        params,
      });

      setAppointmentDashboard(data);
      setMaximumDashboardPage(Math.ceil(data.results.total / LIMIT));
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    }
  };

  const getTotalDashboard = async () => {
    try {
      const { data } = await api.get<ITotalDashboard>('/dashboard/total', {
        params,
      });

      setTotalDashboard(data);
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    getDashboard();
    getTotalDashboard();
  }, [
    initialDate,
    dashboardPage,
    finalDate,
    selectedTherapists,
    selectedRating,
    selectedPatients,
    selectedServices,
    selectedPartners,
    selectedStatus,
    selectedAppointmentType,
    selectedVouchers,
    selectedState,
  ]);

  const handleClearFilters = () => {
    setInitialDate(undefined);
    setFinalDate(undefined);
    setSelectedTherapists([]);
    setSelectedRating(null);
    setSelectedPatients([]);
    setSelectedServices([]);
    setSelectedPartners([]);
    setSelectedVouchers([]);
    setSelectedState(null);
    setAppointmentDashboard(undefined);
    setSelectedStatus(null);
    setSelectedAppointmentType(null);
    setDashboardPage(1);
    setMaximumDashboardPage(1);
  };

  const getTherapistOptions = async (query: string, offset: number) => {
    const page = Math.floor(offset / LIMIT) + 1;

    try {
      const { data } = await api.get<{
        users: IUser[];
        totalUsers: number;
      }>('/user', {
        params: {
          page,
          limit: LIMIT,
          role: 'therapist',
          query: query || undefined,
        },
      });

      return {
        options: data.users.map((user) => ({
          label: user.name,
          value: user.therapist?.id_therapist || '',
        })),
        hasMore: data.totalUsers > page * LIMIT,
      };
    } catch (error) {
      console.error(error);
    }
    return {
      options: [],
      hasMore: false,
    };
  };

  const getPatientOptions = async (search: string, offset: number) => {
    const page = Math.floor(offset / LIMIT) + 1;

    try {
      const { data } = await api.get<{
        users: IUser[];
        totalUsers: number;
      }>('/user', {
        params: {
          page,
          limit: LIMIT,
          role: 'patient',
          query: search || undefined,
        },
      });

      return {
        options: data.users.map((user) => ({
          label: user.name,
          value: user.patient?.id_patient || '',
        })),
        hasMore: data.totalUsers > page * LIMIT,
      };
    } catch (error) {
      console.error(error);
    }
    return {
      options: [],
      hasMore: false,
    };
  };

  const getServiceOptions = async () => {
    setIsLoadingService(true);

    try {
      const { data } = await api.get<IService[]>('/service');

      setServiceOptions(
        data.map((service) => ({
          label: service.name,
          value: service.id_service,
        })),
      );
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoadingService(false);
    }
  };

  const getVoucherOptions = async () => {
    setIsLoadingVoucher(true);
    try {
      const { data } = await api.get<IVoucher[]>('/voucher');

      setVoucherOptions(
        data.map((voucher) => ({
          label: voucher.name,
          value: voucher.id_voucher,
        })),
      );
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoadingVoucher(false);
    }
  };

  const getPartnerOptions = async (search: string, offset: number) => {
    const page = Math.floor(offset / LIMIT) + 1;

    try {
      const { data } = await api.get<{
        partners: IPartner[];
        totalPartners: number;
      }>('partner', {
        params: {
          page,
          limit: LIMIT,
          query: search || undefined,
        },
      });

      return {
        options: data.partners.map((partner) => ({
          label: partner.name,
          value: partner.id_partner || '',
        })),
        hasMore: data.totalPartners > page * LIMIT,
      };
    } catch (error) {
      console.error(error);
    }
    return {
      options: [],
      hasMore: false,
    };
  };

  return (
    <Container>
      <DailyDashboard />
      <FilterGrid>
        <Field>
          <Label>
            Período
            <RequiredAsterisk>
              *
            </RequiredAsterisk>
          </Label>
          <AppDatePicker
            customClassName="admin-dashboard__date-picker"
            value={
              initialDate
                ? `${initialDate?.toLocaleDateString('pt-BR') || ''} à ${
                  finalDate?.toLocaleDateString('pt-BR') || ''
                }`
                : 'Selecione'
            }
            startDate={initialDate}
            endDate={finalDate}
            onChange={handleDatesChange}
            selectsRange
          />
        </Field>
        <Select
          onChange={(option) => {
            setDashboardPage(1);
            setMaximumDashboardPage(1);
            setSelectedRating(option);
          }}
          value={selectedRating}
          defaultOptions={ratingOptions}
          placeholder="Selecione"
          label="Avaliação"
        />
        <Select
          onChange={(option) => {
            setDashboardPage(1);
            setMaximumDashboardPage(1);
            setSelectedState(option);
          }}
          value={selectedState}
          defaultOptions={stateOptions}
          placeholder="Selecione"
          label="Localização"
        />
        <AsyncSelectPaginate
          debounceTimeout={200}
          placeholder="Selecione"
          label="Terapeuta"
          loadOptions={(search, loadedOptions) => getTherapistOptions(search, loadedOptions.length)}
          loadOptionsOnMenuOpen
          onChange={(options) => {
            setDashboardPage(1);
            setMaximumDashboardPage(1);
            setSelectedTherapists(options.map((option) => option));
          }}
          value={selectedTherapists}
        />
        <AsyncSelectPaginate
          loadOptions={(search, loadedOptions) => getPatientOptions(search, loadedOptions.length)}
          debounceTimeout={200}
          loadOptionsOnMenuOpen
          placeholder="Selecione"
          label="Paciente"
          onChange={(options) => {
            setDashboardPage(1);
            setMaximumDashboardPage(1);
            setSelectedPatients(options.map((option) => option));
          }}
          value={selectedPatients}
        />
        <AsyncSelectPaginate
          loadOptions={(search, loadedOptions) => getPartnerOptions(search, loadedOptions.length)}
          placeholder="Selecione"
          label="Parceiro"
          onChange={(options) => {
            setDashboardPage(1);
            setMaximumDashboardPage(1);
            setSelectedPartners(options.map((option) => option));
          }}
          value={selectedPartners}
        />
        <MultiSelect
          defaultOptions={serviceOptions}
          isLoading={isLoadingService}
          onFocus={() => {
            if (serviceOptions.length > 0) return;
            getServiceOptions();
          }}
          placeholder="Selecione"
          label="Serviço"
          onChange={(options) => {
            setDashboardPage(1);
            setMaximumDashboardPage(1);
            setSelectedServices(options.map((option) => option));
          }}
          value={selectedServices}
        />
        <MultiSelect
          defaultOptions={voucherOptions}
          isLoading={isLoadingVoucher}
          onFocus={() => {
            if (voucherOptions.length > 0) return;
            getVoucherOptions();
          }}
          placeholder="Selecione"
          label="Voucher"
          onChange={(options) => {
            setDashboardPage(1);
            setMaximumDashboardPage(1);
            setSelectedVouchers(options.map((option) => option));
          }}
          value={selectedVouchers}
        />
        <Select
          defaultOptions={statusOptions}
          placeholder="Selecione"
          label="Status"
          onChange={(option) => {
            setDashboardPage(1);
            setMaximumDashboardPage(1);
            setSelectedStatus(option);
          }}
          value={selectedStatus}
        />
        <Select
          defaultOptions={appointmentTypeOptions}
          placeholder="Selecione"
          label="Tipo"
          onChange={(option) => {
            setDashboardPage(1);
            setMaximumDashboardPage(1);
            setSelectedAppointmentType(option);
          }}
          value={selectedAppointmentType}
        />

        <TextButton
          type="button"
          onClick={handleClearFilters}
          style={{
            placeSelf: 'center',
          }}
        >
          Limpar filtros
        </TextButton>
      </FilterGrid>

      <Title>Lista de atendimentos</Title>

      <TableWrapper>
        <Table>
          <thead>
            <tr>
              <th>Data</th>
              <th>Status</th>
              <th>Tipo</th>
              <th>Paciente</th>
              <th>Terapeuta</th>
              <th>Serviço</th>
              <th>Cidade</th>
              <th>Estado</th>
              <th>Parceiro</th>
              <th>Voucher</th>
              <th>Valor</th>
              <th>Pago</th>
              <th>Saldo</th>
            </tr>
          </thead>
          <tbody>
            {appointmentDashboard?.results.data.map((appointment) => (
              <tr key={appointment.id_appointment}>
                <td>{format(parseISO(appointment.datetime), 'dd/MM/yyyy')}</td>
                <td>{statusPt[appointment.status] || '-'}</td>
                <td>{scheduleTypePt[appointment.schedule.type_name] || '-'}</td>
                <td>{appointment?.patient?.user?.name || '-'}</td>
                <td>{appointment?.therapist?.user?.name || '-'}</td>
                <td>{appointment?.service?.name || '-'}</td>
                <td>{appointment?.address?.city || '-'}</td>
                <td>{appointment?.address?.uf || '-'}</td>
                <td>{appointment?.partner?.name || '-'}</td>
                <td>{appointment?.voucher?.name || '-'}</td>
                <td>{formatCurrencyInCents(appointment?.total)}</td>
                <td>
                  {formatCurrencyInCents(appointment?.therapist_tip || 0)}
                </td>
                <td>
                  {formatCurrencyInCents(
                    (appointment?.total || 0)
                      - (appointment?.therapist_tip || 0),
                  )}
                </td>
              </tr>
            ))}
            <tr>
              <td colSpan={10}>
                <p
                  style={{
                    width: 'fit-content',
                    marginLeft: 'auto',
                    padding: '0.5rem 0 0.75rem',
                  }}
                >
                  TOTAL
                </p>
              </td>
              <td>
                {formatCurrencyInCents(
                  totalDashboard?.total_billing || 0,
                )}
              </td>
              <td>
                {formatCurrencyInCents(
                  totalDashboard?.total_comission || 0,
                )}
              </td>
              <td>
                {formatCurrencyInCents(
                  totalDashboard?.balance || 0,
                )}
              </td>
            </tr>
          </tbody>
        </Table>
      </TableWrapper>

      <PaginateWrapper>
        <ReactPaginate
          pageCount={maximumDashboardPage}
          onPageChange={(event) => {
            setDashboardPage(event.selected + 1);
          }}
          nextLabel={<MdOutlineKeyboardArrowRight />}
          previousLabel={<MdOutlineKeyboardArrowLeft />}
          forcePage={dashboardPage - 1}
        />
      </PaginateWrapper>

      <Title>Avaliações</Title>

      <RatingDashboard params={params} />

      <Title>Totais</Title>
      <TotalDashboard data={totalDashboard} />
    </Container>
  );
};

export default AdminDashboard;
