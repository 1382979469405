export const NavigationTitles = {
  '/usuarios': 'Usuários',
  '/perfil': 'Meu Perfil',
  '/servicos': 'Serviços',
  '/servicos/adicionar': 'Cadastro Serviço',
  '/servicos/*': 'Serviços',
  '/parceiros': 'Parceiros',
  '/parceiros/adicionar': 'Cadastro Parceiros',
  '/parceiros/*': 'Parceiros',
  '/vouchers': 'Vouchers',
  '/vouchers/adicionar': 'Cadastro Vouchers',
  '/vouchers/*': 'Vouchers',
  '/anamneses': 'Anamneses',
  '/anamneses/adicionar': 'Cadastro Anamneses',
  '/anamneses/*': 'Anamneses',
  '/usuarios/editar/:id': 'Usuários',
  '/usuarios/adicionar': 'Cadastro Usuário',
  '/usuarios/adicionar/administrador': 'Cadastro Administrador',
  '/usuarios/adicionar/prestador': 'Cadastro Prestador',
  '/usuarios/adicionar/prestador/terapeuta': 'Cadastro Terapeuta',
  '/usuarios/adicionar/prestador/interno': 'Cadastro Interno',
  '/usuarios/*': 'Usuários',
  '/clientes/*': 'Clientes',
  '/clientes': 'Clientes',
  '/acesso': 'Perfil de Acesso',
  '/relatorios': 'Relatórios',
  '/dashboard': 'Dashboard',
  '/agenda': 'Agenda',
  '/admindashboard': 'Dashboard',
} as {
  [key: string]: string
};
