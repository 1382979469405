import styled from 'styled-components';
import { Button } from '../Button';

export const Container = styled.div`
  background-color: var(--white);
  border-radius: 2rem;

  max-width: 47rem;
  width: 100vw;

  padding-bottom: 1.5rem;

  box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.16);
`;

export const StyledHeader = styled.header`
  background-color: var(--blue-400);
  color: var(--white);

  font-weight: 700;
  font-size: 1.5rem;

  width: 100%;

  text-align: center;

  border-radius: 2rem 2rem 0 0;
  padding: 1rem 0;
`;

export const PermissionsList = styled.main`
  margin: 2rem;

  max-height: 20rem;
  overflow: auto;

  scrollbar-width: thin;
  scrollbar-color: var(--blue-400) #e5e5ea;

  &::-webkit-scrollbar {
    width: 0.5rem;
    height: 0.5rem;
  }

  &::-webkit-scrollbar-track {
    background-color: #e5e5ea;
    border-radius: 1rem;
    width: 1rem;
    height: 100%;
  }

  &::-webkit-scrollbar-thumb {
    width: 0.25rem;
    border-radius: 1rem;
    background-color: var(--blue-400);
  }
`;

export const PermissionItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #70707015;
  padding: 0.5rem 0;

  margin-right: 1rem;

  span {
    font-size: 1.25rem;
  }

  &:last-child {
    border-bottom: none;
  }
`;

export const StyledButton = styled(Button)`
  font-size: 1.25rem;
  width: 50%;
  margin: 0rem auto;
`;
