import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { EditCell } from '../../components/EditCell';

import { ReactComponent as ArrowDownIcon } from '../../assets/images/arrow-down.svg';
import { ReactComponent as CalendarIcon } from '../../assets/images/calendar.svg';
import { ReactComponent as HomeIcon } from '../../assets/images/home.svg';
import { ReactComponent as PlusIcon } from '../../assets/images/plus.svg';

import { SearchBox } from '../../components/SearchBox';
import { Table } from '../../components/Table';

import { api } from '../../services/api';
import { IPartner, IPartnerType } from '../../types';

import { applySort } from '../../utils/applySort';

import {
  AssociatedPatientsButton, ContainedIconButton, Container, FloatingButtonsContainer,
} from './styles';
import { useInfiniteScroll } from '../../hooks/useInfiniteScroll';
import { PartnerPatientsModal } from '../../components/PartnerPatientsModal';

const partnerTableHeaders = {
  name: {
    label: 'Parceiro',
    sortable: true,
  },
  type_name: {
    label: 'Tipo',
  },
  city: {
    label: 'Cidade',
    sortable: true,
  },
  patient_count: {
    label: 'Associados',
    sortable: true,
  },
  appointmentCount: {
    label: 'Atendimentos',
    sortable: true,
  },
  tip: {
    label: 'Comissão',
    sortable: true,
  },
  discount: {
    label: 'Desconto',
    sortable: true,
  },
};

const limitPerPage = 15;

function formatCurrency(cents: number) {
  return new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
    maximumFractionDigits: 0,
  }).format(cents / 100);
}

export default function Partners() {
  const [partners, setPartners] = useState<IPartner[]>([]);
  const [selectedPartner, setSelectedPartner] = useState<IPartner>();
  const [types, setTypes] = useState<IPartnerType[]>([]);
  const [sortBy, setSortBy] = useState<keyof IPartner>('name');
  const [sortDir, setSortDir] = useState('');
  const [search, setSearch] = useState('');
  const [cityFilter, setCityFilter] = useState('');
  const [filterType, setFilterType] = useState('');
  const history = useHistory();

  const [shouldFetch, setShouldFetch] = useState(true);

  const {
    loadMoreRef,
    page,
    setPage,
    maximumPage,
    setMaximumPage,
  } = useInfiniteScroll();

  useEffect(() => {
    fetchTypes();
  }, []);

  useEffect(() => {
    // Debounce for searches
    const timer = setTimeout(() => {
      setPage(1);
      setMaximumPage(1);
      setShouldFetch(true);
    }, 250);

    return () => clearTimeout(timer);
  }, [search, cityFilter, filterType]);

  useEffect(() => {
    if (page > 1 && page <= maximumPage) {
      setShouldFetch(true);
    }
  }, [page]);

  useEffect(() => {
    if (shouldFetch) {
      fetchPartners();
    }
  }, [shouldFetch]);

  const fetchPartners = async () => {
    const params = {
      type: filterType || undefined,
      query: search || undefined,
      city: cityFilter || undefined,
      page: 1,
      limit: limitPerPage,
    };

    try {
      const partnersResponse = await api.get<{
        partners: IPartner[];
        totalPartners: number;
      }>('/partner', { params });
      setPartners(partnersResponse.data.partners);
      setMaximumPage(Math.ceil(partnersResponse.data.totalPartners / limitPerPage));
    } catch (error: any) {
      toast.error(error?.response?.data?.message || 'Algo deu errado, tente novamente mais tarde');
    } finally {
      setShouldFetch(false);
    }
  };

  const fetchTypes = async () => {
    try {
      const typesResponse = await api.get('/type', {
        params: {
          from: 'partner',
        },
      });

      setTypes(typesResponse.data);
    } catch (error: any) {
      toast.error(error?.response?.data?.message || 'Algo deu errado, tente novamente mais tarde');
    }
  };

  const handleSortChange = (key: string) => {
    if (sortBy === key) {
      if (sortDir === 'asc') {
        setSortDir('desc');
      } else if (sortDir === 'desc') {
        setSortDir('');
      } else {
        setSortDir('asc');
      }
    } else {
      setSortBy(key as keyof IPartner);
      setSortDir('asc');
    }
  };

  const handleGoToPartnerSchedules = () => {
    history.push('/parceiros/agenda');
  };

  const handleAddPartner = () => {
    history.push('/parceiros/adicionar');
  };

  const handleEditPartner = (id: string) => {
    history.push(`/parceiros/editar/${id}`);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  const formattedPartners = partners.map((partner) => ({
    ...partner,
    id: partner.id_partner,
    name: partner.name,
    type_name: partner.type_name,
    patient_count: partner.patient_count || 0,
    appointmentCount: partner?.totalAppointments || 0,
    tip: partner?.totalTip ? formatCurrency(partner.totalTip) : formatCurrency(0),
    discount: partner?.totalDiscount
      ? formatCurrency(partner.totalDiscount) : formatCurrency(0),
    city: partner?.address?.city || '-',
  }));

  const sortedPartners = applySort(formattedPartners, sortBy, sortDir);

  const tableData = sortedPartners.map((partner) => ({
    ...partner,
    patient_count: <AssociatedPatientsButton type="button" onClick={() => setSelectedPartner(partner)}>{partner.patient_count}</AssociatedPatientsButton>,
    name:
  <EditCell
    content={partner.name}
    onEditClick={() => handleEditPartner(partner.id)}
  />,
  }));

  const partnerTypeOptions = [...types.map((type) => ({
    value: type.type,
    label: type.type,
  })), {
    value: '',
    label: 'Todos',
  }];

  const searchBoxExtraFilters = [
    {
      icon: <ArrowDownIcon />,
      name: 'Tipo',
      onChange: (value: string) => {
        setFilterType(value);
      },
      value: filterType,
      isDropdown: true,
      dropdownOptions: partnerTypeOptions,
    },
    {
      icon: <HomeIcon />,
      name: 'Cidade',
      onChange: (value: string) => {
        setCityFilter(value);
      },
      value: cityFilter || '',
      isDropdown: false,
    },
  ];

  return (
    <Container>
      <SearchBox
        placeholder="Buscar parceiros"
        onSubmit={() => {}}
        value={search}
        onChange={handleSearchChange}
        extraFilters={searchBoxExtraFilters}
      />
      <Table
        headers={partnerTableHeaders}
        data={tableData}
        onSort={handleSortChange}
        sortDir={sortDir}
        sortBy={sortBy}
        loadMoreRef={loadMoreRef}
        hasMore={page <= maximumPage}
      />
      <FloatingButtonsContainer>
        <ContainedIconButton type="button" onClick={handleGoToPartnerSchedules}>
          <CalendarIcon />
        </ContainedIconButton>
        <ContainedIconButton type="button" onClick={handleAddPartner}>
          <PlusIcon />
        </ContainedIconButton>
      </FloatingButtonsContainer>
      <PartnerPatientsModal
        isOpen={!!selectedPartner}
        onClose={() => setSelectedPartner(undefined)}
        patients={selectedPartner?.patients || []}
        partnerName={selectedPartner?.name || ''}
      />
    </Container>
  );
}
