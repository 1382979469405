import { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';

import { useAuth } from '../../hooks/useAuth';
import { useNotificationModal } from '../../hooks/useNotificationModal';

import { api } from '../../services/api';
import { IAppFormElement, IUser } from '../../types';
import { delayPromise } from '../../utils/delayPromise';
import { maskPhone } from '../../utils/maskPhone';

import {
  AdditionalInformationalForm,
  IAdditionalInformationFormData,
  initialAdditionalInformation,
} from '../User/AdditionalInformationForm';
import {
  AddressForm,
  IAddressFormData,
  initialAddress,
} from '../User/AddressForm';
import {
  PersonalDetailsForm,
  IPersonalDetailsFormData,
  initialPersonalDetails,
} from '../User/PersonalDetailsForm';

import { StyledButton, TextButton, TherapistProfileForm } from './styles';
import { initialPoint, IPointFormData, TherapistPointsForm } from './TherapistPointsForm';

interface IEditTherapistProfileFormProps {
  userData: IUser;
  onClickEditPassword?: () => void;
}

const initialTherapistFormData = {
  personalDetails: initialPersonalDetails,
  address: initialAddress,
  additionalInfo: initialAdditionalInformation,
  points: initialPoint,
};

export default function EditTherapistProfileForm({
  userData,
  onClickEditPassword = () => {},
}: IEditTherapistProfileFormProps) {
  const [therapistFormData, setTherapistFormData] = useState(
    initialTherapistFormData,
  );
  const [avatar, setAvatar] = useState<File | null>(null);
  const additionalInformationalFormRef = useRef<IAppFormElement>(null);
  const addressFormRef = useRef<IAppFormElement>(null);
  const personalDetailsFormRef = useRef<IAppFormElement>(null);
  const therapistPointsFormRef = useRef<IAppFormElement>(null);
  const { notificate } = useNotificationModal();
  const { setUserInfo } = useAuth();

  useEffect(() => {
    initializeForm();
  }, [userData]);

  const initializeForm = async () => {
    try {
      const response = await api.get('/me');

      const pointsResponse = await api.get('/therapist/point');

      const {
        name, email, phone, cpf, born, address, therapist, img_url,
      } = response.data;

      setTherapistFormData((prevState) => ({
        personalDetails: {
          ...prevState.personalDetails,
          name,
          email,
          phone: maskPhone(phone),
          cpf,
          avatar_url: undefined,
          img_url: img_url || '',
          video: userData.therapist?.video,
          born: new Date(born).toISOString().split('T')[0].replace('+', ''),
          languages: therapist?.languages || '',
          formation: therapist?.formation || '',
          aditionalCertificates: therapist?.aditional_certificates || '',
          created_at: new Date(userData.created_at).toISOString().split('T')[0],
        },
        address: {
          ...prevState.address,
          ...address,
        },
        additionalInfo: {
          ...prevState.additionalInfo,
          ...therapist,
          therapistServices: undefined,
        },
        points:
        pointsResponse.data && pointsResponse.data.length > 0
          ? pointsResponse.data
          : initialPoint,
      }));
    } catch (error: any) {
      toast.error(error?.response?.data?.message || 'Algo deu errado, tente novamente mais tarde!');
    }
  };

  const handleAvatarUpload = (file: File) => {
    setAvatar(file);
  };

  const updatePoint = (point: IPointFormData) => {
    const {
      id_address,
      city,
      district,
      number,
      radius,
      street,
      uf,
      zip,
      complement,
      lat,
      lng,
    } = point;

    const dataToSubmit = {
      id_address,
      city,
      district,
      number,
      radius,
      street,
      uf,
      zip,
      complement: complement || undefined,
    };

    return api.put('/therapist/point', dataToSubmit);
  };

  const createPoints = (points: IPointFormData[]) => {
    const dataToSubmit = points.map((point) => ({
      ...point,
      lat: undefined,
      lng: undefined,
      wasChanged: undefined,
    }));

    return api.post('/therapist/point', { addresses: dataToSubmit });
  };

  const submitTherapistPoints = async () => {
    if (!therapistPointsFormRef.current) {
      return;
    }

    const therapistPoints = therapistPointsFormRef.current.getFormData() as IPointFormData[];

    if (!therapistPoints) {
      return;
    }

    const pointsToUpdate = therapistPoints.filter((point) => {
      if (point.id_address && point.wasChanged) {
        return true;
      }
      return false;
    });

    const pointsToAdd = therapistPoints.filter((point) => {
      if (!point.id_address && point.wasChanged) {
        return true;
      }
      return false;
    });

    const promises: Promise<any>[] = [];

    if (pointsToUpdate.length > 0) {
      pointsToUpdate.forEach((point) => {
        promises.push(delayPromise(() => updatePoint(point), promises.length));
      });
    }

    if (pointsToAdd.length > 0) {
      promises.push(delayPromise(() => createPoints(pointsToAdd), promises.length));
    }

    try {
      await Promise.all(promises);
    } catch (error: any) {
      toast.error('Erro ao atualizar os pontos de atendimento, tente novamente mais tarde');
    }
  };

  const handleTherapistSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    const personalDetails = personalDetailsFormRef
      .current?.getFormData() as IPersonalDetailsFormData;
    const address = addressFormRef.current?.getFormData() as IAddressFormData;
    const additionalInfo = additionalInformationalFormRef
      .current?.getFormData() as IAdditionalInformationFormData;

    if (!personalDetails || !address || !additionalInfo) {
      return;
    }

    const {
      name,
      email,
      phone,
      languages,
      born,
      formation,
      aditionalCertificates,
    } = personalDetails;
    const {
      tip, presentation, cnpj, facebook, instagram, tiktok,
    } = additionalInfo;
    const {
      zip, street, number, complement, district, city, uf,
    } = address;

    const dataToSubmit = {
      userId: userData.id_user,
      name,
      email,
      phone,
      languages,
      born,
      formation,
      aditionalCertificates,
      presentation,
      cnpj,
      facebook,
      instagram,
      tiktok,
      address: {
        zip,
        street,
        number,
        complement,
        district,
        city,
        uf,
      },
    };

    const formDataToSubmit = new FormData();

    Object.entries(dataToSubmit).forEach(([key, value]) => {
      if (value != null) {
        formDataToSubmit.append(key, value.toString());
      }
    });

    formDataToSubmit.set('address', JSON.stringify(dataToSubmit.address));

    if (avatar) {
      formDataToSubmit.append('img', avatar);
    }

    try {
      const response = await api.put<IUser>('/therapist', formDataToSubmit, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      await submitTherapistPoints();

      setUserInfo(response.data);

      notificate('Salvo com sucesso');
    } catch (error: any) {
      toast.error(
        error?.response?.data?.message || error?.message || 'Algo deu errado',
      );
    }
  };

  return (
    <TherapistProfileForm onSubmit={handleTherapistSubmit}>
      <PersonalDetailsForm
        ref={personalDetailsFormRef}
        title="Dados pessoais"
        initialData={therapistFormData.personalDetails}
        onAvatarUpload={handleAvatarUpload}
        onSubmit={() => {}}
        shouldHidePasswordFields
        isEditingSelf
      />
      <TextButton type="button" onClick={onClickEditPassword}>
        Redefinir senha
      </TextButton>
      <AddressForm
        ref={addressFormRef}
        title="Meu endereço"
        initialData={therapistFormData.address}
        onSubmit={() => {}}
      />
      <TherapistPointsForm
        ref={therapistPointsFormRef}
        title="Pontos de atendimento"
        initialData={therapistFormData.points}
        onSubmit={() => {}}
      />
      <AdditionalInformationalForm
        ref={additionalInformationalFormRef}
        title="Dados adicionais"
        initialData={therapistFormData.additionalInfo}
        onSubmit={() => {}}
        shouldHideLinkButtons
        isEditingSelf
      />
      <StyledButton type="submit">Salvar alterações</StyledButton>
    </TherapistProfileForm>
  );
}
