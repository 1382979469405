import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  width: 70%;
  height: calc(100vh - 11rem);
  margin: 0 auto;

  > * {
    margin-top: 2rem;
  }

  table {
    thead tr th:first-child {
      text-align:left;
      padding-left: 3rem;
    }

    thead tr th:last-child {
      width: 20%;
    }
  }
`;

export const PermissionsButton = styled.button`
  background: transparent;
  border: none;


  color: var(--blue-400);

  font-size: 1.125rem;

  transition: filter 0.2s;

  &:hover {
    filter: brightness(0.8);
  }
`;
