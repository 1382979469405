import { IAnamnesisAlternative } from '../../../types';

import { Input } from '../../Input';

import {
  AnswerContainer,
  AnswerTypeButton, AnswerTypeContainer, Container, TextAreaContainer,
} from './styles';

export interface IAnamnesisFormData {
  name: string;
  question: string;
  comments?: string;
  type_name: 'essay' | 'multiple';
  alternatives: IAnamnesisAlternative[];
}

export const initialAnamnesisData: IAnamnesisFormData = {
  name: '',
  question: '',
  type_name: 'multiple',
  comments: '',
  alternatives: [
    {
      answer: '',
      weigth: 1,
      index: 0,
      id_alternative: Math.random().toString(),
    },
    {
      answer: '',
      weigth: 1,
      index: 1,
      id_alternative: Math.random().toString(),
    },
    {
      answer: '',
      weigth: 1,
      index: 2,
      id_alternative: Math.random().toString(),
    },
    {
      answer: '',
      weigth: 1,
      index: 3,
      id_alternative: Math.random().toString(),
    },
  ],
};

interface IAnamnesisFormProps {
  data: IAnamnesisFormData;
  onAnswerChange: (field: 'answer' | 'weigth', index: number, value: string) => void;
  onInputChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onTypeChange: (type: IAnamnesisFormData['type_name']) => void;
}

const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');

export default function AnamnesisForm({
  data, onAnswerChange, onInputChange, onTypeChange,
}: IAnamnesisFormProps) {
  return (
    <Container>
      <Input
        label="Nome da pergunta:"
        name="name"
        value={data.name}
        onChange={onInputChange}
        placeholder="Insira o nome da pergunta"
      />
      <TextAreaContainer>
        <label htmlFor="question-textarea">
          Pergunta
        </label>
        <textarea
          id="question-textarea"
          name="question"
          value={data.question}
          onChange={onInputChange}
          placeholder="Insira a pergunta"
        />
      </TextAreaContainer>
      <h3>Selecione o tipo de resposta</h3>
      <AnswerTypeContainer>
        <AnswerTypeButton type="button" isActive={data.type_name === 'multiple'} onClick={() => onTypeChange('multiple')}>
          Múltipla escolha
        </AnswerTypeButton>
        <AnswerTypeButton type="button" isActive={data.type_name === 'essay'} onClick={() => onTypeChange('essay')}>
          Livre
        </AnswerTypeButton>
      </AnswerTypeContainer>

      {data.type_name === 'multiple' && (
        <>
          {data.alternatives.map((alternative, index) => (
            <AnswerContainer key={`answer-${alternative.id_alternative}`}>
              <TextAreaContainer>
                <label htmlFor={`answer-${alternative.id_alternative}`}>
                  Alternativa
                  {' '}
                  {alphabet[alternative.index]}
                </label>
                <textarea
                  id={`answer-${alternative.id_alternative}`}
                  value={alternative.answer}
                  onChange={(event) => onAnswerChange('answer', index, event.target.value)}
                  placeholder="Insira a resposta da alternativa"
                />
              </TextAreaContainer>
              <Input
                id={`peso-${alternative.id_alternative}`}
                type="number"
                label="Peso"
                className="anamnesis-form__weight-input-container"
                value={alternative.weigth}
                onChange={(event) => onAnswerChange('weigth', index, event.target.value)}
                placeholder="Insira o peso da alternativa"
                min="0"
              />
            </AnswerContainer>
          ))}
          <TextAreaContainer>
            <label htmlFor="question-textarea">
              Observações
            </label>
            <textarea
              id="question-textarea"
              name="comments"
              value={data.comments}
              onChange={onInputChange}
              placeholder="Insira observações se tiver"
            />
          </TextAreaContainer>
        </>
      )}
    </Container>
  );
}
