import { useRef, useState } from 'react';
import ReactDOM from 'react-dom';

import { useOutside } from '../../hooks/useOutside';
import { Container, Content } from './styles';

export interface IModalProps {
  isOpen: boolean;
  onClose: () => void;
  children?: React.ReactNode;
}

export default function Modal({ isOpen, onClose, children }: IModalProps) {
  const [isClosing, setIsClosing] = useState(false);
  const modalRef = useRef(null);
  const modalRoot = document.getElementById('modal-root');

  const handleClose = () => {
    setIsClosing(true);

    setTimeout(() => {
      onClose();
      setIsClosing(false);
    }, 300);
  };

  useOutside(modalRef, handleClose);

  if (!modalRoot || !isOpen) {
    return null;
  }

  return ReactDOM.createPortal(
    <Container isClosing={isClosing}>
      <Content ref={modalRef} isClosing={isClosing}>
        {children}
      </Content>
    </Container>,
    modalRoot,
  );
}
