import { IAddressFormData } from '../User/AddressForm';

const formDataFieldToAddressComponentTypes = {
  number: 'street_number',
  street: 'route',
  zip: 'postal_code',
  city: 'administrative_area_level_2',
  uf: 'administrative_area_level_1',
  district: 'sublocality_level_1',
} as {
  [key in keyof IAddressFormData]: string;
};

export const mapAddressComponentsToFormData = (
  addressComponents: google.maps.GeocoderAddressComponent[],
): IAddressFormData => {
  const addressFormData = {
    number: '',
    street: '',
    zip: '',
    city: '',
    uf: '',
    district: '',
  } as IAddressFormData;

  Object
    .entries(formDataFieldToAddressComponentTypes)
    .forEach(([addressFormDataField, typeName]) => {
      const addressComponent = addressComponents.find(
        (component) => component.types.includes(typeName),
      );

      const castedField = addressFormDataField as keyof IAddressFormData;

      if (addressComponent) {
        if (castedField === 'uf') {
          addressFormData.uf = addressComponent.short_name;
        // TODO: Fix TypeScript
        } else if (castedField === 'lat' || castedField === 'lng') {
          addressFormData[castedField] = Number(addressComponent.long_name);
        } else {
          addressFormData[castedField] = addressComponent.long_name;
        }
      }
    });

  return addressFormData;
};
