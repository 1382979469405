import styled from 'styled-components';
import { Button } from '../Button';
import { IModalProps, Modal } from '../Modal';

interface IConfirmModalProps extends IModalProps {
  onConfirm: () => void;
  onCancel: () => void;
  message: string;
  confirmText?: string;
  cancelText?: string;
}

export default function ConfirmModal({
  isOpen,
  onClose,
  onConfirm,
  onCancel,
  message,
  confirmText = 'Sim',
  cancelText = 'Não',
}: IConfirmModalProps) {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Container>
        <Message>{message}</Message>
        <ButtonsContainer>
          <StyledButton type="button" variant="outlined" onClick={onConfirm}>
            {confirmText}
          </StyledButton>
          <StyledButton type="button" onClick={onCancel}>
            {cancelText}
          </StyledButton>
        </ButtonsContainer>
      </Container>
    </Modal>
  );
}

const Container = styled.div`
  background-color: var(--white);
  border-radius: 2rem;

  max-width: 35rem;
  width: 100vw;

  padding-bottom: 1.5rem;

  box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.16);

  text-align: center;

  border-radius: 2rem;
  padding: 2rem;
`;

const Message = styled.h1`
  font-weight: 700;
  font-size: 1.375rem;
  color: var(--blue-400);
`;

const ButtonsContainer = styled.div`
  display: flex;

  gap: 2rem;

  width: 100%;

  margin-top: 2rem;
`;

const StyledButton = styled(Button)`
  font-size: 1.125rem;
  width: 100%;
  padding: 0.5rem;
`;
