import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 2rem;

  padding-bottom: 3rem;

  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

export const StyledHeader = styled.header`
  display: flex;
  align-items: center;
  gap: 1rem;

  width: 95%;

  .patient-details__date-dropdown {
    width: 20rem;
  }
`;

export const LocationsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;

  background-color: var(--purple-50);
  border-radius: 0.5rem;
  min-height: 3rem;

  padding: 0 1rem;

  flex: 1;
`;

export const GeneralDetailsItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;


`;

export const TextButton = styled.button`
  background: transparent;
  border: none;
  font-size: inherit;
`;
