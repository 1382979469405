import styled, { css } from 'styled-components';
import { VictoryAxis, VictoryBar, VictoryChart } from 'victory';

import { ReactComponent as TriangleIcon } from '../../assets/images/triangle.svg';
import { datePeriodOptions } from '../../resources/DatePeriodOptions';
import { IMonthlyData } from '../../types';
import {
  DatePeriodDropdown,
  DatePeriodDropdownOption,
} from '../DatePeriodDropdown';

const months = [
  'Jan',
  'Fev',
  'Mar',
  'Abr',
  'Mai',
  'Jun',
  'Jul',
  'Ago',
  'Set',
  'Out',
  'Nov',
  'Dez',
];

interface IDashboardChartProps {
  selectedTimePeriod?: DatePeriodDropdownOption;
  onPeriodChange: (period: DatePeriodDropdownOption) => void;
  change: number;
  data: IMonthlyData[];
  title?: string;
  containerClassName?: string;
  yFormatFn?: (value: number) => string;
}

const formatCurrencyInCents = (cents: number) => new Intl.NumberFormat('pt-BR', {
  style: 'currency',
  currency: 'BRL',
  minimumFractionDigits: 2,
}).format(cents / 100);

export function DashboardChart({
  selectedTimePeriod,
  onPeriodChange,
  change,
  data = [],
  title = '',
  containerClassName = '',
  yFormatFn = formatCurrencyInCents,
}: IDashboardChartProps) {
  const formattedData = data.map((item) => ({
    month: months[item.month],
    total: item.total,
  }));

  const defaultGraphData = [
    {
      total: 0,
      month: months[new Date().getMonth()],
    },
  ];

  return (
    <GraphContainer className={containerClassName}>
      <StyledHeader>
        <TotalContainer>
          {title && <Title>{title}</Title>}
          <Total>
            {yFormatFn(change)}
            <StyledTriangle positive={change >= 0} />
          </Total>
        </TotalContainer>
        <DatePeriodDropdown
          onChange={onPeriodChange}
          options={datePeriodOptions}
          option={selectedTimePeriod}
          placeholder="Selecione..."
          showSpecificInterval
          customClassName="graph__date-dropdown"
        />
      </StyledHeader>
      <VictoryChart
        domainPadding={80}
        width={800}
        padding={{
          left: 70,
          bottom: 20,
        }}
      >
        <VictoryAxis
          tickFormat={yFormatFn}
          style={{
            grid: {
              stroke: '#ddd',
              strokeWidth: 2,
            },
            tickLabels: {
              fontFamily: 'SF Pro Text, sans-serif',
              fill: 'var(--gray-500)',
              fontSize: '12px',
            },
            axis: {
              display: 'none',
            },
          }}
          dependentAxis
        />
        <VictoryAxis
          style={{
            tickLabels: {
              fontFamily: 'SF Pro Text, sans-serif',
              fill: 'var(--gray-500)',
              fontSize: '15px',
              textTransform: 'uppercase',
            },
            axis: {
              stroke: '#ddd',
              strokeWidth: 2,
            },
          }}
        />
        <VictoryBar
          data={formattedData.length > 0 ? formattedData : defaultGraphData}
          x="month"
          y="total"
          cornerRadius={4}
          barRatio={0.9}
          style={{
            data: {
              fill: 'var(--blue-400)',
            },
          }}
        />
      </VictoryChart>
    </GraphContainer>
  );
}

export const GraphContainer = styled.div`
  width: 95%;
  height: auto;

  .graph__date-dropdown {
    width: 27ch;
  }

  @media (max-width: 600px) {
    .graph__date-dropdown {
    width: 20ch;
  }
  }
`;

export const StyledHeader = styled.header`
  display: flex;
  justify-content: space-between;
`;

export const TotalContainer = styled.div`
  display: flex;
  flex-direction: column;

  padding-left: 1.5rem;

  gap: 0.5rem;
`;

export const Title = styled.p`
  font-size: 1.25rem;
  font-weight: 500;
  color: var(--blue-400);
`;

export const Total = styled.p`
  font-size: 1.25rem;
`;

export const StyledTriangle = styled(TriangleIcon)<{
  positive: boolean;
}>`
  margin-left: 0.5rem;

  ${({ positive }) => !positive
    && css`
      transform: rotate(180deg);
      path {
        fill: var(--red-300);
      }
    `}
`;
