import styled, { css } from 'styled-components';

export const Container = styled.div`
  margin: 2rem auto;

  padding: 0 2rem;
  padding-bottom: 4rem;

  display: flex;
  flex-direction: column;
  gap: 2rem;

  h3 {
    text-align: center;
    font-size: 1.25rem;
    font-weight: 500;

    margin-top: 2rem;
  }

  input {
    font-size: 1rem;
  }
`;

export const TextAreaContainer = styled.div`
  width: 100%;

  label {
    display: block;
    margin-bottom: 0.375rem;
    padding-left: 0.75em;
  }

  textarea {
    font-family: 'SF Pro Text', 'Segoe UI', sans-serif;

    color: var(--gray-500);

    background: transparent;
    border: none;
    resize: none;

    font-size: 1rem;

    width: 100%;
    border-radius: 0.5em;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    height: 8rem;

    padding: 1em;

    &::placeholder {
      filter: opacity(0.75);
    }

    &:focus {
      outline: 2px solid var(--gray-500);
    }

    scrollbar-width: thin;
    scrollbar-color: var(--blue-400) #E5E5EA;

    &::-webkit-scrollbar {
      width: 0.5rem;
      height: 0.5rem;
    }

    &::-webkit-scrollbar-track {
      background-color: #E5E5EA;
      border-radius: 1rem;
      width: 1rem;
      height: 100%;
      margin: 1rem;
    }

    &::-webkit-scrollbar-thumb {
      width: 0.25rem;
      border-radius: 1rem;
      background-color: var(--blue-400);
    }
  }

  @media (max-width: 600px) {
    grid-column: unset;
    grid-row: unset;
  }
`;

export const AnswerTypeContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  flex-wrap: wrap;
`;

export const AnswerTypeButton = styled.button<{
  isActive: boolean;
}>`
  border: none;

  background-color: var(--white);
  color: var(--grey-500);

  width: 100%;
  min-height: 3.5rem;
  max-width: 16.25rem;

  padding: 1rem;

  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);

  border-radius: 0.5rem;

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 1.25rem;

  transition: all 0.2s;

  &:hover {
    background-color: var(--blue-400);
    color: var(--white);
  }

  ${({ isActive }) => (isActive && css`
    background-color: var(--blue-400);
    color: var(--white);
  `)}
`;

export const AnswerContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  .anamnesis-form__weight-input-container {
    width: 14rem;
    align-self: flex-end;
    margin-left: 3.5rem;
  }
`;
