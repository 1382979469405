import { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { ReactComponent as SettingIcon } from '../../../assets/images/setting.svg';
import { ReactComponent as ProfileIcon } from '../../../assets/images/navbar-icons/profile.svg';
import { Button } from '../../Button';

import { Card, CardsContainer, Container } from './styles';

export default function AddUser() {
  const [selectedType, setSelectedType] = useState<'admin' | 'provider' | ''>('');
  const history = useHistory();

  const handleChangeSelectedType = (type: 'admin' | 'provider') => {
    if (type === selectedType) {
      setSelectedType('');
    } else {
      setSelectedType(type);
    }
  };

  const handleButtonClick = () => {
    history.push(`/usuarios/adicionar/${selectedType === 'admin' ? 'administrador' : 'prestador'}`);
  };

  return (
    <Container>
      <h1>Selecione o tipo de usuário</h1>
      <CardsContainer>
        <Card as="button" onClick={() => handleChangeSelectedType('admin')} isActive={selectedType === 'admin'}>
          <SettingIcon />
          <h2>Administrador</h2>
        </Card>
        <Card as="button" onClick={() => handleChangeSelectedType('provider')} isActive={selectedType === 'provider'}>
          <ProfileIcon />
          <h2>Prestador</h2>
        </Card>
      </CardsContainer>
      <Button type="button" disabled={selectedType === ''} onClick={handleButtonClick}>
        Próximo
      </Button>
    </Container>
  );
}
