import styled from 'styled-components';

import { ReactComponent as PlusIcon } from '../../assets/images/plus.svg';

interface IFloatingAddButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  styledZIndex?: number;
}

export default function FloatingAddButton({
  styledZIndex = 999,
  ...rest
}: IFloatingAddButtonProps) {
  return (
    <StyledButton z={styledZIndex} {...rest}>
      <PlusIcon />
    </StyledButton>
  );
}

const StyledButton = styled.button<{
  z: number;
}>`
  background-color: var(--blue-400);
  border: none;

  padding: 1rem;

  width: fit-content;
  height: fit-content;

  border-radius: 3.5rem;
  position: fixed;
  bottom: 4rem;
  right: 3%;
  z-index: ${({ z }) => z};

  svg {
    width: 1.75rem;
    height: 1.75rem;
    vertical-align: middle;
  }

  transition: filter 0.2s;

  &:hover {
    filter: brightness(0.9);
  }
`;
