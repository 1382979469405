import styled, { css } from 'styled-components';

const timeSlotHeightRem = 3;

export const Container = styled.div`
  width: 100%;

  display: grid;

  grid-template-columns: auto 1fr;

  max-height: ${timeSlotHeightRem * 11}rem;

  overflow: auto;

  padding-right: 4rem;
  margin-top: 3rem;
  padding-top: 0.5rem;

  scrollbar-width: thin;
  scrollbar-color: var(--blue-400) #E5E5EA;

  &::-webkit-scrollbar {
    width: 0.5rem;
    height: 0.5rem;
  }

  &::-webkit-scrollbar-track {
    background-color: #E5E5EA;
    border-radius: 1rem;
    width: 1rem;
    height: 100%;
    margin: 1rem;
  }

  &::-webkit-scrollbar-thumb {
    width: 0.25rem;
    border-radius: 1rem;
    background-color: var(--blue-400);
  }
`;

export const TimeSlotsContainer = styled.div`
  display: grid;

  margin-right: 0.5rem;

  > div {
    position: relative;
    top: -0.5rem;
    height: ${timeSlotHeightRem}rem;
  }
`;

const available = css`
  background-color: var(--blue-50);
  border-color: var(--blue-300);
  width: 33%;
  z-index: var(--available-z);
`;

const regularAppointment = css`
  background-color: var(--yellow-50);
  border-color: var(--yellow-500);
  width: 100%;
  z-index: var(--appointment-z);
`;

const partners = css`
  background-color: var(--red-50);
  border-color: var(--red-300);
  width: 66%;
  z-index: var(--partner-z);
`;

const partnerAppointment = css`
  background-color: var(--green-50);
  border-color: var(--green-300);
  width: 100%;
  z-index: var(--appointment-z);
`;

const pool = css`
  background-color: var(--purple-50);
  border-color: var(--purple-700);
  width: 33%;
  min-width: fit-content;
  z-index: var(--pool-z);
`;

export const SchedulesContainer = styled.div`
  --available-z: 1;
  --partner-z: 2;
  --pool-z: 3;
  --appointment-z: 4;
  --interval-z: 5;

  display: grid;

  border-top: 1px solid #70707010;

  background-color: #70707050;

  grid-row-gap: 1px;

  .container {
    position: relative;
    background-color: white;
    height: ${timeSlotHeightRem}rem;

    display: flex;
    justify-content: flex-end;
  }

`;

export const Schedule = styled.div<{
  span: number;
}>`
  position: absolute;
  top: 0;
  right: 0;

  height: ${({ span }) => span * (timeSlotHeightRem + 0.0625)}rem;

  border-left: 10px solid;
  border-radius: 0.5rem 0 0 0.5rem;

  > p {
    padding: 1rem;
  }

  &.available {
    ${available};
  }

  &.partners {
    ${partners};
  }

  &.pool {
    ${pool};
  }

`;

export const Appointment = styled.div<{
  span: number;
  topOffset?: number;
}>`
  position: relative;

  ${({ topOffset }) => topOffset && css`
    top: ${timeSlotHeightRem * topOffset}rem;
  `};

  height: ${({ span }) => span * timeSlotHeightRem}rem;

  padding: 1rem;

  display: flex;
  align-items: flex-end;

  border-radius: 0.5rem 0 0 0.5rem;
  border-left: 10px solid;

  &.partner-appointment {
    ${regularAppointment};
  }

  &.regular-appointment {
    ${regularAppointment};
  }

  &.pool-appointment {
    ${regularAppointment};
  }

  &.transparent {
    background-color: white;
    width: 100%;
    z-index: var(--interval-z);
    border-left: none;
  }

  &.reduce-opacity {
    filter: opacity(0.4);
    z-index: var(--interval-z);
    background: white;
    padding: 0;
    border: none;
    width: 100%;
  }
`;
