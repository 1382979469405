import axios from 'axios';
import { IAddress } from '../types';

export const addressApi = axios.create({
  baseURL: 'https://viacep.com.br',
});

interface IAddressAPIResponse {
  cep: string;
  logradouro: string;
  bairro: string;
  complemento: string;
  localidade: string;
  ibge: string;
  uf: string;
  erro?: boolean;
}

function mapResponseToAddress(addressResponse: IAddressAPIResponse): Partial<IAddress> {
  return {
    zip: addressResponse?.cep,
    street: addressResponse?.logradouro,
    district: addressResponse?.bairro || '',
    complement: addressResponse?.complemento || '',
    city: addressResponse?.localidade || '',
    uf: addressResponse?.uf || '',
  };
}

export async function getAddressInformation(cep: string): Promise<Partial<IAddress>> {
  const response = await addressApi.get<IAddressAPIResponse>(`/ws/${cep.replace(/-/g, '')}/json`);

  if (response?.data?.erro) {
    throw new Error('CEP não encontrado!');
  }

  return mapResponseToAddress(response.data);
}
