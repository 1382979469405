import styled from 'styled-components';

export const DailyDashboardVariant = styled.div`
  margin-top: 2rem;

  width: 100%;
  padding: 2rem;
  border-radius: 0.5rem;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);

  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

export const VariantRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const DailyVariantCard = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const DailyCardTitle = styled.p`
  font-size: 1.25rem;
  font-weight: 600;
  color: var(--blue-400);
`;

export const DailyCardValue = styled.p`
  font-size: 1.125rem;
  font-weight: 500;
`;
