import {
  ForwardedRef, forwardRef, useEffect, useImperativeHandle, useState,
} from 'react';
import { toast } from 'react-toastify';

import { states } from '../../resources/States';
import { getAddressInformation } from '../../services/addressApi';
import { IAppFormElement } from '../../types';
import { maskCep } from '../../utils/maskCep';

import { Dropdown } from '../Dropdown';
import { Input } from '../Input';
import { FormContainer } from './styles';

export const stateOptions = Object.entries(states).map(([key, value]) => ({
  value: key,
  label: value,
}));

export interface IAddressFormData {
  zip: string;
  street: string;
  number: string;
  district: string;
  city: string;
  uf: string;
  complement?: string;

  lat?: number | null;
  lng?: number | null;
}

export const initialAddress: IAddressFormData = {
  zip: '',
  street: '',
  number: '',
  district: '',
  city: '',
  uf: '',
  complement: '',
};

interface IAddressFormProps {
  initialData: IAddressFormData,
  onSubmit: (data: IAddressFormData) => void;
  title?: string;
}

export const AddressForm = forwardRef(({
  initialData, onSubmit, title,
}: IAddressFormProps, ref: ForwardedRef<IAppFormElement>) => {
  const [formData, setFormData] = useState(initialData);
  const [isAddressValid, setIsAddressValid] = useState(false);
  const [disabledFields, setDisabledFields] = useState<string[]>([]);
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = event.target;

    let newValue = value;

    if (name === 'zip') {
      if (isAddressValid) {
        setIsAddressValid(false);
      }
      newValue = maskCep(value);
    }

    setFormData((prevState) => ({
      ...prevState,
      [name]: newValue,
    }));
  };

  useImperativeHandle(ref, () => ({
    requestSubmit: () => {
      if (!isAddressValid) {
        toast.error('CEP inválido');
        return;
      }

      onSubmit({
        ...formData,
        complement: formData.complement || undefined,
        zip: formData.zip.replace(/\D/g, ''),
      });
    },
    getFormData: () => formData,
  }));

  useEffect(() => {
    if (initialData.zip) {
      setIsAddressValid(true);
    }
    setFormData(initialData);
  }, [initialData]);

  const handleUfChange = (uf: string) => {
    setFormData((prevState) => ({
      ...prevState,
      uf,
    }));
  };

  const handleZipBlur = async () => {
    if (formData.zip.length === 9) {
      try {
        const data = await getAddressInformation(formData.zip);
        setFormData((prevState) => ({
          ...prevState,
          ...data,
        }));

        const filledAddressEntries = Object.entries(data).filter(
          ([key, value]) => !!value,
        ).map(([key]) => key);

        setDisabledFields(filledAddressEntries);

        setIsAddressValid(true);
      } catch (error: any) {
        toast.error(error?.response?.data?.message || 'CEP inválido');
        setIsAddressValid(false);
      }
    }
  };

  return (
    <FormContainer>
      {title && <h1>{title}</h1>}
      <Input
        name="zip"
        label="CEP:"
        placeholder="Insira o CEP"
        onChange={handleInputChange}
        value={formData.zip || ''}
        maxLength={9}
        onBlur={handleZipBlur}
        required
      />
      <Input
        name="street"
        label="Endereço:"
        placeholder="Insira o endereço"
        onChange={handleInputChange}
        value={formData.street || ''}
        className="grid-column-1"
        disabled={disabledFields.includes('street')}
        required
      />
      <Input
        name="district"
        label="Bairro:"
        placeholder="Insira o bairro"
        onChange={handleInputChange}
        value={formData.district || ''}
        disabled={disabledFields.includes('district')}
        required
      />
      <Input
        name="number"
        label="Número:"
        placeholder="Insira o número"
        onChange={handleInputChange}
        value={formData.number || ''}
        required
      />
      <Input
        name="complement"
        label="Complemento:"
        placeholder="Insira o complemento"
        onChange={handleInputChange}
        value={formData.complement || ''}
      />
      <Input
        name="city"
        label="Cidade:"
        placeholder="Insira a cidade"
        onChange={handleInputChange}
        disabled={disabledFields.includes('city')}
        value={formData.city || ''}
        required
      />
      <Dropdown
        onChange={handleUfChange}
        options={stateOptions}
        disabled={disabledFields.includes('uf')}
        label="Estado:"
        value={formData.uf || ''}
        placeholder="Selecione o estado"
      />
    </FormContainer>
  );
});
