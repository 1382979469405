import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { api } from '../../services/api';
import { IRatingDashboard } from '../../types/Dashboard';
import { formatCurrencyInCents } from '../../utils/formatCurrencyInCents';
import { Table, TableWrapper } from './styles';

interface RatingDashboardProps {
  params: Record<string, string | string[] | number | (string | number)[] | undefined>
}

const RatingDashboard = ({ params }: RatingDashboardProps) => {
  const [ratingDashboard, setRatingDashboard] = useState<IRatingDashboard>();

  const getRatingDashboard = async () => {
    try {
      const { data } = await api.get<IRatingDashboard>('/dashboard/rating', {
        params,
      });

      setRatingDashboard(data);
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    getRatingDashboard();
  }, [params]);

  return (
    <TableWrapper>
      <Table>
        <thead>
          <tr>
            <th>Nome</th>
            <th>Avaliação</th>
            <th>Atendimentos</th>
            <th>Total</th>
          </tr>
        </thead>
        <tbody>
          {ratingDashboard?.results?.map((ratingResult) => (
            <tr key={ratingResult.name}>
              <td>{ratingResult.name}</td>
              <td>{(ratingResult.rating).toLocaleString()}</td>
              <td>{ratingResult.appointment_count}</td>
              <td>{formatCurrencyInCents(ratingResult.total)}</td>
            </tr>
          ))}
          <tr>
            <td>TOTAIS</td>
            <td>
              Média (
              {ratingDashboard?.summary?.average_rating?.toLocaleString()}
              )
            </td>
            <td>
              {ratingDashboard?.summary?.total_apppointments || 0}
            </td>
            <td>
              {formatCurrencyInCents(
                ratingDashboard?.summary?.total || 0,
              )}
            </td>
          </tr>
        </tbody>
      </Table>
    </TableWrapper>
  );
};

export default RatingDashboard;
