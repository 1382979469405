import { useRouteMatch } from 'react-router-dom';
import { AddServiceForm } from '../components/Services/AddServiceForm';
import { EditServiceForm } from '../components/Services/EditServiceForm';

import { Services } from '../pages/Services';
import { AdminRoute } from './AdminRoute';

export function ServicesRouter() {
  const { path } = useRouteMatch();
  return (
    <>
      <AdminRoute path={`${path}`} exact component={Services} />
      <AdminRoute path={`${path}/editar/:serviceId`} component={EditServiceForm} />
      <AdminRoute path={`${path}/adicionar`} exact component={AddServiceForm} />
    </>
  );
}
