import ReactSelect, { AsyncProps as ReactSelectProps } from 'react-select/async';
import { ErrorMessage, SelectContainer, SelectLabel } from './styles';

interface Option {
  value: string | number;
  label: string;
}

interface SelectProps extends ReactSelectProps<Option, true, {
  label: string;
  options: Option[];
}> {
  label?: string;
  error?: string;
}

function MultiSelect({
  label,
  error,
  id,
  name,
  options,
  value,
  placeholder,
  onChange,
  ...rest
}: SelectProps) {
  return (
    <SelectContainer>
      {label && <SelectLabel htmlFor={id}>{label}</SelectLabel>}
      <ReactSelect
        id={id}
        name={name}
        options={options}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        isClearable
        isMulti
        loadingMessage={() => 'Carregando...'}
        noOptionsMessage={() => 'Nenhum resultado encontrado'}
        styles={{
          control: (prev, { isFocused }) => ({
            ...prev,
            backgroundColor: '#fff',
            borderRadius: '0.5rem',
            minHeight: '2.6875rem',
            borderWidth: '1px',
            borderColor: '#fff',
            boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.16)',
            padding: '0.5rem 1rem',
            fontSize: '1rem',
            fontFamily: 'SF Pro Text',
            color: 'var(--gray-500)',
            ':hover': {
              border: 'none',
              boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.16)',
            },
            border: 'none',

          }),
          clearIndicator: (prev) => ({
            ...prev,
            padding: 0,
          }),
          indicatorSeparator: (prev) => ({
            ...prev,
            display: 'none',
          }),
          dropdownIndicator: (prev) => ({
            ...prev,
            padding: '0',
            svg: {
              color: 'var(--gray-500)',
            },
          }),
          placeholder: (prev) => ({
            ...prev,
            color: 'var(--gray-500)',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }),
          menuList: (prev) => ({
            ...prev,
            overflowX: 'hidden',
          }),
          menu: (prev) => ({
            ...prev,
            margin: '0',
            top: '85%',
            paddingTop: '0.5rem',
            borderRadius: '0',
            borderBottomLeftRadius: '0.5rem',
            borderBottomRightRadius: '0.5rem',
            border: 'none',
            boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.16)',
            zIndex: 99,
          }),
          option: (prev, { isSelected }) => ({
            ...prev,
            backgroundColor: isSelected ? 'transparent' : 'transparent',
            color: 'var(--gray-500)',
          }),
          singleValue: (prev) => ({
            ...prev,
            color: 'var(--gray-500)',
          }),
          noOptionsMessage: (prev) => ({
            ...prev,
            fontSize: '0.875rem',
          }),
          valueContainer: (prev) => ({
            ...prev,
            padding: '0',
            whiteSpace: 'nowrap',
            overflow: 'ellipsis',
          }),
          input: (prev) => ({
            ...prev,
            color: 'var(--gray-500)',
          }),
          container: (prev) => ({
            ...prev,

          }),
        }}
        {...rest}
      />
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </SelectContainer>
  );
}

export default MultiSelect;
