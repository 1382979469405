import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import { useNotificationModal } from '../../../hooks/useNotificationModal';
import { api } from '../../../services/api';

import { CreationForm } from '../../CreationForm';
import { AnamnesisForm, IAnamnesisFormData, initialAnamnesisData } from '../AnamnesisForm';

export default function AddAnamnesisForm() {
  const history = useHistory();
  const [addAnamnesisFormData, setAddAnamnesisFormData] = useState(
    initialAnamnesisData,
  );
  const { notificate } = useNotificationModal();

  const handleAnswerChange = (field: 'answer' | 'weigth', index: number, value: string) => {
    setAddAnamnesisFormData((prevState) => ({
      ...prevState,
      alternatives: prevState.alternatives.map((answer, answerIndex) => {
        if (answerIndex === index) {
          return {
            ...answer,
            [field]: value,
          };
        }

        return answer;
      }),
    }));
  };

  const handleGoBack = () => {
    history.goBack();
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = event.target;
    setAddAnamnesisFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSuccess = () => {
    setAddAnamnesisFormData(initialAnamnesisData);
    history.push('/anamneses');
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    try {
      const {
        name, question, type_name: type, comments, alternatives,
      } = addAnamnesisFormData;
      const dataToSubmit = {
        name,
        question,
        type,
        comments: comments || undefined,
        alternatives: type === 'multiple' ? alternatives.map(
          (alternative) => ({
            ...alternative, weigth: Number(alternative.weigth), id_alternative: undefined,
          }),
        ) : undefined,
      };

      await api.post('/anamnese', dataToSubmit);

      notificate('Criado com sucesso!', handleSuccess);
    } catch (error: any) {
      toast.error(error?.response?.data?.message || error?.message || 'Algo deu errado');
    }
  };

  const handleTypeChange = (type: IAnamnesisFormData['type_name']) => {
    setAddAnamnesisFormData((prevState) => ({
      ...prevState,
      type_name: type,
    }));
  };

  return (
    <CreationForm
      onArrowClick={handleGoBack}
      onSubmit={handleSubmit}
      title="Dados da pergunta"
      buttonText="Criar"
    >
      <AnamnesisForm
        data={addAnamnesisFormData}
        onAnswerChange={handleAnswerChange}
        onInputChange={handleInputChange}
        onTypeChange={handleTypeChange}
      />
    </CreationForm>
  );
}
