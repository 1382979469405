import styled from 'styled-components';

interface IInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
}

export default function Input({
  label, id, className, name, ...rest
}: IInputProps) {
  return (
    <Container className={className}>
      {label && <label htmlFor={id || name}>{label}</label>}
      <input id={id || name} name={name} {...rest} />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  label {
    padding-left: 0.75em;
    margin-bottom: 0.375rem;
  }

  input {
    background: transparent;
    border: none;

    width: 100%;
    height: 3em;
    border-radius: 0.5em;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);

    padding: 1em;

    &::placeholder {
      filter: opacity(0.75);
    }

    &:focus {
      outline: 2px solid var(--gray-500);
    }
  }
`;
