import { format } from 'date-fns';
import { Dispatch, useState } from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import { ReactComponent as DeleteIcon } from '../../assets/images/delete.svg';
import { SchedulesReducerActions } from '../../pages/Agenda/schedulesReducer';

import { api } from '../../services/api';

import { ISchedule } from '../../types';

import { ConfirmModal } from '../ConfirmModal';

interface IAgendaTableProps {
  schedules: ISchedule[];
  onClickEdit: (scheduleId: string) => void;
  scheduleDispatch: Dispatch<SchedulesReducerActions>
}

const ptScheduleTypeNames = {
  partner: 'Parceiro',
  pool: 'Pool',
  portfolio: 'Pessoal',
} as {
  [key: string]: string;
};

export function AgendaTable({
  schedules,
  onClickEdit,
  scheduleDispatch,
}: IAgendaTableProps) {
  const [handleConfirmModal, setHandleConfirmModal] = useState({
    isOpen: false,
    onConfirm: () => {},
    onCancel: () => setHandleConfirmModal((prevState) => ({
      ...prevState,
      isOpen: false,
    })),
    onClose: () => setHandleConfirmModal((prevState) => ({
      ...prevState,
      isOpen: false,
    })),
    message: 'Tem certeza que deseja excluir?',
  });

  const handleDeleteSchedule = (id?: string) => {
    setHandleConfirmModal((prevState) => ({
      ...prevState,
      isOpen: true,
      onConfirm: () => handleConfirmDeleteSchedule(id),
    }));
  };

  const handleConfirmDeleteSchedule = async (id?: string) => {
    try {
      await api.delete(`/schedule/${id}`);
      scheduleDispatch({
        type: 'DELETE_SCHEDULE',
        payload: { scheduleId: id || '' },
      });
    } catch (error: any) {
      toast.error(error?.response?.data?.message || error?.message);
    } finally {
      setHandleConfirmModal((prevState) => ({
        ...prevState,
        isOpen: false,
      }));
    }
  };

  // specific || recurrent

  const formattedSchedules = schedules.map((schedule) => ({
    ...schedule,
    type: schedule?.partner?.name
    || ptScheduleTypeNames[schedule.type_name]
    || schedule.type_name,
    formattedInitialDate: schedule.initialDate ? format(schedule.initialDate, 'dd/MM/yyyy') : '-',
    formattedFinalDate: schedule.finalDate ? format(schedule.finalDate, 'dd/MM/yyyy') : '-',
    formattedDays: schedule?.days?.sort((a, b) => (a.index > b.index ? 1 : -1)).map((day) => day.name).join(', ') || '',
  }));

  return (
    <Container>
      <table>
        <thead>
          <tr>
            <th>Nome</th>
            <th>Data de início</th>
            <th>Data de término</th>
            <th>Horário inicial</th>
            <th className="edit-agenda__table--second-to-last-col">
              Horário final
            </th>
            <th />
          </tr>
        </thead>
        <tbody>
          {formattedSchedules.map((schedule) => (
            <tr key={schedule.id_schedule}>
              <td className="edit-agenda__table--capitalize">
                <button
                  type="button"
                  className="edit-agenda__table--edit-button"
                  onClick={() => onClickEdit(schedule.id_schedule || '')}
                >
                  {schedule.type}
                </button>
              </td>
              {schedule.frequency === 'specific' ? (
                <>
                  <td className="edit-agenda__table--uppercase">
                    {schedule.formattedInitialDate}
                  </td>
                  <td className="edit-agenda__table--uppercase">
                    {schedule.formattedFinalDate}
                  </td>
                </>
              ) : (
                <td colSpan={2} className="edit-agenda__table--uppercase">
                  {schedule.formattedDays}
                </td>
              )}
              <td>{schedule?.initial_time.substring(0, 5)}</td>
              <td className="edit-agenda__table--second-to-last-col">
                {schedule?.final_time.substring(0, 5)}
              </td>
              <td>
                <button
                  type="button"
                  className="edit-agenda__table-delete-button"
                  onClick={() => handleDeleteSchedule(schedule.id_schedule)}
                >
                  <DeleteIcon />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <ConfirmModal {...handleConfirmModal} />
    </Container>
  );
}

const Container = styled.div`
  max-width: min(50rem, 100%);


  margin: 0 auto;

  table {
    width: 100%;
    max-width: min(50rem, 100%);
    margin: 0 auto;

    font-size: 1rem;

    thead tr th {
      background-color: var(--purple-50);
      font-weight: 700;
      padding: 0.5rem 1.5rem;

      vertical-align: middle;

      &:first-child {
        border-radius: 0.5rem 0 0 0.5rem;
      }

      &.edit-agenda__table--second-to-last-col {
        border-radius: 0 0.5rem 0.5rem 0;
      }

      &:last-child {
        background-color: transparent;
      }
    }

    tbody:before {
      content: "-";
      display: block;
      line-height: 1rem;
      text-indent: -99999px;
    }

    tbody tr td {
      padding: 1rem;

      text-align: center;
      vertical-align: middle;

      background-color: var(--purple-50);

      &.edit-agenda__table--capitalize {
        text-transform: capitalize;
      }

      &.edit-agenda__table--uppercase {
        text-transform: uppercase;
      }

      button {
        background-color: transparent;
        border: none;

        transition: filter 0.2s;

        &:hover {
          filter: brightness(0.9);
        }
      }

      button.edit-agenda__table--edit-button {
        color: var(--blue-400);
        font-size: 1rem;
      }

      button.edit-agenda__table-delete-button {
        svg {
          width: 1rem;
          height: 1rem;

          path {
            fill: var(--gray-500);
          }
        }
      }
    }

    tbody tr:first-child td {
      &:first-child {
        border-radius: 0.5rem 0 0 0;
      }

      &.edit-agenda__table--second-to-last-col {
        border-radius: 0 0.5rem 0 0;
      }
    }

    tbody tr:nth-child(2n) td {
      background-color: var(--white);
    }

    tbody tr td:last-child {
      background-color: transparent;
    }

    tbody tr:last-child td {
      &:first-child {
        border-radius: 0 0 0 0.5rem;
      }

      &.edit-agenda__table--second-to-last-col {
        border-radius: 0 0 0.5rem 0;
      }
    }
  }

  @media (max-width: 600px) {
    overflow-x: auto;
  }
`;
