import styled from 'styled-components';

export const Container = styled.div`
background-color: var(--white);
  border-radius: 2rem;

  max-width: 47rem;
  width: 100vw;

  padding-bottom: 1.5rem;

  box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.16);

  display: flex;
  flex-direction: column;
`;

export const StyledHeader = styled.header`
  background-color: var(--blue-400);
  color: var(--white);

  font-weight: 700;
  font-size: 1.5rem;

  width: 100%;

  text-align: center;

  border-radius: 2rem 2rem 0 0;
  padding: 1rem 0;

  margin-bottom: 1.75rem;
`;

export const PatientsList = styled.main`
  margin: 0 2rem;

  max-height: 20rem;
  overflow: auto;

  padding-right: 0.5rem;

  scrollbar-width: thin;
  scrollbar-color: var(--blue-400) #e5e5ea;

  .patients-list__load-more {
    height: 20px;
    width: 100%;
    background: transparent;
  }

  &::-webkit-scrollbar {
    width: 0.5rem;
    height: 0.5rem;
  }

  &::-webkit-scrollbar-track {
    background-color: #e5e5ea;
    border-radius: 1rem;
    width: 1rem;
    height: 100%;
  }

  &::-webkit-scrollbar-thumb {
    width: 0.25rem;
    border-radius: 1rem;
    background-color: var(--blue-400);
  }
`;

export const PatientItem = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 0.375rem 0;

  font-size: 1.25rem;

  border-top: 1px solid #70707025;

  &:first-child {
    border-top: none;
  }
`;

export const PatientName = styled.p`
`;

export const Subtitle = styled.p`
  font-size: 1.25rem;
  font-weight: bold;
  margin-left: 2rem;
  margin-bottom: 0.5rem;
`;
