import styled from 'styled-components';

import { ReactComponent as DeleteIcon } from '../../assets/images/delete.svg';

import { Button } from '../Button';

export const Container = styled.div`
  background-color: var(--white);
  border-radius: 2rem;

  max-width: 47rem;
  width: 100vw;

  padding-bottom: 1.5rem;

  box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.16);

  display: flex;
  flex-direction: column;
  gap: 1.75rem;
`;

export const StyledHeader = styled.header`
  background-color: var(--blue-400);
  color: var(--white);

  font-weight: 700;
  font-size: 1.5rem;

  width: 100%;

  text-align: center;

  border-radius: 2rem 2rem 0 0;
  padding: 1rem 0;
`;

export const SearchBoxWrapper = styled.div`
  width: 90%;
  margin: 0 auto;
`;

export const PartnerList = styled.main`
  margin: 0 2rem;

  max-height: 20rem;
  overflow: auto;

  scrollbar-width: thin;
  scrollbar-color: var(--blue-400) #e5e5ea;

  .partner-list__load-more {
    height: 20px;
    width: 100%;
    background: transparent;
  }

  &::-webkit-scrollbar {
    width: 0.5rem;
    height: 0.5rem;
  }

  &::-webkit-scrollbar-track {
    background-color: #e5e5ea;
    border-radius: 1rem;
    width: 1rem;
    height: 100%;
  }

  &::-webkit-scrollbar-thumb {
    width: 0.25rem;
    border-radius: 1rem;
    background-color: var(--blue-400);
  }
`;

export const PartnerItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #70707015;
  padding: 0.5rem 0;

  margin-right: 1rem;

  span {
    font-size: 1.25rem;
  }

  &:last-child {
    border-bottom: none;
  }
`;

export const StyledButton = styled(Button)`
  font-size: 1.25rem;
  width: 50%;
  margin: 0rem auto;
`;

export const IconButton = styled.button`
  background-color: transparent;
  border: none;

  width: fit-content;
  height: fit-content;

  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 1.25rem;
    height: 1.25rem;
  }

  transition: filter 0.2s;

  &:hover {
    filter: brightness(0.8);
  }
`;

export const StyledDeleteIcon = styled(DeleteIcon)`
  path {
    fill: var(--gray-500);
  }
`;
