import { useRouteMatch } from 'react-router-dom';
import { AddAdminForm } from '../components/User/AddAdminForm';
import { AddInternalUserForm } from '../components/User/AddInternalUserForm';
import { AddProvider } from '../components/User/AddProvider';
import { AddTherapistForm } from '../components/User/AddTherapistForm';

import { AddUser } from '../components/User/AddUser';
import { EditUserForm } from '../components/User/EditUserForm';
import { Users } from '../pages/Users';
import { AdminRoute } from './AdminRoute';

export function UsersRouter() {
  const { path } = useRouteMatch();
  return (
    <>
      <AdminRoute path={`${path}`} exact component={Users} />
      <AdminRoute path={`${path}/editar/:userId/:userRole`} exact component={EditUserForm} />
      <AdminRoute path={`${path}/editar/:userId`} exact component={EditUserForm} />
      <AdminRoute path={`${path}/adicionar`} exact component={AddUser} />
      <AdminRoute path={`${path}/adicionar/administrador`} exact component={AddAdminForm} />
      <AdminRoute path={`${path}/adicionar/prestador`} exact component={AddProvider} />
      <AdminRoute path={`${path}/adicionar/prestador/terapeuta`} exact component={AddTherapistForm} />
      <AdminRoute path={`${path}/adicionar/prestador/interno`} exact component={AddInternalUserForm} />
    </>
  );
}
