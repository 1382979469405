import styled from 'styled-components';

interface IImageUploadBoxProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label: string;
  id: string;
  customClassName?: string;
  imgUrl?: string;
  placeholderUrl: string;
}

export default function ImageUploadBox({
  label,
  customClassName = '',
  id,
  imgUrl,
  placeholderUrl,
  ...rest
}: IImageUploadBoxProps) {
  return (
    <Container className={customClassName} htmlFor={id}>
      <input id={id} type="file" value="" {...rest} />
      <img
        src={
          imgUrl || placeholderUrl
        }
        alt={label}
        onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => {
          (e.target as HTMLImageElement).src = placeholderUrl;
        }}
      />
      <span>
        {label}
      </span>
    </Container>
  );
}

const Container = styled.label`
  position: relative;

  width: fit-content;
  margin: 0 auto;

  cursor: pointer;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;

  border-radius: 1rem;
  padding: 1rem 1.5rem;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);

  font-size: 1rem;

  img {
    width: 8.5rem;
    height: 8.5rem;

    object-fit: contain;
  }

  input[type="file"] {
    display: none;
  }
`;
