import { useEffect, useState } from 'react';

import { toast } from 'react-toastify';

import subMonths from 'date-fns/subMonths';
import endOfDay from 'date-fns/endOfDay';
import startOfDay from 'date-fns/startOfDay';

import { api } from '../../../services/api';
import { IReportResponse, IUser } from '../../../types';
import {
  DatePeriodDropdown,
  DatePeriodDropdownOption,
} from '../../DatePeriodDropdown';
import { GoBackLocation } from '../../GoBackLocation';
import { Container, LocationsContainer, StyledHeader } from './styles';
import { ReportsItems } from '../../Reports/ReportsItems';
import { AppointmentsList } from '../../Reports/AppointmentsList';
import { AvaliationsList } from '../../Reports/AvaliationsList';
import { datePeriodOptions } from '../../../resources/DatePeriodOptions';

const ptInformationTypes = {
  general: 'Informações Gerais',
  done: 'Atendimentos',
  canceled: 'Cancelamentos',
  avaliation: 'Avaliação',
} as { [key: string]: string };

interface IPatientDetailsProps {
  user: IUser;
  onRemoveSelectedUser: () => void;
}

export default function PatientDetails({
  user,
  onRemoveSelectedUser,
}: IPatientDetailsProps) {
  const [reports, setReports] = useState<IReportResponse>();
  const [selectedInformation, setSelectedInformation] = useState<string>('general');
  const [selectedTimePeriod, setSelectedTimePeriod] = useState<
  DatePeriodDropdownOption
  >(datePeriodOptions[2]);

  useEffect(() => {
    if (user) {
      fetchPatientDetails();
    }
  }, [user, selectedTimePeriod]);

  const fetchPatientDetails = async () => {
    const { endDate, startDate } = selectedTimePeriod.value;

    const params = {
      userId: user.id_user,
      initialDate: startDate ? startOfDay(startDate) : startOfDay(new Date()),
      finalDate: endDate ? endOfDay(endDate) : endOfDay(new Date('2070-12-31')),
    };

    try {
      const response = await api.get<IReportResponse>('/report', {
        params,
      });

      setReports(response.data);
    } catch (error: any) {
      toast.error(
        error?.response?.data?.message
          || 'Erro ao obter detalhes do cliente, tente novamente mais tarde',
      );
    }
  };

  const handleGoBack = () => {
    if (selectedInformation !== 'general') {
      setSelectedInformation('general');
    } else {
      onRemoveSelectedUser();
    }
  };

  const handleSelectType = (type: string) => {
    setSelectedInformation(type);
  };

  const reportsObject = {
    done: {
      label: 'Atendimentos',
      value: reports?.executed_services || 0,
      formattedValue: reports?.executed_services
        ? reports.executed_services.toLocaleString()
        : '0',
    },
    canceled: {
      label: 'Cancelamentos',
      value: reports?.canceled_services || 0,
      formattedValue: reports?.canceled_services
        ? reports.canceled_services.toLocaleString()
        : '0',
    },
    avaliation: {
      label: 'Avaliação',
      value: reports?.avaliation || 0,
      formattedValue: reports?.avaliation
        ? reports.avaliation.toLocaleString('pt-BR', {
          maximumFractionDigits: 1,
        })
        : '0',
    },
    paid: {
      label: 'Total pago pelo cliente',
      value: reports?.paid || 0,
      formattedValue: reports?.paid
        ? new Intl.NumberFormat('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        }).format(reports.paid / 100 || 0)
        : 'R$ 0,00',
      disableButton: true,
    },
  };

  const reportsComponents = {
    done: (
      <AppointmentsList
        userId={user.id_user}
        appointmentStatus="done"
        initialDate={selectedTimePeriod.value.startDate}
        finalDate={selectedTimePeriod.value.endDate}
        isPatient
      />
    ),
    canceled: (
      <AppointmentsList
        userId={user.id_user}
        appointmentStatus="canceled"
        initialDate={selectedTimePeriod.value.startDate}
        finalDate={selectedTimePeriod.value.endDate}
        isPatient
      />
    ),
    avaliation: (
      <AvaliationsList
        userId={user.id_user}
        initialDate={selectedTimePeriod.value.startDate}
        finalDate={selectedTimePeriod.value.endDate}
        isPatient
      />
    ),
  } as {
    [key: string]: JSX.Element;
  };

  return (
    <Container>
      {reports && (
        <>
          <StyledHeader>
            <LocationsContainer>
              <GoBackLocation
                onArrowClick={handleGoBack}
                title={user?.name}
                arrowColor="var(--gray-500)"
                titleColor="var(--gray-500)"
              />
              {selectedInformation !== 'general' && (
                <GoBackLocation
                  onArrowClick={handleGoBack}
                  title={ptInformationTypes[selectedInformation]}
                  arrowColor="var(--gray-500)"
                  titleColor="var(--gray-500)"
                />
              )}
            </LocationsContainer>
            <DatePeriodDropdown
              onChange={setSelectedTimePeriod}
              options={datePeriodOptions}
              option={selectedTimePeriod}
              placeholder="Selecione..."
              showSpecificInterval
              customClassName="patient-details__date-dropdown"
            />
          </StyledHeader>
          {selectedInformation === 'general' && (
            <ReportsItems
              onSelectSubtype={handleSelectType}
              reportsObject={reportsObject}
            />
          )}
          {
            selectedInformation !== 'general' && reportsComponents[selectedInformation]
          }
        </>
      )}
    </Container>
  );
}
