import { useRef, useState } from 'react';

import { ReactComponent as CalendarIcon } from '../../assets/images/calendar.svg';

import { useOutside } from '../../hooks/useOutside';
import { AppDatePicker } from '../AppDatePicker';
import {
  Container,
  DropdownContainer,
  IntervalsContainer,
  Option,
  OptionsContainer,
  OptionsWrapper,
  StyledLabel,
} from './styles';

export type DatePeriodDropdownOption = {
  key: string;
  value: {
    startDate?: Date;
    endDate?: Date;
  };
  label: string;
};

interface IDatePeriodDropdownProps {
  options: DatePeriodDropdownOption[];
  onChange: (value: DatePeriodDropdownOption) => void;
  option?: DatePeriodDropdownOption;
  placeholder?: string;
  showSpecificInterval?: boolean;
  customClassName?: string;
}

export default function DatePeriodDropdown({
  onChange,
  options,
  option: selectedOption,
  customClassName = '',
  placeholder = 'Selecione...',
  showSpecificInterval = false,
}: IDatePeriodDropdownProps) {
  const [isExpanded, setIsExpanded] = useState(false);
  const [selectingInterval, setSelectingInterval] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  const toggle = () => {
    setIsExpanded((prevState) => !prevState);
  };

  const handleClose = () => {
    setIsExpanded(false);
  };

  const handleChangeOption = (option: DatePeriodDropdownOption) => {
    onChange(option);
    setSelectingInterval(false);
    handleClose();
  };

  if (!containerRef) {
    return null;
  }

  useOutside(containerRef, handleClose);

  const getSelectedLabel = () => selectedOption?.label || placeholder;

  const handleSelectSpecificPeriod = () => {
    setSelectingInterval(true);
    onChange({
      key: 'specific',
      value: {
        startDate: undefined,
        endDate: undefined,
      },
      label: 'Período específico',
    });
  };

  const handleStartDateChange = (date: Date) => {
    if (!selectedOption) {
      return;
    }
    onChange({
      ...selectedOption,
      value: {
        ...selectedOption.value,
        startDate: date,
      },
      label: `${date.toLocaleDateString()} - ${
        selectedOption.value.endDate?.toLocaleDateString() || ''
      }`,
    });
  };

  const handleEndDateChange = (date: Date) => {
    if (!selectedOption) {
      return;
    }
    onChange({
      ...selectedOption,
      value: {
        ...selectedOption.value,
        startDate: selectedOption?.value?.startDate
          ? selectedOption.value.startDate
          : new Date(),
        endDate: date,
      },
      label: `${
        selectedOption.value.startDate?.toLocaleDateString()
        || new Date().toLocaleDateString()
      } - ${date.toLocaleDateString()}`,
    });
  };

  return (
    <Container ref={containerRef} className={customClassName}>
      <DropdownContainer isExpanded={isExpanded}>
        <Option type="button" onClick={toggle}>
          {getSelectedLabel()}
        </Option>
        <OptionsWrapper isExpanded={isExpanded}>
          <OptionsContainer>
            {options.map((option) => (
              <Option
                key={option.key}
                type="button"
                onClick={() => handleChangeOption(option)}
              >
                {option.label}
              </Option>
            ))}
            {showSpecificInterval && (
              <Option type="button" onClick={handleSelectSpecificPeriod}>
                Período específico
              </Option>
            )}
            {selectingInterval && (
              <IntervalsContainer>
                <StyledLabel>
                  Data de início
                  <AppDatePicker
                    onChange={handleStartDateChange}
                    selected={selectedOption?.value?.startDate}
                    icon={<CalendarIcon />}
                    leftIcon={false}
                    dateFormat="dd/MM/yyyy"
                    customClassName="date-period-dropdown__datepicker"
                  />
                </StyledLabel>
                <StyledLabel>
                  Data de término
                  <AppDatePicker
                    onChange={handleEndDateChange}
                    selected={selectedOption?.value?.endDate}
                    icon={<CalendarIcon />}
                    leftIcon={false}
                    dateFormat="dd/MM/yyyy"
                    customClassName="date-period-dropdown__datepicker"
                    popperPlacement="bottom-end"
                    popperModifiers={[
                      {
                        name: 'offset',
                        options: {
                          offset: [0, 0],
                        },
                      },
                      {
                        name: 'preventOverflow',
                        enabled: false,
                      },
                      {
                        name: 'hide',
                        enabled: false,
                      },
                      {
                        name: 'flip',
                        enabled: false,
                        options: {
                          allowedAutoPlacements: ['bottom'],
                        },
                      },
                    ]}
                  />
                </StyledLabel>
              </IntervalsContainer>
            )}
          </OptionsContainer>
        </OptionsWrapper>
      </DropdownContainer>
    </Container>
  );
}
