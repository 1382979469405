import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { useNotificationModal } from '../../../hooks/useNotificationModal';
import { api } from '../../../services/api';

import { EditForm } from '../../EditForm';

import { AnamnesisForm, IAnamnesisFormData, initialAnamnesisData } from '../AnamnesisForm';

import { Container } from './styles';

export default function EditAnamnesisForm() {
  const [editAnamnesisFormData, setEditAnamnesisFormData] = useState(initialAnamnesisData);
  const history = useHistory();
  const { anamnesisId } = useParams<{
    anamnesisId: string;
  }>();
  const { notificate } = useNotificationModal();

  useEffect(() => {
    fetchAnamnesisInfo();
  }, [anamnesisId]);

  const fetchAnamnesisInfo = async () => {
    /* setTimeout(() => {
      const foundAnamnesis = mockAnamnesis.find((anamnesis) => anamnesis.id === anamnesisId);

      if (foundAnamnesis) {
        setEditAnamnesisFormData({
          ...foundAnamnesis,
          alternatives:
            foundAnamnesis.answers.length > 0
            ? foundAnamnesis.alternatives
            : initialFormData.alternatives,
        } as IAnamnesisFormData);
      }
    }, 250); */

    try {
      const response = await api.get(`/anamnese/${anamnesisId}`);

      setEditAnamnesisFormData({
        ...response.data,
      });
    } catch (error: any) {
      toast.error(error?.response?.data?.message || error?.message || 'Algo deu errado');
    }
  };

  const handleAnswerChange = (field: 'answer' | 'weigth', index: number, value: string) => {
    setEditAnamnesisFormData((prevState) => ({
      ...prevState,
      alternatives: prevState.alternatives.map((answer, answerIndex) => {
        if (answerIndex === index) {
          return {
            ...answer,
            [field]: value,
          };
        }

        return answer;
      }),
    }));
  };

  const handleGoBack = () => {
    history.goBack();
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = event.target;
    setEditAnamnesisFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSuccess = () => {
    setEditAnamnesisFormData(initialAnamnesisData);

    history.push('/anamneses');
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    try {
      const {
        name, question, type_name: type, comments, alternatives,
      } = editAnamnesisFormData;

      const dataToSubmit = {
        anamneseId: anamnesisId,
        name,
        question,
        type,
        comments: comments || undefined,
        alternatives: type === 'multiple' ? alternatives.map(
          (alternative) => ({
            ...alternative,
            weigth: Number(alternative.weigth),
            id_alternative: undefined,
            anamnese_id: undefined,
            created_at: undefined,
            updated_at: undefined,
          }),
        ) : undefined,
      };

      await api.put('/anamnese', dataToSubmit);
      notificate('Salvo com sucesso!', handleSuccess);
    } catch (error: any) {
      toast.error(error?.response?.data?.message || error?.message || 'Algo deu errado');
    }
  };

  const handleTypeChange = (type: IAnamnesisFormData['type_name']) => {
    if (type === 'multiple' && !editAnamnesisFormData.alternatives.length) {
      setEditAnamnesisFormData((prevState) => ({
        ...prevState,
        alternatives: initialAnamnesisData.alternatives,
      }));
    }

    setEditAnamnesisFormData((prevState) => ({
      ...prevState,
      type_name: type,
    }));
  };

  const handleDeleteAnamnesis = async () => {
    try {
      await api.delete(`/anamnese/${anamnesisId}`);
      history.push('/anamneses');
    } catch (error: any) {
      toast.error(error?.response?.data?.message || error?.message || 'Algo deu errado');
    }
  };

  return (
    <Container>
      <EditForm
        title="Editar pergunta"
        onArrowClick={handleGoBack}
        onSubmit={handleSubmit}
        onDeleteClick={handleDeleteAnamnesis}
      >
        <AnamnesisForm
          data={editAnamnesisFormData}
          onAnswerChange={handleAnswerChange}
          onInputChange={handleInputChange}
          onTypeChange={handleTypeChange}
        />
      </EditForm>
    </Container>
  );
}
