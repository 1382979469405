import styled from 'styled-components';

export const FieldsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 6.75rem;
  row-gap: 2rem;
  padding: 2rem;
  padding-bottom: 4rem;
  width: 85%;
  margin: 0 auto;

  h1 {
    grid-column: span 2;
    font-size: 1.5rem;
    font-weight: 500;
  }

  input {
    font-size: 1rem;
  }

  @media (max-width: 600px) {
    grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));

    h1 {
      grid-column: unset;
      grid-row: 1;
    }
  }
`;
