import { Switch, Redirect, Route } from 'react-router-dom';
import { Reports } from '../pages/Reports';
import { AccessControl } from '../pages/AccessControl';
import { Agenda } from '../pages/Agenda';
import { Dashboard } from '../pages/Dashboard';
import { MyProfile } from '../pages/MyProfile';
import { SignIn } from '../pages/SignIn';
import { AdminRoute } from './AdminRoute';
import { AnamnesisRouter } from './AnamnesisRouter';
import { PartnersRouter } from './PartnersRouter';
import { PatientsRouter } from './PatientsRouter';
import { PrivateRoute } from './PrivateRoute';
import { ServicesRouter } from './ServicesRouter';
import { UsersRouter } from './UsersRouter';
import { VouchersRouter } from './VouchersRouter';
import { AdminDashboard } from '../pages/AdminDashboard';

export default function Router() {
  return (
    <Switch>
      <Route path="/entrar" component={SignIn} />
      <Route path="/usuarios" component={UsersRouter} />
      <Route path="/servicos" component={ServicesRouter} />
      <PrivateRoute path="/perfil" component={MyProfile} />
      <Route path="/parceiros" component={PartnersRouter} />
      <Route path="/vouchers" component={VouchersRouter} />
      <Route path="/anamneses" component={AnamnesisRouter} />
      <Route path="/clientes" component={PatientsRouter} />
      <PrivateRoute path="/dashboard" component={Dashboard} />
      <AdminRoute path="/acesso" component={AccessControl} />
      <PrivateRoute path="/agenda" component={Agenda} />
      <AdminRoute path="/relatorios" component={AdminDashboard} />
      <Route path="*">
        <Redirect to="/entrar" />
      </Route>
    </Switch>
  );
}
