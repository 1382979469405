import { ChangeEvent, FormEvent, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { toast } from 'react-toastify';

import { Button } from '../../components/Button';
import { Input } from '../../components/Input';

import LogoImg from '../../assets/images/logo.png';

import { Container, Content } from './styles';
import { useAuth } from '../../hooks/useAuth';

export default function SignIn() {
  const [signInFormData, setSignInFormData] = useState({
    email: '',
    password: '',
    remember: true,
  });
  const history = useHistory();
  const { data, signIn } = useAuth();

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();
    try {
      await signIn({
        email: signInFormData.email,
        password: signInFormData.password,
      }, signInFormData.remember);
    } catch (error: any) {
      toast.error(
        error?.response?.data?.message || error?.message || 'Usuário ou senha inválidos',
      );
    }
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    setSignInFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleToggleRemember = (event: ChangeEvent<HTMLInputElement>) => {
    setSignInFormData((prevState) => ({
      ...prevState,
      remember: event.target.checked,
    }));
  };

  if (data.token) {
    if (data.user.role === 'therapist') {
      history.push('/agenda');
    } else {
      history.push('/usuarios');
    }
  }

  return (
    <Container>
      <Content>
        <img src={LogoImg} alt="doar care" />

        <form onSubmit={handleSubmit}>
          <h1>Bem-vindo de volta!</h1>
          <h2>Faça seu login</h2>

          <Input name="email" type="email" placeholder="E-mail" required value={signInFormData.email} onChange={handleInputChange} />
          <Input name="password" type="password" placeholder="Senha" required value={signInFormData.password} onChange={handleInputChange} />

          <div className="sign-in__remember-container">
            <label className="sign-in__remember-checkbox-label">
              <input type="checkbox" name="remember" onChange={handleToggleRemember} checked={signInFormData.remember} />
              <span>
                Manter conectado
              </span>
            </label>

            <a href="/entrar">Esqueci minha senha</a>
          </div>

          <Button type="submit">
            Entrar
          </Button>
        </form>
      </Content>
    </Container>
  );
}
