import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import { cpf } from 'cpf-cnpj-validator';

import { useNotificationModal } from '../../../hooks/useNotificationModal';
import { api } from '../../../services/api';
import { maskCpfOrCnpj } from '../../../utils/maskCpfOrCnpj';

import { CreationForm } from '../../CreationForm';
import { Input } from '../../Input';

import { Container } from './styles';

interface IAddAdminFormData {
  name: string;
  email: string;
  cpf: string;
  password: string;
  password_confirmation: string;
}

const initialAddAdmin: IAddAdminFormData = {
  name: '',
  email: '',
  cpf: '',
  password: '',
  password_confirmation: '',
};

export default function AddAdminForm() {
  const [adminFormData, setAdminFormData] = useState<IAddAdminFormData>(initialAddAdmin);
  const { notificate } = useNotificationModal();
  const history = useHistory();

  const handleGoBack = () => {
    history.goBack();
  };

  const handleSuccess = () => {
    setAdminFormData(initialAddAdmin);

    history.push('/usuarios');
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    let newValue = value;

    if (name === 'cpf') {
      newValue = maskCpfOrCnpj(value);
    }

    setAdminFormData((prevState) => ({
      ...prevState,
      [name]: newValue,
    }));
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if (adminFormData.password !== adminFormData.password_confirmation) {
      toast.error('As senhas não conferem');
      return;
    }

    if (adminFormData.cpf && !cpf.isValid(adminFormData.cpf)) {
      toast.error('CPF inválido');
      return;
    }

    try {
      await api.post('/adm', {
        ...adminFormData,
        cpf: adminFormData.cpf.replace(/\D/g, ''),
        password_confirmation: undefined,
      });

      notificate('Criado com sucesso!', handleSuccess);
    } catch (error: any) {
      toast.error(error?.response?.data?.message || 'Algo deu errado');
    }
  };

  return (
    <CreationForm
      title="Dados do administrador"
      onArrowClick={handleGoBack}
      buttonText="Criar"
      onSubmit={handleSubmit}
    >
      <Container>
        <Input
          className="grid-column-1"
          label="Nome completo:"
          name="name"
          type="text"
          placeholder="Insira o nome completo"
          onChange={handleInputChange}
          value={adminFormData.name}
          required
        />
        <Input
          label="E-mail:"
          name="email"
          type="email"
          placeholder="Insira o e-mail"
          onChange={handleInputChange}
          value={adminFormData.email}
          required
        />
        <Input
          label="CPF:"
          name="cpf"
          type="text"
          placeholder="Insira o CPF"
          onChange={handleInputChange}
          value={adminFormData.cpf}
          maxLength={14}
          required
        />
        <Input
          label="Data de cadastro:"
          defaultValue={new Date().toLocaleDateString()}
          readOnly
          required
        />
        <Input
          label="Senha:"
          name="password"
          type="password"
          placeholder="Insira a senha"
          onChange={handleInputChange}
          value={adminFormData.password}
          minLength={8}
          required
          pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$"
          title="A senha deve conter pelo menos 8 caracteres, sendo pelo menos uma letra maiúscula, uma minúscula, um número e um caractere especial"
        />
        <Input
          label="Confirmar senha:"
          name="password_confirmation"
          type="password"
          placeholder="Confirme a senha"
          minLength={8}
          onChange={handleInputChange}
          value={adminFormData.password_confirmation}
          required
          pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$"
          title="A senha deve conter pelo menos 8 caracteres, sendo pelo menos uma letra maiúscula, uma minúscula, um número e um caractere especial"
        />
      </Container>
    </CreationForm>
  );
}
