import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  height: calc(100vh - 7rem);

  > * {
    margin-top: 2rem;
  }
`;

export const TextButton = styled.button`
  background-color: transparent;
  border: none;
  color: var(--blue-400);
  font-size: inherit;

  text-decoration: none;

  transition: filter 0.2s;

  &:hover {
    filter: brightness(0.8);
  }
`;
