import { useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import { useNotificationModal } from '../../../hooks/useNotificationModal';
import { api } from '../../../services/api';
import { IAppFormElement } from '../../../types';

import { CreationForm } from '../../CreationForm';
import { AddressForm, IAddressFormData, initialAddress } from '../../User/AddressForm';
import { initialPersonalDetails, IPartnerPersonalDetailsFormData, PersonalDetailsForm } from '../PersonalDetailsForm';

const addPartnerFormSteps = [
  {
    key: 'personalDetails',
    label: 'Dados pessoais',
  },
  {
    key: 'address',
    label: 'Endereço',
  },
];

interface IAddPartnerFormData {
  address: IAddressFormData;
  personalDetails: IPartnerPersonalDetailsFormData;
}

export default function AddPartnerForm() {
  const [addPartnerFormData, setAddPartnerFormData] = useState<IAddPartnerFormData>({
    address: initialAddress,
    personalDetails: initialPersonalDetails,
  });
  const [currentStep, setCurrentStep] = useState(0);
  const [avatar, setAvatar] = useState<File | null>(null);
  const { notificate } = useNotificationModal();
  const currentFormRef = useRef<IAppFormElement>(null);
  const history = useHistory();

  const onStepChange = (step: number) => {
    if (step < 0) {
      history.goBack();
    } else {
      setCurrentStep(step);
    }
  };

  const handleSuccess = () => {
    setAddPartnerFormData({
      address: initialAddress,
      personalDetails: initialPersonalDetails,
    });
    history.goBack();
  };

  const handleSubmit = async (data?: IPartnerPersonalDetailsFormData | IAddressFormData) => {
    const newAddPartnerFormData = {
      ...addPartnerFormData,
      [addPartnerFormSteps[currentStep].key]: data,
    };
    setAddPartnerFormData(newAddPartnerFormData);
    if (currentStep !== addPartnerFormSteps.length - 1) {
      onStepChange(currentStep + 1);
    } else {
      const dataToSubmit = {
        ...newAddPartnerFormData.personalDetails,
        avatar_url: undefined,
        tip: Number(newAddPartnerFormData.personalDetails.tip),
        discount: Number(newAddPartnerFormData.personalDetails.discount),
        address: newAddPartnerFormData.address,
      };

      const formDataToSubmit = new FormData();

      Object.entries(dataToSubmit).forEach(([key, value]) => {
        if (value) {
          formDataToSubmit.append(key, value.toString());
        }
      });

      formDataToSubmit.set('address', JSON.stringify(newAddPartnerFormData.address));

      if (avatar) {
        formDataToSubmit.append('img', avatar);
      }

      try {
        await api.post('/partner', formDataToSubmit, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        notificate('Criado com sucesso!', handleSuccess);
      } catch (error: any) {
        toast.error(error?.response?.data?.message || error?.message || 'Algo deu errado');
      }
    }
  };

  const handleClickNext = (event: React.FormEvent) => {
    event.preventDefault();
    if (!currentFormRef.current) return;
    currentFormRef.current.requestSubmit();
  };

  const handleAvatarUpload = (file: File) => {
    setAvatar(file);
    setAddPartnerFormData((prevState) => ({
      ...prevState,
      personalDetails: {
        ...prevState.personalDetails,
        avatar_url: URL.createObjectURL(file),
      },
    }));
  };

  const formProps = {
    onSubmit: handleSubmit,
    ref: currentFormRef,
  };

  const addPartnerFormComponents = {
    personalDetails: <PersonalDetailsForm
      {...formProps}
      initialData={addPartnerFormData.personalDetails}
      onAvatarUpload={handleAvatarUpload}
    />,
    address: <AddressForm {...formProps} initialData={addPartnerFormData.address} />,
  } as {[key: string]: JSX.Element};

  return (
    <CreationForm
      title={addPartnerFormSteps[currentStep].label}
      onArrowClick={() => onStepChange(currentStep - 1)}
      buttonText={currentStep === addPartnerFormSteps.length - 1 ? 'Criar' : 'Próximo'}
      onSubmit={handleClickNext}
    >
      {addPartnerFormComponents[addPartnerFormSteps[currentStep].key]}
    </CreationForm>

  );
}
