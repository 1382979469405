import { states } from '../../resources/States';

export const appointmentTypeOptions = [
  {
    value: 'pool',
    label: 'Pool',
  },
  {
    value: 'portfolio',
    label: 'Carteira de clientes',
  },
  {
    value: 'partner',
    label: 'Parceiro',
  },
];

export const ratingOptions = [
  {
    value: '1',
    label: '1',
  },
  {
    value: '2',
    label: '2',
  },
  {
    value: '3',
    label: '3',
  },
  {
    value: '4',
    label: '4',
  },
  {
    value: '5',
    label: '5',
  },
];

export const statusOptions = [
  {
    value: 'pending',
    label: 'Pendente',
  },
  {
    value: 'accepted',
    label: 'Agendado',
  },
  {
    value: 'canceled',
    label: 'Cancelado',
  },
  {
    value: 'done',
    label: 'Executado',
  },
];

export const statusPt: Record<string, string> = {
  done: 'Realizado',
  accepted: 'Agendado',
  canceled: 'Cancelado',
  pending: 'Pendente',
  not_accepted: 'Não aceito',
  transferred: 'Transferido',
};

export const scheduleTypePt: Record<string, string> = {
  pool: 'Pool',
  portfolio: 'Carteira de clientes',
  partner: 'Parceiro',
};

export const stateOptions = Object.entries(states).map(([key, value]) => ({
  value: key,
  label: value,
}));
