import parseISO from 'date-fns/parseISO';

import { IAppointment, IInterval, ISchedule } from '../../types';
import {
  SchedulesContainer,
  Container,
  TimeSlotsContainer,
  Schedule,
  Appointment,
} from './styles';

interface ISchedulerProps {
  dayAppointments?: IAppointment[];
  daySchedules: ISchedule[];
}

interface ISchedulerSchedule {
  id_schedule: string;
  span: number;
  label: string;
  typeClassName: string;

  textOffset?: number;
  topOffset?: number;
}

const intervalInMinutes = 30;

const generateTimeSlots = (
  startTime: number,
  endTime: number,
  interval = intervalInMinutes,
): string[] => {
  const timeSlots: string[] = [];
  const timeSlotInMinutes = interval;

  for (let i = startTime; i <= endTime; i += timeSlotInMinutes) {
    const time = new Date(0, 0, 0, 0, i, 0, 0);
    timeSlots.push(
      time.toLocaleTimeString('pt-BR', { hour: '2-digit', minute: '2-digit' }),
    );
  }

  return timeSlots;
};

const areTimeslotsCompatible = (
  initialTime: string,
  timeSlot: string,
): boolean => {
  const [initialHour, initialMinutes] = initialTime.split(':');
  const [timeSlotHour, timeSlotMinutes] = timeSlot.split(':');

  const initialTimeInMinutes = Number(initialHour) * 60 + Number(initialMinutes);
  const timeSlotInMinutes = Number(timeSlotHour) * 60 + Number(timeSlotMinutes);

  return (
    (initialTimeInMinutes >= timeSlotInMinutes - intervalInMinutes / 2
      && initialTimeInMinutes <= timeSlotInMinutes + intervalInMinutes / 2)
    || (timeSlotInMinutes >= initialTimeInMinutes - intervalInMinutes / 2
      && timeSlotInMinutes <= initialTimeInMinutes + intervalInMinutes / 2)
  );
};

const isIntervalInTimeSlot = (initialTime: string, timeSlot: string) => {
  const [initialHour, initialMinutes] = initialTime.split(':');
  const [timeSlotHour, timeSlotMinutes] = timeSlot.split(':');

  const initialTimeInMinutes = Number(initialHour) * 60 + Number(initialMinutes);
  const timeSlotInMinutes = Number(timeSlotHour) * 60 + Number(timeSlotMinutes);

  return (
    (initialTimeInMinutes >= timeSlotInMinutes)
    && (initialTimeInMinutes < timeSlotInMinutes + intervalInMinutes)
  );
};

const isAppointmentInTimeSlot = (
  appointment: IAppointment,
  timeSlot: string,
): boolean => {
  const [timeSlotHour, timeSlotMinutes] = timeSlot.split(':');
  const date = parseISO(appointment.datetime);

  const appointmentMinutesInDay = date.getHours() * 60 + date.getMinutes();
  const timeSlotInMinutes = Number(timeSlotHour) * 60 + Number(timeSlotMinutes);

  return (
    (appointmentMinutesInDay >= timeSlotInMinutes - intervalInMinutes / 2
      && appointmentMinutesInDay <= timeSlotInMinutes + intervalInMinutes / 2)
    || (timeSlotInMinutes >= appointmentMinutesInDay - intervalInMinutes / 2
      && timeSlotInMinutes <= appointmentMinutesInDay + intervalInMinutes / 2)
  );
};

const getTopOffset = (initialTimeInMinutes: number, timeSlot: string) => {
  const [timeSlotHour, timeSlotMinutes] = timeSlot.split(':');
  const timeSlotInMinutes = Number(timeSlotHour) * 60 + Number(timeSlotMinutes);

  return (initialTimeInMinutes - timeSlotInMinutes) / intervalInMinutes;
};

export default function Scheduler({
  dayAppointments = [],
  daySchedules,
}: ISchedulerProps) {
  const timeSlots = generateTimeSlots(8 * 60, 18 * 60); // 8:00h - 18:00h

  const appointmentsPerTimeSlot = Object.fromEntries<ISchedulerSchedule[]>(
    timeSlots.map((timeSlot) => {
      const foundAppointments = dayAppointments.filter(
        (appointment) => isAppointmentInTimeSlot(appointment, timeSlot),
      );

      if (!foundAppointments.length) {
        return [timeSlot, []];
      }

      const appointmentsInTimeSlot: ISchedulerSchedule[] = [];

      if (foundAppointments.length > 0) {
        appointmentsInTimeSlot.push(
          foundAppointments.map((appointment) => {
            let label: string;
            let typeClassName: string;
            if (appointment.schedule.type_name === 'portfolio') {
              label = `${appointment.patient?.user?.name} - Consulta (carteira)`;
              typeClassName = 'regular-appointment';
            } else if (appointment.schedule.type_name === 'partner') {
              label = `${appointment.patient?.user?.name} - Consulta (${appointment?.schedule?.partner?.name || 'parceiro'})`;
              typeClassName = 'partner-appointment';
            } else if (appointment.schedule.type_name === 'pool') {
              label = `${appointment.patient?.user?.name} - Consulta (pool)`;
              typeClassName = 'pool-appointment';
            } else {
              label = '';
              typeClassName = 'transparent';
            }
            return {
              id_schedule: appointment.id_appointment,
              span: 1,
              label,
              typeClassName,
            };
          })[0],
        );
      }

      return [timeSlot, appointmentsInTimeSlot];
    }),
  );

  const intervalsPerTimeSlot = Object.fromEntries<ISchedulerSchedule[]>(
    timeSlots.map((timeSlot) => {
      const foundIntervals = daySchedules.reduce((acc, schedule) => {
        const intervals = schedule.intervals.filter(
          (interval) => isIntervalInTimeSlot(interval.initial_time, timeSlot),
        );
        return [...acc, ...intervals];
      }, [] as IInterval[]);

      if (!foundIntervals.length) {
        return [timeSlot, []];
      }

      const intervalsInTimeSlot: ISchedulerSchedule[] = [];

      if (foundIntervals.length > 0) {
        foundIntervals.forEach((interval) => {
          const initialTimeInMinutes = Number(interval.initial_time.split(':')[0]) * 60
            + Number(interval.initial_time.split(':')[1]);
          const finalTimeInMinutes = Number(interval.final_time.split(':')[0]) * 60
            + Number(interval.final_time.split(':')[1]);

          const span = (finalTimeInMinutes - initialTimeInMinutes) / intervalInMinutes;
          const topOffset = getTopOffset(initialTimeInMinutes, timeSlot);

          intervalsInTimeSlot.push({
            id_schedule: interval.id_interval || Math.random().toString(),
            span: span || 1,
            label: '',
            typeClassName: 'reduce-opacity',
            topOffset,
          });
        });
      }

      return [timeSlot, intervalsInTimeSlot];
    }),
  );

  const schedulesPerTimeSlot = Object.fromEntries<ISchedulerSchedule[]>(
    timeSlots.map((timeSlot) => {
      const foundSchedules = daySchedules.filter(
        (schedule) => areTimeslotsCompatible(schedule.initial_time.substring(0, 5), timeSlot),
      );

      if (!foundSchedules.length) {
        return [timeSlot, []];
      }

      const schedulesInTimeSlot: ISchedulerSchedule[] = [];

      foundSchedules.forEach((foundSchedule) => {
        const initialTimeInMinutes = Number(foundSchedule.initial_time.split(':')[0]) * 60
          + Number(foundSchedule.initial_time.split(':')[1]);
        const finalTimeInMinutes = Number(foundSchedule.final_time.split(':')[0]) * 60
          + Number(foundSchedule.final_time.split(':')[1]);

        const span = Math.floor(
          (finalTimeInMinutes - initialTimeInMinutes) / intervalInMinutes,
        );

        let label: string;
        let typeClassName: string;

        if (foundSchedule.type_name === 'portfolio') {
          label = 'Disponível';
          typeClassName = 'available';
        } else if (foundSchedule.type_name === 'partner') {
          label = 'Reservado - Parceiro';
          typeClassName = 'partners';
        } else if (foundSchedule.type_name === 'pool') {
          label = 'Pool';
          typeClassName = 'pool';
        } else {
          label = '';
          typeClassName = 'transparent';
        }

        const schedulerSchedule = {
          id_schedule: foundSchedule.id_schedule || '',
          span,
          label,
          typeClassName,
        };

        schedulesInTimeSlot.push(schedulerSchedule);
      });

      return [timeSlot, schedulesInTimeSlot];
    }),
  );

  return (
    <Container>
      <TimeSlotsContainer>
        {timeSlots.map((timeSlot) => (
          <div key={timeSlot}>{timeSlot}</div>
        ))}
      </TimeSlotsContainer>
      <SchedulesContainer>
        {timeSlots.map((time) => (
          <div className="container" key={`schedule-${time}`}>
            {schedulesPerTimeSlot[time].map((schedule) => (
              <Schedule
                key={schedule.id_schedule}
                className={schedule.typeClassName}
                span={schedule.span}
              >
                <p>{schedule.label}</p>
              </Schedule>
            ))}
            {appointmentsPerTimeSlot[time].map((appointment) => (
              <Appointment
                key={appointment.id_schedule}
                className={appointment.typeClassName}
                span={appointment.span}
              >
                <p>{appointment.label}</p>
              </Appointment>
            ))}
            {intervalsPerTimeSlot[time].map((interval) => (
              <Appointment
                key={interval.id_schedule}
                className={interval.typeClassName}
                span={interval.span}
                topOffset={interval.topOffset}
              >
                <p />
              </Appointment>
            ))}
          </div>
        ))}
      </SchedulesContainer>
    </Container>
  );
}
