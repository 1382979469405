import styled from 'styled-components';

export const Container = styled.div`
  background-color: var(--white);
  border-radius: 2rem;

  max-width: 47rem;
  width: 100vw;

  padding-bottom: 1.5rem;

  box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.16);
`;

export const StyledHeader = styled.header`
  color: var(--blue-400);

  font-weight: 700;
  font-size: 1.5rem;

  width: 100%;

  text-align: center;

  border-radius: 2rem 2rem 0 0;
  padding: 1rem 0;
`;

export const StyledForm = styled.form`
  margin: 2rem;

  max-height: 80vh;
  overflow: auto;

  padding: 0 2rem;

  input {
    font-size: 1rem;
  }

  > * + * {
    margin-top: 2rem;
  }

  > button {
    font-size: 1.25rem;
    width: 50%;
    margin: 0 auto;
    margin-top: 2rem;
  }

  scrollbar-width: thin;
  scrollbar-color: var(--blue-400) #e5e5ea;

  &::-webkit-scrollbar {
    width: 0.5rem;
    height: 0.5rem;
  }

  &::-webkit-scrollbar-track {
    background-color: #e5e5ea;
    border-radius: 1rem;
    width: 1rem;
    height: 100%;
  }

  &::-webkit-scrollbar-thumb {
    width: 0.25rem;
    border-radius: 1rem;
    background-color: var(--blue-400);
  }
`;

export const MapWrapper = styled.div`
  width: 100vw;
  height: 100vh;

  max-height: 24.4375rem;
  max-width: 100%;
`;

export const AddressInputLabel = styled.label`
  margin-bottom: 0.375rem;

  display: flex;
  flex-direction: column;
  gap: 0.375rem;

  span {
    margin-left: 0.75rem;
  }
`;

export const AddressInputWrapper = styled.div`
  width: 100%;
  height: 3em;
  border-radius: 0.5em;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);

  position: relative;

  padding: 0 1rem;

  svg {
    position: absolute;
    right: 0.75rem;
    bottom: 0.875rem;
  }

  &:focus-within {
    outline: 2px solid var(--gray-500);
  }
`;

export const AddressInput = styled.input`
  background: transparent;
  border: none;
  outline: none;
  min-width: 0;
  width: 95%;
  line-height: 3rem;
`;
