import { createContext, useState } from 'react';
import { IHandleNotificationModal, NotificationModal } from '../components/NotificationModal';

interface INotificationModalContextData {
  notificate: NotificateFunction;
}

interface INotificationModalProviderProps {
  children: React.ReactNode;
}

type NotificateFunction = (msg: string, fn?: () => void) => void;

export const NotificationModalContext = createContext<INotificationModalContextData>(
  {} as INotificationModalContextData,
);

export function NotificationModalProvider({ children }: INotificationModalProviderProps) {
  const [handleNotificationModal, setHandleNotificationModal] = useState<IHandleNotificationModal>({
    isOpen: false,
    message: '',
    onClose: () => setHandleNotificationModal((prevState) => ({
      ...prevState,
      isOpen: false,
    })),
  });

  const closeModal = (fn?: Function) => {
    setHandleNotificationModal((prevState) => ({
      ...prevState,
      isOpen: false,
    }));

    if (fn) {
      fn();
    }
  };

  const notificate: NotificateFunction = (msg, fn = closeModal) => {
    setHandleNotificationModal((prevState) => ({
      ...prevState,
      isOpen: true,
      message: msg,
      onClose: () => closeModal(fn),
    }));
  };

  return (
    <NotificationModalContext.Provider value={{
      notificate,
    }}
    >
      {children}
      <NotificationModal
        {...handleNotificationModal}
      />
    </NotificationModalContext.Provider>
  );
}
