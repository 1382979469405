import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  width: 70%;
  height: calc(100vh - 11rem);
  margin: 0 auto;

  > * {
    margin-top: 4rem;
  }

  table {
    thead tr th:first-child {
      text-align:left;
      padding-left: 3rem;
    }

    thead tr th:last-child {
      width: 20%;
    }
  }
`;
